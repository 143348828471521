import React from 'react';
import get from 'lodash.get';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

import {
  containerTemplate,
  tooltipTemplate,
} from './tooltip/course-engagement-tooltip';
import { colour } from '/styles/variables';

const scale = {
  activeUserPercentage: {
    min: 0,
  },
  completedLessons: {
    min: 0,
  },
};

const titleMap = {
  completedLessons: 'Lessons Completed',
  activeUserPercentage: 'Students Active',
};

const CourseEngagementChart = ({ data, ...chartProps }) => {
  if (!process.browser) {
    return null;
  }
  const renderLine = data.some(
    ({ activeUserPercentage }) => activeUserPercentage !== 0
  );
  return (
    <Chart
      data={data}
      scale={scale}
      padding={[48, 60, 50, 'auto']}
      forceFit
      {...chartProps}
    >
      <Axis
        name="activeUserPercentage"
        grid={null}
        label={{ formatter: (text) => `${text}%` }}
      />
      <Legend
        custom={true}
        clickable={false}
        position="top-left"
        offsetX={5}
        offsetY={-15}
        itemFormatter={(value) => titleMap[value] || ''}
        items={[
          {
            value: 'completedLessons',
            marker: {
              symbol: 'square',
              fill: colour.gray300,
              radius: 5,
            },
          },
          {
            value: 'activeUserPercentage',
            marker: {
              symbol: 'hyphen',
              stroke: colour.blueLight,
              radius: 5,
              lineWidth: 3,
            },
          },
        ]}
      />
      <Tooltip
        crosshairs={{
          type: 'y',
        }}
        itemTpl={tooltipTemplate}
        containerTpl={containerTemplate}
      />
      <Geom
        type="interval"
        position="date*completedLessons"
        color={colour.gray300}
        tooltip={[
          'date*completedLessons',
          (date, completedLessons) => {
            return {
              name: get(titleMap, 'completedLessons') || '',
              title: date,
              value: completedLessons,
              colour: colour.gray300,
            };
          },
        ]}
      />
      {renderLine && (
        <Geom
          type="line"
          position="date*activeUserPercentage"
          color={colour.blueLight}
          size={2}
          tooltip={[
            'date*activeUserPercentage',
            (date, activeUserPercentage) => {
              return {
                name: get(titleMap, 'activeUserPercentage') || '',
                title: date,
                value: `${activeUserPercentage}%`,
                colour: colour.blueLight,
              };
            },
          ]}
        />
      )}
      {renderLine && (
        <Geom
          type="point"
          position="date*activeUserPercentage"
          color={colour.blueLight}
          size={3}
          shape="circle"
        />
      )}
    </Chart>
  );
};

export { CourseEngagementChart };
