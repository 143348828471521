import { Form, Input, DatePicker, Button, InputNumber, Select, Switch } from 'antd';
import gql from 'graphql-tag';
import { get, without } from 'lodash';
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Redirect } from 'react-router';
import styled from 'styled-components';

import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { boxShadow, colour } from '/styles/variables';
import { isEmail } from '/utils/isEmail';
import { useSplitTreatment } from 'splitio-react/lib/hooks/use-split-treatment';

const SEND_ONBOARDING_EMAIL = gql`
  mutation ACG_createInvitations($input: CreateInvitationInput!, $organisationId: String!) {
    ACG_createInvitations(organisationId: $organisationId, input: $input) {
      taskId
      invitations {
        id
      }
    }
  }
`;

const CREATE_ORGANISATION_MUTATION = gql`
  mutation createOrganisation($input: ACG_CreateSubscriptionOrganisationInput) {
    ACG_createSubscriptionOrganisation(input: $input) {
      organisation {
        id
        name
        chargeModel
        accountType
        isReseller
        subscription {
          startDate
          endDate
          userLicenseCount
        }
      }
    }
  }
`;

const CreateOrganisationForm = ({ form }) => {
  const setResellerFlag = useSplitTreatment('BERNIE_SET_RESELLERS');
  const canSetResellerFlag = setResellerFlag === 'on';
  const [createdOrganisationId, setCreatedOrganisationId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [createOrganisation] = useMutation(CREATE_ORGANISATION_MUTATION);
  const [sendOnboardingEmail] = useMutation(SEND_ONBOARDING_EMAIL);

  const handleCreateOrganisation = async event => {
    event.preventDefault();
    setSubmitting(true);

    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        console.error(err);
        setSubmitting(false);
        return;
      }

      try {
        const result = await createOrganisation({
          variables: {
            input: {
              name: get(fieldsValue, 'name'),
              accountType: get(fieldsValue, 'accountType'),
              startDate: get(fieldsValue, 'startDate').toISOString(),
              endDate: get(fieldsValue, 'endDate').toISOString(),
              userLicenseCount: get(fieldsValue, 'userLicenseCount'),
              packageId: get(fieldsValue, 'packageId'),
              isReseller: get(fieldsValue, 'isReseller', false),
            }
          }
        });

        const organisationId = get(result, 'data.ACG_createSubscriptionOrganisation.organisation.id');

        const adminEmails = get(fieldsValue, 'adminEmails');

        if (get(adminEmails, 'length')) {
          await sendOnboardingEmail({
            variables: {
              organisationId,
              input: {
                emails: adminEmails,
                options: {
                  inviteAsAdmin: true,
                  onboarding: true
                }
              }
            }
          });
        }

        setSubmitting(false);
        setCreatedOrganisationId(organisationId);
      } catch (err) {
        setSubmitting(false);
        setErrorMessage(err.message);
      }
    });
  };

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const validEmails = (rule, value = [], callback) => {
    const invalidEmails = value.filter(email => !isEmail(email));

    if (invalidEmails.length > 0) {
      setFieldsValue({ adminEmails: without(value, ...invalidEmails) });
    }
    callback();
  };

  if (createdOrganisationId) {
    return <Redirect to={`/organizations/${createdOrganisationId}/details`} />;
  }

  return (
    <Form
      onSubmit={handleCreateOrganisation}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Organization Name</InputLabel>
        <InputField>
          {getFieldDecorator('name', {
            rules: [{
              required: true,
              message: 'Please supply an organization name'
            }]
          })(
            <Input />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Package</InputLabel>
        <InputField>
          {getFieldDecorator('packageId', {
            rules: [{
              required: true,
              message: 'Please select a package'
            }]
          })(
            <Select placeholder="Select a package" >
              <Select.Option value="b2b-business-basic-2020-aug">Business Basic</Select.Option>
              <Select.Option value="b2b-business-plus-2020-aug">Business Plus</Select.Option>
            </Select>
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Account Type</InputLabel>
        <InputField>
          {getFieldDecorator('accountType', {
            initialValue: 'PAYING',
            rules: [{
              required: true
            }]
          })(
            <Select>
              <Select.Option value="PAYING">Paying</Select.Option>
              <Select.Option value="TRIAL">Trial</Select.Option>
              <Select.Option value="FREEBIE">Freebie</Select.Option>
              <Select.Option value="INTERNAL">Internal</Select.Option>
              <Select.Option value="TEST">Test</Select.Option>
            </Select>
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>User Licenses Count</InputLabel>
        <InputField>
          {getFieldDecorator('userLicenseCount', {
            rules: [{
              type: 'number', message: 'Invalid license count'
            }, {
              required: true, message: 'Please supply a license count'
            }]
          })(
            <InputNumber />
          )}
        </InputField>
      </FormItem>
      {canSetResellerFlag && <FormItem>
        <InputLabel>Will distribute organization seats</InputLabel>
        <InputField>
          {getFieldDecorator('isReseller', {
            initialValue: false
          })(
            <StyledSwitch
              defaultChecked={false}
              checkedChildren='YES'
              unCheckedChildren='NO'
            />
          )}
        </InputField>
      </FormItem>}
      <FormItem>
        <InputLabel>Subscription Start Date</InputLabel>
        <InputField>
          {getFieldDecorator('startDate', {
            rules: [
              {
                required: true,
                message: 'Please supply a subscription start date'
              }
            ]
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={startDate => {
                const endDate = getFieldValue('endDate');
                return startDate > endDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Subscription End Date</InputLabel>
        <InputField>
          {getFieldDecorator('endDate', {
            rules: [
              {
                required: true,
                message: 'Please supply a subscription end date'
              }
            ]
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={endDate => {
                const startDate = getFieldValue('startDate');
                return endDate < startDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Admin Email Address</InputLabel>
        <InputField>
          {getFieldDecorator('adminEmails', {
            rules: [{
              required: true,
              message: 'Please add emails you wish to send invitations to.',
              validator: validEmails
            }]
          })(
            <EmailList
              autoFocus
              placeholder="Enter comma separated emails or copy and paste in a list"
              mode="tags"
              dropdownStyle={{ display: 'none' }}
              tokenSeparators={[',', ' ']}
            />
          )}
          <Tip>These users will be invited as admins with student access</Tip>
        </InputField>
      </FormItem>
      <Footer>
        <Button type="primary" htmlType="submit" loading={submitting}>
          {submitting ? 'Creating' : 'Create'}
        </Button>
      </Footer>
    </Form>
  );
};

const StyledSwitch = styled(Switch)`
  padding: 0 2px;
`;

const InputField = styled.div`
  flex: 3;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 220px;
`;

const Tip = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colour.gray500};
  line-height: 1;
  margin-top: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
`;

const EmailList = styled(Select)`
  .ant-select-selection {
    border-color: ${props => props.invalid ? colour.red : colour.gray300};
    box-shadow: ${boxShadow.inset};
    min-height: 206px;
    max-height: 420px;
    overflow-y: auto;
  }

  .ant-select-selection__choice {
    color: ${colour.gray900};
    background-color: ${colour.gray100};
    font-weight: 500;
    font-size: 13px;
    border-radius: 3px;
  }

  .ant-form-item-control {
    line-height: 1;
  }
`;

const ComposedCreateOrganisationForm = Form.create()(CreateOrganisationForm);

export {
  ComposedCreateOrganisationForm as CreateOrganisationForm
};
