import { Spinner } from '@a-cloud-guru/kermit';
import get from 'lodash.get';
import React, { useState } from 'react';
import uuid from 'uuid/v4';

import { DateFormat } from '/components/date/DateFormat';
import { TableState } from '/components/table/TableState';
import { PurchasesQuery } from '/data/query';
import { convertStringTimestamp } from '/utils/convertStringTimestamp';
import { StyledTable } from '/components/table'

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'Display Name',
    dataIndex: 'buyerDisplayName'
  },
  {
    title: 'Full Name',
    dataIndex: 'buyerName'
  },
  {
    title: 'Product',
    dataIndex: 'description'
  },
  {
    title: 'Value',
    render: ({ total, currency }) => (
      <React.Fragment>
        {`${total} ${currency}`}
      </React.Fragment>
    )
  },
  {
    title: 'Time',
    dataIndex: 'purchasedTimestamp',
    render: purchasedTimestamp => (
      <DateFormat format='D MMM YYYY, hh:mm:ss A'>
        {convertStringTimestamp(purchasedTimestamp) || '-'}
      </DateFormat>
    )
  }
];

const LegacyTransactions = ({ organisationId }) => {
  const [page, setPage] = useState(0);

  return (
    <React.Fragment>
      <PurchasesQuery page={page} pageSize={PAGE_SIZE} orgId={organisationId}>
        {({ data, loading, error }) => {
          const dataSource = get(data, 'data');
          const totalCount = get(data, 'totalCount');
          const emptyState = data.length === 0;

          return (
            <StyledTable
              rowKey={({ userId, purchasedTimestamp, description }) => `${userId}-${purchasedTimestamp}-${description}-${uuid()}`}
              columns={columns}
              dataSource={dataSource}
              onChange={pagination => setPage(pagination.current - 1)}
              pagination={{
                current: page + 1,
                pageSize: PAGE_SIZE,
                total: totalCount
              }}
              loading={{
                indicator: <Spinner size="2rem" />,
                spinning: loading && !emptyState
              }}
              locale={{
                emptyText: (
                  <TableState
                    title="No transactions found"
                    loading={loading && emptyState}
                    error={!!error}
                  />
                )
              }}
            />
          );
        }}
      </PurchasesQuery>
    </React.Fragment>

  );
};


export { LegacyTransactions };
