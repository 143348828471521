import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { Box } from '/components/box';
import { colour } from '/styles/variables';

const SettingState = ({ active }) => (
  <Box width="45px">
    {
      active
        ? <Enabled type="check" />
        : <Disabled type="close" />
    }
  </Box>
);

const Enabled = styled(Icon)`
  width: 5px;
  height: 5px;
  color: ${colour.green};
  font-size: 14px;
  font-weight: 600;
`;

const Disabled = styled(Icon)`
  width: 5px;
  height: 5px;
  color: ${colour.gray400};
  font-size: 13px;
`;

export { SettingState };
