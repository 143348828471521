import get from 'lodash.get';
import React from 'react'
import { Input, Icon } from 'antd';
import { lighten } from 'polished';
import moment from 'moment';
import styled from 'styled-components';
import { DATE_FORMAT } from './config';
import { boxShadow, colour } from '../styles/variables';

class DateInput extends React.Component {
  state = { input: undefined };

  componentDidUpdate(prevProps) {
    if (get(this, 'props.value') !== prevProps.value) {
      this.setState({ input: this.props.value.format(DATE_FORMAT) });
    }
  }

  parse = (dateStr) => {
    const date = moment(dateStr, DATE_FORMAT, true);

    if (date.isValid()) {
      return date;
    }

    return undefined;
  }

  formatDate = (value) => {
    if (!value || !value.isValid()) {
      return undefined;
    }

    return value.format(DATE_FORMAT);
  }

  handleInputChange = (e) => {
    const value = e.target.value;
    this.setState({ input: value });

    const date = this.parse(value);

    if (date && this.props.validate(date)) {
      this.props.setDate(date);
    }
  }

  handleDateParse = (value) => {
    return this.parse(value);
  }

  render() {
    const { input } = this.state;
    const { setDate, validate } = this.props;

    return (
      <StyledInput
        value={input}
        suffix={<CalendarIcon type="calendar" />}
        onChange={this.handleInputChange}
        onPressEnter={(e) => {
          const { value } = e.target;
          const parsedDate = this.handleDateParse(value);

          if (parsedDate && validate(parsedDate)) {
            return setDate(parsedDate);
          }

          return this.setState({ input: this.formatDate(this.props.value) });
        }}
      />
    );
  }
}

const StyledInput = styled(Input)`
  width: 150px;

  .ant-input-affix-wrapper:hover
  .ant-input:not(.ant-input-disabled),
  .ant-input-affix-wrapper:focus
  .ant-input:not(.ant-input-disabled) {
    :focus, :hover {
      border-color: ${colour.gray400};
    }
  }

  input {
    color: ${colour.gray900};
    box-shadow: ${boxShadow.inset};
    border: solid 1px ${lighten(0.15, colour.gray400)};

    :focus, :hover {
      border-color: ${colour.gray400};
      box-shadow: ${boxShadow.inset};
    }
  }
`;

const CalendarIcon = styled(Icon)`
  color: ${colour.gray400};
`;

export { DateInput };
