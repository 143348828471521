import styled from 'styled-components';

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px 16px 16px;
  border-bottom: solid 1px #e2e7f3;
`;

export { ListItem };
