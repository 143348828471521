import React from 'react';
import gql from 'graphql-tag';
import { get, keyBy } from 'lodash';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_USER_QUIZ_ATTEMPT_STATS_QUERY = (attributes) => gql`
  query ACG_organisationQuizSummaryReport(
    $organisationId: String!,
    $courseId: String!
  ) {
    ACG_organisationQuizSummaryReport (
      organisationId: $organisationId,
      filters: {
        courseId: $courseId
      }
    ) ${attributes}
    courseOverview(courseId: $courseId) {
      title
      sections {
        title
        sequence
        components {
          title
          content {
            type
            ... on QuizContent {
              quizName
            }
          }
        }
      }
    }
  }
`;

const getQuizzes = (sections) => {
  if (!sections) {
    return [];
  }
  const quizzes = sections.reduce((totalQuizzes, section) => {
    const quizComponent = section.components.find(component => get(component, 'content.type') === 'quiz');

    if (!quizComponent) {
      return totalQuizzes;
    }
    return [...totalQuizzes, {
      quizId: get(quizComponent, 'content.quizName'),
      quizTitle: quizComponent.title,
      sectionTitle: section.title,
      sectionSequence: section.sequence
    }];
  }, []);

  return quizzes;
};

const mergeResponse = ({ attemptStats, sections }) => {
  const quizzes = getQuizzes(sections);

  const quizMap = keyBy(quizzes, 'quizId');

  return attemptStats.map(attemptStat => ({
    ...attemptStat,
    quizTitle: get(quizMap, `${attemptStat.quizId}.quizTitle`) || attemptStat.quizTitle,
    sectionSequence: get(quizMap, `${attemptStat.quizId}.sectionSequence`),
    sectionTitle: get(quizMap, `${attemptStat.quizId}.sectionTitle`) || attemptStat.quizTitle
  })).sort((a, b) => a.sectionSequence - b.sectionSequence);
};

const OrganisationUserQuizAttemptStatsQuery = ({
  organisationId,
  courseId,
  attributes = `{
    id
    quizId
    quizTitle
    averageBestScore
    averageScore
    numberOfUsers
    numberOfAttempts
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
  <Query
    query={ACG_ORGANISATION_USER_QUIZ_ATTEMPT_STATS_QUERY(attributes)}
    variables={{
      organisationId,
      courseId
    }}
    {...restProps}
  >
    {props => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data, ...rest }) => {
          const attemptStats = get(data, 'ACG_organisationQuizSummaryReport');
          const sections = get(data, 'courseOverview.sections');

          return children({
            data: mergeResponse({ attemptStats, sections }),
            ...rest
          });
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { OrganisationUserQuizAttemptStatsQuery };
