import { darken } from 'polished';
import styled from 'styled-components';

import { colour } from '../styles/variables';
import { PrimaryButton } from './PrimaryButton';

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${colour.blue};

  :hover {
    background-color: ${darken(0.02, colour.blue)};
  }

  :focus {
    background-color: ${colour.blue};
  }
`;

export { SecondaryButton };
