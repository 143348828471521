import React from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from 'antd';
import { Spinner } from '@a-cloud-guru/kermit';

import { Box } from '/components/box';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Container } from '/components/container';
import { StyledCard, StyledDivider, DetailsRow, Header } from './Shared';
import { UpdateSamlConnectionDetailsAction } from './update-saml-connection-details/UpdateSamlConnectionDetailsAction';
import { RemoveSamlConnectionAction } from './remove-saml-connection/RemoveSamlConnectionAction';

export const SamlConnectionDetailsCard = (organisation) => {
  const { connectionName, companyCode, features, loading: orgLoading } = organisation;
  const { allowInvitations, allowUserReactivationViaSAML } = features || {};

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>SAML / SSO Connection Details</StyledTitle>
        <DisabledWhenReadOnly>
          <StyledActionBar>
            <UpdateSamlConnectionDetailsAction organisation={organisation} />
            {connectionName && <RemoveSamlConnectionAction {...organisation} />}
          </StyledActionBar>
        </DisabledWhenReadOnly>
      </StyledHeader>

      <StyledDivider />

      <Box py="6px">
        {
          orgLoading ?
            <Container><Spinner /></Container> :
            <>
              <DetailsRow label="SAML SSO Enabled">
                {connectionName ? 'Yes' : 'No'}
              </DetailsRow>

              <DetailsRow label="Auth0 Connection Name">{connectionName}</DetailsRow>
              <DetailsRow label="Company Code">{companyCode}</DetailsRow>
              <DetailsRow
                label={
                  <>
                  Allow Email Invitations{' '}
                    <Tooltip title="Controls whether invitations tab is visible on business.acloud.guru (This is disabled by default for SAML Organizations)">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </>
                }
              >
                {allowInvitations ? 'Yes' : 'No'}
              </DetailsRow>
              <DetailsRow label={
                <>
                Allow Reactivation{' '}
                  <Tooltip title="Controls whether users can be reactivated via SAML login (This is disabled by default for SAML Organizations)">
                    <Icon type="question-circle" />
                  </Tooltip>
                </>
              }
              >{allowUserReactivationViaSAML ? 'Yes' : 'No'}</DetailsRow>
            </>
        }
      </Box>
    </StyledCard>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
  text-transform: uppercase;
`;

const StyledActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 210px;
`;
