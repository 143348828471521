import React from 'react';
import { Box } from '@a-cloud-guru/kermit';
import { TeamsTable } from './TeamsTable';

const OrganisationTeams = ({ organisationId }) => (
  <Box mt='-64px'>
    <TeamsTable organisationId={organisationId} />
  </Box>
);

export { OrganisationTeams };
