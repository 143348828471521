import { get } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { AlgoliaContext } from '/providers/algolia/AlgoliaContext';

const useAlgoliaIndex = ({ type, replica }) => {
  const [algoliaIndex, setAlgoliaIndex] = useState(null);
  const algoliaContext = useContext(AlgoliaContext);
  const index = get(algoliaContext, `${type}.replicas.${replica}`)
    || get(algoliaContext, `${type}.default`)
    || get(algoliaContext, type);

  useEffect(() => {
    if (index) {
      setAlgoliaIndex(index);
    }
  }, [type, index, replica]);

  return algoliaIndex;
}

export { useAlgoliaIndex };