import React from 'react';

import { colour } from '@a-cloud-guru/themes';

const Status = {
  PENDING: 'pending',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};

class ProgressiveImage extends React.Component {
  static defaultProps = {
    preload: () => { }
  }

  constructor(props) {
    super(props);
    this.state = { status: props.src ? Status.LOADING : Status.PENDING };
  }

  componentDidMount() {
    if (this.state.status === Status.LOADING) {
      this.createLoader();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({
        status: nextProps.src ? Status.LOADING : Status.PENDING
      });
    }
  }

  componentDidUpdate() {
    if (this.state.status === Status.LOADING && !this.img) {
      this.createLoader();
    }
  }

  componentWillUnmount() {
    this.destroyLoader();
  }

  createLoader() {
    this.destroyLoader();

    this.img = new Image();
    this.img.onload = (e) => this.handleLoad(e);
    this.img.onerror = (e) => this.handleError(e);
    this.img.src = this.props.src;

    this.img.srcset = this.props.srcSet || this.props.src;
  }

  destroyLoader() {
    if (this.img) {
      this.img.onload = null;
      this.img.onerror = null;
      this.img = null;
    }
  }

  handleLoad(event) {
    this.destroyLoader();
    this.setState({ status: Status.LOADED });

    if (this.props.onLoad) this.props.onLoad(event);
  }

  handleError(error) {
    this.destroyLoader();
    this.setState({ status: Status.FAILED });

    if (this.props.onError) this.props.onError(error);
  }

  render() {
    const { src, srcSet, wrapperProps, children, fallback, preload } = this.props;
    return (
      <div {...wrapperProps}>
        {this.state.status === Status.LOADED &&
          React.cloneElement(children, { src, srcSet })
        }
        {this.state.status === Status.FAILED && (
          fallback ? fallback() : React.cloneElement(children, { style: { background: `${colour.brandPastel}` }, srcSet })
        )}
        {(this.state.status === Status.LOADING || this.state.status === Status.PENDING) &&
          preload()
        }
      </div>
    )
  }
};

export default ProgressiveImage;