import React from 'react';
import get from 'lodash.get';
import { colour } from '@a-cloud-guru/kermit';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

const scale = {
  'activeUserPercentage': {
    min: 0
  },
  'completedLessons': {
    min: 0
  }
};

const titleMap = {
  completedLessons: 'Completed Lessons',
  activeUserPercentage: '% Active Users'
};

const LessonEngagementChart = ({ data, onLoad }) => {
  if (!process.browser) {
    return null;
  }

  return (
    <Chart
      height={400}
      data={data}
      scale={scale}
      padding={[55, 50, 30, 50]}
      onGetG2Instance={onLoad}
      forceFit
    >
      <Axis
        name="activeUserPercentage"
        grid={null}
        label={{
          formatter: (text) => `${text}%`
        }}
      />
      <Legend
        custom={true}
        clickable={false}
        position="top-left"
        offsetX={10}
        offsetY={-10}
        itemFormatter={value => {
          return titleMap[value] || '';
        }}
        items={[
          {
            value: "completedLessons",
            marker: {
              symbol: "square",
              fill: colour.gray500,
              radius: 5
            }
          },
          {
            value: "activeUserPercentage",
            marker: {
              symbol: "hyphen",
              stroke: colour.blueLight,
              radius: 5,
              lineWidth: 3
            }
          }
        ]}
      />
      <Tooltip
        crosshairs={{
          type: "y"
        }}
      />
      <Geom
        type="interval"
        position="date*completedLessons"
        color={colour.gray300}
        tooltip={['date*completedLessons', (date, completedLessons) => {
          return {
            name: get(titleMap, 'completedLessons') || '',
            title: date,
            value: completedLessons
          }
        }]}
      />
      <Geom
        type="line"
        position="date*activeUserPercentage"
        color={colour.blueLight}
        size={2}
        tooltip={['date*activeUserPercentage', (date, activeUserPercentage) => {
          return {
            name: get(titleMap, 'activeUserPercentage') || '',
            title: date,
            value: `${activeUserPercentage}%`
          }
        }]}
      />
      <Geom
        type="point"
        position="date*activeUserPercentage"
        color={colour.blueLight}
        size={3}
        shape="circle"
      />
    </Chart>
  );
};

export { LessonEngagementChart };