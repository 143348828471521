import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';
import urlJoin from 'url-join';

import { getConfig } from '../config/get-config';

const { AUTH0_DOMAIN, AUTH0_CUSTOM_DOMAIN, AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, ACG_BERNIE_DOMAIN } = getConfig();

const authService = makeAuthService({
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  AUTH0_CLIENT_ID,
  DEFAULT_REDIRECT_URI: urlJoin(ACG_BERNIE_DOMAIN, 'login', 'callback')
});

export {
  authService
};
