import { useAlgoliaIndex } from '/hooks/use-algolia-index';

const makeBrowseAll = ({
  browse,
  browseFrom
}) => async (query = '', queryParams = {
  hitsPerPage: 1000
}) => {
    const results = [];

    const { cursor, hits } = await browse(query, queryParams);

    results.push(...hits);

    if (!cursor) {
      return results;
    }

    const moveNext = async (cursor) => {
      const { cursor: nextCursor, hits: nextHits } = await browseFrom(cursor, queryParams);

      results.push(...nextHits);

      const shouldStop = !nextCursor;

      if (shouldStop) {
        return;
      }

      return moveNext(nextCursor);
    };

    const runUntil = () => {
      return moveNext(cursor);
    };

    await runUntil();

    return results;
  };

const useAlgoliaBrowseAll = ({
  type,
  replica,
  query = '',
  filters
}) => {
  const index = useAlgoliaIndex({ type, replica });

  const browseAll = makeBrowseAll({
    browse: (query = '', queryParams) => index.browse(query, queryParams),
    browseFrom: (cursor) => index.browseFrom(cursor)
  });

  return {
    browse: index ? () => browseAll(query, { hitsPerPage: 1000, filters: filters }) : () => Promise.resolve([])
  };
};

export { useAlgoliaBrowseAll };