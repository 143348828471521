import { Form, Input, InputNumber, DatePicker, Button, Switch, Select } from 'antd';
import get from 'lodash.get'
import moment from 'moment';
import gql from 'graphql-tag';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '/lib/hooks';
import { useLazyQuery } from 'react-apollo';

import { authService } from '/services/auth';
import { Box, Flex } from '/components/box';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { GET_ACG_SUBSCRIBER_PACKAGE } from './../../../../data/query/PackageQuery';

const CONVERT_TO_DIRECT = gql`
  mutation ($input: ACGDEV_convertToDirectSaleInput!) {
    ACGDEV_convertToDirectSale (input: $input) {
        organisation {
            id
        }
    }
  }
`;

const ConvertToSubscriptionForm = ({
  organisation,
  closeModal,
  form
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [extendSubscription, setExtendSubscription] = useState(!!get(organisation, "subscription.endDateOverride"));
  const convertToDirect = useMutation({
    mutation: CONVERT_TO_DIRECT
  });
  const [getSubscriberPackage, { data: packageData, packageLoading }] = useLazyQuery(GET_ACG_SUBSCRIBER_PACKAGE);
  const organisationId = get(organisation, 'id');

  const packageId = get(
    packageData,
    'ACG_Packaging_subscriberPackage.subscriberPackage.packageId'
  );

  useEffect(() => {
    if (packageId) {
      setFieldsValue({packageId});
    }
  }, [packageId]);

  useEffect(() => {
    async function getUserId() {
      const userId = await authService.getUserIdFromToken();
      getSubscriberPackage({
        variables: {
          input: {
            organisationId,
            userId,
          },
        },
      });
    }
    getUserId();
  }, []);

  const handleSelectChange = (value) => {
    form.setFieldsValue({packageId: value});
  };

  const updateSubscription = async (event) => {
    event.preventDefault();
    const checkValues = () => new Promise((resolve) => {
      form.validateFields((err) => {
        resolve(err ? false : true);
      });
    });
    const result = await checkValues();
    if (!result) return;
    setSubmitting(true);

    const input = {
      organisationId,
      subscriptionStartDate: getFieldValue('startDate').toISOString(),
      subscriptionEndDate: getFieldValue('endDate').toISOString(),
      subscriptionUserLicenseCount: getFieldValue('userLicenseCount'),
      endDateOverride: extendSubscription ? getFieldValue('endDateOverride').toISOString() : "",
      endDateOverrideReason: extendSubscription ? getFieldValue('endDateOverrideReason') : "",
      packageId: getFieldValue('packageId')
    };
    
    await convertToDirect({
      variables: { input }
    }).catch((e) => {
      setErrorMessage(e.message);
      setSubmitting(false);
    }).then(() => {
      closeModal();
      window.location.reload();
    });
  }

  return (
    <Form
      onSubmit={updateSubscription}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Contract Start Date</InputLabel>
        <InputField>
          {getFieldDecorator('startDate', {
            initialValue: moment(get(organisation, 'subscription.startDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the start date for this organization subscription',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={startDate => {
                const endDate = getFieldValue('endDate');
                return startDate > endDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Contract End Date</InputLabel>
        <InputField>
          {getFieldDecorator('endDate', {
            initialValue: moment(get(organisation, 'subscription.endDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the end date for this organization subscription',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={endDate => {
                const startDate = getFieldValue('startDate');
                return endDate < startDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Extended End Date</InputLabel>
        <InputField>
          <Flex flexDirection="column" position="relative" top="-6px">
            <Box mb="8px">
              <Switch
                defaultChecked={extendSubscription}
                onChange={e => setExtendSubscription(e)}
              />
            </Box>
            {
              extendSubscription && (
                <>
                  {getFieldDecorator('endDateOverride', {
                    initialValue: moment(get(organisation, 'subscription.endDateOverride') || new Date()),
                    rules: [
                      {
                        required: extendSubscription,
                        message: 'Please give the date to extend this organisation subscription by',
                      }
                    ],
                  })(
                    <StyledDatePicker
                      format="LL"
                      allowClear={false}
                      disabled={!extendSubscription}
                      disabledDate={endDate => {
                        const startDate = getFieldValue('startDate');
                        return endDate < startDate;
                      }}
                    />
                  )}
                  <Box mt="2px">
                    {getFieldDecorator('endDateOverrideReason', {
                      initialValue: get(organisation, 'subscription.endDateOverrideReason') || '',
                      rules: [
                        {
                          required: extendSubscription,
                          message: 'Please give a reason for extending the subscription',
                        }
                      ],
                    })(
                      <Input disabled={!extendSubscription} placeholder={extendSubscription ? "Please give a reason" : ""} />
                    )}
                  </Box>
                </>
              )
            }
          </Flex>
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Licensed User Count</InputLabel>
        <InputField>
          {getFieldDecorator('userLicenseCount', {
            initialValue: get(organisation, 'subscription.userLicenseCount') || 0,
            rules: [{
              type: 'number', message: 'The input is not valid license count',
            }, {
              required: true, message: 'Please input the organization license count',
            }],
          })(
            <InputNumber />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        {getFieldDecorator('packageId', {
          rules: [{ required: true, message: 'Please a package' }],
        })(
          <Select
            loading={packageLoading}
            placeholder="Select a package"
            onChange={handleSelectChange}
          >
            <Select.Option value="b2b-business-basic-2020-aug">Business Basic</Select.Option>
            <Select.Option value="b2b-business-plus-2020-aug">Business Plus</Select.Option>
          </Select>,
        )}
      </FormItem>
      <Footer>
        <span>{errorMessage}</span>
        <Button type="primary" htmlType="submit" loading={submitting}>
          {submitting ? 'Updating' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
}

const InputField = styled.div`
  flex: 3;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 220px;
`;

export const DirectSalesForm = Form.create()(ConvertToSubscriptionForm);

