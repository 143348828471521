import { Modal } from 'antd';
import { darken, lighten } from 'polished';
import styled from 'styled-components';
import get from 'lodash.get';
import { colour, brandFont } from '../styles/variables';

const StyledModal = styled(Modal)`
  padding: calc(100vh / 12) 0;

  .ant-modal-body {
    padding: 22px 24px 14px;
  }

  .ant-modal-footer {
    border: 0;
    padding: 0 24px 24px;
  }

  .ant-btn {
    border: 0;
    padding: 0 18px;
    color: ${colour.gray900};
    background-color: ${colour.gray100};

    &:hover {
      background-color: ${darken(0.03, colour.gray100)};
    }
  }

  .ant-btn-primary {
    color: #fff;
    background-color: ${props => lighten(0.03, get(colour, props.primaryButton || 'blueLight'))};

    &:hover,
    &:focus {
      color: #fff;
      background-color: ${props => darken(0.03, get(colour, props.primaryButton || 'blueLight'))};
    }
  }
`;

const Content = styled.div`
  font-size: 15px;
  font-weight: 350;
  color: ${colour.gray600};
  padding-bottom: 8px;
`;

const Title = styled.h1`
  font-size: 22px;
  font-family: ${brandFont};
  font-weight: 650;
  line-height: 1.6rem;
  margin: 0 0 6px;
  color: ${colour.blueDark};
`;

const Subtitle = styled.div`
  padding: 0 12px 20px 0;
`;

export { StyledModal, Title, Subtitle, Content };
