import { Button } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CreateOrganisationForm } from './CreateOrganisationForm';
import { StyledModal, FormContent, Title } from '/components/modal';
import { boxShadow, colour } from '/styles/variables';
import { isReadOnly } from '../../hooks/isReadOnly';

const CreateOrganisation = ({ isClickDisabled }) => {
  const [visible, setVisible] = useState(false);
  const readOnly = isReadOnly();

  return (
    <React.Fragment>
      <StyledButton onClick={() => setVisible(!readOnly)}>
        <AddIcon>+</AddIcon>Create Organization
      </StyledButton>
      <StyledModal
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
      >
        <FormContent>
          <TitleContainer>
            <Title>Create Organization</Title>
          </TitleContainer>
          <CreateOrganisationForm />
        </FormContent>
      </StyledModal>
    </React.Fragment>
  );
};

const AddIcon = styled.span`
  position: relative;
  top: -1px;
  font-weight: 800;
  padding: 0 6px 0 0;
`;

const StyledButton = styled(Button)`
  padding: 10px 14px;
  line-height: 1;
  background-image: linear-gradient(to top, ${colour.hover}, #ffffff);
  box-shadow: ${boxShadow.subtle};
  height: fit-content;
  font-weight: 600;
  color: ${colour.gray900};
  border: solid 1px ${colour.gray400};

  :focus,
  :hover {
    border-color: ${colour.gray500};
    background-image: linear-gradient(to top, ${colour.hover}, #ffffff);
    box-shadow: ${boxShadow.standard};
  }
`;

const TitleContainer = styled.div`
  padding: 18px 20px;
  border-bottom: solid 1px ${colour.gray300};
`;

export { CreateOrganisation };
