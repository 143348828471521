import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query, QueryBoundary } from '/components/data';

const ACG_ORGANISATION_USERS_JOINED_BY_ORGANISATION_QUERY = (attributes) => gql`
  query ACG_organisationUsersJoinedByOrganisation($organisationId: String!) {
    ACG_organisationUsersJoinedByOrganisation(organisationId: $organisationId) ${attributes}
  }
`;

const OrganisationUsersJoinedByOrganisationQuery = ({
  attributes = `{
    id
    joinedAt
    joinMethod
  }`,
  organisationId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
    <Query
      variables={{
        organisationId,
      }}
      query={ACG_ORGANISATION_USERS_JOINED_BY_ORGANISATION_QUERY(attributes)}
      {...restProps}>
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              users: get(data, 'ACG_organisationUsersJoinedByOrganisation') || [],
              ...rest,
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );

export { OrganisationUsersJoinedByOrganisationQuery };
