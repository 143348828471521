import React from 'react';
import { Box, Flex } from '/components/box';
import { AccountDetailsCard } from './AccountDetailsCard';
import { PackageDetailsCard } from './PackageDetailsCard';
import { SubscriptionCard } from './SubscriptionCard';
import { SamlConnectionDetailsCard } from './SamlConnectionDetailsCard';
import { InvitationCard } from './InvitationCard';
import { SettingCard } from './SettingCard';
import { FeatureRequestCard } from './FeatureRequestCard';
import { LicenseReallocationTrackingCard } from './LicenseReallocationTrackingCard';
import { MarketplaceEventHistory } from './MarketplaceEventHistory';
import { SelfServiceBillingHistory } from './SelfServiceBillingHistory';
import { SelfServiceBillingInvoices } from './SelfServiceBillingInvoices';
import get from 'lodash.get';

const OrganisationDetails = (organisation) => {
  const chargeModel = get(organisation, 'chargeModel');
  const trackLicenseReallocations = get(organisation, 'features.trackLicenseReallocations') || false;

  return (
    <Flex mt="-64px">
      <Box flex="2" mr="32px">
        <AccountDetailsCard {...organisation} />
        <SubscriptionCard {...organisation} />
        { trackLicenseReallocations && <LicenseReallocationTrackingCard {...organisation} /> }
        <SamlConnectionDetailsCard {...organisation} />
        <PackageDetailsCard {...organisation} />
        <InvitationCard {...organisation} />
      </Box>
      <Box flex="3">
        {chargeModel === 'marketplace' && (
          <MarketplaceEventHistory {...organisation} />
        )}
        {chargeModel === 'selfservice' && (
          <SelfServiceBillingHistory {...organisation} />
        )}
        {chargeModel === 'selfservice' && (
          <SelfServiceBillingInvoices {...organisation} />
        )}
        <SettingCard {...organisation} />
        {get(organisation, 'betaFeatureRequests') && (
          <FeatureRequestCard {...organisation} />
        )}
      </Box>
    </Flex>
  );
};

export { OrganisationDetails };
