const calculatePercentage = (numerator, denominator, decimal = 0) => {
  return (
    Math.round((numerator * 100 * Math.pow(10, decimal)) / denominator) / 100
  );
};

const calculateDifferenceInPercentage = (original, current) => {
  if (current === 0 && original === 0) {
    return 0;
  }

  if (current === 0 && original > 0) {
    return -(original * 100);
  }

  const denominator = original === 0 ? 1 : original;
  return calculatePercentage(current - original, denominator, 2);
};

export { calculatePercentage, calculateDifferenceInPercentage };
