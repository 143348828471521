import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components'

import { colour } from '/styles/variables';

const Footer = () => (
  <TeamsFooter>
    <span>
      <Trademark>© {new Date().getFullYear()} A Cloud Guru Ltd.</Trademark>
      <a href="https://acloud.guru/code-of-conduct" target="_blank" rel="noopener noreferrer">
        Code of Conduct
      </a>
      <a href="https://acloud.guru/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      <a href="https://acloud.guru/terms-of-use" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>
    </span>
    Keep being awesome, Cloud Gurus
  </TeamsFooter>
);

const TeamsFooter = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 0 60px 24px 60px;
  font-size: 13px;
  color: ${lighten(0.1, colour.gray600)};

  a {
    padding: 0 12px 0 0;
    text-decoration: underline;
    color: ${lighten(0.1, colour.gray600)};
  }
`;

const Trademark = styled.span`
  font-weight: 450;
  padding: 0 20px 0 0;
`;

export { Footer };
