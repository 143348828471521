import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { ResendModal } from './ResendModal';
import { TableAction } from '/components/table/TableAction';

const RESEND_INVITATION_MUTATION = gql`
  mutation ACG_resendOrganisationInvitation($code: String!) {
    ACG_resendOrganisationInvitation(code: $code) {
      invitation {
        id
        email
        createdAt
        sentAt
        updatedAt
        status
      }
    }
  }
`;

class ResendInvite extends React.PureComponent {
  state = {
    loading: false,
    modalVisible: false
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  onCancel = () => {
    this.setState({ modalVisible: false });
  };

  resend = async ({ invitationCode }) => {
    this.setState({ loading: true });

    await this.props.resendInvitation({ variables: { code: invitationCode } });
    this.onCancel();
  };

  render() {
    const { invitationCode, email } = this.props;

    return (
      <React.Fragment>
        <TableAction
          onClick={() => {
            this.showModal();
          }}
          last={false}
        >
          Resend
        </TableAction>
        <ResendModal
          loading={this.state.loading}
          mutationCallback={() => this.resend({ invitationCode })}
          onCancel={this.onCancel}
          onClose={() => this.props.addResentEmail({ email })}
          email={email}
          visible={this.state.modalVisible}
        />
      </React.Fragment>
    );
  }
};

const ResendInviteWithMutation = graphql(
  RESEND_INVITATION_MUTATION, { name: 'resendInvitation' }
)(ResendInvite);

export { ResendInviteWithMutation as ResendInvite };
