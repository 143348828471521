import { colour } from '@a-cloud-guru/themes';
import React from 'react';
import styled from 'styled-components';

import Flex from '../flex';
import Spinner from '../spinner';
import ErrorStatus from '../error-status';

class TableState extends React.PureComponent {
  static defaultProps = {
    loading: false,
    error: false,
    title: 'No results found',
    subtitle: ''
  }

  renderContainerBody = () => {
    const { error, loading, title, subtitle } = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorStatus message="Something went wrong while trying to find your results." />;
    }

    return (
      <>
        <Title>{title}</Title>
        <div>{subtitle}</div>
      </>
    );
  }

  render() {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        my="4rem"
        flexDirection="column"
      >
        {this.renderContainerBody()}
      </Flex>
    );
  }
}

const Title = styled.div`
  font-size: 24px;
  color: ${colour.brandNavyDarker};
`;

export default TableState;
