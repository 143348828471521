import React from 'react';
import { Box } from '@a-cloud-guru/kermit';
import { StudyGroupTable } from './StudyGroupTable';

const OrganisationStudyGroups = ({ organisationId }) => (
  <Box mt="-64px">
    <StudyGroupTable organisationId={organisationId} />
  </Box>
);

export { OrganisationStudyGroups };
