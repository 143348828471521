import React from 'react';
import { AlgoliaContext } from './AlgoliaContext';
import { useAllAlgoliaIndex } from './use-all-algolia-index';

const AlgoliaProvider = ({
  children,
  organisationId,
  apolloClient
}) => {
  const indexes = useAllAlgoliaIndex({
    organisationId,
    apolloClient
  });

  return (
    <AlgoliaContext.Provider value={indexes}>
      {children}
    </AlgoliaContext.Provider>
  )
};

export { AlgoliaProvider };