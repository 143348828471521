const {
  brandFont,
  colour,
  boxShadow,
  borderRadius
} = require('@a-cloud-guru/kermit');

const merge = require('lodash.merge');

const variables = merge({
    brandFont,
    colour,
    boxShadow,
    borderRadius
  },
  {

    colour: {
      yellow: '#fdc018',
      gray300: '#e2e7f3',
      gray500: '#78839e',
      gray600: '#8B9DBB',

      // To prevent having names like grey302, using color names from
      // http://www.htmlcsscolor.com
      // pls keep order alphabetical to make it easier to find
      bunting: '#243252',
      midnightBlue: '#261E7D',
      portGore: '#3B4966',
      shipCove: '#78839e',
      spindle: '#ADBAD0',
      white: '#FFFFFF'
    }
  }
);

module.exports = variables;
