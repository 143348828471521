import { Button } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components';

import { colour } from '/styles/variables';

const DangerButton = styled(Button)`
  width: ${props => props.width || 'inherit'};
  height: ${props => props.height || 'inherit'};
  padding: 6px 18px;
  border: 0;
  color: #fff;
  background-color: ${colour.red};

  &:hover {
    color: #fff;
    background-color: ${darken(0.05, colour.red)};
  }

  &:focus {
    color:  #fff;
    background-color: ${colour.red};
  }
`;

export { DangerButton };
