import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Icon } from 'antd';

import { Flex, Box } from '/components/box';
import { PercentageDifferenceIndicator } from '/components/stat/PercentageDifferenceIndicator';
import { IntervalRadioButton } from '/components/data-entry/IntervalRadioButton';
import { CourseStatsQuery } from './CourseStatsQuery';
import { Module } from '/components/module/Module';

const today = moment();

const CourseStatsTile = ({ title, type, organisationId }) => {
  const [interval, setInterval] = useState({
    value: 30,
    type: 'days',
  });

  const getRangeFromInterval = ({ value, type }) => {
    return {
      from: today.clone().subtract(value, type),
      to: today,
      lastFrom: today.clone().subtract(value * 2, type),
      lastTo: today.clone().subtract(value, type),
    };
  };

  return (
    <CourseStatsQuery
      organisationId={organisationId}
      range={getRangeFromInterval(interval)}
    >
      {({
        loading,
        averageLessons,
        totalContentDurationMinutes,
        lessonsCompleted,
        averageLessonsGrowth,
        lessonsCompletedGrowth,
        totalContentDurationMinutesGrowth,
      }) => {
        if (totalContentDurationMinutes === 0) {
          return;
        }

        return (
          <div>
            <Container>
              <Flex flexDirection="column">
                <Box>
                  <Title>{title}</Title>
                </Box>
                <Box>
                  {loading ? (
                    <Spinner>
                      <Icon type="loading" spin />
                    </Spinner>
                  ) : (
                    <>
                      {type === 'lessonsCompleted' && (
                        <Value loading={loading}>
                          {lessonsCompleted.toLocaleString()}
                        </Value>
                      )}
                      {type === 'averageLessons' && (
                        <Value loading={loading}>
                          {averageLessons.toLocaleString()}
                        </Value>
                      )}
                      {type === 'minutesWatched' && (
                        <Value loading={loading}>
                          {totalContentDurationMinutes.toLocaleString()}
                        </Value>
                      )}
                    </>
                  )}
                </Box>
                <Flex justifyContent="space-between" alignItems="center">
                  <Box mt="10px">
                    <IntervalRadioButton setInterval={setInterval} />
                  </Box>
                  <Box mt="10px">
                    {type === 'lessonsCompleted' && (
                      <PercentageDifferenceIndicator
                        value={lessonsCompletedGrowth}
                        loading={loading}
                      />
                    )}
                    {type === 'averageLessons' && (
                      <PercentageDifferenceIndicator
                        value={averageLessonsGrowth}
                        loading={loading}
                      />
                    )}
                    {type === 'minutesWatched' && (
                      <PercentageDifferenceIndicator
                        value={totalContentDurationMinutesGrowth}
                        loading={loading}
                      />
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Container>
          </div>
        );
      }}
    </CourseStatsQuery>
  );
};

const Container = styled.div`
  padding: 20px;
`;

const Value = styled.div`
  color: ${colours.darkGrey900};
  line-height: 1;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 38px;
  text-align: center;
  margin-top: 10px;
  border: 1px solid ${colours.lightGrey500};
  border-radius: 2px;
  padding: 5px;
`;

const Spinner = styled.div`
  color: ${colours.blue300};
  line-height: 1;
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 38px;
  text-align: center;
  margin-top: 10px;
  border: 1px solid ${colours.lightGrey500};
  border-radius: 2px;
  padding: 5px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 17px;
  text-align: center;
  color: ${colours.darkGrey400};
  text-transform: uppercase;
`;

export { CourseStatsTile };
