import React from 'react';
import { Flex, Box } from '@a-cloud-guru/kermit';
import { ToggleFeatureFlags } from './ToggleFeatureFlags';
import { DetailsRow } from './Shared';
import styled from 'styled-components';

const FeatureFlagList = ({
  featureFlags,
  organisationId,
  organisationName
}) => {
  return featureFlags.map(({ 
    id, 
    name, 
    enabled, 
    description 
  }) => (
    <DetailsRow
      key={id}
      label={
        <Flex alignItems='center' mr='4px'>
          <Box width='45px'>
            <ToggleFeatureFlags
              organisationId={organisationId}
              organisationName={organisationName}
              featureFlagName={name}
              enabled={enabled}
            />
          </Box>
          <Box width='150px'>
            <BreakAll>{name}</BreakAll>
          </Box>
        </Flex>
      }
    >
      <Flex justifyContent='space-between'>
        <Box mr='10px'>{description}</Box>
      </Flex>
    </DetailsRow>
  ));
};

const BreakAll = styled.div`
  word-break: break-all;
`;

export { FeatureFlagList };
