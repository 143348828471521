import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

import { MarketplaceEventHistoryQuery } from '/data/query';
import { DateFormat } from '/components/date/DateFormat';
import { StyledTable } from '/components/table/StyledTable';
import { StyledCard, StyledDivider, Header } from './Shared';
import { colour } from '/styles/variables';
import { Button, Tooltip } from 'antd';

const columns = [
  {
    title: 'Received',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (date) =>
      date ? <DateFormat format="D MMM YYYY, hh:mm A">{date}</DateFormat> : '-',
  },
  {
    title: 'Product',
    dataIndex: 'productTitle',
    key: 'productTitle',
    render: (_, { productCode, productTitle, acgPackageId, marketplaceProvider }) => (
      <div>
        <span>{productTitle}</span>
        <br />
        <SubProperty>- {marketplaceProvider.toUpperCase()} product: {productCode}</SubProperty>
        <br />
        <SubProperty>- package: {acgPackageId || 'N/A'}</SubProperty>
      </div>
    ),
  },
  {
    title: 'Total Seats',
    dataIndex: 'seats',
    key: 'seats',
    render: (seats) => ({
      children: seats || (
        <NoEntitlements>
          Failed Payment, Refund, or Expired Contract
        </NoEntitlements>
      ),
      props: { colSpan: !seats ? 2 : 1 },
    }),
  },
  {
    title: 'Expiry',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: (date, { seats }) => ({
      children: date ? (
        <DateFormat format="D MMM YYYY, hh:mm A">{date}</DateFormat>
      ) : (
        ''
      ),
      props: { colSpan: !seats ? 0 : 1 },
    }),
  },
];

const MarketplaceEventHistory = (organisation) => {
  let refetchData;
  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Marketplace History</StyledTitle>
        <Tooltip title="Refresh Marketplace History">
          <StyledRefreshButton icon="reload" onClick={() => {
            if (refetchData) {
              refetchData();
            }
          }}/>
        </Tooltip>
      </StyledHeader>

      <StyledDivider />

      <MarketplaceEventHistoryQuery
        notifyOnNetworkStatusChange={true}
        marketplaceCustomerId={get(
          organisation,
          'entitlement.customerIdentifier'
        )}
      >
        {({ data, refetch }) => {
          refetchData = refetch;
          return (
            <StyledTable
              rowKey="id"
              pagination={{ pageSize: 5 }}
              columns={columns}
              dataSource={data.sort((prev, next) =>
                prev.timestamp > next.timestamp ? -1 : 1
              )}
            />
          );
        }}
      </MarketplaceEventHistoryQuery>
    </StyledCard>
  );
};

export { MarketplaceEventHistory };

const SubProperty = styled.span`
  font-size: 13px;
  color: ${colour.gray600};
`;

const NoEntitlements = styled.div`
  color: ${colour.gray600};
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

const StyledRefreshButton = styled(Button)`
  min-width: auto;
`;
