import React, { useState } from "react";
import styled from "styled-components";
import { PaginatedTable, colour } from "@a-cloud-guru/kermit";
import { GroupUsersQuery } from "/data/query";
import { useGroupsQuery } from '/data/query-hooks';
import { Group } from "../OrganisationStudyGroups/Group";
import { ReportingGroupMembersTable } from "./ReportingGroupMembersTable";

const getColumns = ({ organisationId }) => [
  {
    title: "Group Name",
    dataIndex: "name",
    render: (name, { description, colorScheme }) => (
      <Group
        name={name}
        description={description}
        colorScheme={colorScheme || "sugarsweets"}
      />
    )
  },
  {
    title: "Members",
    render: ({ id }) => {
      return (
        <MemberCount>
          <GroupUsersQuery groupId={id} organisationId={organisationId}>
            {({ loading, pagination: { total } }) => (loading ? "" : total)}
          </GroupUsersQuery>{" "}
          Members
        </MemberCount>
      );
    }
  }
];
const PAGE_SIZE = 10;

const ReportingGroupTable = ({ organisationId }) => {
  const [page, setPage] = useState(1);
  const { data, pagination: { total, pageSize } } = useGroupsQuery({
    page,
    pageSize: PAGE_SIZE,
    groupType: 'reporting_group'
  });
  return (
    <StyledTable
      rowKey={({ id }) => id}
      columns={getColumns({ organisationId })}
      dataSource={data}
      pagination={{
        current: page,
        pageSize,
        total,
        onChange: current => setPage(current)
      }}
      expandedRowRender={({ id }) => (
        <ReportingGroupMembersTable
          groupId={id}
          organisationId={organisationId}
        />
      )}
    />
  );
};

const MemberCount = styled.div`
  word-break: keep-all;
`;

const StyledTable = styled(PaginatedTable)`
  .ant-table table {
    table-layout: fixed;
  }

  .ant-table-tbody > tr {
    color: ${colour.gray500};
  }

  tr > th {
    text-transform: uppercase;
    color: ${colour.blue};
    padding: 12px 16px;
  }
`;

export { ReportingGroupTable };
