import React, { useState } from 'react';
import styled from 'styled-components';

import { PaginatedTable, colour } from '@a-cloud-guru/kermit';

import { useGroupsQuery } from '/data/query-hooks';
import { GroupUsersQuery } from '/data/query';
import { Group } from './Group';
import { TeamMembersTable } from './TeamMembersTable';

const groupUserCountMap = {};

const getColumns = ({ organisationId }) => [
  {
    title: 'Team',
    dataIndex: 'name',
    render: (name, { description, colorScheme }) => (
      <Group
        name={name}
        description={description}
        colorScheme={colorScheme || 'sugarsweets'}
      />
    )
  },
  {
    title: 'Member Count',
    render: ({ id }) => (
      <KeepAll>
        <GroupUsersQuery groupId={id} organisationId={organisationId}>
          {({ loading, pagination: { total } }) => {
            groupUserCountMap[id] = total;
            return loading ? '' : total;
          }}
        </GroupUsersQuery>
        {' '}Students
      </KeepAll>
    )
  }
];

const PAGE_SIZE = 10;

const TeamsTable = ({ organisationId }) => {
  const [page, setPage] = useState(1);
  const { data, pagination: { total, pageSize } } = useGroupsQuery({
    page,
    pageSize: PAGE_SIZE,
    groupType: 'team'
  });

  return (
    <StyledTable
      rowKey={({ id }) => id}
      columns={getColumns({ organisationId })}
      dataSource={data}
      pagination={{
        current: page,
        pageSize,
        total,
        onChange: current => setPage(current)
      }}
      expandedRowRender={({ id }) => (<TeamMembersTable groupId={id} />)}
    />
  );
};

const KeepAll = styled.div`
  word-break: keep-all;
`;

const StyledTable = styled(PaginatedTable)`
  .ant-table table {
    table-layout: fixed;
  }

  .ant-table-tbody > tr {
    color: ${colour.gray500};
  }

  tr > th {
    text-transform: uppercase;
    color: ${colour.blue};
    padding: 12px 16px;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: #fff;
  }

  td:first-child {
    cursor: pointer;
  }

  col:first-child {
    width: 5%
  }

  col:nth-child(2) {
    width: 25%
  }

  col:nth-child(3) {
    width: 70%
  }
`;

export { TeamsTable };
