import React from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import { Icon } from 'antd';
import { StyledTable } from '/components/table/StyledTable';
import { colour } from '/styles/variables';

const PAGINATION_LIMIT = 1000;

class PaginatedTable extends React.PureComponent {
  getPagination = ({ current, pageSize, total, onChange }) => {
    const totalOverLimit = total > PAGINATION_LIMIT;
    const totalResultsText = `${total} ${total > 1 ? ' Results' : ' Result'}`;

    return {
      current,
      pageSize,
      total: totalOverLimit ? PAGINATION_LIMIT : total,
      onChange,
      showTotal: (total, range) => (
        <React.Fragment>
          {totalOverLimit && (
            <TooManyResults>
              <TooManyResultsIcon type="info-circle" theme="filled" />
              Displaying first 1000 results, refine your search to view all relevant results
            </TooManyResults>
          )}
          <TotalResults>Showing <b>{range[0]}</b>-<b>{range[1]}</b> of <b>{totalResultsText}</b></TotalResults>
        </React.Fragment>
      )
    };
  }

  render() {
    const { pagination, ...restProps } = this.props;
    const paginationConfig = pagination ? this.getPagination(pagination) : null;

    return (
      <StyledPaginatedTable
        {...restProps}
        pagination={paginationConfig}
        overLimit={(get(pagination, 'total') || 0) > PAGINATION_LIMIT}
      />
    )
  }
}

const StyledPaginatedTable = styled(StyledTable)`
  .ant-pagination {
    display: flex;
    flex: 1;
    float: none;
  }

  .ant-pagination-total-text {
    order: 2;
    display: flex;
    flex: 1;
    justify-content: ${props => props.overLimit ? 'space-between' : 'flex-end'};
    align-items: center;
  }
`
const TooManyResults = styled.span`
  color: ${colour.gray500};
  padding: 5px;
  line-height: 1.2;
`;

const TotalResults = styled.span`
  line-height: 1.2;
`;

const TooManyResultsIcon = styled(Icon)`
  margin: 0 8px 0 12px;
  color: ${colour.blueLight};
`;

export { PaginatedTable };