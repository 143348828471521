const INITIAL_STATE = {
  query: '',
  page: 1,
  filter: 'all',
  resendEmails: [],
  revokedEmails: []
};

const ACTION_TYPES = {
  'RELOAD': 'RELOAD',
  'UPDATE_FILTER': 'SET_FILTER',
  'UPDATE_QUERY': 'SET_QUERY',
  'UPDATE_STATE': 'UPDATE_STATE',
  'RESEND_EMAIL': 'RESEND_EMAIL',
  'REVOKE_EMAIL': 'REVOKE_EMAIL'
};

const reducer = (state, { type, payload }) => {
  switch(type) {
    case (ACTION_TYPES.RELOAD):
      return {
        ...INITIAL_STATE,
        resendEmails: state.resendEmails,
        revokedEmails: state.revokedEmails
      };

    case (ACTION_TYPES.UPDATE_FILTER):
      return {
        ...state,
        filter: payload.value,
        page: 1
      }

    case (ACTION_TYPES.UPDATE_QUERY):
      return {
        ...state,
        query: payload.value,
        page: 1
      }

    case (ACTION_TYPES.UPDATE_STATE):
      return {
        ...state,
        [payload.key]: payload.value
      };

    case (ACTION_TYPES.RESEND_EMAIL):
      return {
        ...state,
        resendEmails: [...state.resendEmails, payload.email]
      }

    case (ACTION_TYPES.REVOKE_EMAIL):
      return {
        ...state,
        revokedEmails: [...state.revokedEmails, payload.email]
      }

    default:
      return state;
  };
};

export {
  INITIAL_STATE,
  ACTION_TYPES,
  reducer
};