import { Form } from 'antd';
import styled from 'styled-components';
import { lighten } from 'polished';

import { colour } from '/styles/variables';

const FormItem = styled(Form.Item)`
  margin: 0;
  border-bottom: solid 1px ${lighten(0.15, colour.gray400)};
  padding: 18px 20px;

  .ant-form-item-children {
    display: ${(props) => props.display || 'flex'};
  }

  label:after {
    content: '';
  }

  .has-error .ant-input,
  .has-error .ant-form-explain {
    color: ${colour.red};
    border-color: ${colour.red};

    :focus {
      border-color: ${colour.red};
    }
  }
`;

export { FormItem };
