import gql from 'graphql-tag';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';

const GET_ALGOLIA_API_KEY = gql`
  query ACG_searchApiKey($organisationIds: [String!]) {
    ACG_searchApiKey(organisationIds: $organisationIds) {
      id
      scopedApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

const useAlgoliaApiKey = (organisationIds) => {
  const { data } = useQuery(GET_ALGOLIA_API_KEY, {
    variables: { organisationIds }
  });

  return { apiKey: get(data, 'ACG_searchApiKey.scopedApiKey.apiKey') }
};

export { useAlgoliaApiKey };