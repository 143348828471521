import React, { useState } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { ApolloConsumer } from 'react-apollo';
import { Spinner, DateFormat, Table, SearchBox, Flex, Box } from '@a-cloud-guru/kermit';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { OrganisationUsersQuery, OrganisationUserQuizAttemptsQuery } from '/data/query';
import { DisplayName } from '/components/table/DisplayName';
import { ExportButton } from './ExportButton';

const DOWNLOAD_QUIZ_ATTEMPTS_QUERY = gql`
  query ACG_organisationQuizActivityReport (
    $organisationId: String!,
    $courseId: String!,
    $userIds: [String]
  ){
    ACG_organisationQuizActivityReport (
      organisationId: $organisationId,
      filters: {
        courseId: $courseId,
        userIds: $userIds
      }
    ) {
      downloadUrl
    }
  }
`;

const columns = [
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    render: (displayName, record) => (
      <DisplayName name={displayName} avatar={record.picture} />
    ),
  },
  {
    title: 'Email Address',
    dataIndex: 'email'
  },
  {
    title: 'Quiz Title',
    dataIndex: 'quizTitle',
    render: (quizTitle, { sectionSequence }) => {
      if (isNaN(sectionSequence)) {
        return sectionTitle;
      }
      return `${sectionSequence + 1} - ${quizTitle}`
    }
  },
  {
    title: 'Score',
    dataIndex: 'score',
    render: score => (
      `${score}%`
    )
  },
  {
    title: 'Completed At',
    dataIndex: 'completedAt',
    render: completedAt => (
      <DateFormat format='D MMM YYYY, hh:mm:ss A'>{completedAt || '-'}</DateFormat>
    )
  }
];

const UserQuizAttemptsTable  = ({ courseId, organisationId }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  return (
    <OrganisationUsersQuery
      organisationId={organisationId}
      query={query}
    >
      {({ data: users }) => {
        const userIds = query ? users.map(({ user_id }) => user_id) : undefined;

        return (
          <StyledModule>
            <ModuleHeader header="Recent Attempts">
              <Flex justifyContent="flex-end">
                <Box mr="8px">
                  <SearchBox
                    placeholders="Search users"
                    onChange={e => setQuery(e.target.value)}
                  />
                </Box>

                <ApolloConsumer>
                  {client => (
                    <ExportButton
                      onExport={async () => {
                        const { data } = await client.query({
                          query: DOWNLOAD_QUIZ_ATTEMPTS_QUERY,
                          variables: {
                            organisationId,
                            courseId,
                            userIds
                          }
                        });
                        return data.ACG_organisationQuizActivityReport.downloadUrl;
                      }}
                    />
                  )}
                </ApolloConsumer>
              </Flex>
            </ModuleHeader>
            <OrganisationUserQuizAttemptsQuery
              organisationId={organisationId}
              courseId={courseId}
              userIds={userIds}
              page={page}
              pageSize={10}
            >
              {({ loading, data, pagination: { total, page, pageSize } }) => (
                <Table
                  rowKey={({ userId, completedAt }) => `${userId}-${completedAt}`}
                  loading={{
                    indicator: <Spinner size="2rem" />,
                    spinning: loading
                  }}
                  columns={columns}
                  dataSource={data}
                  onChange={(pagination) => setPage(pagination.current)}
                  pagination={{
                    current: page,
                    pageSize,
                    total
                  }}
                />
              )}
            </OrganisationUserQuizAttemptsQuery>
          </StyledModule>
        )
      }}
    </OrganisationUsersQuery>
  )
};

const StyledModule = styled(Module)`
  margin-bottom: 0;
`;

export { UserQuizAttemptsTable };