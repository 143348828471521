import styled from 'styled-components'
import {
  space,
  width,
  height,
  flex,
  borders,
  textAlign,
  maxWidth,
  minWidth,
  position,
  top,
  left,
  bottom,
  right,
  fontSize,
  fontWeight,
  lineHeight,
  color
} from 'styled-system'

const Box = styled.div`
  ${flex};
  ${space};
  ${width};
  ${height};
  ${borders};
  ${textAlign};
  ${minWidth};
  ${maxWidth};
  ${position};
  ${top};
  ${left};
  ${bottom};
  ${right};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${color};
`;

export { Box };
