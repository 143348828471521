import React from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from 'antd';
import { Spinner } from '@a-cloud-guru/kermit';

import { Box } from '/components/box';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Container } from '/components/container';
import { StyledCard, StyledDivider, DetailsRow, Header } from './Shared';
import { DateFormat } from '/components/date/DateFormat';

import { UpdateReallocationTracking } from './update-reallocation-tracking/UpdateReallocationTracking';

export const LicenseReallocationTrackingCard = (organisation) => {
  const {
    reallocationTrackingConfig,
    licenseReallocationStats,
    features,
    loading: orgLoading
  } = organisation;
  const { trackLicenseReallocations } = features || {};
  const { startDate, endDate, licensePercentage } =
    reallocationTrackingConfig || {};
  const { reallocationUsage, reallocationThreshold } =
    licenseReallocationStats || {};

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>License Reallocation Tracking</StyledTitle>
        <DisabledWhenReadOnly>
          <StyledActionBar>
            <UpdateReallocationTracking organisation={organisation} />
          </StyledActionBar>
        </DisabledWhenReadOnly>
      </StyledHeader>

      <StyledDivider />

      <Box py="6px">
        {orgLoading ? (
          <Container>
            <Spinner />
          </Container>
        ) : (
          <>
            <DetailsRow label="Tracking Enabled">
              {trackLicenseReallocations === true ? 'Yes' : 'No'}
            </DetailsRow>

            <DetailsRow label="Track From">
              <DateFormat>{startDate}</DateFormat>
            </DetailsRow>
            <DetailsRow label="Track To">
              <DateFormat>{endDate}</DateFormat>
            </DetailsRow>
            <DetailsRow label="Reallocation Threshold">
              {licensePercentage}% ({reallocationThreshold} seats)
            </DetailsRow>
            <DetailsRow label="Seats Reallocated">
              {reallocationUsage}/{reallocationThreshold} seats
            </DetailsRow>
          </>
        )}
      </Box>
    </StyledCard>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
  text-transform: uppercase;
`;

const StyledActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 210px;
`;
