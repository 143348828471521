import { format } from 'date-fns';

const configs = {
  week: {
    format: (date) => format(date, 'DD MMM')
  },
  month: {
    format: (date) => format(date, 'YYYY MMM')
  }
}

export const transform = ({ dataSource, type }) => {
  const completedComponentsData = dataSource.find(row => row.event === 'Completed Lessons');
  const percentageActiveUserData = dataSource.find(row => row.event === '% Active Users');

  const completedComponentsChartData = Object.keys(completedComponentsData).reduce((data, key) => {
    if (key === 'event') {
      return { ...data, event: completedComponentsData.event };
    }
    return { ...data, [configs[type.toLowerCase()].format(key)]: completedComponentsData[key]}
  }, {});
  const percentageActiveUserChartData = Object.keys(percentageActiveUserData).reduce((data, key) => {
    if (key === 'event') {
      return { ...data, event: percentageActiveUserData.event };
    }
    return { ...data, [configs[type.toLowerCase()].format(key)]: percentageActiveUserData[key] }
  }, {});

  const dates = Object.keys(completedComponentsChartData).filter(key => key !== 'event');

  return dates.map(date => {
    return {
      date,
      'completedLessons': completedComponentsChartData[date],
      'activeUserPercentage': percentageActiveUserChartData[date]
    }
  });
}