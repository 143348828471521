import React from 'react';
import { Spinner, Flex, ProgressiveImage, ImageOptimizer } from '@a-cloud-guru/kermit';
import styled from 'styled-components';

const LearningPathInfo = ({ name, shortDescription, tileUrl }) => (
  <Flex alignItems="center">
    <ImageOptimizer>
      {({ buildOptimizedSrc }) => (
        <ProgressiveImage
          src={buildOptimizedSrc(tileUrl)}
          preload={() => <Flex justifyContent="center" width="40px"><Spinner size="1rem" /></Flex>}
        >
          <LearningPathArtwork />
        </ProgressiveImage>
      )}
    </ImageOptimizer>
    <Flex flexDirection="column" ml="12px">
      <LearningPathName>{name}</LearningPathName>
      <LearningPathDesc>{shortDescription}</LearningPathDesc>
    </Flex>
  </Flex>
);

const LearningPathArtwork = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const LearningPathName = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const LearningPathDesc = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

export { LearningPathInfo };
