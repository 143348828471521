import { Form, Input, InputNumber, DatePicker, Button, Switch } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash.get'
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Flex } from '/components/box';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { useMutation } from '/lib/hooks';
import { getConfig } from '../../../../config/get-config'

const { ACG_ENV } = getConfig();

const UPDATE_REALLOCATION_TRACKING_CONFIG = gql`
  mutation updateOrganisationReallocationTrackingConfig($input: ACG_UpdateOrganisationReallocationTrackingConfigInput) {
    ACG_updateOrganisationReallocationTrackingConfig(input: $input) {
      organisation {
        id
        reallocationTrackingConfig {
          startDate
          endDate
          licensePercentage
        }
        licenseReallocationStats {
          reallocationUsage
          reallocationThreshold
        }
      }
    }
  }
`;

const UpdateReallocationTrackingForm = (props) => {
  const organisationId = get(props, 'id');
  const closeModal = get(props, 'closeModal');
  const reallocationTrackingConfig = get(props, 'reallocationTrackingConfig');

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateReallocationTrackingConfigMutation = useMutation({
    mutation: UPDATE_REALLOCATION_TRACKING_CONFIG
  });

  const form = get(props, 'form');
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const updateReallocationTracking = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    const validateForm = () => new Promise((resolve) => {
      form.validateFields((err) => {
        resolve(err ? false : true);
      });
    });

    try {
      const valid = await validateForm();

      if (!valid) {
        setSubmitting(false);
        return false;
      }

      await updateReallocationTrackingConfigMutation({
        variables: {
          input: {
            organisationId,
            startDate: getFieldValue('startDate').toISOString(),
            endDate: getFieldValue('endDate').toISOString(),
            licensePercentage: getFieldValue('licensePercentage')
          }
        }
      });

      return closeModal();
    } catch(e) {
      setErrorMessage(e.message);
      setSubmitting(false);
    }

    return false;
  }

  return (
    <Form
      onSubmit={updateReallocationTracking}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Track From</InputLabel>
        <InputField>
          {getFieldDecorator('startDate', {
            initialValue: moment(get(reallocationTrackingConfig, 'startDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the date that the tracking period starts for this organization',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={startDate => {
                const endDate = getFieldValue('endDate');
                return startDate > endDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Track To</InputLabel>
        <InputField>
          {getFieldDecorator('endDate', {
            initialValue: moment(get(reallocationTrackingConfig, 'endDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the date that the tracking period ends for this organization',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabledDate={endDate => {
                const startDate = getFieldValue('startDate');
                return endDate < startDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Reallocation Threshold (%)</InputLabel>
        <InputField>
          {getFieldDecorator('licensePercentage', {
            initialValue: get(reallocationTrackingConfig, 'licensePercentage') || 0,
            rules: [{
              type: 'number', message: 'Please input a valid whole number percentage',
            }, {
              required: true, message: 'Please input the percentage of licenses that this organization may reallocate',
            }],
          })(
            <InputNumber />
          )}
        </InputField>
      </FormItem>
      <Footer>
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        <Button type="primary" htmlType="submit" loading={submitting}>
          {submitting ? 'Updating' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
}

const InputField = styled.div`
  flex: 3;
`;

const StyledSwitch = styled(Switch)`
  padding: 0 2px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 220px;
`;

const StyledErrorMessage = styled.div`
  flex: 1;
  color: #F40064;
`;

const ComposedUpdateReallocationTrackingForm = Form.create()(UpdateReallocationTrackingForm);

export {
  ComposedUpdateReallocationTrackingForm as UpdateReallocationTrackingForm
};
