import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { RangePicker } from '@a-cloud-guru/kermit';

const today = moment();

const getLabel = (dates) => {
  return `${dates[0].format('YYYY-MM-DD')} - ${dates[1].format('YYYY-MM-DD')}`;
};

const RANGES = {
  'Last 3 weeks': [today.clone().subtract(3, 'weeks'), today],
  'Last 6 weeks': [today.clone().subtract(6, 'weeks'), today],
  'Last 9 weeks': [today.clone().subtract(9, 'weeks'), today],
  'Last 12 weeks': [today.clone().subtract(12, 'weeks'), today],
  'Last 1 month': [today.clone().subtract(1, 'month'), today],
  'Last 3 months': [today.clone().subtract(3, 'months'), today],
  'Last 6 months': [today.clone().subtract(6, 'months'), today]
};

const EngageRangePicker = ({
  value,
  onRangeChange,
  onTypeChange
}) => (
  <RangePicker
    value={value}
    renderToId="user-engagement-range-picker"
    ranges={RANGES}
    disabledDate={date => date > today}
    getCalendarContainer={() => document.getElementById('user-engagement-range-picker')}
    onOk={dates => {
      onRangeChange(dates);

      const weeks = Object.keys(RANGES).slice(0, 4).map(rangeKey => RANGES[rangeKey][0]);
      if (weeks.includes(dates[0])) {
        onTypeChange('WEEK');
      }
      const months = Object.keys(RANGES).slice(4).map(rangeKey => RANGES[rangeKey][0]);
      if (months.includes(dates[0])) {
        onTypeChange('MONTH');
      }
    }}
  >
    <Button>{getLabel(value)}</Button>
  </RangePicker>
);

export { EngageRangePicker };