import React from 'react';
import { CourseEngagementChart } from '/charts/CourseEngagementChart';
import { Box, Flex } from '/components/box';
import { CTAButton } from '/components/button/CTAButton';

const RecentEngagementChartDisplay = ({ chartData, organisationId }) => (
  <Box position="initial" top="16px">
    <Box pl="15px">
      <CourseEngagementChart
        height={450}
        data={chartData}
        padding={[48, 60, 50, 'auto']}
      />
    </Box>
    <Flex p="16px 26px 12px" justifyContent="flex-end">
      <Box my="auto">
        <CTAButton
          shape="round"
          className={`CTAButton ${location}`}
          onClick={() =>
            window.location.replace(
              `/organizations/${organisationId}/reports/course-engagement-report`
            )
          }
        >
          View Report
        </CTAButton>
      </Box>
    </Flex>
  </Box>
);

export { RecentEngagementChartDisplay };
