import React from 'react';
import { Spinner } from '@a-cloud-guru/kermit';
import { Layout, Menu, Icon, Spin } from 'antd';
import { withRouter, Link } from "react-router-dom";
import urlJoin from 'url-join';

import LogoImage from '../images/logo.png';
import { getConfig } from '../config/get-config';
import { Footer } from './Footer';
import { colour } from '../styles/variables';

import styled from 'styled-components';

const { ACG_BERNIE_DOMAIN } = getConfig();

Spin.setDefaultIndicator(<Spinner />);

const AdminLayout = ({ masthead, location, children }) => (
  <StyledLayout>
    <StyledHeader>
      <LHS
        mode="horizontal"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="logo">
          <Logo
            onClick={() => window.location.replace(urlJoin(ACG_BERNIE_DOMAIN, 'users'))}
            src={LogoImage}
            alt=""
          />
        </Menu.Item>
        <Menu.Item key="/users">
          <a href={urlJoin(ACG_BERNIE_DOMAIN, 'users')}>Users</a>
        </Menu.Item>
        <Menu.Item key="/organizations">
          <Link to="/organizations">Organizations</Link>
        </Menu.Item>
        <Menu.Item key="/roles">
          <a href={urlJoin(ACG_BERNIE_DOMAIN, 'roles')}>Site Roles</a>
        </Menu.Item>
        <Menu.Item key="/skills-assessments">
          <Link to="/skills-assessments">Skills Assessments</Link>
        </Menu.Item>
        <Menu.Item key="/logout">
          <a href={urlJoin(ACG_BERNIE_DOMAIN, 'logout')}>Logout</a>
        </Menu.Item>
      </LHS>
      <RHS mode="horizontal">
        <Menu.Item key="support">
          <HelpLink href="https://help.acloud.guru/" target="_blank">
            <Icon type="question-circle-o" />
          </HelpLink>
        </Menu.Item>
      </RHS>
    </StyledHeader>
    {masthead}
    <PageBody>{children}</PageBody>
    <Footer />
  </StyledLayout>
);

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: ${colour.blue};
  padding: 0 60px 0 60px;

  li {
    padding: 0 0.75rem;
    color: ${colour.gray400};
    border: 0!important;

    &:first-child {
      padding-left: 0;
    }

    &::selection {
      border: 0;
    }
  }
`;

const LHS = styled(Menu)`
  border: 0;
  background-color: ${colour.blue};

  .ant-menu-item {
    a {
      color: ${colour.gray400};
      transition: all 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }

  .ant-menu-item-selected {
    a {
      color: #fff;
    }
  }
`;

const Logo = styled.img`
  position: relative;
  top: -2px;
`;

const RHS = styled(LHS)`
  li {
    padding: 0 0.25rem;
  }
`;

const HelpLink = styled.a`
  &:hover {
    .anticon {
      color: #fff;
    }
  }

  .anticon {
    transition: all 0.3s;
    position: relative;
    top: 0.25rem;
    font-size: 1.25rem;
    font-weight: 200;
    margin: 0 10px;
    color: ${colour.gray400};
  }
`;

const PageBody = styled.div`
  padding: 24px 60px 22px 60px;
  flex: 1;
`;

const composedAdminLayout = withRouter(AdminLayout);

export {
  composedAdminLayout as AdminLayout
};
