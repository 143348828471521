import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { boxShadow, colour } from '/styles/variables';
import { ImageOptimizer } from '/components/image/ImageOptimizer';
import { Box, Flex } from '/components/box';
import { CTAButton } from '/components/button/CTAButton';

const CourseStatItem = ({
  courseId,
  title,
  artworkUrl,
  numberOfUsers,
  organisationId,
}) => (
  <ImageOptimizer>
    {({ buildOptimizedSrc }) => (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px #e2e7f3 solid"
        p={[16, 16]}
      >
        <CourseInfo>
          <CourseArtwork src={buildOptimizedSrc(artworkUrl)} alt="" />
          <Flex
            flexDirection="column"
            justifyContent="center"
            justifyItems="center"
            my="auto"
            ml="14px"
          >
            <Box mb="4px">
              <CourseTitle>{title}</CourseTitle>
            </Box>
            <CourseSubtitle>
              {numberOfUsers} {numberOfUsers > 1 ? 'Students' : 'Student'}
            </CourseSubtitle>
          </Flex>
        </CourseInfo>
        <Link to={`/organizations/${organisationId}/reports/course-popularity`}>
          <CTAButton>View</CTAButton>
        </Link>
      </Flex>
    )}
  </ImageOptimizer>
);

const CourseInfo = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 70%;
`;

const CourseArtwork = styled(Avatar)`
  min-width: 60px;
  height: 60px;
  border-radius: 2px;
  box-shadow: ${boxShadow.subtle};
`;

const CourseTitle = styled.div`
  color: ${colour.gray900};
  font-size: 14px;
  font-weight: 600;
  margin: auto 0;
  line-height: 1.2;
`;

const CourseSubtitle = styled.div`
  color: ${colour.gray500};
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
`;

export { CourseStatItem };
