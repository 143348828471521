import { colour } from '@a-cloud-guru/themes';
import { Menu } from 'antd';
import styled from 'styled-components';

const FilterMenu = styled(Menu)`
  width: 160px;
  padding: 6px 0;
  box-shadow: 0 3px 7px 0 rgba(${colour.navy700}, 0.1);
  border: 1px solid ${colour.grey100};
  border-radius: 3px;

  .ant-dropdown-menu-item-selected {
    font-weight: 500;
    color: ${colour.grey900}
  }
`;

export { FilterMenu };
