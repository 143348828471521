import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { formatDate, dateDistanceInWordsToNow } from '/utils/date';
import { jsonToCsvDownload } from '/lib/json-to-csv';

class ExportButton extends React.PureComponent {
  state = { loading: false };

  static defaultProps = {
    label: 'Export CSV',
    loadingLabel: 'Exporting',
    fileName: `users-${new Date().toISOString()}.csv`,
    onExport: () => Promise.resolve()
  }

  makeMapper = ({ defaults }) => (data) => data.map(
    ({ name, user_id, email, organisation_admin, organisation_license_consumed, last_seen_timestamp }) => ({
      'User ID': user_id,
      'Name': name,
      'Email Address': email,
      'Role': organisation_admin ? 'Administrator' : 'User',
      'Status': organisation_license_consumed ? 'Active' : 'Deactivated',
      'Last Seen': last_seen_timestamp
        ? formatDate({ date: last_seen_timestamp, dateFormat: 'D MMM YYYY hh:mm A' })
        : dateDistanceInWordsToNow({ date: defaults.last_seen_timestamp })
    })
  );

  render() {
    const { label, loadingLabel, onExport, fileName, defaults } = this.props;
    const mapper = this.makeMapper({ defaults });

    return (
      <StyledButton
        loading={this.state.loading}
        type="primary"
        onClick={async () => {
          this.setState({ loading: true });

          const data = await onExport();

          jsonToCsvDownload({
            fileName,
            data,
            mapper
          });

          this.setState({ loading: false });
        }}
      >
        {this.state.loading ? loadingLabel : label}
      </StyledButton>
    )
  }
};

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export { ExportButton };