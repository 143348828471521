import React from 'react';
import { Dropdown } from 'antd';

import { DropdownCaretIcon } from '/components/dropdown';
import { FilterDropdownButton, FilterMenu, FilterMenuItem } from '/components/filter';

const filterLabels = {
  All: 'All status',
  Active: 'Active',
  Inactive: 'Deactivated'
};

const onClickStatusFilter = (key, updateStatusFilter) => {
  updateStatusFilter(key);
}

const StatusFilter = ({ statusFilter, updateStatusFilter }) => {
  return (
  <Dropdown
    trigger={["click"]}
    placement="bottomLeft"
    overlay={
      <FilterMenu
        onClick={({ key }) => onClickStatusFilter(key, updateStatusFilter)}
        selectedKeys={[statusFilter]}
      >
        {
          ['All', 'Active', 'Inactive'].map(filter =>
            <FilterMenuItem
              text={filterLabels[filter]}
              key={filter.toLowerCase()}
              selected={statusFilter === filter.toLowerCase()}
            />
          )
        }
      </FilterMenu>
    }
  >
    <FilterDropdownButton>
      Status <DropdownCaretIcon type="caret-down" />
    </FilterDropdownButton>
  </Dropdown>
)};

export { StatusFilter };
