module.exports = {
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  AUTH0_PRIMARY_CLIENT_ID: 'Bt4dXfW1xk6Wur9rNBOOZTidZakD092X',
  AUTH0_CLIENT_ID: 'Bt4dXfW1xk6Wur9rNBOOZTidZakD092X',
  ALL_ORGANISATION_INDEX: 'private_prod_all_organisations',
  ALL_ORGANISATION_USERS_INDEX: 'private_prod_all_organisation_users',
  ALL_ORGANISATION_GROUPS_INDEX: 'private_prod_all_organisation_groups',
  ALL_ORGANISATION_INVITATIONS_INDEX: 'private_prod_all_organisation_invitations',
  ORGANISATION_INVITATION_INDEX_PREFIX: 'private_prod_organisation_invitation_',
  ORGANISATION_GROUP_INDEX_PREFIX: 'private_prod_organisation_group_',
  COURSE_INDEX_NAME: 'prod_courses',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  ACG_BERNIE_DOMAIN: 'https://bernie.acloud.guru',
  ACG_DOMAIN: 'https://acloud.guru/',
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY,
  BUGSNAG_RELEASE_STAGE: 'production',
  SPLIT_IO_API_KEY: 'u28vabgv0jhqedmpunitvia0eg7s3f2gou3l',
  ACG_ORGANISATION_ID: '6b0fdb94-bb69-f073-6623-312cf71ac88e',
  PS_ORGANISATION_ID: '23c48559-7087-46c8-9b7d-c5f21e36bad2',
  ALGOLIA_STAGE: 'prod',
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '5a7c7b5afd95332db01686a1'
};
