import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { get } from 'lodash';
import { Button, Modal, message } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { Spinner } from '@a-cloud-guru/kermit';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { Box, Flex } from '/components/box';

const GET_IMPERSONATE_URL = gql`
  query ACG_impersonationUrl($auth0Email: String!, $platform: ImpersonatePlatform) {
    ACG_impersonationUrl(auth0Email: $auth0Email, platform: $platform)
  }
`;
const ImpersonateModal = ({ email, isAdmin }) => {
  const [visible, setVisible] = useState(false);
  const [execute, { data, loading }] = useLazyQuery(GET_IMPERSONATE_URL, {
    variables: {
      auth0Email: email,
      platform: isAdmin ? 'TEAMS' : 'LEARN'
    },
    fetchPolicy: 'network-only'
  });
  const url = get(data, 'ACG_impersonationUrl');

  return (
    <div>
      <span onClick={() => {
        setVisible(true);
        execute();
      }}>Impersonate</span>
      <Modal
        width="50%"
        title="Impersonate User"
        visible={visible}
        footer={[
          <Button key="ok" onClick={() => setVisible(false)}>
            Close
          </Button>
        ]}
        onCancel={() => setVisible(false)}
      >
        <Flex p="8px 22px">
          {loading ? <Spinner /> : (
            <Flex flexDirection="column" width="100%">
              <Box mb="4px">Right click below and select...</Box>
              <Box mb="6px">
                <a href={url} onClick={e => {
                  return e.preventDefault() && e.stopPropagation();
                }}>
                  <PrimaryButton
                    onClick={() => message.warn('Open Link in Private Window')}
                  >
                    Open Link In New Private / Incognito Window
                  </PrimaryButton>
                </a>
              </Box>
              <CopyToClipboard text={url} onCopy={() => message.success('Impersonate link is copied to clipboard')}>
                <CopyText>
                  Copy link to clipboard
                </CopyText>
              </CopyToClipboard>
              <Box mt="8px">
                <strong>Warning:</strong>
                <ul>
                  <li>Close any other incognito windows that are logged in to ACG to avoid conflicting user sessions or sign-in issues</li>
                  <li>If using Chrome, you will need to allow 3rd party cookies in your settings. See <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Change your cookie settings</a></li>
                  <li>any action you take while 'impersonating' will appear as that user</li>
                </ul>

              </Box>
            </Flex>
          )}
        </Flex>
      </Modal>
    </div>
  )
};

const CopyText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export { ImpersonateModal };
