import React from 'react';
import { Box, Flex } from '/components/box';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { DurationFilter } from '../../../../../components/date/DurationFilter';

const RecentEngagementModuleHeader = ({ ranges, type, setRange, setType }) => (
  <ModuleHeader header="Recent Engagement" hideDivider>
    <Flex justifyContent="flexEnd" alignItems="center">
      <Box ml="10px">
        <DurationFilter
          ranges={ranges}
          type={type}
          setRange={setRange}
          setType={setType}
        />
      </Box>
    </Flex>
  </ModuleHeader>
);

export { RecentEngagementModuleHeader };
