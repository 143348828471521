import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import get from 'lodash.get';
import styled from 'styled-components';

import { Box, Flex } from '/components/box';
import { authService } from '/services/auth';

import { StyledCard, StyledDivider, DetailsRow, Header } from './Shared';
import { ModifyAccountDetails } from './modify-account-details/ModifyAccountDetails';
import { UpdatePackageModal } from './update-package/UpdatePackageModal';
import { PackageChangeModal } from './update-package/PackageChangeModal';
import { GET_ACG_SUBSCRIBER_PACKAGE } from '../../../data/query/PackageQuery';
import { useLazyQuery } from 'react-apollo';

export const PackageDetailsCard = (organisation) => {
  const [userId, setUserId] = useState(null);
  const [overrides, setOverrides] = useState([]);
  const [packageName, setPackageName] = useState('');
  const organisationId = get(organisation, 'id');
  const chargeModel = get(organisation, 'chargeModel');
  const orgLoading = get(organisation, 'loading');

  const entryToObject = (entry) => ({ id: entry[0], ...entry[1] });

  const [getSubscriberData, subscriberQuery] = useLazyQuery(GET_ACG_SUBSCRIBER_PACKAGE, {
    variables: {
      input: {
        organisationId: organisationId,
        userId: userId,
      },
    },
    options: {
      fetchPolicy: 'cache-and-network',
    },
  });
  const subscriberData = get(
    subscriberQuery,
    'data.ACG_Packaging_subscriberPackage.subscriberPackage',
    ''
  );
  useEffect(() => {
    const overridesString = get(
      subscriberQuery,
      'data.ACG_Packaging_subscriberPackage.subscriberPackage.overrides'
    );

    const getPackageName = get(
      subscriberQuery,
      'data.ACG_Packaging_subscriberPackage.subscriberPackage.packageName',
      ''
    );

    setPackageName(getPackageName);

    if (overridesString) {
      const overridesParsed = JSON.parse(overridesString);
      const flattenedOverrides = Object.entries(overridesParsed).map(
        entryToObject
      );
      setOverrides(flattenedOverrides);
    }
  }, [subscriberData]);

  useEffect(() => {
    async function getUserId() {
      const userId = await authService.getUserIdFromToken();
      setUserId(userId);
    }
    getUserId();
  }, []);

  useEffect(() => {
    getSubscriberData();
  }, [userId]);

  useEffect(() => {
    if (subscriberQuery && subscriberQuery.data && !orgLoading) {
      subscriberQuery.refetch();
    }
  }, [orgLoading]);

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Packages</StyledTitle>
        <UpdatePackageModal
          organisationId={organisationId}
          userId={userId}
          chargeModel={chargeModel}
          refetch={subscriberQuery.refetch}
        />
      </StyledHeader>
      <StyledDivider />
      {(!subscriberData || subscriberQuery.loading || orgLoading) ? (
        <Flex justifyContent="center" style={{ padding: '2em' }}>
          <Spin />
        </Flex>
      ) : (
        <Box py="6px">
          <DetailsRow label="Package">{packageName}</DetailsRow>
          <DetailsRow label="Overrides">
            {overrides.map((override, i) => {
              return (
                <Row type="flex" key={i} style={{ marginBottom: '1em' }}>
                  <Col span={16}>
                    <span>{(override && override.displayName) || ''}</span>
                  </Col>
                  <Col span={8} style={{ textAlign: 'center' }}>
                    <span
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {override.accessLevel}
                    </span>
                  </Col>
                </Row>
              );
            })}
          </DetailsRow>
          <DetailsRow
            label="Audit Trail"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <PackageChangeModal organisationId={organisationId} />
          </DetailsRow>
        </Box>
      )}
    </StyledCard>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
  text-transform: uppercase;
`;
