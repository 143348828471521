import React, { useState } from 'react';
import styled from 'styled-components';

import { StyledModal, FormContent, Title, Subtitle } from '/components/modal';
import { DangerButton } from '/components/button/DangerButton';

import { BulkRemoveValidateEmailForm } from './BulkRemoveValidateEmailForm';
import { BulkRemoveConfirmForm } from './BulkRemoveConfirmForm';
import { isReadOnly } from '../../../hooks/isReadOnly';

const BulkRemoveModalContent = ({ organisationId, setVisible }) => {
  const [formStep, setFormStep] = useState('VALIDATE');
  const [found, setFound] = useState([]);
  const [missing, setMissing] = useState([]);

  return (
    <FormContent>
      <Title>Bulk Deactivate</Title>
      <Subtitle>
        {formStep === 'VALIDATE' ? (
          <BulkRemoveValidateEmailForm
            organisationId={organisationId}
            setFound={setFound}
            setMissing={setMissing}
            setFormStep={setFormStep}
          />
        ) : (
          <BulkRemoveConfirmForm
            organisationId={organisationId}
            found={found}
            missing={missing}
            onClose={() => setVisible(false)}
          />
        )}
      </Subtitle>
    </FormContent>
  );
};

const BulkRemove = ({ organisationId }) => {
  const [visible, setVisible] = useState(false);
  const readOnly = isReadOnly();

  return (
    <>
      <DangerButton onClick={() => setVisible(!readOnly)}>
        Bulk Deactivate
      </DangerButton>
      <BulkRemoveModal
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
      >
        <BulkRemoveModalContent
          organisationId={organisationId}
          setVisible={setVisible}
        />
      </BulkRemoveModal>
    </>
  );
};

const BulkRemoveModal = styled(StyledModal)`
  .ant-modal-body {
    padding: 20px 24px;
  }
`;

export { BulkRemove };
