import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_USER_COURSE_ACTIVITY_QUERY = (attributes) => gql`
  query ACG_organisationCourseActivityReport(
    $organisationId: String!,
    $groupId: String,
    $courseId: String,
    $page: Int!,
    $pageSize: Int!
  ) {
    ACG_organisationCourseActivityReport (
      organisationId: $organisationId,
      filters: {
        groupId: $groupId,
        courseId: $courseId
      },
      pagination: {
        pageNumber: $page,
        pageSize: $pageSize
      }
    ) ${attributes}
  }
`;

const OrganisationUserCourseActivityQuery = ({
  page,
  pageSize,
  groupId,
  courseId,
  organisationId,
  attributes,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={ACG_ORGANISATION_USER_COURSE_ACTIVITY_QUERY(attributes)}
      variables={{
        organisationId,
        groupId,
        courseId,
        page,
        pageSize
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            const activities = get(data, `ACG_organisationCourseActivityReport.data`) || [];
            const total = get(data, `ACG_organisationCourseActivityReport.total`) || 0;
            return children({
              data: activities,
              pagination: {
                page,
                pageSize,
                total
              },
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  )
};

export { OrganisationUserCourseActivityQuery };