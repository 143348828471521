import { Avatar } from 'antd';
import React from 'react';
import { darken } from 'polished';
import styled from 'styled-components';

import { ImageOptimizer } from '/components/image/ImageOptimizer';
import { colour } from '/styles/variables';

const DisplayName = ({ name, avatar, active }) => (
  <Container>
    <ImageOptimizer>
      {({ buildOptimizedSrc }) => <StyledAvatar src={buildOptimizedSrc(avatar)} />}
    </ImageOptimizer>
    <UserName active={active}>{name ? name : `(unknown)`}</UserName>
  </Container>
);

const Container = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
    color: ${darken(0.05, colour.gray900)};
  }
`;

const StyledAvatar = styled(Avatar)`
  margin: 0 10px 0 0;
  min-width: 34px;
  min-height: 34px;
`;

const UserName = styled.div`
  color: ${({ active }) => active ? 'inherit' : colour.gray600};
  font-weight: 700;
  word-break: keep-all;
  margin: auto 0;
`;

export { DisplayName };
