import { useMutation } from '@apollo/react-hooks';
import { Form, Select, Button, message, Alert } from 'antd';
import Bluebird from 'bluebird';
import gql from 'graphql-tag';
import { get, without } from 'lodash';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Flex } from '/components/box';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { useAlgoliaIndex } from '/hooks/use-algolia-index';
import { boxShadow, colour } from '/styles/variables';
import { isEmail } from '/utils/isEmail';

const SEND_ORGANISATION_INVITATIONS = gql`
  mutation ACG_createInvitations($input: CreateInvitationInput!, $organisationId: String!) {
    ACG_createInvitations(organisationId: $organisationId, input: $input) {
      taskId
      invitations {
        id
      }
    }
  }
`;

const InvitationForm = ({
  tip,
  inviteAsAdmin,
  form,
  organisationId,
  closeModal,
  history,
  currentEmailStatus,
  checkingInvites = false
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const invitationIndex = useAlgoliaIndex({ type: 'invitation' });

  const [sendInvitations] = useMutation(SEND_ORGANISATION_INVITATIONS, {
    onCompleted: async (data) => {
      closeModal();
      history.push(`/organizations/${organisationId}/invitations`);
      const closeMessage = message.loading('Successfully queued invitations. They will be sent shortly.');
      const taskId = get(data, 'ACG_createInvitations.taskId');
      invitationIndex.waitTask(taskId)
        .then(() => Bluebird.resolve().delay(5000))
        .then(closeMessage);
    },
    onError: (e) => {
      setErrorMessage(e.message);
      setSubmitting(false);
    }
  });
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;

  const emailsToBeSent = getFieldValue('emails') || [];

  function handleAdminInvite(event) {
    event.preventDefault();
    setSubmitting(true);

    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        console.log('oops')
        return;
      }

      const emails = get(fieldsValue, 'emails');
      return sendInvitations({
        variables: {
          organisationId,
          input: {
            emails,
            options: {
              inviteAsAdmin,
              skipEmail: false
            }
          }
        }
      });
    });
  }

  const { active, inactive, pending } = currentEmailStatus;

  const validEmails = (rule, value, callback) => {
    setErrorMessage('');

    const lastEmailAdded = value[value.length - 1];

    const invalidEmails = value.filter(email => !isEmail(email));

    if (invalidEmails.length > 0) {
      setFieldsValue({ emails: without(value, ...invalidEmails) });
    }

    if (active.includes(lastEmailAdded)) {
      setErrorMessage(checkingInvites ? `${lastEmailAdded} has already joined!` : `${lastEmailAdded} is an active user already!`);
      setFieldsValue({ emails: without(value, lastEmailAdded) });
    }

    if (inactive.includes(lastEmailAdded)) {
      setErrorMessage(`${lastEmailAdded} is a deactivated user!`);
      setFieldsValue({ emails: without(value, lastEmailAdded) });
    }

    if (pending.includes(lastEmailAdded)) {
      setErrorMessage(`${lastEmailAdded} has a pending invitation already!`);
      setFieldsValue({ emails: without(value, lastEmailAdded) });
    }

    callback();
  };

  return (
    <Form
      onSubmit={handleAdminInvite}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Email Address</InputLabel>
        <Box flex="3">
          {getFieldDecorator('emails', {
            rules: [{
              required: true,
              message: 'Please add emails you wish to send invitations to.',
              validator: validEmails
            }]
          })(
            <EmailList
              autoFocus
              placeholder="Enter comma separated emails or copy and paste in a list"
              mode="tags"
              dropdownStyle={{ display: 'none' }}
              tokenSeparators={[',', ' ']}
            />
          )}
          <Tip>{tip}</Tip>
        </Box>
      </FormItem>
      <Flex justifyContent="flex-end" p="18px 20px">
        <Error>{errorMessage ?
          <Alert message={errorMessage} type="error" showIcon />
          : null}
        </Error>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!emailsToBeSent.length}>
          {submitting ? 'Inviting' : 'Invite'}
        </Button>
      </Flex>
    </Form>
  );
};

const Tip = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colour.gray500};
  line-height: 1;
  margin-top: 8px;
`;

const Error = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: black;
  line-height: 1;
  margin-right: 10px;
`;

const EmailList = styled(Select)`
  .ant-select-selection {
    border-color: ${props => props.invalid ? colour.red : colour.gray300};
    box-shadow: ${boxShadow.inset};
    min-height: 206px;
    max-height: 420px;
    overflow-y: auto;
  }

  .ant-select-selection__choice {
    color: ${colour.gray900};
    background-color: ${colour.gray100};
    font-weight: 500;
    font-size: 13px;
    border-radius: 3px;
  }

  .ant-form-item-control {
    line-height: 1;
  }
`;

const ComposedInvitationForm = Form.create()(withRouter(InvitationForm));

export {
  ComposedInvitationForm as InvitationForm
}