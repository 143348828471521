import React from "react";
import { Box } from "@a-cloud-guru/kermit";
import { ReportingGroupTable } from "./ReportingGroupTable";

const OrganisationReportingGroups = ({ organisationId }) => (
  <Box mt="-64px">
    <ReportingGroupTable organisationId={organisationId} />
  </Box>
);

export { OrganisationReportingGroups };
