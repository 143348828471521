import { darken } from 'polished';
import styled from 'styled-components';

import { colour } from '../styles/variables';
import { PrimaryButton } from './PrimaryButton';

const DefaultButton = styled(PrimaryButton)`
  color: ${colour.gray600};
  background-color: ${colour.gray100};

  :hover {
    color: ${colour.gray600};
    background-color: ${darken(0.02, colour.gray100)};
  }

  :focus {
    color: ${colour.gray600};
    background-color: ${colour.gray100};
  }
`;

export { DefaultButton };
