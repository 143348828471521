import React from 'react';
import gql from 'graphql-tag';
import { get, keyBy } from 'lodash';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_USER_QUIZ_ATTEMPTS_QUERY = (attributes) => gql`
  query ACG_organisationQuizActivityReport(
    $organisationId: String!,
    $courseId: String!,
    $filters: OrganisationQuizActivityFilters,
    $pagination: Page
  ) {
    ACG_organisationQuizActivityReport (
      organisationId: $organisationId,
      filters: $filters,
      pagination: $pagination
    ) ${attributes}
    courseOverview(courseId: $courseId) {
      title
      sections {
        title
        sequence
        components {
          title
          content {
            type
            ... on QuizContent {
              quizName
            }
          }
        }
      }
    }
  }
`;

const getQuizzes = (sections) => {
  if (!sections) {
    return [];
  }
  const quizzes = sections.reduce((totalQuizzes, section) => {
    const quizComponent = section.components.find(component => get(component, 'content.type') === 'quiz');

    if (!quizComponent) {
      return totalQuizzes;
    }
    return [...totalQuizzes, {
      quizId: get(quizComponent, 'content.quizName'),
      quizTitle: quizComponent.title,
      sectionTitle: section.title,
      sectionSequence: section.sequence
    }];
  }, []);

  return quizzes;
};

const mergeResponse = ({ attempts, sections }) => {
  const quizzes = getQuizzes(sections);
  const quizMap = keyBy(quizzes, 'quizId');

  return attempts.map(attempt => ({
    ...attempt,
    quizTitle: get(quizMap, `${attempt.quizId}.quizTitle`) || attempt.quizTitle,
    sectionSequence: get(quizMap, `${attempt.quizId}.sectionSequence`),
    sectionTitle: get(quizMap, `${attempt.quizId}.sectionTitle`) || attempt.quizTitle
  }));
};

const OrganisationUserQuizAttemptsQuery = ({
  page,
  pageSize,
  courseId,
  userIds,
  organisationId,
  attributes = `{
    total
    data {
      id
      userId
      displayName
      email
      picture
      courseId
      quizId
      quizTitle
      score
      completedAt
    }
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={ACG_ORGANISATION_USER_QUIZ_ATTEMPTS_QUERY(attributes)}
      variables={{
        organisationId,
        courseId,
        filters: {
          courseId,
          userIds
        },
        pagination: (page && pageSize) ? {
          pageNumber: page,
          pageSize
        } : undefined
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            const attempts = get(data, `ACG_organisationQuizActivityReport.data`) || [];
            const total = get(data, `ACG_organisationQuizActivityReport.total`) || 0;
            const sections = get(data, 'courseOverview.sections');

            return children({
              data: mergeResponse({ attempts, sections }),
              pagination: {
                page,
                pageSize,
                total
              },
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  )
};

export { OrganisationUserQuizAttemptsQuery };
