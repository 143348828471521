import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import { ImageOptimizer } from '/components/image/ImageOptimizer';
import { colour } from '/styles/variables';

const UserDetails = ({ name, jobTitle, picture, children }) => (
  <>
    <ImageOptimizer>
      {({ buildOptimizedSrc }) => (
        <StyledAvatar src={buildOptimizedSrc(picture)} />
      )}
    </ImageOptimizer>
    <div>
      <ListTitle>{name}</ListTitle>
      <ListSubtitle>{jobTitle || '-'}</ListSubtitle>
      {children}
    </div>
  </>
);

const StyledAvatar = styled(Avatar)`
  margin: 0 10px 0 0;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ListTitle = styled.div`
  color: ${colour.gray900};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  padding: 4px 0 0;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListSubtitle = styled.div`
  color: ${colour.gray500};
  font-size: 13px;
  font-weight: 500;
`;

export { UserDetails, ListTitle, ListSubtitle };
