const TipHeader = `font-size: 16px; font-weight: 600; color: #070152; margin-top: 4px; padding-bottom: 4px;`;
const Score = `display: flex; justify-content: space-between; margin: 2px 2px 12px;`
const KeyTile = `height: 14px; width: 14px; margin-right: 8px; position: relative; top: 3px;`;
const ScoreTitle = `font-size: 14px; color: #78839E;`;
const ScoreValue = `font-weight: 600; color: #3B4966; font-size: 14px;`;

const tooltipTemplate = `
  <span style="${Score}">
    <div style="display: flex" >
      <div style="${KeyTile} background-color: {color}"> </div>
      <div style="${ScoreTitle}">{name}</div>
    </div>
    <div style="${ScoreValue}">{value}</div>
  </span>
`;

const containerTemplate = `
  <div class="g2-tooltip" style="width: 260px; padding: 12px;">
    <!-- title -->
    <div class="g2-tooltip-title" style="${TipHeader}"></div>
    <!-- content list container -->
    <ul class="g2-tooltip-list" style="margin-top: 4px;"></ul>
  </div>
`;

export { containerTemplate, tooltipTemplate };
