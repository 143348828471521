import React from 'react';
import { Link } from 'react-router-dom';
import urlJoin from 'url-join';
import styled from 'styled-components';
import { Flex } from '/components/box';
import { DateFormat } from '/components/date/DateFormat';
import { ListItem } from '/page-components/dashboard/ListItem';
import {
  UserDetails,
  ListTitle,
  ListSubtitle,
} from '/page-components/dashboard/UserDetails';

const UserListItem = ({
  name,
  jobTitle,
  picture,
  userId,
  joinedAt,
  joinMethod,
}) => {
  return (
    <Link to={`/user/${userId}`}>
      <UserCard>
        <Flex flex="2" style={{ maxWidth: '170px' }}>
          <UserDetails name={name} picture={picture} jobTitle={jobTitle} />
        </Flex>
        <Flex
          flexDirection="column"
          textAlign="right"
          minWidth="120px"
          flex="1"
        >
          <Flex width="100%" justifyContent="flex-end">
            <ListTitle>
              <DateFormat format="D MMM YYYY">{joinedAt}</DateFormat>
            </ListTitle>
          </Flex>
          <ListSubtitle>
            by{' '}
            {joinMethod === 'INVITATION' ? 'Email Invitation' : 'Other Means'}
          </ListSubtitle>
        </Flex>
      </UserCard>
    </Link>
  );
};

const UserCard = styled(ListItem)`
  cursor: pointer;
`;

export { UserListItem };
