import React, { useState } from 'react';
import styled from 'styled-components';
import { Table, DateFormat } from '@a-cloud-guru/kermit';
import { DisplayName } from '/components/table/DisplayName';
import { useLearningPathUsersQuery } from '/data/query-hooks';
import { sortNumbersByAttribute, sortAlphaNumericByAttribute } from '/lib/sorters';
import { LevelAchieved } from './LevelAchieved';

const COLUMNS = [
  {
    title: 'Student',
    dataIndex: 'name',
    width: '33%',
    render: (name, record) => (
      <DisplayName name={name} avatar={record.picture} />
    ),
  },
  {
    title: "Level Achieved",
    dataIndex: 'levelAchieved',
    sorter: sortNumbersByAttribute('levelAchieved'),
    width: '33%',
    render: (level, { levelAchievedTitle }) => <LevelAchieved level={level} title={levelAchievedTitle} />
  },
  {
    title: 'Start date',
    dataIndex: 'startDate',
    sorter: sortAlphaNumericByAttribute('startDate'),
    width: '33%',
    render: (date) => <>{date ? <DateFormat format={'D MMMM YYYY'}>{date}</DateFormat> : 'Not started'}</>
  }
];

const LearningPathStudentTable = ({ learningPathId }) => {
  const [page, setPage] = useState(1);

  const {
    loading,
    data,
    error,
    clearCache,
    pagination: { total }
  } = useLearningPathUsersQuery({
    learningPathId,
    page: 1,
    pageSize: 1000
  });

  return (
    <StyledTable
      rowKey={({ id }) => id}
      columns={COLUMNS}
      pagination={{
        current: page,
        pageSize: 10,
        total,
        onChange: current => setPage(current)
      }}
      dataSource={data}
    />
  );
};

const StyledTable = styled(Table)`
  &&& {
    box-shadow: none;
    background-color: #fbfbfb;
    margin: 0;
    tr > th {
      background-color:  #fbfbfb;
    }

    .ant-pagination {
      float: right;
      padding: 0 10px;
    }
  }
`
export { LearningPathStudentTable };
