import React from 'react';
import { get } from 'lodash';
import { RecentEngagementChartDisplay } from './RecentEngagementChartDisplay';
import { RecentEngagementChartNoDataState } from './RecentEngagementChartNoDataState';
import { transform } from './transform-chart-data';

const generateDataSource = (engagementStats) => {
  const row = (event, key) => {
    return engagementStats.reduce(
      (row, stat) => {
        return { ...row, [stat.endDate]: stat[key] };
      },
      { event }
    );
  };

  const percentageRow = (event, joinedCountRow, activeUserRow) => {
    const dateKeys = Object.keys(activeUserRow).filter(
      (key) => key !== 'event'
    );
    return dateKeys.reduce(
      (row, date) => {
        const activeUserCount = activeUserRow[date];
        const joinedCount = joinedCountRow[date];
        return {
          ...row,
          [date]:
            joinedCount === 0
              ? 0
              : Math.round((activeUserCount * 100) / joinedCount),
        };
      },
      { event }
    );
  };
  const joinedCountRow = row('Users Joined', 'joinedCount');
  const activeUserRow = row('Active Users', 'activeUsersCount');
  const completedLessonRow = row(
    'Completed Lessons',
    'completedComponentsCount'
  );
  const completedLessonPerUserRow = row(
    'Completed Lessons Per Active User',
    'completedComponentsPerActiveUser'
  );
  const activeUserPercentageRow = percentageRow(
    '% Active Users',
    joinedCountRow,
    activeUserRow
  );
  return [
    joinedCountRow,
    activeUserRow,
    activeUserPercentageRow,
    completedLessonRow,
    completedLessonPerUserRow,
  ];
};

const RecentEngagementChart = ({ data, summary, type, organisationId }) => {
  const totalContentDurationMinutes =
    get(summary, 'totalContentDurationMinutes') || 0;

  if (totalContentDurationMinutes > 0) {
    const dataSource = generateDataSource(data);
    const chartData = transform({ dataSource, type });
    return (
      <RecentEngagementChartDisplay
        chartData={chartData}
        organisationId={organisationId}
      />
    );
  }

  return <RecentEngagementChartNoDataState />;
};

export { RecentEngagementChart };
