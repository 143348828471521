import React from 'react';
import styled from 'styled-components';
import { CertificationQuery } from './CertificationQuery';
import { Box } from '@a-cloud-guru/kermit';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { CertificationChart } from './CertificationChart';
import { transform } from './transform-chart-data';

export const CertificationsGraph = ({ organisationId }) => {
  return (
    <CertificationQuery organisationId={organisationId}>
      {({
        dataForValid,
        dataForAll,
        getLatest,
        addedLastThirtyDays,
        addedLastMonth,
        addedTwoMonthsAgo,
        loading,
      }) => {
        const latestValid = getLatest(dataForValid);
        const latestAll = getLatest(dataForAll);
        return (
          <React.Fragment>
            <NoShadowModule>
              <ModuleHeader header="Certifications" hideDivider />
            </NoShadowModule>
            <Box pl="15px">
              <CertificationChart
                loading={loading}
                height={350}
                data={transform([dataForValid, dataForAll])}
              />
            </Box>
          </React.Fragment>
        );
      }}
    </CertificationQuery>
  );
};

const NoShadowModule = styled(Module)`
  box-shadow: none;
`;
