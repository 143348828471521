import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from '@a-cloud-guru/react-auth';

import { withAuthorization } from '/lib/withAuthorization';
import { Organisations } from '/routes/Organisations/Organisations';
import { Organisation } from '/routes/Organisation/Organisation';
import { Permission } from '/routes/Permission';
import { SplitIO } from '/providers/splitio';
import { authService } from '/services/auth';
import store from './store';
import { Provider } from 'react-redux';
import { UserDataProvider } from './lib/user-profile';
import { LaunchDarklyFlagsProvider } from './providers/launchdarkly';

const App = () => (
  <Provider store={store}>
    <AuthProvider client={authService}>
      <UserDataProvider>
        <LaunchDarklyFlagsProvider>
          <SplitIO>
            <Router>
              <div>
                <Helmet
                  titleTemplate="%s | Bernie | A Cloud Guru"
                  defaultTitle="Bernie | A Cloud Guru"
                />
                <Route
                  exact
                  path="/organizations"
                  component={withAuthorization(Organisations)}
                />
                <Route
                  path="/organizations/:organisationId/:subroute/:option?"
                  component={withAuthorization(Organisation)}
                />
                <Route
                  path={'/permission'}
                  component={withAuthorization(Permission)}
                />
              </div>
            </Router>
          </SplitIO>
        </LaunchDarklyFlagsProvider>
      </UserDataProvider>
    </AuthProvider>
  </Provider>
);

export default App;
