import { Divider } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { SettingState } from './SettingState';
import { Box, Flex } from '/components/box';
import { Card } from '/components/card/Card';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { colour, brandFont } from '/styles/variables';

export const DetailsRow = ({ label, children, danger = false, style }) => (
  <Row style={style}>
    <Label>{label}</Label>
    <Info danger={danger}>{children || '-'}</Info>
  </Row>
);

export const DetailsRowWithState = ({ value, name, description }) => (
  <DetailsRow
    label={
      <Flex alignItems="center" mr="4px">
        <SettingState active={value} />
        <Box width="150px">{name}</Box>
      </Flex>
    }
  >
    <Flex justifyContent="space-between">
      <Box mr="10px">{description}</Box>
    </Flex>
  </DetailsRow>
);

export const Header = styled.div`
  color: ${colour.gray900};
  font-size: 14px;
  font-weight: 700;
  font-family: ${brandFont};
  text-transform: uppercase;
  line-height: 1;
  padding: 16px 22px;
  margin: 0;
`;

export const StyledCard = styled(Card)`
  height: fit-content;
  margin-bottom: 32px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const Row = styled.span`
  display: flex;
  margin: 12px 24px;
`;

export const Label = styled.div`
  flex: 1;
  color: ${colour.gray900};
  font-size: 14px;
  font-weight: 600;
  margin: 0 14px 0 0;
  min-width: 200px;
`;

export const Info = styled.div.withConfig({
  shouldForwardProp: prop => !['danger'].includes(prop)
})`
  flex: 3;
  display: flex;
  flex-direction: column;
  margin: 0;
  word-wrap: break-word;
  color: ${(props) => (props.danger ? colour.red : undefined)};
`;

export const Text = styled.div`
  width: ${(props) => props.width || 'auto'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: 10px;
`;
