module.exports = {
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  BUGSNAG_APP_VERSION: process.env.REACT_APP_VERSION,
  ACG_ENV: process.env.REACT_APP_ACG_ENV || process.env.ACG_ENV,
  SPLIT_IO_API_KEY: 'n4qvnchl7dgkalisiojdta3cdmml511agda5',
  ACG_ORGANISATION_ID: process.env.ACG_ORGANISATION_ID,
  PS_ORGANISATION_ID: process.env.PS_ORGANISATION_ID,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID
};
