import { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { getConfig } from '/config/get-config';
import { useAlgoliaIndex } from '/hooks/use-algolia-index'
import { getApolloClient } from './make-apollo-client';
import Bluebird from 'bluebird';

Bluebird.config({
  cancellation: true
});

export function useApolloClient() {
  return getApolloClient();
}

export function useMutation(options) {
  const client = useApolloClient();
  return (overrides) => client.mutate({ ...options, ...overrides });
}

export function useAlgoliaRunQuery({ type }) {
  const index = useAlgoliaIndex({ type });

  if (!index) {
    return () => { };
  }

  return options => {
    return index.search({
      query: get(options, 'query') || '',
      filters: get(options, 'filters') || '',
      page: get(options, 'page') - 1 || 0, // page in algolia starts from 0
      hitsPerPage: get(options, 'hitsPerPage') || 10
    });
  };
}

export function useProcessList({ command, delay = 100 }) {
  const [itemsToProcess, setItemsToProcess] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [total, setTotal] = useState(0);
  const [processed, setProcessed] = useState(0);
  const [success, setSuccess] = useState([]);
  const [failed, setFailed] = useState([]);

  useEffect(() => {
    const [item, ...rest] = itemsToProcess;
    let promise;

    if (!item) {
      setCompleted(true);
      promise = Bluebird.resolve();
    } else {
      promise = Bluebird.delay(delay)
        .then(() => command(item))
        .then(response => {
          setSuccess(success.concat(response));
        })
        .catch(() => {
          setFailed(failed.concat(item));
        })
        .finally(() => {
          setProcessed(processed + 1);
          setItemsToProcess(rest);
        });
    }

    return () => promise.cancel();
  }, [itemsToProcess])

  return {
    setItemsToProcess: (items) => {
      setTotal(items.length);
      setCompleted(false);
      setItemsToProcess(items);
    },
    total,
    percent: total ? Math.round((processed / total) * 100) : 100,
    completed,
    processed,
    success,
    failed
  }
}
