import React from 'react';
import moment from 'moment';
import { Spinner, colour } from '@a-cloud-guru/kermit';
import { format } from 'date-fns';
import styled from 'styled-components';
import { OrganisationUserEngagementQuery } from '/data/query';
import { useCachedState } from '/hooks/use-cached-state';
import { Flex, Box } from '/components/box';
import { StyledTable } from '/components/table/StyledTable';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { jsonToCsvDownload } from '/lib/json-to-csv';
import { EngagementChart } from './EngagementChart';
import { EngageRangePicker } from './EngageRangePicker';
import { IntervalButtonGroup } from './IntervalButtonGroup';
import { DownloadButton } from './DownloadButton';
import { generateDataSource} from './transform-table-data';
import { transform } from './transform-chart-data';

const FILTER_KEY_PREFIX = "ernie-engagement-filter-key";

const today = moment();

const configs = {
  week: {
    format: (date) => format(date, 'DD MMM')
  },
  month: {
    format: (date) => format(date, 'YYYY MMM')
  }
};

const download = (dataSource) => jsonToCsvDownload({
  data: dataSource,
  mapper: data => data.map(item => {
    return Object.keys(item).reduce((current, nextProp) => {
      if (nextProp === 'event') {
        return { ...current, 'Event': item[nextProp] };
      }

      return { ...current, [format(nextProp, 'YYYY-MM-DD')]: item[nextProp] };
    }, {})
  })
});

const generateColumns = (engagementStats, type) => {
  const eventColumn = {
    title: 'As at end of',
    dataIndex: 'event',
    width: 250,
    fixed: 'left',
    render: (event) => <strong>{event}</strong>
  };

  const dates = engagementStats.map(({ endDate }) => endDate);
  const columns = dates.reduce((columns, date, index) => {
    return [...columns, {
      title: configs[type.toLowerCase()].format(date),
      dataIndex: date,
      width: 150,
      render: (value) => value === 0 ? '-' : value
    }]
  }, [eventColumn])
  return columns;
};

const EVENTS = ['Joined', 'Started Course', 'Completed Course'];

let chart;
const CourseEngagement = ({ organisationId }) => {
  const [ranges, setRanges] = useCachedState([today.clone().subtract(6, 'months'), today], `${FILTER_KEY_PREFIX}-ranges`, (ranges) => {
    return [moment(ranges[0]), moment(ranges[1])];
  });
  const [type, setType] = useCachedState('MONTH', `${FILTER_KEY_PREFIX}-type`);

  return (
    <NoMarginModule>
      <ModuleHeader
        header="COURSE ENGAGEMENT"
        subheader="Track unique users who have joined and engaged with a course"
      >
        <Flex justifyContent="flex-end">
          <Box ml="10px">
            <IntervalButtonGroup
              value={type}
              onChange={setType}
            />
          </Box>
          <Box ml="10px">
            <EngageRangePicker
              value={ranges}
              onRangeChange={setRanges}
              onTypeChange={setType}
            />
          </Box>
        </Flex>
      </ModuleHeader>
      <OrganisationUserEngagementQuery
        organisationId={organisationId}
        from={ranges[0].format('YYYY-MM-DD')}
        to={ranges[1].format('YYYY-MM-DD')}
        interval={type}
        renderLoading={() => (
          <Flex justifyContent="center" alignItems="center" height="400px">
            <Spinner size="3rem" />
          </Flex>
        )}
      >
        {
          ({ data: { engagementStats, organisation }, loading }) => {
            const columns = generateColumns(engagementStats, type);
            const dataSource = generateDataSource(engagementStats, organisation);
            const chartData = loading ? [] : transform({
              columns,
              dataSource: dataSource.filter(({ event }) => EVENTS.includes(event)),
              type: type.toLowerCase()
            });
            const scrollX = 250 + (Object.keys(dataSource[0]).length - 1) * 150;
            const filteredDataSource = dataSource.filter(({ event }) => EVENTS.includes(event));
            return (
              <React.Fragment>
                <EngagementChart
                  data={chartData}
                  onLoad={(chartInstance) => chart = chartInstance }
                />
                <StyledEngagementTable
                  rowKey="event"
                  pagination={false}
                  columns={columns}
                  dataSource={filteredDataSource}
                  scroll={{ x: scrollX }}
                  title={() => (
                    <Flex justifyContent="flex-end" pr="20px" pb="5px">
                      <DownloadButton
                        downloadTable={() => filteredDataSource && download(filteredDataSource)}
                        downloadChart={() => chart && chart.downloadImage()}
                      />
                    </Flex>
                  )}
                  loading={{
                    indicator: <Spinner size="2rem" />,
                    spinning: loading
                  }}
                />
              </React.Fragment>
            );
          }
        }
      </OrganisationUserEngagementQuery>
    </NoMarginModule>
  );
}

const NoMarginModule = styled(Module)`
  margin: 0;

  .kermit-date-range-picker {
    right: 10px;
    z-index: 1049;
  }
`;

const StyledEngagementTable = styled(StyledTable)`
  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td {
    background: ${colour.gray100};
  }
  tr > th {
    word-break: keep-all;
    line-height: 1.2
  }
  tr > td {
    word-break: keep-all;
  }
`;

export { CourseEngagement };
