import React from 'react';

const ActionsEnum = Object.freeze({
  FEATURE_OVERRIDE_ADDED: 'Add User Feature Override',
  UPDATE_USER_PACKAGE: 'Update User Package',
  PACKAGE_FEATURE_REMOVED: 'Package Feature Removed',
  PACKAGE_FEATURE_ADDED: 'Package Feature Added',
  FEATURE_CREATED: 'Feature Created',
  PACKAGE_CREATED: 'Package Created',
  FEATURE_DELETED: 'Feature Deleted',
  PACKAGE_DELETED: 'Package Deleted',
  ADD_ORG_FEATURE_OVERRIDE: 'Add Organisation Feature Override',
});

export const DetailsColumn = ({ row }) => {
  let details = '';
  switch (row.action) {
    case ActionsEnum.FEATURE_OVERRIDE_ADDED:
    case ActionsEnum.PACKAGE_FEATURE_REMOVED:
    case ActionsEnum.PACKAGE_FEATURE_ADDED:
    case ActionsEnum.FEATURE_CREATED:
    case ActionsEnum.FEATURE_DELETED:
    case ActionsEnum.ADD_ORG_FEATURE_OVERRIDE:
      details = row.featureId;
      break;

    default:
      details = row.featureId;
      break;
  }
  return <span>{details}</span>;
};
