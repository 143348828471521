import React, { useEffect } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { GET_ACG_SUBSCRIBER_PACKAGE } from '/data/query/PackageQuery';

import { Box, Flex } from '/components/box';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { colour } from '/styles/variables';
import { StyledCard, StyledDivider, Header } from './Shared';
import { getConfig } from '/config/get-config';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';
import { isReadOnly } from '../../../hooks/isReadOnly';

const { ACG_ENV, ACG_ORGANISATION_ID } = getConfig();

const APPROVE_FEATURE_REQUEST_MUTATION = gql`
  mutation ACG_approveBetaFeature(
    $organisationId: String!
    $feature: BetaFeature!
  ) {
    ACG_approveBetaFeature(organisationId: $organisationId, feature: $feature) {
      organisation {
        id
        betaFeatureRequests {
          displayName
          feature
          status
        }
      }
    }
  }
`;

const FeatureRequestCard = (organisation) => {
  const [approveBetaFeature, { loading }] = useMutation(
    APPROVE_FEATURE_REQUEST_MUTATION
  );

  const readOnly = isReadOnly();
  const featureRequests = get(organisation, 'betaFeatureRequests') || [];
  const orgLoading = get(organisation, 'loading');

  const {
    data,
    refetch: getPackageDetails,
    loading: packageLoading,
  } = useQuery(GET_ACG_SUBSCRIBER_PACKAGE, {
    variables: {
      input: {
        organisationId: organisation.id,
      },
    },
  });

  const packageId =
    get(data, 'ACG_Packaging_subscriberPackage.subscriberPackage.packageId') ||
    '';
  const businessPlusCustomer = packageId.startsWith('b2b-business-plus');
  const allowedToEnableCustomLearningPaths =
    businessPlusCustomer &&
    !(ACG_ENV === 'production' && organisation.id === ACG_ORGANISATION_ID);

  useEffect(() => {
    if (getPackageDetails && !orgLoading) {
      getPackageDetails();
    }
  }, [orgLoading]);

  if (orgLoading || packageLoading) {
    return (
      <StyledCard>
        <Header>Beta Features</Header>
        <StyledDivider />
        <Box>
          <Container>
            <Spinner />
          </Container>
        </Box>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <Header>Beta Features</Header>
      <StyledDivider />
      {featureRequests.map(({ displayName, feature, status }) => (
        <Flex
          key={feature}
          justifyContent="space-between"
          alignItems="center"
          p="12px 24px"
        >
          <Box>
            <Label>{displayName || feature}</Label>
          </Box>
          <Box>
            {status === 'REQUESTED' ? (
              <DisabledWhenReadOnly>
                <PrimaryButton
                  loading={loading}
                  onClick={() => {
                    if (!readOnly)
                      approveBetaFeature({
                        variables: {
                          organisationId: get(organisation, 'id'),
                          feature,
                        },
                      });
                  }}
                >
                  Approve
                </PrimaryButton>
              </DisabledWhenReadOnly>
            ) : (
              <Box color={colour.green}>{status}</Box>
            )}
          </Box>
        </Flex>
      ))}
    </StyledCard>
  );
};

export const Label = styled.div`
  flex: 1;
  color: ${colour.gray900};
  font-size: 14px;
  font-weight: 600;
  margin: 0 14px 0 0;
  min-width: 200px;
`;

export { FeatureRequestCard };
