import React, { useState } from 'react';
import { Alert } from 'antd';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';

import get from 'lodash.get';
import styled from 'styled-components';
import { DateFormat, PaginatedTable, Flex, colour } from '@a-cloud-guru/kermit';
import { useGroupsQuery } from '/data/query-hooks';
import { GroupUsersQuery } from '/data/query';
import { AsyncExportButton } from '/components/export/AsyncExportButton';
import { Group } from './Group';
import { StudyGroupProgressTable } from './StudyGroupProgressTable';
import { StudyGroupUserProgressQuery } from '../../../data/query';

const groupUserCountMap = {};

const STUDY_GROUP_PROGRESS_DOWNLOAD_URL = gql`
  query ACG_organisationStudyGroupProgressReport($organisationId: String!) {
    ACG_organisationStudyGroupProgressReport(organisationId: $organisationId) {
      csvDownloadUrl
    }
  }
`;

const getColumns = ({ organisationId }) => [
  {
    title: 'Group Name',
    dataIndex: 'name',
    width: '30%',
    render: (name, { description, colorScheme }) => (
      <Group
        name={name}
        description={description}
        colorScheme={colorScheme || 'sugarsweets'}
      />
    ),
  },
  {
    title: 'Course',
    dataIndex: 'courseTitle',
    width: '30%',
    render: (courseTitle) => <KeepAll>{courseTitle || '-'}</KeepAll>,
  },
  {
    title: 'Accel Pgm?',
    width: '15%',
    render: ({ active, studyPlanEnabled }) => (
      <KeepAll>{studyPlanEnabled && active ? 'Yes' : ''}</KeepAll>
    ),
  },
  {
    title: 'Students',
    width: '15%',
    render: ({ id }) => (
      <KeepAll>
        <GroupUsersQuery groupId={id} organisationId={organisationId}>
          {({ loading, pagination: { total } }) => {
            groupUserCountMap[id] = total;
            return loading ? '' : total;
          }}
        </GroupUsersQuery>{' '}
        Students
      </KeepAll>
    ),
  },
  {
    title: 'Avg Complete',
    width: '15%',
    render: ({ id }) => {
      return (
        <StudyGroupUserProgressQuery
          groupId={id}
          organisationId={organisationId}
        >
          {({ data }) => {
            let avgPercentComplete = 0;
            if (data.length > 0) {
              for (const student of data) {
                avgPercentComplete += student.progress;
              }
              avgPercentComplete = Math.round(avgPercentComplete / data.length);
            }
            return <div>{avgPercentComplete}%</div>;
          }}
        </StudyGroupUserProgressQuery>
      );
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    width: '15%',
    render: (startDate, { active }) => (
      <KeepAll>
        {startDate && active ? (
          <DateFormat format="MMM D, YYYY">{startDate}</DateFormat>
        ) : (
          '-'
        )}
      </KeepAll>
    ),
  },
  {
    title: 'Target Date',
    dataIndex: 'completeDate',
    width: '15%',
    render: (completeDate, { active }) => (
      <KeepAll>
        {completeDate && active ? (
          <DateFormat format="MMM D, YYYY">{completeDate}</DateFormat>
        ) : (
          '-'
        )}
      </KeepAll>
    ),
  },
];

const PAGE_SIZE = 10;

const StudyGroupTable = ({ organisationId }) => {
  const [page, setPage] = useState(1);
  const {
    data,
    pagination: { total, pageSize },
  } = useGroupsQuery({
    page,
    pageSize: PAGE_SIZE,
    groupType: 'study_group',
  });

  return (
    <StyledTable
      rowKey={({ id }) => id}
      columns={getColumns({ organisationId })}
      dataSource={data}
      title={() => (
        <Flex justifyContent="flex-end" alignItems="center" mr="16px">
          <ApolloConsumer>
            {(client) => (
              <AsyncExportButton
                label="Export CSV"
                onExport={async () => {
                  const { data } = await client.query({
                    query: STUDY_GROUP_PROGRESS_DOWNLOAD_URL,
                    variables: {
                      organisationId,
                    },
                  });

                  return get(
                    data,
                    'ACG_organisationStudyGroupProgressReport.csvDownloadUrl'
                  );
                }}
              />
            )}
          </ApolloConsumer>
        </Flex>
      )}
      pagination={{
        current: page,
        pageSize,
        total,
        onChange: (current) => setPage(current),
      }}
      expandedRowRender={({ id, active }) =>
        active ? (
          <StudyGroupProgressTable
            groupId={id}
            organisationId={organisationId}
            numberOfUsers={groupUserCountMap[id]}
          />
        ) : (
          <Alert
            message="This study group has not been activated."
            type="info"
            showIcon
          />
        )
      }
    />
  );
};

const KeepAll = styled.div`
  word-break: keep-all;
`;

const StyledTable = styled(PaginatedTable)`
  .ant-table table {
    table-layout: fixed;
  }

  .ant-table-tbody > tr {
    color: ${colour.gray500};
  }

  tr > th {
    text-transform: uppercase;
    color: ${colour.blue};
    padding: 12px 16px;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: #fff;
  }

  td:first-child {
    cursor: pointer;
  }
`;

export { StudyGroupTable };
