import { addDays, format } from 'date-fns';
import get from 'lodash.get';
import qs from 'qs';

import { constructUrlQuery } from '/utils/constructUrlQuery';

const generateExpiryFilter = () => {
  const currentDate = Date.now();
  const comparisonDate = format(addDays(currentDate, 30), 'x')
  return `active:true AND archived_at = 0 AND (subscription_end_date: ${currentDate} TO ${comparisonDate})`;
};

const generateExpiredFilter = () => {
  const comparisonDate = format(Date.now(), 'x')
  return `active:true AND archived_at = 0 AND (subscription_end_date < ${comparisonDate})`;
};

const LICENSE_FILTER_MAP = {
  ALL: 'active:true',
  EXPIRING: generateExpiryFilter(),
  EXPIRED: generateExpiredFilter(),
  ACTIVE: 'active: true AND archived_at = 0',
  ARCHIVED: 'active: true AND archived_at > 0'
};

const ACCOUNT_TYPE_FILTER_MAP = {
  PAYING: `account_type:PAYING`,
  TEST: `account_type:'TEST'`,
  TRIAL: `account_type:'TRIAL'`,
  INTERNAL: `account_type:'INTERNAL'`,
  FREEBIE: `account_type:'FREEBIE'`
};

const CHARGE_MODEL_FILTER_MAP = {
  SUBSCRIPTION: 'charge_model: subscription',
  MARKETPLACE: 'charge_model: marketplace',
  'SELF SERVICE': 'charge_model: selfservice',
  POINTS: 'charge_model: points',
  PURCHASE: 'charge_model: purchase',
  LEGACY: 'charge_model: legacy'
};

const getLicense = ({ urlQuery }) => {
  if (get(urlQuery, 'license')) {
    return urlQuery.license.toUpperCase();
  }

  return 'ACTIVE';
};

const getAccountType = ({ urlQuery }) => {
  const DEFAULT_ACCOUNT_TYPE = {
    PAYING: false,
    TEST: false,
    TRIAL: false,
    INTERNAL: false,
    FREEBIE: false
  };

  const account = get(urlQuery, 'account');
  if (account) {
    return account
      .split(',')
      .reduce((current, next) => Object.assign({}, current, { [next.toUpperCase()]: true }), DEFAULT_ACCOUNT_TYPE);
  }

  return DEFAULT_ACCOUNT_TYPE;
};

const getChargeModel = ({ urlQuery }) => {
  const chargeModel = get(urlQuery, 'chargemodel') || '';
  return chargeModel.split(',').map(cm => cm.toUpperCase()).filter(Boolean);
};


const OrganisationsFilters = ({ serverState, children }) => {
  const urlQuery = qs.parse(serverState.location.search, { ignoreQueryPrefix: true });
  const licenseStateFilter = getLicense({ urlQuery });
  const accountTypeFilter = getAccountType({ urlQuery });
  const chargeModelFilter = getChargeModel({ urlQuery });
  const searchQuery = get(urlQuery, 'query') || '';

  const constructLicenseQuery = (value) => {
    const { license, ...rest } = urlQuery;
    return constructUrlQuery({ license: value, ...rest });
  }

  const constructAccountTypeQuery = (value) => {
    const { account, ...rest } = urlQuery;
    const normalizedValue = value || [];
    if (!normalizedValue.length) {
      return constructUrlQuery(rest);
    }

    return constructUrlQuery({
      ...rest,
      account: normalizedValue.map(accountType => accountType.toLowerCase()).join(',')
    });
  };

  const constructChargeModelQuery = (value) => {
    const { chargemodel, ...rest } = urlQuery;
    const normalizedValue = value || [];
    if (!normalizedValue.length) {
      return constructUrlQuery(rest);
    }

    return constructUrlQuery({
      ...rest,
      chargemodel: normalizedValue.map(accountType => accountType.toLowerCase()).join(',')
    });
  }

  const constructSearchQuery = (value) => {
    const { query, ...rest } = urlQuery;
    return constructUrlQuery({ query: value, ...rest });
  };

  const constructQuery = ({ type, value }) => {
    switch (type) {
      case 'license':
        return constructLicenseQuery(value);
      case 'account':
        return constructAccountTypeQuery(value);
      case 'chargemodel':
        return constructChargeModelQuery(value);
      case 'query':
        return constructSearchQuery(value);
      default:
        return serverState.location.search;
    }
  }

  const updateUrlQuery = (props) => {
    const query = constructQuery(props);
    serverState.history.push(`organizations${query}`);
  }

  const licenseFilter = LICENSE_FILTER_MAP[licenseStateFilter];
  const accountFilter = Object.entries(accountTypeFilter)
    .filter(([_, value]) => !!value)
    .reduce((current, [key]) => {
      if (current === '') {
        return ` AND ${ACCOUNT_TYPE_FILTER_MAP[key]}`;
      }
      return current += ` OR ${ACCOUNT_TYPE_FILTER_MAP[key]}`;
    }, '');

  const chargeModelIndexFilter = chargeModelFilter
    .reduce((current, chargeModel) => {
      if (!current) {
        return ` AND ${CHARGE_MODEL_FILTER_MAP[chargeModel]}`;
      }
      return current.concat(` OR ${CHARGE_MODEL_FILTER_MAP[chargeModel]}`);
    }, '');

  return children({
    filters: licenseFilter.concat(accountFilter).concat(chargeModelIndexFilter),
    licenseStateFilter,
    accountTypeFilter,
    chargeModelFilter,
    query: searchQuery,
    updateUrlQuery,
    ACCOUNT_TYPES: Object.keys(ACCOUNT_TYPE_FILTER_MAP),
    CHARGE_MODELS: Object.keys(CHARGE_MODEL_FILTER_MAP)
  })
};

export { OrganisationsFilters };
