import React from 'react';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

const EngagementChart = ({ data, onLoad }) => {
  if (!process.browser) {
    return null;
  }

  return (
    <Chart
      height={400}
      data={data}
      forceFit
      scale={{ userCount: { min: 0 }}}
      padding={[55, 50, 45, 50]}
      onGetG2Instance={onLoad}
    >
      <Axis name="userCount" />
      <Legend
        clickable={false}
        position="top-left"
        offsetX={10}
        offsetY={-10}
      />
      <Tooltip />
      <Geom
        type="interval"
        position="Period*userCount"
        color={['event', ['#86E6C8', '#209BDD', '#0860BF']]}
        adjust={[
          {
            type: "dodge",
            dodgeBy: "event",
            marginRatio: 0
          }
        ]}
      />
    </Chart>
  );
};

export { EngagementChart };