import { Alert } from 'antd';

export const PackageError = ({ error, ...props }) =>
  !error ? null : (
    <Alert
      message={error.code || 'Error from Package Service'}
      description={error.message}
      type="error"
      showIcon
    />
  );
