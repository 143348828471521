import React from 'react';
import { OrganisationUsersJoinedByOrganisationQuery } from '/data/query';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { UserList } from './UserList';

const LatestUsers = ({ organisationId }) => (
  <Module>
    <ModuleHeader
      header="Recent Sign Ups"
      subheader="The most recent users to join your team"
    />
    <OrganisationUsersJoinedByOrganisationQuery organisationId={organisationId} renderLoading={false}>
      {({ users, loading }) => (
        <UserList
          loading={loading}
          users={users}
          organisationId={organisationId}
        />
      )}
    </OrganisationUsersJoinedByOrganisationQuery>
  </Module>
);

export { LatestUsers };
