import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { StyledModal, FormContent, Title } from '/components/modal';
import { MenuIcon } from '/components/ernie-icons/MenuIcon';
import { colour } from '/styles/variables';
import { TransferSubscriptionForm } from './TransferSubscriptionForm';
import { isReadOnly } from '../../../../hooks/isReadOnly';

const MenuOptions = ({ chargeModel, openModal }) => {
  const showSubscription = !/subscription/gi.test(chargeModel);

  return (
    <Menu style={{ boxShadow: '0 12px 24px 0 rgb(7 2 71 / 6%)' }}>
      <Menu.Item key="0">
        <a onClick={() => openModal({ type: 'Marketplace' })}>
          Convert to marketplace
        </a>
      </Menu.Item>
      {showSubscription && (
        <Menu.Item key="1">
          <a onClick={() => openModal({ type: 'Direct' })}>
            Convert to Direct Sale
          </a>
        </Menu.Item>
      )}
    </Menu>
  );
};

export const TransferSubscriptionMenu = ({ organisation }) => {
  const { chargeModel } = organisation;
  const [showingMenu, setShowingMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState();
  const readOnly = isReadOnly();

  const openModal = ({ type }) => {
    if (readOnly) return;
    setShowingMenu(false);
    setModalType(type);
    setVisible(true);
  };

  return (
    <>
      <Dropdown
        visible={showingMenu}
        overlay={() => (
          <MenuOptions openModal={openModal} chargeModel={chargeModel} />
        )}
      >
        <a
          className="ant-dropdown-link"
          onClick={(e) => {
            e.preventDefault();
            setShowingMenu(!showingMenu);
          }}
        >
          <MenuIcon margin={'0 0 0 10px'} size={'1.25em'} />
        </a>
      </Dropdown>
      <StyledModal
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
      >
        <FormContent>
          <TitleContainer>
            <Title>Convert organization to {modalType}</Title>
          </TitleContainer>
          <TransferSubscriptionForm
            closeModal={() => setVisible(false)}
            organisation={organisation}
            type={modalType}
          />
        </FormContent>
      </StyledModal>
    </>
  );
};

const TitleContainer = styled.div`
  padding: 18px 20px;
  border-bottom: solid 1px ${colour.gray300};
`;
