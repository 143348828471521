import React from 'react';
import { MarketplaceForm } from './TransferToMarketplace';
import { DirectSalesForm } from './DirectSalesForm';

export const TransferSubscriptionForm = ({
  type,
  closeModal,
  organisation
}) => {
  if (/marketplace/gi.test(type)) {
    return <MarketplaceForm organisationId={organisation.id} />
  } else if (/direct/gi.test(type)) {
    return <DirectSalesForm closeModal={closeModal} organisation={organisation} />
  }
  closeModal();

  return <></>;
};
