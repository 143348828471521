import React from "react";
import { StyledModal, Title, Content } from "/components/modal";
import { Box } from "@a-cloud-guru/kermit"

const ToggleFeatureFlagsModal = ({ 
  loading, 
  mutationCallback, 
  onCancel, 
  visible, 
  enabled, 
  featureFlagName 
}) => (
  <StyledModal
    closable={false}
    confirmLoading={loading}
    maskClosable={!loading}
    onOk={async () => {
      await mutationCallback();
      setTimeout(onCancel, 300);
    }}
    onCancel={onCancel}
    okText="Confirm"
    visible={visible}
    width="600px"
  >
    <Box mb="30px">
      <Content>
        <Title>
          Do you wish to <strong>{enabled ? "turn off" : "turn on"}</strong>{" for feature "}<strong>{featureFlagName}</strong>?
        </Title>
      </Content>
    </Box>
  </StyledModal>
);

export { ToggleFeatureFlagsModal };
