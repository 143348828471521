import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import { space } from 'styled-system';

import { colour } from '../styles/variables';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  ${space}
  display: block;
  border: 2px solid ${props => props.colour || colour.purple};
  border-left-color: ${props => rgba(props.colour || colour.purple, 0.3)};
  border-radius: 100%;
  animation: ${rotation} 0.8s infinite linear;
  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          height: 1rem;
          width: 1rem;
        `;
      case "medium":
        return css`
          height: 2rem;
          width: 2rem;
        `;
      case "large":
        return css`
          height: 3rem;
          width: 3rem;
        `;
      default:
        return css`
          height: ${size || "2rem"};
          width: ${size || "2rem"};
        `;
    }
  }}
`;

export default Spinner;
