import React from 'react';
import { get, isString, isEmpty } from 'lodash';
import { getConfig } from '/config/get-config';
import { AlgoliaProvider, defineAlgoliaApp } from 'react-algolia';
import { useAlgoliaApiKey } from './use-algolia-api-key';

const { ALGOLIA_APP_ID, ALGOLIA_STAGE } = getConfig();

const PUBLIC_INDEX_NAMES = {
  course: `${ALGOLIA_STAGE}_courses`,
  product: `${ALGOLIA_STAGE}_products`
};

const SCOPED_INDEX_NAMES = {
  user: {
    default: `private_${ALGOLIA_STAGE}_all_organisation_users`,
    replicas: {
      last_seen_timestamp_ascend: `private_${ALGOLIA_STAGE}_all_organisation_users_last_seen_timestamp_ascend`,
    },
  },
  group: `private_${ALGOLIA_STAGE}_all_organisation_groups`,
  invitation: {
    default: `private_${ALGOLIA_STAGE}_all_organisation_invitations`,
    replicas: {
      sent_at_ascend: `private_${ALGOLIA_STAGE}_all_organisation_invitations_sent_at_ascend`,
    },
  },
};

const defineAlgoliaApps = ({ applicationId, apiKey, indexNames }) => {
  if (!applicationId || !apiKey) {
    return [];
  }

  return Object.keys(indexNames).reduce((apps, type) => {
    const indexName = indexNames[type];

    if (isString(indexName)) {
      const app = defineAlgoliaApp(applicationId, apiKey, indexName);

      return [...apps, app];
    }

    const defaultIndexName = get(indexName, 'default');
    const defaultApp = defineAlgoliaApp(
      applicationId,
      apiKey,
      defaultIndexName
    );

    const replicas = get(indexName, 'replicas');

    const replicaApps = Object.keys(replicas).map((replica) => {
      return defineAlgoliaApp(applicationId, apiKey, replicas[replica]);
    });

    return [...apps, ...[defaultApp, ...replicaApps]];
  }, []);
};
const Algolia = ({ children, organisationId }) => {
  const { publicKey, scopedKey } = useAlgoliaApiKey(organisationId);

  const scopedApplications = defineAlgoliaApps({
    indexNames: SCOPED_INDEX_NAMES,
    applicationId: ALGOLIA_APP_ID,
    apiKey: scopedKey,
  });

  const publicApplications = defineAlgoliaApps({
    indexNames: PUBLIC_INDEX_NAMES,
    applicationId: ALGOLIA_APP_ID,
    apiKey: publicKey,
  });

  const applications = [...scopedApplications, ...publicApplications];

  return (
    <AlgoliaProvider applications={applications}>{children}</AlgoliaProvider>
  );
};

const getIndexNameFromType = (type, sorter) => {
  const indexMap = {
    ...SCOPED_INDEX_NAMES,
    ...PUBLIC_INDEX_NAMES,
  };

  const indexName = indexMap[type];

  if (isString(indexName)) {
    return indexName;
  }

  const replica = isEmpty(sorter)
    ? undefined
    : `${sorter.field}_${sorter.order}`;

  return replica
    ? get(indexName, `replicas.${replica}`) || get(indexName, 'default')
    : get(indexName, 'default');
};

export { getIndexNameFromType };

export default Algolia;
