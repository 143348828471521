import get from 'lodash.get';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const getFilters = ({
  learningPathId,
  teamIds
}) => {
  let filters = 'active:true';

  if (learningPathId) {
    filters += ` AND learning_path:${learningPathId}`;
  }

  if (teamIds && !teamIds.includes('all')) {
    filters += ` AND ${teamIds.map(id => `team:${id}`).join(' OR ')}`;
  }

  return filters;
}

const useLearningPathUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  learningPathId,
  teamIds,
  key = 0
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'user',
    query,
    page,
    hitsPerPage: pageSize,
    filters: getFilters({ learningPathId, teamIds }),
    key
  });

  const hits = get(searchResults, 'hits') || [];

  const users = hits.map(hit => {
    const { objectID, team, name, picture, learning_path_level, ...rest } = hit;
    const learningPath = get(hit, `learning_path_${learningPathId}`);
    const startDate = get(learningPath, `start_date`) || null;
    const levelAchieved = get(learningPath, 'level') || 0;
    const levelAchievedTitle = get(learningPath, 'level_name') || 'No level attained';

    return {
      id: objectID,
      name,
      teamId: team === 'no-team' ? undefined : team,
      picture,
      startDate,
      levelAchieved,
      levelAchievedTitle,
      ...rest
    };
  });

  return {
    data: users,
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useLearningPathUsersQuery };