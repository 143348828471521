import styled from 'styled-components';
import { Button } from 'antd';
import { lighten } from 'polished';

import { colour } from '/styles/variables';

export const LinkButton = styled(Button)`
  &&& {
    background: transparent;
    box-shadow: none;
    border: none;
    color: ${colour.blueLight};

    &:hover {
      background-color: ${lighten(0.4, colour.blueLight)};
    }

    &:disabled {
      color: unset;
    }
  }
`;
