import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import Flex from '../flex';
import { KeyboardNavigation } from './KeyboardNavigation';
import { DropdownReset } from './DropdownReset';
import { DropdownButton } from './DropdownButton';
import { DropdownInput } from './DropdownInput';
import { DropdownMenu } from './DropdownMenu';
import { DropdownMenuItem } from './DropdownMenuItem';
import { DropdownCaretIcon } from './DropdownCaretIcon';
import { DropdownMenuDivider } from './DropdownMenuDivider';
import { MultiCategoryDropdown } from './MultiCategoryDropdown';

class ACGDropdown extends React.Component {
  static Reset = DropdownReset;
  static Button = DropdownButton;
  static Input = DropdownInput;
  static Menu = DropdownMenu;
  static MenuItem = DropdownMenuItem;
  static CaretIcon = DropdownCaretIcon;
  static Divider = DropdownMenuDivider;
  static MultiCategoryDropdown = MultiCategoryDropdown;

  static defaultProps = {
    async: false,
    width: "252px",
    options: [],
    size: 10,
    showReset: true,
    placeholder: "",
    defaultLabel: "All",
    emptyState: null,
    onChange: () => { },
    onSearch: () => { }
  }

  constructor(props) {
    super(props);
    this.initialState = {
      visible: false,
      focused: false,
      value: this.props.value,
      input: ''
    };
    this.state = this.initialState;
  }

  selectItem = (value) => {
    this.setState({ value, visible: false, focused: false, selectedIndex: -1 });
    const selectedOption = this.getOptions().find(option => option.value === value);
    this.props.onChange(value, selectedOption);
  }

  getLabel = () => {
    const { value } = this.state;

    const option = this.getOptions().find(option => option.value === value);

    return option ? option.text : this.props.defaultLabel;
  }

  getOptions = () => {
    const { async, options, size } = this.props;
    const { input } = this.state;

    if (async) {
      return options;
    }

    return options.filter(({ text }) => text.toLowerCase().includes(input.toLowerCase()))
      .slice(0, size);
  }

  handleInputChange = (e) => {
    const { async, onSearch } = this.props;
    const input = e.target.value;

    if (async) {
      return onSearch(input);
    }
    return this.setState({ input });
  }

  render() {
    const { value, visible, focused } = this.state;
    const {
      showReset,
      placeholder,
      emptyState,
      onChange
    } = this.props;

    const options = this.getOptions();

    return (
      <KeyboardNavigation
        options={options}
        onSelect={this.selectItem}
      >
        {({ selectedIndex }) => (
          <Flex alignItems="center">
            <DropdownReset
              visible={value && showReset}
              reset={() => {
                this.setState({ ...this.initialState, value: undefined });
                onChange();
              }}
            />

            <Dropdown
              visible={visible}
              trigger={["click"]}
              onVisibleChange={(visible) => {
                if (!visible && focused) return;
                this.setState({ visible });
              }}
              overlay={
                <DropdownMenu onClick={({ item, key }) => {
                  if (key === 'input' || key === 'empty-state') return;
                  this.selectItem(key);
                }}>
                  <DropdownMenuItem key="input" type="input">
                    <DropdownInput
                      placeholder={placeholder}
                      onMouseLeave={() => {
                        this.setState({ focused: false });
                      }}
                      onChange={this.handleInputChange}
                    />
                  </DropdownMenuItem>
                  <DropdownMenuDivider />
                  {options.length === 0 && emptyState && (
                    <DropdownEmptyStateMenuItem key="empty-state">
                      {emptyState}
                    </DropdownEmptyStateMenuItem>
                  )}
                  {
                    options.map(({ value, text }, index) => {
                      return (
                        <DropdownMenuItem
                          key={value}
                          selected={selectedIndex === index}
                        >
                          {text}
                        </DropdownMenuItem>
                      )
                    })
                  }
                </DropdownMenu>
              }
            >
              <StyledDropdownButton width={this.props.width}>
                <TruncatedLabel>{this.getLabel()}</TruncatedLabel>
                <DropdownCaretIcon type="caret-down" />
              </StyledDropdownButton>
            </Dropdown>
          </Flex>
        )}
      </KeyboardNavigation>
    )
  }
};

const StyledDropdownButton = styled(DropdownButton)`
  padding: 8px 8px 8px 14px;
  margin: 0 8px;
`;

const TruncatedLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const DropdownEmptyStateMenuItem = styled(DropdownMenuItem)`
  cursor: default;

  background-color: #fff;

  :hover {
    background-color: #fff;
  }
`;

export default ACGDropdown;