import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '/lib/hooks';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';

const CONVERT_TO_MARKETPLACE = gql`
  mutation ($input: ACGDEV_convertToMarketplaceChargeModelInput!) {
    ACGDEV_convertToMarketplaceChargeModel (input: $input)
  }
`;

const TransferToMarketplace = ({
  organisationId,
  form
}) => {
  const { getFieldDecorator, getFieldValue, getFieldError, isFieldTouched } = form;
  const convertToMarketplace = useMutation({
    mutation: CONVERT_TO_MARKETPLACE
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const updateSubscription = async (event) => {
    event.preventDefault();
    const checkValues = () => new Promise((resolve) => {
      form.validateFields((err) => {
        resolve(err ? false : true);
      });
    });
    const result = await checkValues();
    if (!result) {
      return;
    }
    setSubmitting(true);

    await convertToMarketplace({
      variables: {
        input: {
          organisationId,
          ProductCode: getFieldValue('productCode'),
          CustomerIdentifier: getFieldValue('customerId'),
        },
      },
    }).catch((e) => {
      setErrorMessage(e.message);
      setSubmitting(false);
    }).then(() => {
      window.location.reload();
    });
  }

  const customerIdError = isFieldTouched('customerId') && getFieldError('customerId');
  const productCodeError = isFieldTouched('productCode') && getFieldError('productCode');

  return (
    <Form
      onSubmit={updateSubscription}
      hideRequiredMark
    >
      <Container>
        <Form.Item validateStatus={customerIdError ? 'error' : ''} help={customerIdError || ''}>
          {getFieldDecorator('customerId', {
            rules: [{ required: true, message: 'Please input the marketplace customer id' }],
          })(
            <Input placeholder="Customer Id"/>,
          )}
        </Form.Item>
        <Form.Item validateStatus={productCodeError ? 'error' : ''} help={productCodeError || ''}>
          {getFieldDecorator('productCode', {
            rules: [{ required: true, message: 'Please input the marketplace product code' }],
          })(
            <Input placeholder="Product Code"/>,
          )}
        </Form.Item>

        <Footer>
          <span>{errorMessage}</span>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitting ? 'Converting' : 'Convert'}
          </Button>
        </Footer>
      </Container>
    </Form>
  );
};

export const MarketplaceForm = Form.create()(TransferToMarketplace);

const Container = styled.div`
  width: 100%;
  padding: 20px 100px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
`;
