import { colour } from '@a-cloud-guru/kermit';
import get from 'lodash.get';
import { darken } from 'polished';
import styled from 'styled-components';


const TableAction = styled.span`
  color: ${props => get(colour, props.colour || 'blueLight')};
  cursor: pointer;
  word-break: keep-all;
  padding: ${props => props.last ? '0' : '0 18px 0 0'};

  :hover {
    color: ${props => darken(0.15, get(colour, props.colour|| 'blueLight'))};
  }
`;

export { TableAction };
