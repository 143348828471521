import React from 'react';
import styled from 'styled-components';
import { colour } from '../styles/variables';

const DropdownReset = ({ visible, reset }) => (
  visible ? <ResetLink onClick={reset}>Reset</ResetLink> : null
)

const ResetLink = styled.a`
  color: ${colour.blueLight};
`;

export { DropdownReset };