import React from 'react';
import { Icon, Input } from 'antd';
import styled from 'styled-components';
import { lighten } from 'polished';

import { boxShadow, colour } from '/styles/variables';

const SearchBox = ({ placeholder, onChange, ...restProps }) => (
  <Search
    prefix={<Icon type="search" />}
    placeholder={placeholder}
    onChange={onChange}
    {...restProps}
  />
);

const Search = styled(Input)`
  width: ${props => props.width || '272px'};
  height: ${props => props.height || '32px'};

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    :focus, :hover {
      border: none;
    }
  }

  input {
    color: ${colour.gray900};
    box-shadow: ${boxShadow.inset};
    border: solid 1px ${lighten(0.15, colour.gray400)};

    :focus, :hover {
      border-color: ${colour.gray400};
      box-shadow: ${boxShadow.inset};
    }
  }
`;

export { SearchBox };