import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import { authService } from '../services/auth';
import { getConfig } from '../config/get-config';

const {
  GRAPHQL_API_ENDPOINT,
  BUGSNAG_APP_VERSION: APP_VERSION,
  BUGSNAG_RELEASE_STAGE: STAGE
} = getConfig();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const authLink = () =>
  setContext(() => {
    return authService.getToken()
      .then(token => {
        const jwtToken = token || '';
        return {
          headers: {
            authorization: `Bearer ${jwtToken}`,
          },
        };
      });
  });

const httpLink = () => {
  return new HttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_API_ENDPOINT
  });
};

const makeApolloClient = () => new ApolloClient({
  name: `ernie-${STAGE}`,
  version: APP_VERSION,
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  link: ApolloLink.from([authLink(), httpLink()]),
  cache: new InMemoryCache({ fragmentMatcher })
});

const client = makeApolloClient();

const getApolloClient = () => client;

export {
  getApolloClient
};
