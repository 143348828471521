import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components'

import { Flex } from '/components/box';
import { boxShadow, brandFont, colour } from '/styles/variables';

const Masthead = ({ title, subtitle, navigation = false, children }) => (
  <Container navigation={navigation}>
    <div>
      <PageTitle>{title}</PageTitle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
    <Flex>
      {children}
    </Flex>
  </Container>
);

const Container = styled.div.withConfig({
  shouldForwardProp: prop => !['navigation'].includes(prop) 
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: ${colour.blue};
  height: ${props => props.navigation ? '116px' : '148px'};
  padding: 32px 60px 62px 60px;
  box-shadow: ${boxShadow.standard};
`;

const PageTitle = styled.div`
  font-family: ${brandFont};
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
`;

const Subtitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.1px;
  color: ${rgba('#fff', 0.7)};
`;

export { Masthead };
