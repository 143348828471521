import React from 'react';
import { Dropdown as AntDropdown} from 'antd';
import { Dropdown } from '@a-cloud-guru/kermit';

const CATEGORIES = {
  period: 'During Period',
  new: 'New Activity',
  total: 'All Time'
}

const CategoryDropdown = ({ onChange, value }) => (
  <AntDropdown
    placement='bottomRight'
    overlay={(
      <Dropdown.Menu width="150px" onClick={({ key }) => onChange(key)}>
        <Dropdown.MenuItem key="new">{CATEGORIES['new']}</Dropdown.MenuItem>
        <Dropdown.MenuItem key="total">{CATEGORIES['total']}</Dropdown.MenuItem>
      </Dropdown.Menu>
    )}
  >
    <Dropdown.Button>
      <a className="ant-dropdown-link">
        Show {CATEGORIES[value]} <Dropdown.CaretIcon type="caret-down" />
      </a>
    </Dropdown.Button>
  </AntDropdown>
);

export { CategoryDropdown };