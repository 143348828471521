import React from 'react';
import formatDate from 'date-fns/format';

const DateFormat = ({ format = 'D MMMM YYYY', children }) => (
  <>
    {children ? formatDate(children, format) : ''}
  </>
);

export default DateFormat;
