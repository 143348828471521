import { Form, Select, Button, Switch, Input } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash.get'
import React, { useState } from 'react';
import { useSplitTreatment } from 'splitio-react/lib/hooks/use-split-treatment';
import styled from 'styled-components';

import { Flex } from '/components/box';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { useMutation } from '/lib/hooks';

const UPDATE_ORGANISATION = gql`
  mutation ACG_updateOrganisation($input: ACG_UpdateOrganisationInput) {
    ACG_updateOrganisation(input: $input) {
      organisation {
        id
        name
        isReseller
      }
    }
  }
`;

const UPDATE_ORGANISATION_SUBSCRIPTION = gql`
  mutation updateOrganisationSubscription($input: ACG_ModifyOrganisationAccountDetailsInput) {
    ACG_modifyOrganisationAccountDetails(input: $input) {
      organisation {
        id
        archivedAt
        accountType
      }
    }
  }
`;

const ModifyAccountDetailsForm = (props) => {
  const setResellerFlag = useSplitTreatment('BERNIE_SET_RESELLERS');
  const canSetResellerFlag = setResellerFlag === 'on';
  const organisationId = get(props, 'id');
  const closeModal = get(props, 'closeModal');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const updateOrganisationMutation = useMutation({
    mutation: UPDATE_ORGANISATION
  });
  const updateOrganisationSubscriptionMutation = useMutation({
    mutation: UPDATE_ORGANISATION_SUBSCRIPTION
  });

  const form = get(props, 'form');

  const { getFieldDecorator } = form;

  function modifySubscription(event) {
    event.preventDefault();
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        console.error('Unable to validate fields')
        return;
      }

      setSubmitting(true);
      const isReseller = typeof get(fieldsValue, 'isReseller') === 'boolean' ? get(fieldsValue, 'isReseller') : props.isReseller;
      const updateOrganisation = updateOrganisationMutation({
        variables: {
          input: {
            orgId: organisationId,
            name: get(fieldsValue, 'name'),
            isReseller,
          }
        }
      });

      const updateAccountDetails = updateOrganisationSubscriptionMutation({
        variables: {
          input: {
            organisationId,
            accountType: get(fieldsValue, 'accountType'),
            archive: !!get(fieldsValue, 'archived')
          }
        }
      });

      return Promise.all([updateOrganisation, updateAccountDetails])
        .then(() => closeModal())
        .catch(e => {
          setErrorMessage(e.message);
          setSubmitting(false);
        });
    });
  }

  const { name, archivedAt, accountType, isReseller } = props;

  return (
    <Form
      onSubmit={modifySubscription}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Organization Name</InputLabel>
        <InputField>
          {getFieldDecorator('name', {
            initialValue: name,
            rules: [
              {
                required: true,
                message: 'Your organization requires a name',
              },
            ]
          })(
            <Input type="text" />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Archive Organisation & Deactivate All Users</InputLabel>
        <InputField>
          {getFieldDecorator('archived', {
            initialValue: !!archivedAt
          })(
            <StyledSwitch
              defaultChecked={!!archivedAt}
              checkedChildren='YES'
              unCheckedChildren='NO'
            />
          )}
        </InputField>
      </FormItem>
      {canSetResellerFlag && <FormItem>
        <InputLabel>Will distribute organization seats</InputLabel>
        <InputField>
          {getFieldDecorator('isReseller', {
            initialValue: !!isReseller
          })(
            <StyledSwitch
              defaultChecked={!!isReseller}
              checkedChildren='YES'
              unCheckedChildren='NO'
            />
          )}
        </InputField>
      </FormItem>}
      <FormItem>
        <InputLabel>Account Type</InputLabel>
        <InputField>
          {getFieldDecorator('accountType', {
            initialValue: accountType
          })(
            <Select>
              <Select.Option value='PAYING'>Paying</Select.Option>
              <Select.Option value='TRIAL'>Trial</Select.Option>
              <Select.Option value='FREEBIE'>Freebie</Select.Option>
              <Select.Option value='INTERNAL'>Internal</Select.Option>
              <Select.Option value='TEST'>Test</Select.Option>
            </Select>
          )}
        </InputField>
      </FormItem>
      <Flex justifyContent='flex-end' p='18px 20px'>
        <span>{ errorMessage }</span>
        <Button type='primary' htmlType='submit' loading={submitting}>
          {submitting ? 'Updating' : 'Update'}
        </Button>
      </Flex>
    </Form>
  );
}

const InputField = styled.div`
  flex: 3;
`;

const StyledSwitch = styled(Switch)`
  padding: 0 2px;
`;



const ComposedModifyAccountDetails = Form.create()(ModifyAccountDetailsForm);

export {
  ComposedModifyAccountDetails as ModifyAccountDetailsForm
};
