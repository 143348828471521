import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_USER_ENGAGEMENT_QUERY = (attributes) => gql`
  query ACG_organisationUserEngagement (
    $organisationId: String!,
    $from: String!,
    $to: String!,
    $interval: DatePeriodInterval!
  ){
    organisationUserStartedCourseEventStats: ACG_organisationUserCourseEventStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
        eventType: START_COURSE
      }
    ) ${attributes[0]}
    organisationUserCompletedCourseEventStats: ACG_organisationUserCourseEventStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
        eventType: COMPLETE_COURSE
      }
    ) ${attributes[0]}
    organisationUserJoinedStats: ACG_organisationUserJoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[1]}
    organisationUserLeftStats: ACG_organisationUserLeftStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[2]}
    organisationUserRejoinedStats: ACG_organisationUserRejoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[3]}
    organisation: ACG_organisation(orgId: $organisationId) {
      id
      consumedLicenses
      chargeModel
      subscription {
        endDate
        userLicenseCount
        expired
        daysRemaining
      }
    }
  }
`;

const mergeResponse = (data) => {
  const {
    organisationUserJoinedStats,
    organisationUserLeftStats,
    organisationUserRejoinedStats,
    organisationUserStartedCourseEventStats,
    organisationUserCompletedCourseEventStats,
    organisation
  } = data;

  const engagementStats = organisationUserJoinedStats.map((userJoined, index) => {
    const { startDate, endDate } = userJoined;
    const startedCourse = {
      startedCourse: get(organisationUserStartedCourseEventStats, `${index}.userCount`) || 0,
      newlyStartedCourse: get(organisationUserStartedCourseEventStats, `${index}.newUserCount`) || 0,
      totalStartedCourse: get(organisationUserStartedCourseEventStats, `${index}.cumulativeUserCount`) || 0
    };

    const completedCourse = {
      completedCourse: get(organisationUserCompletedCourseEventStats, `${index}.userCount`) || 0,
      newlyCompletedCourse: get(organisationUserCompletedCourseEventStats, `${index}.newUserCount`) || 0,
      totalCompletedCourse: get(organisationUserCompletedCourseEventStats, `${index}.cumulativeUserCount`) || 0
    }

    const totalJoined = get(organisationUserJoinedStats, `${index}.cumulativeJoinedCount`) || 0;
    const totalLeft = get(organisationUserLeftStats, `${index}.cumulativeLeftCount`) || 0;
    const totalRejoined = get(organisationUserRejoinedStats, `${index}.cumulativeRejoinedCount`) || 0;
    const joined = {
      newlyJoined: get(organisationUserJoinedStats, `${index}.joinedCount`) || 0,
      totalJoined: totalJoined - totalLeft + totalRejoined
    };
    return {
      startDate,
      endDate,
      ...joined,
      ...startedCourse,
      ...completedCourse
    };
  });

  return { engagementStats, organisation };
}

const OrganisationUserEngagementQuery = ({
  organisationId,
  from,
  to,
  interval,
  attributes = [
    `{
      startDate
      endDate
      userCount
      newUserCount
      cumulativeUserCount
    }`,
    `{
      startDate
      endDate
      joinedCount
      cumulativeJoinedCount
    }`,
    `{
      startDate
      endDate
      leftCount
      cumulativeLeftCount
    }`,
    `{
      startDate
      endDate
      rejoinedCount
      cumulativeRejoinedCount
    }`
  ],
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={ACG_ORGANISATION_USER_ENGAGEMENT_QUERY(attributes)}
      variables={{
        organisationId,
        from,
        to,
        interval
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: mergeResponse(data),
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );
}

export { OrganisationUserEngagementQuery };