import DataSet from '@antv/data-set/lib/data-set';
import '@antv/data-set/lib/transform/fold';
import { get } from 'lodash';

const transform = ([validData, allData]) => {
  const data = allData.reduce((all, { startDate, totalCerts, data }, index) => {
    return [
      ...all,
      {
        startDate,
        all: totalCerts,
        valid: get(validData, `${index}.totalCerts`, 0),
        allData: data,
        validData: get(validData, `${index}.data`, '')
      },
    ];
  }, []);

  const ds = new DataSet();
  const dv = ds.createView().source(data);
  dv.transform({
    type: 'fold',
    fields: ['all', 'valid'],
    key: 'type',
    value: 'totalCerts',
  });
  return dv;
};

export { transform };
