import React from 'react';
import { Spinner } from '@a-cloud-guru/kermit';
import gql from 'graphql-tag';
import { isUndefined } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Box } from '/components/box';
import { colour } from '/styles/variables';

const TOGGLE_PERMISSION_MUTATION = gql`
  mutation ACG_updateInternalRoles($input: UpdateInternalRolesInput!) {
    ACG_updateInternalRoles(input: $input) {
      permission {
        id
        organisationWriteAccess
      }
    }
  }
`;

const GrantWrite = ({ loading, grant }) => {
  if (loading) {
    return <Spinner size="1rem" />;
  }
  return (
    <Box color={colour.blueLight} onClick={grant}>
      Grant Write Access
    </Box>
  )
};

const RevokeWrite = ({ loading, revoke }) => {
  if (loading) {
    return <Spinner size="1rem" />;
  }
  return (
    <Box color={colour.red} onClick={revoke}>
      Revoke Write Access
    </Box>
  )
}

const ToggleUserPermission = ({
  userId,
  writeAccess
}) => {
  const [togglePermission, { loading }] = useMutation(TOGGLE_PERMISSION_MUTATION);

  if (isUndefined(writeAccess)) {
    return null;
  }

  return writeAccess ? (
    (
      <RevokeWrite
        loading={loading}
        revoke={() => togglePermission({
          variables: {
            input: {
              userId,
              organisationWriteAccess: false
            }
          }
        })}
      />
    )
  ) : (
    <GrantWrite
      loading={loading}
      grant={() => togglePermission({
        variables: {
          input: {
            userId,
            organisationWriteAccess: true
          }
      }
    })} />
  );
};

export { ToggleUserPermission };