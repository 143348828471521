import { Button } from 'antd';
import styled from 'styled-components';
import { boxShadow, colour } from '/styles/variables';

const DropdownButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  line-height: 1;
  border: solid 1px #bfc6d8;
  border-radius: 3px;
  box-shadow: ${boxShadow.subtle};
  font-size: 14px;
  font-weight: 600;
  color: ${colour.gray900};
  background-image: linear-gradient(to top, ${colour.hover}, #ffffff);

  :hover :focus{
    border-color: ${colour.gray400};
    box-shadow: ${boxShadow.inset};
  }
`;

export { DropdownButton };

