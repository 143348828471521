import {
  brandFont,
  colour as defaultColour,
  boxShadow,
  borderRadius,
} from './variables.default';

const colour = {
  ...defaultColour,
  yellowLight: '#ffefca',
  yellow: '#fdc018',
  gray300: '#e2e7f3',
  gray500: '#78839e',
}

export {
  brandFont,
  boxShadow,
  borderRadius,
  colour
}
