import React, { useState } from 'react';
import { Button } from 'antd';

const ExportButton = ({ onExport }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      loading={loading}
      type="primary"
      onClick={async () => {
        setLoading(true)
        const url = await onExport();
        location.href = url;
        setLoading(false)
      }}
    >
      {loading ? 'Exporting' : 'Export CSV'}
    </Button>
  )
};

export { ExportButton };