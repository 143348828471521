import React from 'react';
import { CertificationActivityTable } from './CertificationActivityTable';
import { CertificationsGraph } from './CertificationsGraph';
import { CertificationSummaryPanel } from './CertificationSummaryPanel'

const CertificateActivityReport = ({ organisationId }) => {
  return (
    <React.Fragment>
      <CertificationSummaryPanel organisationId={organisationId}  location="certificationsReport" />
      <CertificationsGraph organisationId={organisationId} />
      <CertificationActivityTable organisationId={organisationId} />
    </React.Fragment>
  );
};

export { CertificateActivityReport };
