import styled from 'styled-components';

import { colour } from '/styles/variables';

const InputLabel = styled.div`
  flex: 1;
  color: ${colour.gray900};
  font-weight: 600;
  line-height: 1.2;
  margin: 6px 12px 6px 0;
  display: flex;
`;

export {
  InputLabel
};
