import React, { useState } from 'react';

import { ExportButton } from './ExportButton';

const AsyncExportButton = ({
  label = 'Export CSV',
  exportLabel = 'Exporting',
  onExport,
  onClick = async () => {
    const url = await onExport();
    location.href = url;
  }}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ExportButton
      loading={loading}
      type="primary"
      onClick={async () => {
        setLoading(true)

        await onClick();

        setLoading(false)
      }}
    >
      {loading ? exportLabel : label}
    </ExportButton>
  )
};

export { AsyncExportButton };
