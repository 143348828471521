import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'
import { getConfig } from '/config/get-config';
import { authService } from '/services/auth';

const {
  BUGSNAG_API_KEY: apiKey,
  BUGSNAG_RELEASE_STAGE: releaseStage,
  BUGSNAG_APP_VERSION: appVersion
} = getConfig();

const makeBugsnagErrorBoundary = () => {
  if (!apiKey) {
    return React.Fragment;
  }

  const bugsnagClient = Bugsnag.start({
    apiKey,
    appVersion,
    releaseStage,
    plugins: [new BugsnagReact()],
    beforeSend: async (report) => {
      const id = await authService.getUserIdFromToken();
      report.user = {
        id
      };
    }
  });

  return bugsnagClient.getPlugin('react').createErrorBoundary(React);
}

const BugsnagErrorBoundary = makeBugsnagErrorBoundary();

export {
  BugsnagErrorBoundary
};

