import get from 'lodash.get'
import { SelfServiceSubscriptionQuery } from '/data/query/SelfServiceSubscriptionQuery'
import { DetailsRow } from './Shared'
import { DateFormat } from '/components/date/DateFormat';
import { useSplitTreatment } from 'splitio-react';
import React, { useEffect } from 'react'

const SelfServiceSubscriptionDetails = organisation => {
  const trialSectionSplit = useSplitTreatment('BERNIE_TRIAL_SECTION');
  const showTrial = trialSectionSplit === 'on';
  const orgId =  get(organisation, 'id');
  const licenseCount = get(organisation, 'subscription.userLicenseCount') || 0;
  const deactivatedLicenses = get(organisation, 'deactivatedLicenses') || 0;
  const consumedLicenseCount = get(organisation, 'consumedLicenses') || 0;
  const orgLoading = get(organisation, 'loading');
  let refetchDetails;

  useEffect(() => {
    if (refetchDetails && !orgLoading) {
      refetchDetails();
    }
  }, [orgLoading]);

  return (
    <SelfServiceSubscriptionQuery orgId={orgId} notifyOnNetworkStatusChange={true}>
      {({ data, refetch }) => {
        const providerSubscriptionId = get(data, 'providerSubscriptionId', '');
        const providerSubscriptionUrl = get(data, 'providerSubscriptionUrl', '');
        let providerLink;

        refetchDetails = refetch;

        if (providerSubscriptionUrl.length > 0) {
          providerLink = <a href={providerSubscriptionUrl} target="_blank">{providerSubscriptionId}</a>
        } else {
          providerLink = <span>-</span>
        }

        return (
          <>
            <DetailsRow label='Current Term Start'>
              <DateFormat>
                {
                  get(data, 'currentTermStart') || '-'
                }
              </DateFormat>
            </DetailsRow>
            <DetailsRow label='Current Term End'>
              <DateFormat>
                {
                  get(data, 'currentTermEnd') || '-'
                }
              </DateFormat>
            </DetailsRow>
            {
              showTrial && <span>
                <DetailsRow label='Free Trial Start'>
                  { 
                  get(data, 'trialStart') ? 
                    <DateFormat>
                      {
                        get(data, 'trialStart')
                      }
                    </DateFormat>
                    :
                    '-'
                  }
                </DetailsRow>
                <DetailsRow label='Free Trial End'>
                  { 
                  get(data, 'trialEnd') ? 
                    <DateFormat>
                      {
                        get(data, 'trialEnd')
                      }
                    </DateFormat>
                    :
                    '-'
                  }
                </DetailsRow>
              </span>
            }
            <DetailsRow label='Seats'>
              {
                `${licenseCount} seats`
              }
            </DetailsRow>
            <DetailsRow label='Seats Used'>
              {
                `${consumedLicenseCount} seats`
              }
            </DetailsRow>
            <DetailsRow label='Deactivations'>
              {
                `${deactivatedLicenses}`
              }
            </DetailsRow>
            <DetailsRow label='Package'>
              {
                get(data, 'plan.subscriberPackage.packageName') || '-'
              }
            </DetailsRow>
            <DetailsRow label='Plan'>
              {
                get(data, 'plan.name') || '-'
              }
            </DetailsRow>
            <DetailsRow label='Chargebee Subscription'>
              {providerLink}
            </DetailsRow>
          </>
        );
      }}
    </SelfServiceSubscriptionQuery>
  )
}

export {
  SelfServiceSubscriptionDetails
}