import React from 'react';
import { space, color } from 'styled-system';
import styled from 'styled-components';

import { DefaultButton} from './DefaultButton';
import { AddButton } from './AddButton';
import { PrimaryButton } from './PrimaryButton';
import { DangerButton } from './DangerButton';
import { SecondaryButton } from './SecondaryButton';

const BaseButton = ({
  type,
  ...restProps
}) => {
  switch (type) {
    case "add":
      return <AddButton {...restProps} />;

    case "default":
      return <DefaultButton {...restProps} />;

    case "secondary":
      return <SecondaryButton {...restProps} />;

    case "primary":
      return <PrimaryButton {...restProps} />;

    case "danger":
      return <DangerButton {...restProps} />;

    default:
      return <DefaultButton {...restProps} />;
  }
};

const Button = styled(BaseButton)`
  ${space}
  ${color}
`;

export default Button;