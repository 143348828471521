import { Tooltip, Input } from 'antd';
import React, { useState } from 'react';
import urlJoin from 'url-join';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Box, Flex } from '/components/box';
import { useMutation } from '/lib/hooks';
import { getConfig } from '/config/get-config';
import { DangerButton } from '/components/button/DangerButton';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { copyToClipboard } from '/utils/copyToClipboard';
import { StyledCard, StyledDivider, DetailsRow, Header } from './Shared';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';
import { isReadOnly } from '../../../hooks/isReadOnly';

const { ACG_DOMAIN } = getConfig();

const ADD_JOIN_CODE = gql`
  mutation addJoinCode($input: ACG_AddJoinCodeInput!) {
    ACG_addJoinCode(input: $input) {
      organisation {
        id
        joinCodes
      }
    }
  }
`;

const REMOVE_JOIN_CODE = gql`
  mutation removeJoinCode($input: ACG_RemoveJoinCodeInput!) {
    ACG_removeJoinCode(input: $input) {
      organisation {
        id
        joinCodes
      }
    }
  }
`;

const InvitationCard = ({ domains, joinCodes, id, loading: orgLoading }) => {
  const [loading, setLoading] = useState(false);
  const mutation = useMutation({
    mutation: ADD_JOIN_CODE,
    variables: {
      input: {
        organisationId: id,
      },
    },
  });
  const readOnly = isReadOnly();
  const onClick = async () => {
    if (readOnly) return;
    setLoading(true);
    await mutation();
    setLoading(false);
  };

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Invitation Details</StyledTitle>
        <DisabledWhenReadOnly>
          <PrimaryButton onClick={onClick} loading={loading}>
            Generate join link
          </PrimaryButton>
        </DisabledWhenReadOnly>
      </StyledHeader>
      <StyledDivider />
      <Box py="6px">
        {orgLoading ? (
          <Container>
            <Spinner />
          </Container>
        ) : (
          <>
            <DetailsRow label="Auto-join Domains">
              {domains ? domains.join(', ') : '-'}
            </DetailsRow>
            <DetailsRow label="Join links">
              {joinCodes
                ? joinCodes.map((code, key) => (
                    <JoinLinkItem key={key} code={code} organisationId={id} />
                  ))
                : '-'}
            </DetailsRow>
          </>
        )}
      </Box>
    </StyledCard>
  );
};

const JoinLinkItem = ({ code, organisationId }) => {
  const [loading, setLoading] = useState(false);
  const mutation = useMutation({
    mutation: REMOVE_JOIN_CODE,
    variables: {
      input: {
        code,
        organisationId,
      },
    },
  });
  const readOnly = isReadOnly();

  const onClick = async () => {
    if (readOnly) return;
    setLoading(true);
    await mutation();
    setLoading(false);
  };

  const link = urlJoin(ACG_DOMAIN, 'join-organisation', code);
  return (
    <Flex mb="12px">
      <Flex flex="1 1 auto">
        <GroupedInput type="text" readOnly value={link} />
        <Tooltip
          title="Copy to clipboard"
          placement="left"
          mouseEnterDelay={0.3}
        >
          <GroupedPrimaryButton
            onClick={() => copyToClipboard(link)}
            icon="copy"
          />
        </Tooltip>
      </Flex>
      <DisabledWhenReadOnly>
        <Tooltip title="Remove link" placement="right" mouseEnterDelay={0.3}>
          <StyledDangerButton
            onClick={onClick}
            loading={loading}
            icon="delete"
          />
        </Tooltip>
      </DisabledWhenReadOnly>
    </Flex>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

const StyledDangerButton = styled(DangerButton)`
  margin-left: 5px;
  min-width: 60px;
`;

const GroupedInput = styled(Input)`
  flex: 1;
  margin-right: 12px;
`;

const GroupedPrimaryButton = styled(PrimaryButton)`
  flex: 0;
  min-width: 60px;
`;

export { InvitationCard };
