import React, { useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';

import { InvitationForm } from './InvitationForm';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { StyledModal, FormContent, Title } from '/components/modal';
import { colour } from '/styles/variables';
import { isReadOnly } from '../../hooks/isReadOnly';

const InviteUserModal = ({ isClickDisabled, buttonText, ...props }) => {
  const [visible, setVisible] = useState(false);
  const readOnly = isReadOnly();
  const infoMessageOne =
    'Any emails which already have a pending or active invitation status will be automatically removed.';
  const infoMessageTwo =
    'Users with a pending invitation status will have to be resent their existing invitation.';
  const infoMessageThree = 'Users who are active cannot be sent an invitation.';

  return (
    <>
      <PrimaryButton onClick={() => setVisible(!readOnly)}>
        {buttonText}
      </PrimaryButton>
      <StyledModal
        destroyOnClose
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
      >
        <FormContent>
          <TitleContainer>
            <Title>{buttonText}</Title>
            <Alert
              style={{ margin: '10px 0px 5px 0px' }}
              message={infoMessageOne}
              type="info"
              showIcon
            />
            <Alert
              style={{ margin: '10px 0px 5px 0px' }}
              message={infoMessageTwo}
              type="info"
              showIcon
            />
            <Alert
              style={{ margin: '10px 0px 5px 0px' }}
              message={infoMessageThree}
              type="info"
              showIcon
            />
          </TitleContainer>
          <InvitationForm closeModal={() => setVisible(false)} {...props} />
        </FormContent>
      </StyledModal>
    </>
  );
};

const TitleContainer = styled.div`
  padding: 18px 20px;
  border-bottom: solid 1px ${colour.gray300};
`;

export { InviteUserModal };
