import React from 'react';
import { get } from 'lodash';
import { GroupUsersQuery } from './GroupUsersQuery';
import { StudyGroupProgressQuery } from './StudyGroupProgressQuery';

const mergeResponse = ({
  users,
  userProgress
}) => {
  if (!users || !userProgress) {
    return { userProgress: [], averageProgress: 0 };
  }
  const userProgressMap = userProgress.reduce((current, next) => {
    const { userId, progress, lastActivity } = next;
    return { ...current, [userId]: { progress, lastActivity } };
  }, {});

  return users.map(user => {
    const { user_id, picture, displayname, name, email } = user;
    return {
      userId: user_id,
      displayName: displayname || name || email,
      picture,
      progress: Math.round(get(userProgressMap, `${user_id}.progress`) || 0),
      lastActivity: get(userProgressMap, `${user_id}.lastActivity`)
    }
  });
}

const StudyGroupUserProgressQuery = ({
  organisationId,
  groupId,
  numberOfUsers,
  children
}) => (
  <GroupUsersQuery
    organisationId={organisationId}
    groupId={groupId}
    pageSize={numberOfUsers}
  >
    {({ data: users, loading: groupUsersLoading }) => (
      <StudyGroupProgressQuery
        organisationId={organisationId}
        groupId={groupId}
        renderLoading={false}
        renderError={false}
      >
          {({ data: userProgress, loading: progressLoading, error }) => {
            return children({
              data: mergeResponse({ users, userProgress }),
              loading: groupUsersLoading || progressLoading,
              error
            })
          }}
      </StudyGroupProgressQuery>
    )}
  </GroupUsersQuery>
);

export { StudyGroupUserProgressQuery };