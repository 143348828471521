import React from 'react';
import { Link } from 'react-router-dom';
import { PopularCourseList } from './PopularCourseList';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { TableAction } from '/components/table/TableAction';
import { CourseProgressQuery } from '/data/query/CourseProgressQuery';

const PopularCourses = ({ organisationId }) => (
  <Module>
    <ModuleHeader
      header="Popular Courses"
      subheader="The top 3 courses your team are studying"
      style={{ padding: '16px' }}
    >
      <Link to={`/organizations/${organisationId}/reports/course-popularity`}>
        <TableAction
          style={{ minWidth: '65px', display: 'block', padding: '0' }}
        >
          View All
        </TableAction>
      </Link>
    </ModuleHeader>
    <CourseProgressQuery renderLoading={false} organisationId={organisationId}>
      {({ data: courses = [], loading }) => {
        return (
          <PopularCourseList
            popularCourses={courses.slice(0, 3)}
            loading={loading}
            organisationId={organisationId}
          />
        );
      }}
    </CourseProgressQuery>
  </Module>
);

export { PopularCourses };
