import { calculatePercentage } from '/utils/calculatePercentage';

const generateDataSource = (engagementStats) => {
  const row = (event, key) => {
    return engagementStats.reduce((row, stat) => {
      return { ...row, [stat.endDate]: stat[key] };
    }, { event });
  };

  const percentageRow = (event, joinedCountRow, activeUserRow) => {
    const dateKeys = Object.keys(activeUserRow).filter(key => key !== 'event');
    return dateKeys.reduce((row, date) => {
      const activeUserCount = activeUserRow[date];
      const joinedCount = joinedCountRow[date];
      return {
        ...row,
        [date]: joinedCount === 0 ? 0 : calculatePercentage(activeUserCount, joinedCount, 2)
      }
    }, { event })
  }
  const joinedCountRow = row('Users Joined', 'joinedCount');
  const activeUserRow = row('Active Users', 'activeUsersCount');
  const completedLessonRow = row('Completed Lessons', 'completedComponentsCount');
  const completedLessonPerUserRow = row('Completed Lessons Per Active User', 'completedComponentsPerActiveUser');
  const activeUserPercentageRow = percentageRow('% Active Users', joinedCountRow, activeUserRow);
  return [joinedCountRow, activeUserRow, activeUserPercentageRow, completedLessonRow, completedLessonPerUserRow];
};

export { generateDataSource };