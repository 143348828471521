import React from 'react';
import get from 'lodash.get';
import { groupBy } from 'lodash';
import { startOfMonth, subYears, subMonths, isAfter, isBefore, subDays } from 'date-fns';
import { OrganisationUserCertificationActivityQuery } from '/data/query';

import { makeTransformer } from './transform-certification-chart-data';

const QUERY_ATTRIBUTES = `
  {
    data {
      certificateId
      certificateTitle
      certificateProvider
      acquiredDate
      expireDate
      expired
    }
  }
`;

const NOW = new Date();
const LAST_YEAR = subYears(startOfMonth(NOW), 1);
const START_OF_LAST_MONTH = startOfMonth(subMonths(NOW, 1));
const START_OF_TWO_MONTH_AGO = startOfMonth(subMonths(NOW, 2));
const LAST_THIRTY_DASY = subDays(NOW, 30);

const filterCertifications = ({ data, from, to = NOW }) => {
  return data.filter(({ acquiredDate }) => isAfter(acquiredDate, from) && isBefore(acquiredDate, to));
}

const getLastOf = (data, index) => {
  if (data.length === 0) {
    return {
      total: 0,
      data: []
    }
  }

  const dataAtIndex = data[index];

  if (!dataAtIndex) {
    return {
      total: 0,
      data: []
    }
  }

  const groupByCerts = groupBy(get(dataAtIndex, 'data') || [], 'certificateId');

  return {
    total: get(dataAtIndex, 'totalCerts') || 0,
    data: Object.keys(groupByCerts).map(certificateId => {
      const certificates = groupByCerts[certificateId];

      return {
        id: certificateId,
        count: certificates.length,
        title: certificates[0].certificateTitle,
        provider: certificates[0].certificateProvider
      }
    })
  };
};

const getLatest = (data) => {
  return getLastOf(data, data.length - 1);
};

const CertificationQuery = ({ organisationId, groupId, renderLoading, children }) => (
  <OrganisationUserCertificationActivityQuery
    organisationId={organisationId}
    groupId={groupId}
    acquiredAfter={LAST_YEAR}
    attributes={QUERY_ATTRIBUTES}
    renderLoading={renderLoading}
  >
    {({ data: dataOfLastYear, loading: dataOfLastYearLoading }) => {
      const { transform, addHistoricalData } = makeTransformer(true);
      const chartDataForValid = transform(dataOfLastYear, LAST_YEAR);

      const { transform: transformForAll, addHistoricalData: addHistoricalDataForAll } = makeTransformer(false);
      const chartDataForAll = transformForAll(dataOfLastYear, LAST_YEAR);

      return (
        <OrganisationUserCertificationActivityQuery
          organisationId={organisationId}
          groupId={groupId}
          acquiredBefore={LAST_YEAR}
          attributes={QUERY_ATTRIBUTES}
          renderLoading={renderLoading}
        >
          {({ data: dataBeforeLastYear, loading: dataBeforeLastYearLoading }) => {
            const dataForValid = addHistoricalData(chartDataForValid, dataBeforeLastYear);
            const dataForAll = addHistoricalDataForAll(chartDataForAll, dataBeforeLastYear);

            return children({
              loading: dataOfLastYearLoading || dataBeforeLastYearLoading,
              dataForValid,
              dataForAll,
              getLatest,
              addedLastMonth: filterCertifications({
                data: dataOfLastYear,
                from: START_OF_LAST_MONTH
              }).length,
              addedTwoMonthsAgo: filterCertifications({
                data: dataOfLastYear,
                from: START_OF_TWO_MONTH_AGO,
                to: START_OF_LAST_MONTH
              }).length,
              addedLastThirtyDays: filterCertifications({
                data: dataOfLastYear,
                from: LAST_THIRTY_DASY
              }).length
            })
          }}
        </OrganisationUserCertificationActivityQuery>
      )
    }}
  </OrganisationUserCertificationActivityQuery>
);

export { CertificationQuery };