import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query, QueryBoundary } from '/components/data';

const ORGANISATION_USERS_JOINED_QUERY = (attributes) => gql`
  query organisationUsersJoined {
    organisationUsersJoined ${attributes}
  }
`;

const OrganisationUsersJoinedQuery = ({
  attributes = `{
    id
    joinedAt
    joinMethod
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
  <Query query={ORGANISATION_USERS_JOINED_QUERY(attributes)} {...restProps}>
    {(props) => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data, ...rest }) => {
          return children({
            users: get(data, 'organisationUsersJoined') || [],
            ...rest,
          });
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { OrganisationUsersJoinedQuery };
