import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { useQuery } from 'react-apollo';
import { useSelector, useDispatch } from 'react-redux';
import { setRoles } from '../../reducers/roles-reducer';

const INTERNAL_ROLES_QUERY = gql`
  query ACG_organisationSupportRoles {
    ACG_organisationSupportRoles {
      id
      organisationWriteAccess
    }
  }
`;
const ROLES_QUERY = gql`
  query SiteRolesPage {
    roles {
      superadmin
    }
  }
`;

const DisabledWhenReadOnly = ({ children }) => {
  const rolesState = useSelector((state) => state.rolesReducer);
  const readOnly =
    !rolesState.organisationWriteAccess && !rolesState.superAdmin;
  const dispatch = useDispatch();
  const { loading: loadingInternalRoles, data: internalRolesData } =
    useQuery(INTERNAL_ROLES_QUERY);
  const { loading: loadingRoles, data: rolesQueryData } = useQuery(ROLES_QUERY);
  const organisationWriteAccess = !!get(
    internalRolesData,
    'ACG_organisationSupportRoles.organisationWriteAccess'
  );
  const superAdmin = !!get(rolesQueryData, 'roles.superadmin');

  useEffect(() => {
    if (rolesState.initialState && !loadingRoles && !loadingInternalRoles) {
      dispatch(
        setRoles({
          initialState: false,
          superAdmin,
          organisationWriteAccess,
        })
      );
    }
  }, [loadingRoles, loadingInternalRoles]);

  if (readOnly) {
    return <div className="read-only">{children}</div>;
  }
  return children;
};

export { DisabledWhenReadOnly };
