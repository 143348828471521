import React from 'react';
import { AlgoliaConfigProvider } from './AlgoliaConfigProvider';
import { AlgoliaSearch } from './AlgoliaSearch'

class AlgoliaQuery extends React.Component {
  static defaultProps = {
    page: 1,
    hitsPerPage: 10,
    query: '',
    filters: '',
    children: () => {}
  }

  render() {
    const { page, hitsPerPage, query, filters, children, indexName, ...rest } = this.props;
    return (
      <AlgoliaConfigProvider {...rest}>
        {({ configuring, appId, apiKey }) => {
          if (configuring) {
            return (
              <Results
                children={children}
                loading
              />
            )
          }
          return (
            <AlgoliaSearch
              appId={appId}
              apiKey={apiKey}
              indexName={indexName}
              query={query}
              filters={filters}
              page={page}
              hitsPerPage={hitsPerPage}
            >
              {({ searchResults, ...rest }) => {
                const results = searchResults ? {
                  data: searchResults.hits,
                  total: searchResults.nbHits,
                  page: searchResults.page + 1,
                  pageSize: searchResults.hitsPerPage
                } : { data: [], total: 0, page: 1, pageSize: undefined }

                return (
                  <Results
                    searchResults={results}
                    { ...rest }
                  >
                    {children}
                  </Results>
                )
              }}
            </AlgoliaSearch>
          )
        }}
      </AlgoliaConfigProvider>
    )
  }
}

const Results = ({ searchResults, children, search = () => {}, ...rest }) => {
  const defaultSearchResults = { data: [], total: 0, page: 1, pageSize: undefined };
  return children({
    searchResults: searchResults || defaultSearchResults,
    search,
    ...rest
})};

export { AlgoliaQuery };