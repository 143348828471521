import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_COURSE_ENGAGEMENT_QUERY = (attributes) => gql`
  query ACG_organisationCourseEngagementReport (
    $organisationId: String!,
    $courseId: String,
    $from: String!,
    $to: String!,
    $period: Period!
    $interval: DatePeriodInterval!
  ){
    ACG_organisationCourseEngagementReport (
      organisationId: $organisationId,
      filters: {
        courseId: $courseId,
        period: $period,
        dateRange: {
          from: $from,
          to: $to
        }
      }
    ) ${attributes[0]}
    ACG_organisationUserJoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[1]}
    ACG_organisationUserLeftStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[2]}
    ACG_organisationUserRejoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        interval: $interval
      }
    ) ${attributes[3]}
  }
`;

const mergeResponse = (data) => {
  const engagementStats = get(data, `ACG_organisationCourseEngagementReport`) || [];
  const userJoinedStats = get(data, `ACG_organisationUserJoinedStats`);
  const userLeftStats = get(data, `ACG_organisationUserLeftStats`);
  const userRejoinedStats = get(data, 'ACG_organisationUserRejoinedStats');

  return engagementStats.map((stat, index) => {
    const userJoinedCount = get(userJoinedStats, `${index}.cumulativeJoinedCount`) || 0;
    const userLeftCount = get(userLeftStats, `${index}.cumulativeLeftCount`) || 0;
    const userRejoinedCount = get(userRejoinedStats, `${index}.cumulativeRejoinedCount`) || 0;

    return {
      ...stat,
      joinedCount: userJoinedCount - userLeftCount + userRejoinedCount
    }
  });
}

const OrganisationCourseEngagementQuery = ({
  organisationId,
  courseId,
  from,
  to,
  interval,
  attributes = [
    `{
      id
      startDate
      endDate
      activeUsersCount
      completedComponentsCount
      completedComponentsPerActiveUser
    }`,
    `{
      startDate
      endDate
      joinedCount
      cumulativeJoinedCount
    }`,
    `{
      startDate
      endDate
      leftCount
      cumulativeLeftCount
    }`,
    `{
      startDate
      endDate
      rejoinedCount
      cumulativeRejoinedCount
    }`
  ],
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={ACG_ORGANISATION_COURSE_ENGAGEMENT_QUERY(attributes)}
      variables={{
        organisationId,
        courseId,
        from,
        to,
        period: interval,
        interval,
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: mergeResponse(data),
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );
}

export { OrganisationCourseEngagementQuery };