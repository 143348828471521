import React from "react";
import styled from "styled-components";
import { Table } from "@a-cloud-guru/kermit";
import { DisplayName } from "/components/table/DisplayName";
import { GroupUsersQuery } from "/data/query";
import { formatDate, dateDistanceInWordsToNow } from "/utils/date";

const LAST_SEEN_DEPLOY_DATE = new Date(2018, 11, 3);

const columns = [
  {
    title: "Student",
    dataIndex: "displayname",
    width: "40%",
    render: (displayname, { picture }) => (
      <DisplayName name={displayname} avatar={picture} />
    )
  },
  {
    title: "Last Seen",
    dataIndex: "last_seen_timestamp",
    render: last_seen_timestamp =>
      last_seen_timestamp ? (
        <>
          {formatDate({
            date: last_seen_timestamp,
            dateFormat: "D MMM YYYY hh:mm A"
          })}
        </>
      ) : (
        <>{dateDistanceInWordsToNow({ date: LAST_SEEN_DEPLOY_DATE })}</>
      )
  }
];

const ReportingGroupMembersTable = ({ groupId, organisationId }) => (
  <GroupUsersQuery groupId={groupId} organisationId={organisationId}>
    {({ data }) => (
      <StyledTable
        pagination={true}
        rowKey={({ user_id }) => user_id}
        columns={columns}
        dataSource={data}
      />
    )}
  </GroupUsersQuery>
);

const StyledTable = styled(Table)`
  &&& {
    box-shadow: none;
    background-color: #fbfbfb;
    margin: 0;
    tr > th {
      background-color: #fbfbfb;
    }

    .ant-pagination {
      float: right;
      padding: 0 10px;
    }
  }
`;

export { ReportingGroupMembersTable };
