import { Button } from 'antd';
import styled from 'styled-components';

import { colour, boxShadow } from '/styles/variables';

const ExportButton = styled(Button)`
  display: flex;
  justify-content: center;
  line-height: 1;
  border: solid 1px ${colour.gray300};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  color: ${colour.gray900};
  background-color: #fff;
  height: 36px;
  min-width: 128px;
  box-shadow: none;

  &:hover, &:focus {
    color: ${colour.gray900};
    background-color: #fff;
    box-shadow: ${boxShadow.inset};
  }
`;

export { ExportButton };
