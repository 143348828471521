import get from 'lodash.get';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const currentDate = new Date().valueOf();

const FILTERS = {
  all: 'valid_until > 0',
  joined: 'accepted_at > 0',
  pending: `valid_until > ${currentDate} AND accepted_at = 0`,
  expired: `valid_until < ${currentDate} AND accepted_at = 0`
};

const useGroupsQuery = ({
  query,
  page,
  pageSize = 10,
  groupType,
  key
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'group',
    query,
    page,
    hitsPerPage: pageSize,
    filters: `active:true AND group_type:${groupType}`,
    key
  });
  return {
    data: (get(searchResults, 'hits') || []).map(group => {
      const {
        objectID,
        study_plan_id,
        name,
        course_title,
        description,
        color_scheme,
        start_date,
        complete_date,
        study_plan_active,
        study_plan_enabled
      } = group;

      return {
        id: objectID,
        name,
        courseTitle: course_title,
        description,
        colorScheme: color_scheme,
        studyPlanId: study_plan_id,
        startDate: start_date,
        completeDate: complete_date,
        active: study_plan_active || false,
        studyPlanEnabled: study_plan_enabled
      };
    }),
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  }
};

export { useGroupsQuery };