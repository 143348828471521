import React, { useState } from 'react';
import { Dropdown } from '@a-cloud-guru/kermit';
import { CoursesQuery } from '/data/query';

const CourseDropdown = ({ courseId, selectCourse }) => {
  const [query, setQuery] = useState();
  return (
    <CoursesQuery query={query}>
      {({ data }) => {
        const options = data.map(({ courseId, courseTitle }) => ({ text: courseTitle, value: courseId }));
        return (
          <Dropdown
            async
            value={courseId}
            options={options}
            onSearch={setQuery}
            onChange={selectCourse}
          />
        )
      }}
    </CoursesQuery>
  );
};

export { CourseDropdown };