import { Icon } from 'antd';
import styled from 'styled-components';
import { colour } from '/styles/variables';

const DropdownCaretIcon = styled(Icon)`
  position: relative;
  margin: 0 0 0 2px;
  color: ${colour.gray500};
  align-self: center;
`;

export { DropdownCaretIcon };

