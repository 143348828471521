import React from 'react';
import { Link } from 'react-router-dom';
import { get, keyBy } from 'lodash';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Flex } from '/components/box';
import { Container } from '/components/container';
import { ListSubtitle } from '/page-components/dashboard/UserDetails';
import { useAlgoliaGetObjects } from 'react-algolia';
import { getIndexNameFromType } from '/lib/algolia';
import { UserListItem } from './UserListItem';

const mergeUsers = ({ ddbUsers, algoliaUsers }) => {
  const userMap = keyBy(algoliaUsers, 'objectID');

  return ddbUsers.map((ddbUser) => {
    const { name, picture, job_title: jobTitle } =
      get(userMap, ddbUser.id) || {};

    return {
      ...ddbUser,
      name,
      picture,
      jobTitle,
    };
  });
};

const UserList = ({ loading, users, organisationId }) => {
  const userIds = users.map(({ id }) => id);
  const { objects, loading: algoliaLoading } = useAlgoliaGetObjects({
    indexName: getIndexNameFromType('user'),
    objectIds: userIds,
  });

  const isLoading = loading || algoliaLoading;
  const waitingMerge = users.length > 0 && objects.length === 0;
  if (isLoading || waitingMerge) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <>
      {mergeUsers({
        ddbUsers: users,
        algoliaUsers: objects,
      }).map(({ id, ...user }) => {
        return <UserListItem key={id} userId={id} {...user} />;
      })}
      <Link to={`/organizations/${organisationId}/users`}>
        <ViewAllUsers justifyContent="center" p="22px">
          <ListSubtitle>View all users</ListSubtitle>
        </ViewAllUsers>
      </Link>
    </>
  );
};

const ViewAllUsers = styled(Flex)`
  cursor: pointer;
`;

export { UserList };
