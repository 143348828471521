import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import gql from 'graphql-tag';
import { AlgoliaProvider, defineAlgoliaApp } from 'react-algolia';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@a-cloud-guru/kermit';
import { AdminLayout } from '/layouts/AdminLayout';
import { Masthead } from '/components/masthead';
import { Flex } from '/components/box';
import { getConfig } from '/config/get-config';
import { UserPermissionTable } from './UserPermissionTable';
import { useAlgoliaApiKey } from './use-algolia-api-key';

const {
  ALGOLIA_APP_ID,
  ALL_ORGANISATION_USERS_INDEX,
  ACG_ORGANISATION_ID,
  PS_ORGANISATION_ID,
} = getConfig();

const ADMIN_USERS_QUERY = gql`
  query ACG_userSiteRoles {
    ACG_userSiteRoles(filter: { role: ADMIN }) {
      userIdentity {
        id
        displayName
        email
      }
    }
  }
`;

const Permission = () => {
  const { loading, data } = useQuery(ADMIN_USERS_QUERY);
  const [userSiteRole, setUserSiteRole] = useState(undefined);
  const { apiKey } = useAlgoliaApiKey([
    ACG_ORGANISATION_ID,
    PS_ORGANISATION_ID,
  ]);
  useEffect(() => {
    if (data && data.ACG_userSiteRoles) setUserSiteRole(data.ACG_userSiteRoles);
  }, [loading]);
  return (
    <AlgoliaProvider
      applications={
        apiKey
          ? [
              defineAlgoliaApp(
                ALGOLIA_APP_ID,
                apiKey,
                ALL_ORGANISATION_USERS_INDEX
              ),
            ]
          : []
      }
    >
      <AdminLayout
        masthead={
          <Masthead
            title="Permissions"
            subtitle="Setup who can have write access to organization records"
          />
        }
      >
        <Helmet>
          <title>Permissions</title>
        </Helmet>
        {userSiteRole ? (
          <UserPermissionTable
            userIds={userSiteRole.map(({ userIdentity }) => {
              if (!userIdentity) {
                return null;
              }
              return userIdentity.id;
            }).filter(Boolean)}
          />
        ) : (
          <Flex justifyContent="center">
            <Spinner size="3rem" />
          </Flex>
        )}
      </AdminLayout>
    </AlgoliaProvider>
  );
};

export { Permission };
