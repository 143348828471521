import { useState, useEffect } from 'react';
import { get, isString } from 'lodash';
import gql from 'graphql-tag';
import algoliasearch from 'algoliasearch';
import { getConfig } from '/config/get-config';

const {
  ALGOLIA_APP_ID,
  ALL_ORGANISATION_USERS_INDEX,
  ALL_ORGANISATION_GROUPS_INDEX,
  ALL_ORGANISATION_INVITATIONS_INDEX,
  COURSE_INDEX_NAME
} = getConfig();
const GET_ALGOLIA_API_KEY_AND_ORG_ID = gql`
  query algoliaApiKeyQuery($organisationIds: [String!]){
    ACG_searchApiKey(organisationIds: $organisationIds) {
      id
      publicApiKey {
        apiKey
        validUntil
      }
      scopedApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

const PRIVATE_INDEX_NAMES = {
  user: {
    default: ALL_ORGANISATION_USERS_INDEX,
    replicas: {
      'last_seen_timestamp_ascend': `${ALL_ORGANISATION_USERS_INDEX}_last_seen_timestamp_ascend`,
      'last_seen_timestamp_descend': `${ALL_ORGANISATION_USERS_INDEX}_last_seen_timestamp_descend`,
    }
  },
  group: `${ALL_ORGANISATION_GROUPS_INDEX}`,
  invitation: `${ALL_ORGANISATION_INVITATIONS_INDEX}`,
};

const PUBLIC_INDEX_NAMES = {
  course: COURSE_INDEX_NAME
};

const makeInitIndex = ({
  privateClient,
  publicClient
}) =>
  (indexNames, type) => {
    const algoliaClient = Object.keys(PRIVATE_INDEX_NAMES).includes(type) ? privateClient : publicClient;
    const indexName = get(indexNames, type);

    if (isString(indexName)) {
      return algoliaClient.initIndex(indexName);
    }

    const defaultIndexName = get(indexNames, `${type}.default`);
    const defaultIndex = algoliaClient.initIndex(defaultIndexName);
    const replicas = get(indexNames, `${type}.replicas`);

    if (!replicas) {
      return defaultIndex;
    }

    const defaultIndexMap = {
      default: defaultIndex,
      replicas: {}
    };

    const index = Object.keys(replicas).reduce((accu, replica) => {
      const replicaIndexName = get(indexNames, `${type}.replicas.${replica}`);
      const replicaIndex = algoliaClient.initIndex(replicaIndexName);

      return {
        ...accu,
        replicas: {
          ...get(accu, 'replicas'),
          [`${replica}`]: replicaIndex
        }
      }
    }, defaultIndexMap);
    return index;
  }

const useAllAlgoliaIndex = ({
  organisationId,
  apolloClient
}) => {
  const [algoliaIndexes, setAlgoliaIndexes] = useState({});

  useEffect(() => {
    const initAlgoliaIndexes = async () => {
      try {
        const res = await apolloClient.query({
          query: GET_ALGOLIA_API_KEY_AND_ORG_ID,
          variables: {
            organisationIds: [organisationId]
          },
          fetchPolicy: 'network-only'
        });

        const publicApiKey = get(res, 'data.ACG_searchApiKey.publicApiKey.apiKey');
        const publicClient = algoliasearch(ALGOLIA_APP_ID, publicApiKey);

        const privateApiKey = get(res, 'data.ACG_searchApiKey.scopedApiKey.apiKey');
        const privateClient = algoliasearch(ALGOLIA_APP_ID, privateApiKey);

        const initIndex = makeInitIndex({
          privateClient,
          publicClient
        });

        const indexNames = {
          ...PUBLIC_INDEX_NAMES,
          ...PRIVATE_INDEX_NAMES
        };
        const indexes = Object.keys(indexNames).reduce((accu, type) => {
          return {
            ...accu,
            [type]: initIndex(indexNames, type)
          };
        }, {});

        setAlgoliaIndexes(indexes);
      } catch (err) {
        setAlgoliaIndexes({});
        console.error('algolia error', err)
        throw err;
      }
    };

    if (organisationId) {
      initAlgoliaIndexes();
    }
  }, [organisationId]);

  return algoliaIndexes;
};

export { useAllAlgoliaIndex };
