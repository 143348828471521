import styled from 'styled-components';
import { flexDirection, alignItems, justifyContent } from 'styled-system';

import Box from '../box';

const Flex = styled(Box)`
  display: flex;
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
`;

export default Flex;
