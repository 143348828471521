import { property } from 'lodash';
import DataSet from "@antv/data-set/lib/data-set";
import "@antv/data-set/lib/transform/fold";

const transform = ({ data }) => {
  if (!data) {
    return;
  }

  const joined = {
    name: 'Employees Joined',
    ...data.reduce((accu, { startDate, acceptedCount }, index) => {

      return {
        ...accu,
        [startDate]: acceptedCount
      }
    }, {})
  };
  const invited = {
    name: 'Employees Invited',
    ...data.reduce((accu, { startDate, sentCount, acceptedCount }, index) => {
      return {
        ...accu,
        [startDate]: (sentCount - acceptedCount) < 0 ? 0 : (sentCount - acceptedCount)
      }
    }, {})
  };

  const ds = new DataSet();
  const dv = ds.createView().source([
    invited,
    joined
  ]);
  dv.transform({
    type: 'fold',
    fields: data.map(property('startDate')),
    key: 'date',
    value: 'count'
  });
  return dv;
};

export { transform };