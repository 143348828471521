import React from 'react';
import { AlgoliaProvider } from './AlgoliaProvider';

const Algolia = ({
  children,
  organisationId,
  apolloClient
}) => (
    <AlgoliaProvider
      organisationId={organisationId}
      apolloClient={apolloClient}
    >
      {children}
    </AlgoliaProvider>
  );

export default Algolia;
