import React from 'react';
import { Spinner, Flex, Box, DateFormat, SearchBox, TableState, PaginatedTable } from '@a-cloud-guru/kermit';
import uuid from 'uuid/v4';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';
import styled from 'styled-components';
import { OrganisationUserCertificationActivityQuery } from '/data/query';
import { OrganisationUsersQuery } from '/data/query';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { DisplayName } from '/components/table/DisplayName';
import { AsyncExportButton } from '/components/export/AsyncExportButton';

const PAGE_SIZE = 10;

const QUERY_ATTRIBUTES = `
  {
    total
    data {
      userId
      displayName
      email
      picture
      certificateId
      certificateTitle
      certificateProvider
      acquiredDate
      expireDate
    }
  }
`;

const columns = [
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    render: (displayName, record) => (
      <DisplayName name={displayName} avatar={record.picture} />
    ),
  },
  {
    title: 'Email Address',
    dataIndex: 'email'
  },
  {
    title: 'Certification',
    dataIndex: 'certificateTitle'
  },
  {
    title: 'Acquired Date',
    dataIndex: 'acquiredDate',
    render: acquiredDate => <DateFormat format='D MMM YYYY'>{acquiredDate}</DateFormat>
  },
  {
    title: 'Expiration Date',
    dataIndex: 'expireDate',
    render: expireDate => <DateFormat format='D MMM YYYY'>{expireDate}</DateFormat>
  }
];

const GET_USER_CERTIFICATION_ACTIVITY_QUERY = gql`
  query ACG_organisationCertificationActivityReport($organisationId: String!) {
    ACG_organisationCertificationActivityReport(organisationId: $organisationId) {
      downloadUrl
    }
  }
`;

class CertificationActivityTable extends React.Component {
  constructor(props) {
    super(props);
    this.initialLoad = true;
    this.state = {
      query: '',
      page: 1
    };
  }

  render() {
    const { groupId, organisationId } = this.props;
    const { query } = this.state;
    return (
      <OrganisationUsersQuery
        organisationId={organisationId}
        query={this.state.query}
        pageSize={10}
      >
        {({ data: users }) => {
          const userIds = query ? users.map(({ user_id }) => user_id) : undefined;
          return (
            <OrganisationUserCertificationActivityQuery
              attributes={QUERY_ATTRIBUTES}
              organisationId={organisationId}
              groupId={groupId}
              userIds={userIds}
              page={this.state.page}
              pageSize={PAGE_SIZE}
              renderLoading={false}
            >
              {({ loading, data, error, pagination: { total, page, pageSize } }) => {
                if (!loading) {
                  this.initialLoad = false;
                }

                const emptyState = this.initialLoad || data.length === 0;

                return (
                  <React.Fragment>
                    <NoMarginModule>
                      <ModuleHeader header="Student List">
                        <Flex>
                          <SearchBox
                            height="35px"
                            placeholder="Search Users"
                            onChange={e => this.setState({ query: e.target.value })}
                          />

                          <Box ml="8px">
                            <ApolloConsumer>
                              {client => (
                                <AsyncExportButton
                                  onExport={async () => {
                                    const { data } = await client.query({
                                      query: GET_USER_CERTIFICATION_ACTIVITY_QUERY,
                                      variables: {
                                        organisationId
                                      }
                                    });
                                    return data.ACG_organisationCertificationActivityReport.downloadUrl;
                                  }}
                                />
                              )}
                            </ApolloConsumer>
                          </Box>
                        </Flex>
                      </ModuleHeader>
                      <ActivityTable
                        cursor="pointer"
                        rowKey={({ userId, certificateId }) => `${userId}-${certificateId}-${uuid()}`}
                        loading={{
                          indicator: <Spinner size="3rem" />,
                          spinning: loading && !emptyState
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                          current: page,
                          pageSize,
                          total,
                          onChange: current => this.setState({ page: current })
                        }}
                        locale={{
                          emptyText: (
                            <TableState
                              title="No certifications found"
                              subtitle={`Your students have not uploaded any certifications.`}
                              loading={loading && emptyState}
                              error={!!error}
                            />
                          )
                        }}
                      />
                    </NoMarginModule>
                  </React.Fragment>
                )
              }}
            </OrganisationUserCertificationActivityQuery>
          )
        }}
      </OrganisationUsersQuery>
    )
  }
}

const ActivityTable = styled(PaginatedTable)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding-left: 20px;
  }
`
const NoMarginModule = styled(Module)`
  margin-bottom: 0;
`;
export { CertificationActivityTable };
