import React from 'react';
import { colour } from '/styles/variables';
import styled from 'styled-components';
import { CertificationQuery } from './CertificationQuery';
import { Box } from '@a-cloud-guru/kermit';
import { CTAButton } from '/components/button/CTAButton';

export const CertificationSummaryPanel = ({ organisationId, location }) => {
  return (
    <CertificationQuery organisationId={organisationId}>
      {({ dataForValid, dataForAll, getLatest, addedLastThirtyDays }) => {
        const latestValid = getLatest(dataForValid);
        const latestAll = getLatest(dataForAll);
        return (
          <React.Fragment>
            <Box pl="15px">
              <SummaryPanel className={'summaryPanel'}>
                <SummaryPanelItem className={`SummaryPanelItem ${location}`}>
                  <SummaryPanelItemInfo
                    className={`${location} SummaryPanelItemInfo`}
                  >
                    {latestValid.total}
                  </SummaryPanelItemInfo>
                  <SummaryPanelItemContext
                    className={`${location} SummaryPanelItemContext`}
                  >
                    Current Certifications
                  </SummaryPanelItemContext>
                  <SummaryPanelItemScope
                    className={`${location} SummaryPanelItemScope`}
                  >
                    All Time
                  </SummaryPanelItemScope>
                </SummaryPanelItem>

                <SummaryPanelItem
                  className={`SummaryPanelItem ${location} SummaryPanelItem`}
                >
                  <SummaryPanelItemInfo
                    className={`${location} SummaryPanelItemInfo`}
                  >
                    {latestAll.total}
                  </SummaryPanelItemInfo>
                  <SummaryPanelItemContext
                    className={`${location} SummaryPanelItemContext`}
                  >
                    All Certifications
                  </SummaryPanelItemContext>
                  <SummaryPanelItemScope
                    className={`${location} SummaryPanelItemScope`}
                  >
                    All Time
                  </SummaryPanelItemScope>
                </SummaryPanelItem>

                <SummaryPanelItem className={`SummaryPanelItem ${location}`}>
                  <SummaryPanelItemInfo
                    className={`${location} SummaryPanelItemInfo`}
                  >
                    {addedLastThirtyDays}
                  </SummaryPanelItemInfo>
                  <SummaryPanelItemContext
                    className={`${location} SummaryPanelItemContext`}
                  >
                    Recently Added
                  </SummaryPanelItemContext>
                  <SummaryPanelItemScope
                    className={`${location} SummaryPanelItemScope`}
                  >
                    Last Month
                  </SummaryPanelItemScope>
                </SummaryPanelItem>

                <SummaryPanelItem
                  className={`SummaryPanelItem ${location} disciplinesContainer`}
                >
                  <SummaryPanelItemInfo
                    className={`SummaryPanelItemInfo ${location} disciplinesContainer`}
                  >
                    {latestValid.data.length}
                  </SummaryPanelItemInfo>
                  <SummaryPanelItemContext
                    className={`SummaryPanelItemContext ${location} disciplinesContainer`}
                  >
                    Disciplines
                  </SummaryPanelItemContext>
                </SummaryPanelItem>

                <SummaryPanelItem
                  className={`SummaryPanelItem ${location} ctaButtonContainer`}
                >
                  <CTAButton
                    shape="round"
                    style={{ height: 'fit-content' }}
                    className={`CTAButton ${location}`}
                    onClick={() =>
                      window.location.replace(
                        `/organizations/${organisationId}/reports/user-certificate-activity`
                      )
                    }
                  >
                    View Report
                  </CTAButton>
                </SummaryPanelItem>
              </SummaryPanel>
            </Box>
          </React.Fragment>
        );
      }}
    </CertificationQuery>
  );
};

export const SummaryPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 161px;
  padding-top: 41px;
`;

export const SummaryPanelItem = styled.div`
  &.SummaryPanelItem {
    height: 40%;
    &.certificationsReport {
      width: 23%;
      &.disciplinesContainer,
      &.ctaButtonContainer {
        display: none;
      }
    }
    &.dashboard {
      width: 15%;
      &.disciplinesContainer {
        width: 15%;
      }
      &.ctaButtonContainer {
        width: 50%;
        margin-left: auto;
        justify-content: flex-end;
        display: flex;
        margin-right: 50px;
      }
    }
  }
`;

export const SummaryPanelItemInfo = styled.div`
  &.SummaryPanelItemInfo {
    color: ${colour.portGore};
    line-height: 1;
    &.certificationsReport {
      font-size: 36px;
      font-weight: 500;
    }
    &.dashboard {
      font-size: 32px;
      font-weight: 600;
    }
  }
`;
export const SummaryPanelItemContext = styled.div`
  font-weight: 500;

  &.certificationsReport {
    font-size: 15px;
    color: ${colour.bunting};
    line-height: 1.5;
  }
  &.dashboard {
    font-size: 13px;
    color: ${colour.shipCove};
    line-height: 1.4;
  }
`;

export const SummaryPanelItemScope = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: ${colour.shipCove};
  &.dashboard {
    display: none;
  }
`;
