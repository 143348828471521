// reducers/yourReducer.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  superAdmin: false,
  organisationWriteAccess: false,
  initialState: true,
};

const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {
    setRoles: (state, action) =>
      (state = { ...state, ...action.payload, initialState: false }),
  },
});

export const { setRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
