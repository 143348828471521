import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { RevokeModal } from './RevokeModal';
import { TableAction } from '/components/table/TableAction';

const REVOKE_INVITATION_MUTATION = gql`
  mutation ACG_revokeOrganisationInvitation($code: String!) {
    ACG_revokeOrganisationInvitation(code: $code) {
      invitation {
        id
        email
        createdAt
        sentAt
        updatedAt
        status
      }
    }
  }
`;

class RevokeInvite extends React.PureComponent {
  state = {
    loading: false,
    modalVisible: false
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  onCancel = () => {
    this.setState({ modalVisible: false });
  };

  revoke = async ({ invitationCode }) => {
    this.setState({ loading: true });

    await this.props.revokeInvitation({ variables: { code: invitationCode } });
    this.onCancel();
  };

  render() {
    const { invitationCode, email } = this.props;

    return (
      <React.Fragment>
        <TableAction
          onClick={() => {
            this.showModal();
          }}
          colour='red'
          last
        >
          Revoke
        </TableAction>
        <RevokeModal
          loading={this.state.loading}
          mutationCallback={() => this.revoke({ invitationCode })}
          onCancel={this.onCancel}
          onClose={() => this.props.addRevokedEmail({ email })}
          email={email}
          visible={this.state.modalVisible}
        />
      </React.Fragment>
    );
  }
};

const RevokeInviteWithMutation = graphql(
  REVOKE_INVITATION_MUTATION, { name: 'revokeInvitation' }
)(RevokeInvite);

export { RevokeInviteWithMutation as RevokeInvite };
