import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query } from 'react-apollo';

import { QueryBoundary } from '/components/data/QueryBoundary';
import { CoursesQuery } from '.';

const ORGANISATION_COURSE_PROGRESS_QUERY = gql`
  query ACG_organisationCourseProgressReport($organisationId: String!) {
    ACG_organisationCourseProgressReport(organisationId: $organisationId) {
      id
      courseId
      numberOfUsers
      progress
    }
  }
`;

const OrganisationCourseProgressQuery = ({
  organisationId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
  <Query
    query={ORGANISATION_COURSE_PROGRESS_QUERY}
    variables={{ organisationId }}
    {...restProps}
  >
    {(props) => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data }) => { 
          const orgCourseProgress = get(data, 'ACG_organisationCourseProgressReport', []);
          const filters = orgCourseProgress.map(course => `objectID:${course.id}`).join(' OR ');
          return (
            <CoursesQuery pageSize={orgCourseProgress.length} filters={filters}>
              {({ data: courseData = [], loading }) => {
                const populateTitleData = orgCourseProgress.map(({ courseId, ...rest }) => {
                  const course = courseData.find(course => course.courseId === courseId);
                  return { courseId, courseTitle: loading ? '' : get(course, 'courseTitle', courseId), ...rest };
                });

                return children({
                  data: populateTitleData
                });
              }}
            </CoursesQuery>
          );
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { OrganisationCourseProgressQuery };
