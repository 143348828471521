import { format, distanceInWordsStrict } from 'date-fns';

const formatDate = ({ date, dateFormat = 'D MMMM YYYY' }) => {
  return date ? format(date, dateFormat) : '';
};

const dateDistanceInWordsToNow = ({ date, addSuffix = true, addPrefix = true }) => {
  return (addPrefix ? 'Over ' : '') + distanceInWordsStrict(date, new Date()) + (addSuffix ? ' ago' : '');
};

export { formatDate, dateDistanceInWordsToNow };
