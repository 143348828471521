import { get, isEmpty } from 'lodash';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const constructFilter = ({ role, status }) => {
  let filter = 'active:true';

  if (role === 'admin') {
    filter += ' AND organisation_admin:true';
  };

  if (status === 'active') {
    filter += ' AND organisation_license_consumed:true';
  };

  if (status === 'inactive') {
    filter += ' AND organisation_license_consumed:false';
  };

  return filter;
}

const useOrganisationUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  filters = {
    role: 'user',
    status: 'all'
  },
  sorter,
  key = 0
}) => {
  const { role, status } = filters;
  const replica = isEmpty(sorter) ? undefined : `${sorter.field}_${sorter.order}`;

  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'user',
    replica,
    query,
    page,
    hitsPerPage: pageSize,
    filters: constructFilter({ role, status }),
    key
  });

  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useOrganisationUsersQuery };