import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';
import { PrimaryButton } from '../button/PrimaryButton';
import { DefaultButton } from '../button/DefaultButton';
import { PresetFilter } from './PresetFilter';
import { DateInput } from './DateInput';
import { colour } from '../styles/variables';

const { RangePicker: AntRangePicker } = DatePicker;

class RangePicker extends React.Component {
  state = {
    startDate: undefined,
    endDate: undefined
  }

  static defaultProps = {
    ranges: undefined
  }

  componentDidMount() {
    document.addEventListener('keydown', this.bindEnterPress);
    if (this.props.value) {
      const [startDate, endDate] = this.props.value;
      this.setRange(startDate, endDate);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate
    || prevState.endDate !== this.state.endDate) {
      this.notify();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.bindEnterPress);
  }

  bindEnterPress = (e) => {
    const { startDate, endDate } = this.state;

    if (e.which === 13 && startDate && endDate) {
      this.handleChange();
    }
  }

  notify = () => {
    const { startDate, endDate } = this.state;
    this.props.onChange([startDate, endDate]);
  }

  setDate = (key, date) => {
    this.setState({ [key]: date });
  }

  setRange = (startDate, endDate) => {
    this.setState({
      startDate,
      endDate
    });
  }

  handleCalendarChange = (dates) => {
    const startDate = dates[0];
    const endDate = dates[1] || this.state.endDate;

    this.setRange(startDate, endDate);
  }

  handleChange = () => {
    const { startDate, endDate } = this.state;
    const { onOk, closePicker } = this.props;

    onOk([startDate, endDate]);
    closePicker();
  }

  render() {
    const { startDate, endDate } = this.state;
    const { ranges, closePicker, value, ...restProps } = this.props;
    return (
      <RangePickerContainer>
        <FilterContainer justifyContent={ranges ? 'space-between' : 'flex-end'}>
          {ranges && (
            <PresetFilter
              ranges={ranges}
              setRange={this.setRange}
            />
          )}
          <InputContainer>
            <DateInput
              value={startDate}
              setDate={date => this.setDate('startDate', date)}
              validate={date => endDate && date.isBefore(endDate)}
            />
            <DateInput
              value={endDate}
              setDate={date => this.setDate('endDate', date)}
              validate={date => startDate && date.isAfter(startDate)}
            />
          </InputContainer>
        </FilterContainer>

        <AntRangePicker
          open
          value={[startDate, endDate]}
          style={{ display: 'none' }}
          onCalendarChange={this.handleCalendarChange}
          renderExtraFooter={() => (
            <ButtonContainer>
              <DefaultButton onClick={() => {
                closePicker();
              }}>
                Cancel
              </DefaultButton>
              <PrimaryButton onClick={this.handleChange}>
                Apply
              </PrimaryButton>
            </ButtonContainer>
          )}
          {...restProps}
        />
      </RangePickerContainer>
    );
  }
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
`;

const RangePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 4px 8px 22px;
  width: 552px;
  height: 332px;
  background-color: #fff;
  box-shadow: 0 0 24px 0 ${rgba(colour.blueDark, 0.06)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;

  * {
    margin-left: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;

  *  {
    margin-left: 5px;
  }
`;

export { RangePicker };