import React from 'react';
import get from 'lodash.get';
import moment from 'moment';

import { calculateDifferenceInPercentage } from '/utils/calculatePercentage';
import { AcgOrganisationCourseEngagementSummary } from '/data/query';

const today = moment();
const startLastMonth = today.clone().subtract(1, 'months').startOf('month');
const endLastMonth = today.clone().subtract(1, 'months').endOf('month');

const startTwoMonthsAgo = today.clone().subtract(2, 'months').startOf('month');
const endTwoMonthsAgo = today.clone().subtract(2, 'months').endOf('month');

const CourseStatsQuery = ({
  organisationId,
  courseId,
  groupId,
  children,
  renderLoading = false,
  renderError = false,
  range: { from, to, lastFrom, lastTo } = {},
}) => (
  <AcgOrganisationCourseEngagementSummary
    organisationId={organisationId}
    renderLoading={renderLoading}
    renderError={renderError}
    from={from || startLastMonth.format('YYYY-MM-DD')}
    to={to || endLastMonth.format('YYYY-MM-DD')}
    courseId={courseId}
    groupId={groupId}
  >
    {({ summary, loading }) => {
      const activeUsers = get(summary, 'activeUsersCount') || 0;
      const averageLessons =
        get(summary, 'completedComponentsPerActiveUser') || 0;
      const lessonsCompleted = get(summary, 'completedComponentsCount') || 0;
      const totalContentDurationMinutes =
        get(summary, 'totalContentDurationMinutes') || 0;

      return (
        <AcgOrganisationCourseEngagementSummary
          renderLoading={renderLoading}
          renderError={renderError}
          from={lastFrom || startTwoMonthsAgo.format('YYYY-MM-DD')}
          to={lastTo || endTwoMonthsAgo.format('YYYY-MM-DD')}
          courseId={courseId}
          groupId={groupId}
          organisationId={organisationId}
        >
          {({ summary: lastMonthSummary, loading: loadingLastMonth }) => {
            const activeUsersGrowth = calculateDifferenceInPercentage(
              get(lastMonthSummary, 'activeUsersCount') || 0,
              activeUsers
            );
            const averageLessonsGrowth = calculateDifferenceInPercentage(
              get(lastMonthSummary, 'completedComponentsPerActiveUser') || 0,
              averageLessons
            );
            const lessonsCompletedGrowth = calculateDifferenceInPercentage(
              get(lastMonthSummary, 'completedComponentsCount') || 0,
              lessonsCompleted
            );
            const totalContentDurationMinutesGrowth = calculateDifferenceInPercentage(
              get(lastMonthSummary, 'totalContentDurationMinutes') || 0,
              totalContentDurationMinutes
            );
            return children({
              loading: loading || loadingLastMonth,
              activeUsers,
              averageLessons,
              lessonsCompleted,
              totalContentDurationMinutes,
              activeUsersGrowth,
              averageLessonsGrowth,
              lessonsCompletedGrowth,
              totalContentDurationMinutesGrowth,
            });
          }}
        </AcgOrganisationCourseEngagementSummary>
      );
    }}
  </AcgOrganisationCourseEngagementSummary>
);

export { CourseStatsQuery };
