import React from 'react'

import PageStatus from '../page-status';

const ErrorStatus = ({ message }) => (
  <PageStatus header="An error occurred">
    {message || `Something has gone wrong.`}
    <br />
    Please either contact <a href="mailto:support@acloud.guru">support</a> for help.
  </PageStatus>
);

export default ErrorStatus;
