import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';
import { get } from 'lodash'

const SELF_SERVICE_INVOICES_QUERY = (attributes) => gql`
  query ACG_billingInvoices($organisationId: String!) {
    ACG_billingInvoices(organisationId: $organisationId) ${attributes}
  }
`;

const SelfServiceBillingInvoicesQuery = ({
  organisationId,
  attributes = `{
    invoices {
      id
      providerCode
      status
      date
      dueDate
      total
      currencyCode
      downloadUrl
    }
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
    <Query
      query={SELF_SERVICE_INVOICES_QUERY(attributes)}
      variables={{ organisationId }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: get(data, 'ACG_billingInvoices.invoices') || [],
              ...rest
            })
          }}
        </QueryBoundary>
      )}
    </Query>
  );

export { SelfServiceBillingInvoicesQuery };
