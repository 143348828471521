import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Form, Input, Button, Switch } from 'antd';
import { useMutation } from 'react-apollo';

import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { colour } from '/styles/variables';

const UPDATE_ORGANISATION_SAML_CONFIGURATION = gql`
  mutation updateOrganisationSamlConfiguration(
    $input: ACG_UpdateOrganisationSamlConfigurationInput!
  ) {
    ACG_updateOrganisationSamlConfiguration(input: $input) {
      organisation {
        id
        connectionName
        companyCode
        features {
          allowInvitations
          allowUserReactivationViaSAML
        }
      }
    }
  }
`;

const UpdateSamlConnectionDetailsForm = ({
  id: organisationId,
  connectionName,
  companyCode,
  features,
  closeModal,
  form
}) => {
  const { allowInvitations, allowUserReactivationViaSAML } = features || {};

  const [allowInvitationsToggle, setAllowInvitationsToggle] = useState(
    allowInvitations
  );

  const [allowUserReactivationViaSAMLToggle, setAllowUserReactivationViaSamlToggle] = useState(
    allowUserReactivationViaSAML
  );

  const [connectionNameInput, setConnectionNameInput] = useState(
    connectionName
  );

  const [companyCodeInput, setCompanyCodeInput] = useState(companyCode);

  const [updateSamlConfiguration, samlConfigurationMutation] = useMutation(
    UPDATE_ORGANISATION_SAML_CONFIGURATION
  );

  const { getFieldDecorator } = form;

  const updateSamlConfigurationDetails = async (event) => {
    event.preventDefault();
    form.validateFields(async (err) => {
      if (err) {
        console.error('Unable to validate fields', err);
        return;
      }

      const { data } = await updateSamlConfiguration({
        variables: {
          input: {
            organisationId,
            connectionName: connectionNameInput,
            companyCode: companyCodeInput,
            disableInvitations: !allowInvitationsToggle,
            allowUserReactivationViaSAML: allowUserReactivationViaSAMLToggle,
            multiCompany: companyCodeInput ? true : false
          }
        }
      });

      if (data) {
        return closeModal();
      }
    });
  };

  return (
    <Form onSubmit={updateSamlConfigurationDetails} hideRequiredMark>
      <FormItem
        display="block"
        style={{ borderBottom: 'none', paddingBottom: 0 }}
      >
        <InputLabel>Auth0 Connection Name</InputLabel>
        <InputField>
          {getFieldDecorator('connectionName', {
            initialValue: connectionName || '',
            rules: [
              {
                required: true,
                message:
                  'Connection Name is required. To remove connectionName, please contact For Business dev team.'
              }
            ]
          })(
            <Input
              onChange={(e) => {
                setConnectionNameInput(e.target.value);
                setAllowInvitationsToggle(!e.target.value);
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem
        display="block"
        style={{ borderBottom: 'none', paddingBottom: 0 }}
      >
        <InputLabel>Company Code</InputLabel>
        <InputField>
          {getFieldDecorator('companyCode', {
            initialValue: companyCode || '',
            rules: [
              {
                required: false
              }
            ]
          })(
            <Input
              onChange={(e) => {
                setCompanyCodeInput(e.target.value);
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Allow Email Invitations</InputLabel>
        <InputField>
          {getFieldDecorator('allowInvitations', {
            initialValue: !!allowInvitationsToggle
          })(
            <StyledSwitch
              checked={allowInvitationsToggle}
              onChange={(isToggled) => setAllowInvitationsToggle(isToggled)}
            />
          )}
          {allowInvitationsToggle && connectionNameInput && (
            <InputLabel style={{ fontWeight: 'normal', color: colour.red }}>
              Caution: This override should only be used during SSO Onboarding.
              SAML/SSO users cannot use email/password credentials to log in.
            </InputLabel>
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Allow User Reactivation</InputLabel>
        <InputField>
          {getFieldDecorator('allowUserReactivationViaSAML', {
            initialValue: !!allowUserReactivationViaSAMLToggle
          })(
            <StyledSwitch
              checked={allowUserReactivationViaSAMLToggle}
              onChange={(isToggled) => setAllowUserReactivationViaSamlToggle(isToggled)}
            />
          )}
        </InputField>
      </FormItem>
      <Footer>
        {samlConfigurationMutation.error && (
          <p>{samlConfigurationMutation.error.message}</p>
        )}
        <Button
          type="primary"
          htmlType="submit"
          loading={samlConfigurationMutation.loading}
          disabled={!connectionNameInput}
          style={
            !connectionNameInput ? { backgroundColor: colour.gray400 } : {}
          }
        >
          {samlConfigurationMutation.loading ? 'Updating' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};

const InputField = styled.div`
  flex: 3;
`;

const StyledSwitch = styled(Switch)`
  padding: 0 2px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
`;

const ComposedUpdateSamlConnectionDetailsForm = Form.create()(
  UpdateSamlConnectionDetailsForm
);
export { ComposedUpdateSamlConnectionDetailsForm as UpdateSamlConnectionDetailsForm };
