import React from 'react';
import gql from 'graphql-tag';
import { Spinner, DateFormat, Box, Flex } from '@a-cloud-guru/kermit';
import { OrganisationUserCourseActivityQuery } from '/data/query';
import { DisplayName } from '/components/table/DisplayName';
import { StyledTable } from '/components/table/StyledTable';
import { ApolloConsumer } from 'react-apollo';
import { AsyncExportButton } from '/components/export/AsyncExportButton';
import { ModuleHeader } from '/components/module/ModuleHeader';

const QUERY_ATTRIBUTES = `
  {
    total
    data {
      userId
      displayName
      email
      picture
      courseId
      courseTitle
      progress
      minsWatchedAllTime
      lastActivity
    }
  }
`;

const ACG_GET_USER_COURSE_ACTIVITY_DOWNLOAD_URL_QUERY = gql`
  query ACG_organisationCourseActivityReport(
    $organisationId: String!
    $groupId: String
    $courseId: String
  ) {
    ACG_organisationCourseActivityReport(
      organisationId: $organisationId
      filters: { groupId: $groupId, courseId: $courseId }
    ) {
      downloadUrl
    }
  }
`;

const columns = [
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    render: (displayName, record) => (
      <DisplayName name={displayName} avatar={record.picture} />
    )
  },
  {
    title: 'Email Address',
    dataIndex: 'email'
  },
  {
    title: 'Course',
    dataIndex: 'courseTitle'
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    render: (progress) => `${progress}%`
  },
  {
    title: 'Minutes Watched',
    dataIndex: 'minsWatchedAllTime',
    render: (minsWatchedAllTime) => `${minsWatchedAllTime ? minsWatchedAllTime.toLocaleString() : '0'}`
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    render: (lastActivity) => (
      <DateFormat format="D MMM YYYY, hh:mm:ss A">
        {lastActivity || '-'}
      </DateFormat>
    )
  }
];

class ActivityReport extends React.Component {
  state = { page: 1 };

  render() {
    const { courseId, organisationId } = this.props;

    return (
      <OrganisationUserCourseActivityQuery
        attributes={QUERY_ATTRIBUTES}
        organisationId={organisationId}
        courseId={courseId}
        page={this.state.page}
        pageSize={10}
        renderLoading={false}
      >
        {({ loading, data, pagination: { total, page, pageSize } }) => (
          <>
            <ModuleHeader header="User Activity">
              <Flex justifyContent="flex-end">
                <Box ml="8px">
                  <ApolloConsumer>
                    {(client) => (
                      <AsyncExportButton
                        onExport={async () => {
                          const { data } = await client.query({
                            query: ACG_GET_USER_COURSE_ACTIVITY_DOWNLOAD_URL_QUERY,
                            variables: {
                              organisationId
                            }
                          });
                          return data.ACG_organisationCourseActivityReport
                            .downloadUrl;
                        }}
                      />
                    )}
                  </ApolloConsumer>
                </Box>
              </Flex>
            </ModuleHeader>
            <StyledTable
              rowKey={({ userId, courseId }) => `${userId}-${courseId}`}
              loading={{
                indicator: <Spinner size="2rem" />,
                spinning: loading
              }}
              columns={columns}
              dataSource={data}
              onChange={(pagination) =>
                this.setState({ page: pagination.current })
              }
              pagination={{
                current: page,
                pageSize,
                total
              }}
            />
          </>
        )}
      </OrganisationUserCourseActivityQuery>
    );
  }
}

export { ActivityReport };
