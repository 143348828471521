import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';

import { SelfServiceBillingHistoryQuery } from '/data/query';
import { DateFormat } from '/components/date/DateFormat';
import { StyledTable } from '/components/table/StyledTable';
import { Header, StyledCard, StyledDivider } from "./Shared";
import { Button, Tooltip } from 'antd';

const SelfServiceBillingHistory = organisation => {
  const organisationId = get(organisation, 'id');
  const websocket = get(organisation, 'websocket');
  const [ lastMessage, setLastMessage ] = useState({
    id: null,
    dateSent: 0
  });
  let refetchData;

  const onMessage = (message) => {
    const websocketMessage = JSON.parse(message.data);
    const id = get(websocketMessage, 'id', '');
    const dateSent = get(websocketMessage, 'dateSent', 0);
    const action = get(websocketMessage, 'action', '');
    const messageOrgId = get(websocketMessage, 'context.organisationId', '');
    if (
      organisationId === messageOrgId &&
      action === 'BillingHistoryUpdate' &&
      refetchData &&
      id !== lastMessage.id &&
      dateSent > lastMessage.dateSent
    ) {
      setLastMessage(websocketMessage);
      refetchData();
    }
  };

  useEffect(() => {
    if (websocket) {
      websocket.addEventListener('message', onMessage);
      return () => {
        websocket.removeEventListener('message', onMessage)
      };
    }
  }, [websocket]);

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Billing History</StyledTitle>
        <Tooltip title="Refresh Billing History">
          <StyledRefreshButton icon="reload" onClick={() => {
            if (refetchData) {
              refetchData();
            }
          }}/>
        </Tooltip>
      </StyledHeader>

      <StyledDivider />

      <SelfServiceBillingHistoryQuery
        organisationId={organisationId}
        notifyOnNetworkStatusChange={true}
      >
        {({ data, refetch }) => {
          refetchData = refetch;
          return (
            <ReStyledTable
            rowKey="id"
            pagination={data.histories.length > 10 ? { pageSize: 10 } : false}
            scroll={{ y: 460 }}
            columns={[
              {
                title: 'Event',
                dataIndex: 'event',
                key: 'event',
              },
              {
                title: 'Logged',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: createdAt => (
                  createdAt
                    ? <DateFormat format='D MMM YYYY, hh:mm A'>{ createdAt }</DateFormat>
                    : '-'
                )
              },
              {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
                render: Message => Message || '-'
              }
            ]}
            dataSource={data.histories}
          />
          );
        }}
      </SelfServiceBillingHistoryQuery>
    </StyledCard>
  )
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

const StyledRefreshButton = styled(Button)`
  min-width: auto;
`;

const ReStyledTable = styled(StyledTable)`
  &&& {
    .ant-table-header {
      overflow-y: auto !important;
    }
    .ant-table-body {
      min-height: ${props => props.dataSource.length > 10 ? '460px' : '0px'};
      overflow-y: auto !important;
    }
    .ant-pagination {
      padding: 16px 10px;
      margin: 0px;
      border-top: 1px solid rgba(0,0,0,0.12);
      width: 100%;
    }
  }
`;

export {
  SelfServiceBillingHistory
};
