import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { useEffect } from 'react';

const API_SETTINGS_QUERY = gql`
  query ACG_apiSettings ($organisationId: String!) {
    ACG_apiSettings(organisationId: $organisationId) {
      id
      createdAt
    }
  }
`;

const useApiSettingsQuery = ({ organisationId }) => {
  const [fetchApiKeys, { data, loading, refetch }] = useLazyQuery(API_SETTINGS_QUERY, { variables: { organisationId }});

  useEffect(() => {
    if (organisationId) {
      fetchApiKeys();
    }
  }, [organisationId]);

  const apiSettings = get(data, 'ACG_apiSettings') || [];
  const lastCreatedKey = apiSettings.reduce((current, next) => current > next.createdAt ? current : next.createdAt, '')

  return { apiSettings, lastCreatedKey, refetch, loading };
};

export { useApiSettingsQuery };
