import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query, QueryBoundary } from '/components/data';

const ORGANISATION_COURSE_PROGRESS_QUERY = (attributes) => gql`
  query ACG_organisationCourseProgressReport($organisationId: String!) {
    ACG_organisationCourseProgressReport(organisationId: $organisationId) ${attributes}
  }
`;

const CourseProgressQuery = ({
  attributes = `{
    id
    courseId
    numberOfUsers
    progress
  }`,
  renderLoading,
  renderError,
  children,
  organisationId,
  ...restProps
}) => (
  <Query
    query={ORGANISATION_COURSE_PROGRESS_QUERY(attributes)}
    variables={{
      organisationId,
    }}
    {...restProps}
  >
    {(props) => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data, ...rest }) => {
          return children({
            data: get(data, 'ACG_organisationCourseProgressReport') || [],
            ...rest,
          });
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { CourseProgressQuery };
