import React from 'react';
import { Icon, Dropdown } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import {
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuDivider,
} from '/components/dropdown';
import { colour } from '/styles/variables';

const today = moment();
const END_OF_LAST_WEEK = today.clone().subtract(1, 'weeks').endOf('week');

const DEFAULT_RANGES = {
  week: {
    '3 weeks': [today.clone().subtract(3, 'weeks'), END_OF_LAST_WEEK],
    '6 weeks': [today.clone().subtract(6, 'weeks'), END_OF_LAST_WEEK],
    '12 weeks': [today.clone().subtract(12, 'weeks'), END_OF_LAST_WEEK],
  },
  month: {
    '3 months': [today.clone().subtract(3, 'months'), today],
    '6 months': [today.clone().subtract(6, 'months'), today],
  },
};

const getLabel = (ranges, type, options) => {
  const rangeType = options[type];
  const selectedRange = Object.keys(rangeType).find((key) => {
    const range = rangeType[key];
    return (
      ranges[0].isSame(range[0], 'day') && ranges[1].isSame(range[1], 'day')
    );
  });

  return selectedRange || '12 weeks';
};

const DurationFilter = ({
  ranges = DEFAULT_RANGES['week']['12 weeks'],
  type,
  setRange,
  setType,
  options = DEFAULT_RANGES,
  onChange = () => {},
}) => {
  const weekOptions = Object.keys(options.week);
  const monthOptions = Object.keys(options.month);

  const handleRangeChange = ({ key }) => {
    const type = key.includes('week') ? 'week' : 'month';
    const [startDate, endDate] = options[type][key];
    setType(type.toUpperCase());
    onChange(key);
    setRange([startDate, endDate]);
  };

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <DropdownMenu onClick={handleRangeChange}>
          {weekOptions.map((week) => (
            <StyledDropdownMenuItem key={week}>{week}</StyledDropdownMenuItem>
          ))}
          <DropdownMenuDivider />
          {monthOptions.map((month) => (
            <StyledDropdownMenuItem key={month}>{month}</StyledDropdownMenuItem>
          ))}
        </DropdownMenu>
      }
    >
      <StyledDropdownButton>
        <a className="ant-dropdown-link">
          Last {getLabel(ranges, type.toLowerCase(), options)}{' '}
          <CaretIcon type="caret-down" />
        </a>
      </StyledDropdownButton>
    </Dropdown>
  );
};

const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  margin: 0;
  padding: 6px 16px;
`;

const StyledDropdownButton = styled(DropdownButton)`
  padding: 6px 8px 8px 14px;
  height: 100%;
  width: 100%;
`;

const CaretIcon = styled(Icon)`
  position: relative;
  margin: 2px 0 0 4px;
  color: ${colour.gray500};
  align-self: center;
`;

export { DurationFilter };
