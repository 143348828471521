import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

import Table from '../table';
import { colour } from '../styles/variables';

const DEFAULT_PAGINATION_LIMIT = 1000;

const getPagination = ({ current, pageSize, total, onChange, tooManyResultsWarning }, limit = DEFAULT_PAGINATION_LIMIT, showOverLimitMessage) => {
  const totalOverLimit = total > limit;
  const totalResultsText = `${total.toLocaleString()} ${total > 1 ? ' Results' : ' Result'}`;

  return {
    current,
    pageSize,
    total: totalOverLimit ? limit : total,
    onChange,
    showTotal: (total, range) => (
      <React.Fragment>
        {totalOverLimit && showOverLimitMessage && (
          <TooManyResults>
            <TooManyResultsIcon type="info-circle" theme="filled" />
            {
              tooManyResultsWarning
                ? tooManyResultsWarning
                : `Displaying first ${limit} results, refine your search to view all relevant results`
            }
            </TooManyResults>
        )}
        <TotalResults>Showing <b>{range[0]}</b>-<b>{range[1]}</b> of <b>{totalResultsText}</b></TotalResults>
      </React.Fragment>
    )
  };
}

const PaginatedTable = (props) => {
  const { pagination, limit, showOverLimitMessage = true,  ...restProps } = props;
  const paginationConfig = pagination ? getPagination(pagination, limit, showOverLimitMessage) : null;

  return (
    <StyledPaginatedTable
      {...restProps}
      pagination={paginationConfig}
      overLimit={false}
    />
  )
}

const StyledPaginatedTable = styled(Table)`
  .ant-pagination {
    display: flex;
    flex: 1;
    float: none;
  }

  .ant-pagination-total-text {
    order: 2;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-table-row:hover {
    cursor: ${({ cursor }) => cursor || 'default'}
  }
`;

const TooManyResults = styled.span`
  color: ${colour.gray500};
  padding: 5px;
  line-height: 1.2;
`;

const TotalResults = styled.span`
  line-height: 1.2;
`;

const TooManyResultsIcon = styled(Icon)`
  margin: 0 8px 0 12px;
  color: ${colour.blueLight};
`;

export default PaginatedTable;