import React from 'react';
import { StyledModal, Title, Subtitle, Content } from '/components/modal';

const ResendModal = props => (
  <StyledModal
    closable={false}
    confirmLoading={props.loading}
    maskClosable={!props.loading}
    onOk={async () => {
      await props.mutationCallback();
      setTimeout(props.onClose, 300);
    }}
    onCancel={() => {
      props.onCancel();
    }}
    okText='Resend'
    visible={props.visible}
    width="600px"
  >
    <Content>
      <Title>
        Are you sure you wish to resend an invitation for '{props.email}'?
      </Title>
      <Subtitle>
        They will be sent a new invitation and all previously sent invitations to this user will become invalid.
      </Subtitle>
    </Content>
  </StyledModal>
);

export { ResendModal };
