import styled from 'styled-components';
import { flexDirection, alignItems, justifyContent, flexWrap } from 'styled-system';

import { Box } from './Box';

const Flex = styled(Box)`
  display: flex;
  ${flexWrap};
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
`;

export { Flex };
