import { Spinner } from '@a-cloud-guru/kermit';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { Flex } from '/components/box';
import { colour } from '/styles/variables';

class TableState extends React.PureComponent {
  static defaultProps = {
    loading: false,
    error: false,
    title: 'No results found',
    subtitle: ''
  }

  renderContainerBody = () => {
    const { error, loading, title, subtitle } = this.props;

    if (loading) {
      return <Spinner size="2rem" />;
    }

    if (error) {
      return <div>Something went wrong while trying to find your results.</div>
    }

    return (
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    );
  }

  render() {
    return (
      <Flex justifyContent="center" alignItems="center" padding="4rem 0 6rem">
        {this.renderContainerBody()}
      </Flex>
    );
  }
}


const Title = styled.div`
  font-size: 24px;
  color: ${lighten(0.05, colour.gray900)};
`;

const Subtitle = styled.div`
  color: ${colour.gray500};
`;

export { TableState };
