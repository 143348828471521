import get from 'lodash.get';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const useGroupUsersQuery = ({
  query,
  page = 1,
  pageSize = 10,
  groupId
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'user',
    query,
    page,
    hitsPerPage: pageSize,
    filters: `active:true AND group:${groupId}`
  });

  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  };
};

export { useGroupUsersQuery };