import React from 'react';
import gql from 'graphql-tag';

import { UserCourseActivityTable } from './UserCourseActivityTable';
import { StyledTable } from '/components/table/StyledTable';
import { AsyncExportButton } from '/components/export/AsyncExportButton';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { jsonToCsvDownload } from '/lib/json-to-csv';
import { OrganisationCourseProgressQuery } from '/data/query';

const columns = [
  {
    title: 'Course Title',
    dataIndex: 'courseTitle'
  },
  {
    title: 'Number of Students',
    dataIndex: 'numberOfUsers'
  },
  {
    title: 'Average Progress',
    dataIndex: 'progress',
    render: (progress) => `${progress}%`
  }
];

export const CourseProgressTable = ({ organisationId }) => (
  <OrganisationCourseProgressQuery organisationId={organisationId}>
    {({ data }) => (
      <>
        <ModuleHeader header="Course Popularity">
          <AsyncExportButton
            onClick={async () => {
              return jsonToCsvDownload({
                fileName: `course-popularity-${new Date().toISOString()}.csv`,
                data,
                mapper: data => data.map(
                  ({ courseTitle, numberOfUsers, progress }) => ({
                    'Course Title': courseTitle,
                    'Number of Users': numberOfUsers,
                    'Average Progress': progress,
                  })
                )
              });
            }}
          />
        </ModuleHeader>
        <StyledTable
          rowKey={({ courseId }) => courseId}
          columns={columns}
          pagination={false}
          dataSource={data}
          expandedRowRender={({ courseId }) => (
            <UserCourseActivityTable
              organisationId={organisationId}
              courseId={courseId}
            />
          )}
        />
      </>
    )
    }
  </OrganisationCourseProgressQuery>
);
