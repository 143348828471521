import React from 'react';
import { groupBy } from 'lodash';
import { colour } from '/styles/variables';
import { LoadingState } from '/components/chart/LoadingState';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

import {
  containerTemplate,
  tooltipTemplate,
} from './tooltip/certification-tooltip';

const SCALE = {
  totalCerts: {
    min: 0,
  },
  startDate: {
    range: [0, 1],
  },
};

const handleTooltipChange = (ev) => {
  const items = ev.items;
  if (items.length === 0) return;
  const item = items[0];

  const { name, title, color } = item;
  const total = item.value;

  const tooltipTitle = `${title} (${total} ${
    name === 'all' ? '' : 'Valid '
  }Certificates)`;
  const originData =
    name === 'all' ? item.point._origin.allData : item.point._origin.validData;
  const data = groupBy(originData, 'certificateId');
  items.splice(0);

  Object.keys(data).forEach((key) => {
    const certs = data[key];
    items.push({
      title: tooltipTitle,
      color,
      marker: true,
      name: certs[0].certificateTitle,
      value: certs.length,
    });
  });
  if (items.length === 0) {
    items.push({
      title: tooltipTitle,
      color,
      marker: true,
      name: 'Total Certificates',
      value: 0,
    });
  }
  items.sort((a, b) => b.value - a.value);
};

const titleMap = {
  valid: 'Valid Certificates',
  all: 'All Certificates',
};

const CertificationChart = ({ loading, data, height }) => {
  if (loading) {
    return <LoadingState height={height} />;
  }
  return (
    <Chart
      forceFit
      height={height}
      data={data}
      scale={SCALE}
      padding={[55, 60, 30, 'auto']}
      onTooltipChange={handleTooltipChange}
    >
      <Legend
        position="top-left"
        offsetX={5}
        offsetY={-15}
        itemFormatter={(value) => titleMap[value] || ''}
      />
      <Axis
        name="startDate"
        label={{
          formatter: (text) => text.split('-')[1],
        }}
      />
      <Axis name="totalCerts" />
      <Tooltip
        crosshairs={{
          type: 'y',
        }}
        itemTpl={tooltipTemplate}
        containerTpl={containerTemplate}
      />
      <Geom
        type="line"
        position="startDate*totalCerts"
        size={2}
        color={['type', [colour.gray300, colour.blueLight]]}
      />
      <Geom
        type="point"
        position="startDate*totalCerts"
        size={4}
        shape={'circle'}
        color={['type', [colour.gray300, colour.blueLight]]}
        style={{
          stroke: '#fff',
          lineWidth: 1,
        }}
      />
    </Chart>
  );
};

export { CertificationChart };
