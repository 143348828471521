import React from 'react';
import styled from 'styled-components';
import { RangePicker as RangePickerBase } from './RangePicker';
import Flex from '../flex';
import Box from '../flex';
import './style/index.less';

const getFlexJustifyContentFromPlacement = (placement) => {
  switch (placement) {
    case 'bottomLeft':
      return 'flex-start';

    case 'bottomRight':
      return 'flex-end';

    case 'center':
      return 'center';

    default:
      return 'flex-start';
  }
}

class RangePicker extends React.Component {
  state = { visible: false };

  static defaultProps= {
    onChange: () => {},
    onOk: () => {},
    placement: 'bottomLeft'
  }

  closePicker = () => {
    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    const { renderToId, placement, children } = this.props;

    return (
      <Flex flexDirection="column">
        <Box>
          {React.cloneElement(children, {
            onClick: () => this.setState({ visible: !this.state.visible })
          })}
        </Box>

        {visible && (
          <Box justifyContent={getFlexJustifyContentFromPlacement(placement)}>
            <RangePickerPanel className="kermit-date-range-picker" id={renderToId}>
              <RangePickerBase
                {...this.props}
                closePicker={this.closePicker}
                getCalendarContainer={() => document.getElementById(renderToId)}
              />
            </RangePickerPanel>
          </Box>
        )}
      </Flex>
    );
  }
};

export default RangePicker;

const RangePickerPanel = styled.div`
  position: absolute;
  z-index:9999;
  margin-top: 5px;
`;