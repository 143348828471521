import React from 'react';
import { Tooltip } from 'antd';
import { Flex, ImageOptimizer } from '@a-cloud-guru/kermit';
import styled from 'styled-components';
import { colour } from '/styles/variables';

const Group = ({ name, description, colorScheme }) => (
  <Flex my='2px'>
    <ImageOptimizer>
      {({ buildOptimizedSrc }) =>
        <GroupIcon
          src={buildOptimizedSrc(`http://tinygraphs.com/isogrids/${encodeURIComponent(name)}?theme=${colorScheme}&numcolors=4&size=42&lines=4`)}
        />}
    </ImageOptimizer>
    <Text>
      <GroupName title={name}>{name}</GroupName>
      <Tooltip placement='bottomLeft' title={description}>{description || '-'}</Tooltip>
    </Text>
  </Flex>
);

const GroupIcon = styled.img`
  border-radius: 50%;
  margin: 0 12px 0 0;
  height: 42px;
  min-width: 42px;
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GroupName = styled.div`
  color: ${colour.gray900};
  font-weight: 600;
  line-height: 1;
  margin: 4px 0 0;
`;

export { Group };