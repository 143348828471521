import React from 'react';
import styled from 'styled-components';
import { Table, Flex } from '@a-cloud-guru/kermit';
import { DisplayName } from '/components/table/DisplayName';
import { useGroupUsersQuery } from '/data/query-hooks';
import { formatDate, dateDistanceInWordsToNow } from '/utils/date';

const LAST_SEEN_DEPLOY_DATE = new Date(2018, 11, 3);

const columns = [
  {
    title: 'Team Member',
    dataIndex: 'name',
    width: '20%',
    render: (name, { picture }) => (
      <Text>
        <DisplayName name={name} avatar={picture} />
      </Text>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '20%',
    render: email => <Text>{email}</Text>
  },
  {
    title: 'Last Seen',
    dataIndex: 'last_seen_timestamp',
    width: '20%',
    render: lastSeenTimestamp =>
      lastSeenTimestamp ? (
        <Text>
          {formatDate({
            date: lastSeenTimestamp,
            dateFormat: 'D MMM YYYY hh:mm A'
          })}
        </Text>
      ) : (
        <Text>{dateDistanceInWordsToNow({ date: LAST_SEEN_DEPLOY_DATE })}</Text>
      )
  },
  {
    title: 'Team Coordinator',
    dataIndex: 'organisation_team_coordinator',
    width: '40%',
    render: teamCoordinator => (
      <Flex>
        {teamCoordinator && '⭐️'}
      </Flex>)
  }
];

const TeamMembersTable = ({ groupId }) => {
  const { data } = useGroupUsersQuery({
    groupId
  });

  return (
    <StyledTable
      pagination
      rowKey={({ user_id: userId }) => userId}
      columns={columns}
      dataSource={data}
    />
  );
};

const StyledTable = styled(Table)`
  &&& {
    box-shadow: none;
    background-color: #fbfbfb;
    margin: 0;
    tr > th {
      background-color: #fbfbfb;
    }

    .ant-pagination {
      float: right;
      padding: 0 10px;
    }

    td:first-child {
      cursor: pointer;
    }
  }
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { TeamMembersTable };
