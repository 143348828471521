import React from 'react';
import { useEffect } from 'react';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useUserData } from '../../hooks/use-user-data';
import { getConfig } from '../../config/get-config';

const LDProviderWithProfile = ({ children }) => {
  const { userData = {}, userDataLoading } = useUserData();
  const client = useLDClient();

  useEffect(() => {
    if (client && !userDataLoading && userData && userData.pluralsightHandle) {
      const ldClientIdentifyPayload = {
        kind: 'user',
        key: userData.pluralsightHandle,
        acgUserId: userData.acgUserId,
        acgOrganisationId: userData.organisationId,
        pluralsightId: userData.pluralsightId,
        pluralsightHandle: userData.pluralsightHandle,
        isMember: userData.isMember,
        hasOrg: !!userData.organisationId,
        packageName: userData.packageName
      };
      client.identify(ldClientIdentifyPayload);
    }
  }, [client, userData, userDataLoading]);

  return children;
};

export const LaunchDarklyFlagsProvider = ({ children }) => {
  const { LAUNCH_DARKLY_CLIENT_SIDE_ID } = getConfig();
  return (
    <LDProvider clientSideID={LAUNCH_DARKLY_CLIENT_SIDE_ID}>
      <LDProviderWithProfile>{children}</LDProviderWithProfile>
    </LDProvider>
  );
};
