import gql from 'graphql-tag';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';

const PURCHASES_QUERY = (attributes) => gql`
  query purchasesOrganisationQuery($orgId: String!, $queryOpts: String) {
    purchasesOrganisationQuery(orgId: $orgId, queryOpts: $queryOpts) ${attributes}
  }
`;

const PurchasesQuery = ({
  page = 0,
  pageSize = 10,
  orgId,
  attributes = `{
    totalCount
    data {
      userId
      buyerDisplayName
      buyerName
      currency
      description
      purchasedTimestamp
      total
    }
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {

  const queryOpts = JSON.stringify({
    sort: {
      predicate: 'purchased',
      reverse: true
    },
    search: {
      predicateObject: {}
    },
    pagination: {
      start: page,
      totalItemCount: pageSize
    }
  })

  return (
    <Query
      query={PURCHASES_QUERY(attributes)}
      variables={{
        orgId,
        queryOpts
      }}
      {...restProps}
    >
      {({ data, ...rest }) => {
        return children({
          data: get(data, 'purchasesOrganisationQuery') || {},
          ...rest
        })
      }}
    </Query>
  );
};

export { PurchasesQuery };
