import React, { useState } from 'react';
import get from 'lodash.get';
import { lighten } from 'polished';
import styled from 'styled-components';
import { Button, Form, Select, Progress } from 'antd';
import Bluebird from 'bluebird';

import { useAlgoliaRunQuery, useProcessList } from '/lib/hooks';
import { boxShadow, colour } from '/styles/variables';

const BulkRemoveValidateEmail = (props) => {
  const {
    setFound,
    setMissing,
    setFormStep
  } = props;;
  const [submitting, setSubmitting] = useState(false);
  const runQuery = useAlgoliaRunQuery({
    type: 'user'
  });

  const {
    setItemsToProcess,
    completed,
    percent,
    success,
    failed
  } = useProcessList({
    command: findEmail
  });

  if (submitting && completed) {
    setFound(success);
    setMissing(failed);
    setFormStep('CONFIRM');
  }

  const { getFieldDecorator } = props.form;

  function findEmail({ email }) {
    return Bluebird.resolve()
      .then(() => runQuery({ query: email }))
      .then(res => {
        const hits = get(res, 'hits') || [];
        const matchingRecord = hits.find(record => record.email?.toLowerCase() === email?.toLowerCase() && record.active && record.organisation_license_consumed);

        if (!matchingRecord) {
          return Bluebird.reject(new Error('Email could not be found'));
        }

        return {
          userId: get(matchingRecord, 'objectID'),
          email
        };
      })
  }

  const makeProcessEmails = props => event => {
    event.preventDefault();
    const { form } = props;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }

      const emailList = get(fieldsValue, 'emailList') || [];
      setSubmitting(true);
      setItemsToProcess(emailList.map(email => ({ email })));
    });
  };

  return (
    <Form onSubmit={makeProcessEmails(props)}>
      <StyledDescription>
        Please provide emails, that are comma separated (csv)
      </StyledDescription>
      <StyledFormItem>
        {getFieldDecorator('emailList', {
          initialValue: [],
          rules: [
            {
              required: true,
              message: 'Please add emails you wish to deactivate',
            }
          ],
        })(
          <EmailList
            autoFocus
            mode="tags"
            dropdownStyle={{ display: 'none' }}
            tokenSeparators={[',', ' ']}
          />
        )}
      </StyledFormItem>
      <StyledFormItem>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          {
            submitting
            ? `Validating...`
            : 'Deactivate'
          }
        </Button>
        {
          submitting
          ? <Progress percent={percent} />
          : null
        }
      </StyledFormItem>
    </Form>
  );
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`

const StyledDescription = styled.div`
  margin: 10px 0;
`

const EmailList = styled(Select)`
  .ant-select-selection {
    border-color: ${props =>
    props.invalid ? colour.red : lighten(0.15, colour.gray400)};
    box-shadow: ${boxShadow.inset};
    min-height: 206px;
    max-height: 420px;
    overflow-y: auto;
    padding: 2px 0;
  }

  .ant-select-selection__choice {
    color: ${colour.gray900};
    background-color: ${colour.gray100};
    font-weight: 500;
    font-size: 13px;
    border-radius: 3px;
  }

  .ant-select-selection__rendered > ul > li {
    margin: 5px 6px 0 0;
  }

  .ant-form-item-control {
    line-height: 1;
  }
`;

const BulkRemoveValidateEmailForm = Form.create()(BulkRemoveValidateEmail);

export { BulkRemoveValidateEmailForm };
