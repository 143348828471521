import { rgba } from 'polished';
import styled from 'styled-components';
import { colour } from '/styles/variables';

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 12px 24px 0 ${rgba(colour.blueDark, 0.06)};
`;

export { Card };
