import React from 'react';
import { useState } from 'react';
import get from 'lodash.get';
import { Spinner, PaginatedTable } from '@a-cloud-guru/kermit';
import { useAllOrganisationLearningPathsQuery } from '/data/query-hooks';
import { LearningPathInfo } from './LearningPathInfo';
import { LearningPathStudentCount } from './LearningPathStudentCount';
import { LearningPathStudentTable } from './LearningPathStudentTable';

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name, props) => <LearningPathInfo {...props} />
  },
  {
    title: 'Students',
    render: ({ id }) => (
      <LearningPathStudentCount learningPathId={id} />
    )
  }
];


const LearningPathsTable = ({ organisationId }) => {
  const { data, loading } = useAllOrganisationLearningPathsQuery(organisationId);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginatedTable
      rowKey={({ id }) => id}
      loading={{
        indicator: <Spinner size="3rem" />,
        spinning: loading
      }}
      dataSource={data}
      columns={COLUMNS}
      pagination={{
        current: currentPage,
        pageSize: 10,
        total: get(data, 'length') || 0,
        onChange: (page) => setCurrentPage(page)
      }}
      expandedRowRender={({ id }) => (
        <LearningPathStudentTable learningPathId={id} />
      )}
    />
  );
};

export { LearningPathsTable };
