import { colour } from '@a-cloud-guru/themes';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-modal-body {
    padding: 0;
  }

  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item:hover {
    background-color: ${colour.grey25};
  }

  .read-only {
    pointer-events: none;
    opacity: 0.4;
  }

  h1 {
    margin: 0
  }

  .ant-btn {
    min-width: 100px;
  }

  .ant-menu-item:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${colour.grey25};
  }
`;

export { GlobalStyle };
