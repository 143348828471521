import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query, QueryBoundary } from '/components/data';

const ACG_ORGANISATION_COURSE_ENGAGEMENT_SUMMARY_QUERY = (attributes) => gql`
  query ACG_organisationCourseEngagementSummaryReport($organisationId: String!, $filters: OrganisationCourseEngagementSummaryFilters!) {
    ACG_organisationCourseEngagementSummaryReport(organisationId: $organisationId, filters: $filters) ${attributes}
  }
`;

const AcgOrganisationCourseEngagementSummary = ({
  from,
  to,
  courseId,
  groupId,
  attributes = `{
    activeUsersCount
    completedComponentsCount
    completedComponentsPerActiveUser
    totalContentDurationMinutes
  }`,
  organisationId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
  <Query
    query={ACG_ORGANISATION_COURSE_ENGAGEMENT_SUMMARY_QUERY(attributes)}
    variables={{
      organisationId,
      filters: {
        courseId,
        groupId,
        dateRange: {
          from,
          to,
        },
      },
    }}
    {...restProps}
  >
    {(props) => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data, ...rest }) => {
          return children({
            summary: get(data, 'ACG_organisationCourseEngagementSummaryReport'),
            ...rest,
          });
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { AcgOrganisationCourseEngagementSummary };
