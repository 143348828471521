import React from 'react';
import { keyBy } from 'lodash';
import styled from 'styled-components';
import { colour } from '/styles/variables';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';
import { useAlgoliaGetObjects } from 'react-algolia';
import { getIndexNameFromType } from '/lib/algolia';
import { CourseStatItem } from './CourseStatItem';

const mergeData = (courses, popularCourses) => {
  const courseMap = keyBy(courses, 'objectID');

  return popularCourses.map(({ courseId, numberOfUsers }) => ({
    courseId,
    numberOfUsers,
    ...courseMap[courseId],
  }));
};

const PopularCourseList = ({ popularCourses, loading, organisationId }) => {
  const courseIds = popularCourses.map(({ courseId }) => courseId);
  const { objects, loading: algoliaLoading } = useAlgoliaGetObjects({
    indexName: getIndexNameFromType('course'),
    objectIds: courseIds,
  });

  const isLoading = loading || algoliaLoading;
  const waitingMerge = popularCourses.length > 0 && objects.length === 0;
  if (isLoading || waitingMerge) {
    return (
      <Container>
        <Spinner size="3rem" />
      </Container>
    );
  }

  if (courseIds.length === 0) {
    return (
      <Description>Courses your team are taking will appear here</Description>
    );
  }

  const data = mergeData(objects, popularCourses);
  return data.map(({ courseId, title, artworkUrl, numberOfUsers }) => (
    <CourseStatItem
      key={courseId}
      courseId={courseId}
      title={title}
      artworkUrl={artworkUrl}
      numberOfUsers={numberOfUsers}
      organisationId={organisationId}
    />
  ));
};

const Description = styled.div`
  color: ${colour.gray500};
  font-size: 13px;
  padding: 16px;
  text-align: center;
`;

export { PopularCourseList };
