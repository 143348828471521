import { Component } from 'react';
import algoliasearch from 'algoliasearch';
import debounce from 'lodash.debounce';

class AlgoliaSearch extends Component {
  static defaultProps = {
    appId: '',
    apiKey: '',
    indexName: '',
    page: 1,
    hitsPerPage: 10,
    delay: 800
  };

  state = {
    searchResults: null,
    loading: false,
    error: null
  };

  constructor(props) {
    super(props);

    const client = algoliasearch(props.appId, props.apiKey);
    this.client = client;
    this.index = client.initIndex(props.indexName);
    this.indexMap = {
      [props.indexName]: this.index
    };

    const boundedClearCache = () => {
      console.log('Clearing cache...')
      this.index.clearCache()
    };

    const boundedSearch = this.search.bind(this);
    this.debouncedSearch = debounce(boundedSearch, this.props.delay);
    this.debouncedClearCache = debounce(boundedClearCache, 5000);
  };

  componentDidMount() {
    return this.search(this.props);
  }

  componentDidUpdate(prevProps) {
    const { query, page, filters, indexName } = this.props;

    const hasPageChanged = prevProps.page !== page;
    const hasQueryChanged = prevProps.query !== query;
    const haveFiltersChanged = prevProps.filters !== filters;
    const hasIndexNameChanged = prevProps.indexName !== indexName;

    if (hasIndexNameChanged) {
      if (!this.indexMap[indexName]) {
        this.indexMap[indexName] = this.client.initIndex(indexName);
      }
      this.index = this.indexMap[indexName];
    }
    if (hasPageChanged || haveFiltersChanged || hasIndexNameChanged) {
      return this.search(this.props);
    }

    if (hasQueryChanged) {
      return this.debouncedSearch(this.props);
    }
  }

  search = ({ query, page, filters, hitsPerPage }) => {
    this.setState({ loading: true });

    return this.index.search({
      query: query || '',
      filters: filters || '',
      page: page - 1, // page in algolia starts from 0
      hitsPerPage
    })
    .then(searchResults => this.setState({
      loading: false,
      searchResults
    }))
    .catch(err => this.setState({
      loading: false,
      error: err,
      searchResults: null
    }))
  }

  render() {
    const { loading, error, searchResults } = this.state;
    return this.props.children({
      loading,
      error,
      searchResults,
      search: (options = {}) => this.search(options),
      index: this.index,
      clearCache: () => this.debouncedClearCache()
    });
  }
}

export { AlgoliaSearch };