import React from 'react';
import { useState, useEffect } from 'react';
import { SplitIOProvider } from 'splitio-react';
import { authService } from '/services/auth';
import { getConfig } from '/config/get-config';
import { SPLIT_NAMES } from './split-names';

const { SPLIT_IO_API_KEY } = getConfig();

const SplitIO = ({ children }) => {
  const [userId, setUserId] = useState();
  const [organisationId, setOrganisationId] = useState();
  useEffect(() => {
    const getUserId = async () => {
      const [userIdFromToken, organisationIdFromToken] = await Promise.all([
        authService.getUserIdFromToken(),
        authService.getOrganisationIdFromToken()
      ]);

      setUserId(userIdFromToken);
      setOrganisationId(organisationIdFromToken);
    };

    getUserId();
  }, [userId, organisationId]);

  return (
    <SplitIOProvider
      splitKey={userId}
      authorizationKey={SPLIT_IO_API_KEY}
      attributes={{
        userId,
        organisationId
      }}
      splits={SPLIT_NAMES}
    >
      {children}
    </SplitIOProvider>
  )
};

export { SplitIO };