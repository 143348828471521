import { Button } from 'antd';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colour } from '../styles/variables';

const FilterButton = styled(Button)`
  width: ${props => props.width || 'inherit'};
  ${props => props.selected && css`
    background-color: ${colour.blue};
    color: #fff;
    border-color: ${colour.blue};

    &:hover {
      color: #fff;
      background-color: ${colour.blue};
    }

    &:focus {
      background-color: ${colour.blue};
      color: #fff;
      border-color: ${colour.blue};
    }
  `}

  ${props => !props.selected && css`
    background-color: transparent;
    color: ${colour.gray600};
    border-color: ${colour.gray600};

    &:hover {
      color: ${darken(0.50, colour.gray600)};
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
      color: ${darken(0.50, colour.gray600)};
      border-color: ${colour.gray600};
    }
  `}
`;

export default FilterButton;