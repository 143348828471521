import { colour } from '@a-cloud-guru/themes';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from './PrimaryButton';

const AddButton = (props) => (
  <CTAButton
    {...props}
  >
    <AddIcon>+</AddIcon>
    {props.children}
  </CTAButton>
)

const CTAButton = styled(PrimaryButton)`
  padding: 6px 22px 6px 18px;
  background-color: ${colour.brandNavy};

  &:hover {
    background-color: ${darken(0.05, colour.brandNavy)};
    cursor: pointer;
  }

  &:focus {
    background-color: ${colour.brandNavy};
  }
`;

const AddIcon = styled.span`
  position: relative;
  top: -1px;
  font-weight: 800;
  padding: 0 6px 0 0;
`;

export { AddButton };
