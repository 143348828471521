import { useContext } from 'react';
import { UserDataContext } from '../lib/user-profile';

export const useUserData = () => {
  const { loading, error, data } = useContext(UserDataContext);

  return {
    userData: data,
    userDataLoading: loading,
    userDataError: error
  };
};
