import get from 'lodash.get';
import { isBefore, format, subMonths } from 'date-fns';
import { groupByTime, getFirstDays } from '/lib/datetime/group-by-time';

const makeTransformer = (validOnly) => {
  const filterExpiredIfRequired = (data) => {
    return !validOnly || !data.expired;
  }

  const sortByAcquiredDate = (a, b) => {
    return isBefore(a.acquiredDate, b.acquiredDate) ? -1 : 1;
  };

  const getChartStartDate = (sortedData, from) => {
    const earliestAcquiredDate = new Date(sortedData[0].acquiredDate).valueOf();

    return new Date(Math.max(from, earliestAcquiredDate));
  };

  const addPreviousMonthIfRequired = (dataSource) => {
    if (dataSource.length < 12 && dataSource[0].totalCerts > 0) {
      const firstRecord = dataSource[0];

      return [
        {
          startDate: subMonths(firstRecord.startDate, 1),
          totalCerts: 0,
          data: []
        },
        ...dataSource
      ]
    }

    return dataSource;
  };

  const supplementPeriodsWithoutData = (periods, periodsHavingData, groupedData) => {
    let totalCerts = 0;
    let allCerts = [];
    const result = periods.reduce((current, next) => {
      const dataOfCurrentGroup = groupedData[next] || [];
      const startDate = new Date(next);

      totalCerts += get(dataOfCurrentGroup, 'length') || 0;
      allCerts.push(...dataOfCurrentGroup);

      if (!periodsHavingData.includes(next)) {
        return [...current, {
          startDate,
          totalCerts,
          data: [...allCerts]
        }];
      }

      return [...current, {
        startDate,
        totalCerts,
        data: [...allCerts]
      }];
    }, []);

    return addPreviousMonthIfRequired(result);
  }

  const transform = (data, from) => {
    if (data.length === 0) {
      return [];
    }

    const filteredData = data.filter(filterExpiredIfRequired);
    const sortedData = filteredData.filter(filterExpiredIfRequired).concat().sort(sortByAcquiredDate);
    const chartStartDate = getChartStartDate(sortedData, from);

    const groupedData = groupByTime(sortedData, 'acquiredDate', 'month');
    const periods = getFirstDays(Object.keys(groupedData), 'month', chartStartDate);
    const periodsHavingData = Object.keys(groupedData).map(key => parseInt(key));

    return supplementPeriodsWithoutData(periods, periodsHavingData, groupedData)
      .map((record) => {
        return {
          ...record,
          startDate: format(record.startDate, 'YYYY-MMM')
        }
      });
  };

  const addHistoricalData = (data, historicalData) => {
    const filteredHistoricalData = historicalData.filter(filterExpiredIfRequired);

    if (data.length === 0) {
      return [{
        totalCerts: filteredHistoricalData.length,
        data: filteredHistoricalData
      }]
    }
    return data.map(record => ({
      ...record,
      totalCerts: record.totalCerts + filteredHistoricalData.length,
      data: record.data.concat(filteredHistoricalData)
    }))
  }
  return { transform, addHistoricalData };
}

export { makeTransformer };