import React from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Icon } from 'antd';
import styled from 'styled-components';
import { Flex, Box } from '/components/box';

const getIndicatorClassName = (value) => {
  if (value > 0) return 'up';
  if (value < 0) return 'down';

  return 'no-change';
};

const capValue = (value, valueCap) =>
  value >= valueCap ? `>${valueCap}%` : `${value}%`;

const PercentageDifferenceIndicator = ({
  value = '',
  loading = false,
  valueCap = 500,
}) => {
  if (loading) {
    return (
      <StyledCaretIconContainer>
        <Flex justifyContent="space-between" alignItems="center" px="8px">
          <Flex flexDirection="column" mr="4px">
            <>
              <StyledCaretIcon type="caret-up" />
              <StyledCaretIcon type="caret-down" />
            </>
          </Flex>
        </Flex>
      </StyledCaretIconContainer>
    );
  }

  return (
    <StyledCaretIconContainer className={getIndicatorClassName(value)}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="20px"
        px="8px"
      >
        <Flex justifyContent="center" flexDirection="column" pb="2px" mr="4px">
          <StyledCaretIcon
            type="caret-up"
            className={getIndicatorClassName(value)}
          />
          <StyledCaretIcon
            type="caret-down"
            className={getIndicatorClassName(value)}
          />
        </Flex>
        <Flex width="100%" alignItems="center">
          <ValueContainer>
            {value !== 0
              ? `${capValue(Math.ceil(Math.abs(value)), valueCap)}`
              : '—'}
          </ValueContainer>
        </Flex>
      </Flex>
    </StyledCaretIconContainer>
  );
};

const StyledCaretIconContainer = styled.div`
  border-radius: 15px;
  width: 72px;
  height: 24px;
  background-color: ${colours.lightGrey300};
  border: 1px solid ${colours.lightGrey500};

  &.up {
    background-color: ${colours.green100};
    border: 1px solid ${colours.green200};
  }

  &.down {
    background-color: ${colours.red100};
    border: 1px solid ${colours.red200};
  }
`;

const StyledCaretIcon = styled(Icon)`
  font-size: 12px;
  color: #ffffff;
  height: 9px;

  &.anticon-caret-up.up {
    color: ${colours.green700};
  }

  &.anticon-caret-down.down {
    color: ${colours.red500};
  }

  &.anticon.no-change {
    color: ${colours.lightGrey900};
  }
`;

const ValueContainer = styled(Box)`
  font-size: 11px;
  color: ${colours.navy900};
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  width: 100%;
`;

export { PercentageDifferenceIndicator };
