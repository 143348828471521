const merge = require('lodash.merge');
const Joi = require('joi');

const { schema } = require('./schema');

// Parcel does not support dynamic requires
const devConfig = require('./dev.config');
const testConfig = require('./test.config');
const prodConfig = require('./production.config');

const configs = {
  dev: devConfig,
  test: testConfig,
  production: prodConfig
};

const getConfigForEnvironment = (environment) => {
  const currentConfig = configs[environment] || {};

  const localConfig = {
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    AUTH0_CUSTOM_DOMAIN: process.env.AUTH0_CUSTOM_DOMAIN,
    AUTH0_API_AUDIENCE: process.env.AUTH0_API_AUDIENCE,
    AUTH0_PRIMARY_CLIENT_ID: process.env.AUTH0_PRIMARY_CLIENT_ID,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    GRAPHQL_API_ENDPOINT: process.env.GRAPHQL_API_ENDPOINT,
    ACG_BERNIE_DOMAIN: process.env.ACG_BERNIE_DOMAIN,
    ACG_DOMAIN: process.env.ACG_DOMAIN,
    ACG_ORGANISATION_ID: process.env.ACG_ORGANISATION_ID,
    PS_ORGANISATION_ID: process.env.PS_ORGANISATION_ID,
    ALL_ORGANISATION_INDEX: process.env.ALL_ORGANISATION_INDEX,
    ALL_ORGANISATION_USERS_INDEX: process.env.ALL_ORGANISATION_USERS_INDEX,
    ALL_ORGANISATION_GROUPS_INDEX: process.env.ALL_ORGANISATION_GROUPS_INDEX,
    ALL_ORGANISATION_INVITATIONS_INDEX: process.env.ALL_ORGANISATION_INVITATIONS_INDEX,
    ORGANISATION_INVITATION_INDEX_PREFIX: process.env.ORGANISATION_INVITATION_INDEX_PREFIX,
    ORGANISATION_GROUP_INDEX_PREFIX: process.env.ORGANISATION_GROUP_INDEX_PREFIX,
    COURSE_INDEX_NAME: process.env.COURSE_INDEX_NAME,
    BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY,
    BUGSNAG_RELEASE_STAGE: process.env.BUGSNAG_RELEASE_STAGE,
    SPLIT_IO_API_KEY: process.env.SPLIT_IO_API_KEY,
    ALGOLIA_STAGE: process.env.ALGOLIA_STAGE,
    LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID
  };

  const config = merge(
    require('./common.config.js'),
    localConfig,
    currentConfig
  );

  const { error, value } = schema.validate(config);

  if (error) {
    throw error;
  }

  return value;
};

module.exports = { getConfigForEnvironment };
