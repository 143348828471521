import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const ACG_ORGANISATION_USER_CERTIFICATION_ACTIVITY_QUERY = (attributes) => gql`
  query ACG_organisationCertificationActivityReport (
    $organisationId: String!,
    $filters: OrganisationCertificationActivityFilters,
    $pagination: Page
  ){
    ACG_organisationCertificationActivityReport (
      organisationId: $organisationId,
      filters: $filters,
      pagination: $pagination
    ) ${attributes}
  }
`;

const getAcquiredDateFilter = ({ acquiredBefore, acquiredAfter }) => {
  return {
    from: acquiredAfter,
    to: acquiredBefore
  };
};

const OrganisationUserCertificationActivityQuery = ({
  page,
  pageSize,
  groupId,
  userIds,
  organisationId,
  certificateId,
  acquiredBefore,
  acquiredAfter,
  attributes,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={ACG_ORGANISATION_USER_CERTIFICATION_ACTIVITY_QUERY(attributes)}
      variables={{
        organisationId,
        filters: {
          certificateId,
          groupId,
          userIds,
          acquiredDateRange: getAcquiredDateFilter({ acquiredBefore, acquiredAfter })
        },
        pagination: (page && pageSize) ? {
          pageNumber: page,
          pageSize
        } : undefined
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            const activities = get(data, `ACG_organisationCertificationActivityReport.data`) || [];
            const total = get(data, `ACG_organisationCertificationActivityReport.total`) || 0;
            return children({
              data: activities,
              pagination: {
                page,
                pageSize,
                total
              },
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );
}

export { OrganisationUserCertificationActivityQuery };