import { get, isUndefined } from 'lodash';
import { calculatePercentage } from '/utils/calculatePercentage';

const generateDataSource = (engagementStats, organisation) => {
  const userLicenseCount = get(organisation, 'subscription.userLicenseCount');

  const row = (event, key) => {
    return engagementStats.reduce((row, stat) => {
      return { ...row, [stat.endDate]: stat[key] }
    }, { event });
  };

  const percentageRow = ({ event, numeratorRow, denominatorRow, denominator }) => {
    const dateKeys = Object.keys(numeratorRow).filter(key => key !== 'event');

    return dateKeys.reduce((row, date) => {
      const denominatorValue = isUndefined(denominator) ? denominatorRow[date] : denominator;
      const numeratorValue = numeratorRow[date];

      return {
        ...row,
        [date]: denominatorValue === 0 ? '-' : `${calculatePercentage(numeratorValue, denominatorValue, 2)}%`
      }
    }, { event });
  }

  const newlyJoined = row('Joined', 'newlyJoined');
  const totalJoined = row('Total Joined', 'totalJoined');
  const joinedOrganisationPercentage = percentageRow({
    event: 'License Adoption (%)',
    numeratorRow: totalJoined,
    denominator: userLicenseCount
  });

  const newlyStartedCourse = row('Newly Started Course', 'newlyStartedCourse');
  const startedCourse = row('Started Course', 'startedCourse');
  const totalStartedCourse = row('Total Started Course', 'totalStartedCourse');
  const startedCoursePercentage = percentageRow({
    event: 'Started Course (%)',
    numeratorRow: totalStartedCourse,
    denominatorRow: totalJoined
  });


  const newlyCompletedCourse = row('Newly Completed Course', 'newlyCompletedCourse');
  const completedCourse = row('Completed Course', 'completedCourse');
  const totalCompletedCourse = row('Total Completed Course', 'totalCompletedCourse');
  const completedCoursePercentage = percentageRow({
    event: 'Completed Course (%)',
    numeratorRow: totalCompletedCourse,
    denominatorRow: totalStartedCourse
  });

  const dataSource = [
    newlyJoined,
    totalJoined,
    joinedOrganisationPercentage,
    newlyStartedCourse,
    startedCourse,
    totalStartedCourse,
    startedCoursePercentage,
    newlyCompletedCourse,
    completedCourse,
    totalCompletedCourse,
    completedCoursePercentage
  ];

  return dataSource;
};

export { generateDataSource };