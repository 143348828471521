import React from 'react';
import { colour } from '@a-cloud-guru/themes';
import { Menu, Icon } from 'antd';
import styled from 'styled-components';

import { Box } from '/components/box';

const FilterMenuItem = ({
  selected,
  text,
  key,
  ...rest
}) => (
  <DropdownMenuItem key={key} {...rest}>
    {selected ? <Selected type="check" /> : <Unselected width="22px" />}
    <Box ml="4px">
      {text}
    </Box>
  </DropdownMenuItem>
);

const DropdownMenuItem = styled(Menu.Item)`
  background-color: #fff;
  padding: 8px 16px;
  display: flex;
  align-items: center;

  :hover {
    background: ${colour.grey50};
  }
`;

const Selected = styled(Icon)`
  color: ${colour.grey900};
`;

const Unselected = styled(Box)`
  display: inline-block;
`;

export { FilterMenuItem };
