import { colour } from '@a-cloud-guru/themes';
import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';

const PageStatus = ({ header, children }) => (
  <Status>
    {header && <Title>{header}</Title>}
    {children}
  </Status>
);

const Status = styled.div`
  text-align: center;

  span,
  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${rgba(0, 0, 0, 0.45)};

    &:hover {
      color: ${colour.brandNavyDarker};
    }
  }
`;

const Title = styled.div`
  font-size: 24px;
  color: ${colour.brandNavyDarker};
`;

export default PageStatus;
