import { Radio, Select } from 'antd';
import { BigNumber } from 'bignumber.js';
import get from 'lodash.get';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { distanceInWordsStrict } from 'date-fns';
import { Box, Flex } from '/components/box';
import { OrganisationsFilters } from './OrganisationsFilters';
import { getConfig } from '/config/get-config';
import { AdminLayout } from '/layouts/AdminLayout';
import { Masthead } from '/components/masthead';
import { DateFormat } from '/components/date/DateFormat';
import { SearchBox } from '/components/search/SearchBox';
import { PaginatedTable } from '/components/table/PaginatedTable';
import { AlgoliaQuery } from '/components/algolia/AlgoliaQuery';
import { useCachedState } from '/hooks/use-cached-state';
import { colour } from '/styles/variables';

import { CreateOrganisation } from './CreateOrganisation';
import { ToggleColumnsButton } from './ToggleColumnsButton';
import { PermissionButton } from './PermissionButton';

const { ALL_ORGANISATION_INDEX } = getConfig();

const TODAY = new Date();

const ALL_COLUMNS = [
  'Organization',
  'Subscription Model',
  'Start Date',
  'End Date',
  'Licenses',
  'Pending Invitations',
  'Accepted Invitations',
  'Consumed',
  'Remaining',
  'Remaining %',
  'Last Updated'
];

const getIndexName = (sorter) => {
  if (!get(sorter, 'field')) {
    return ALL_ORGANISATION_INDEX;
  }

  const order = sorter.order === 'ascend' ? 'asc' : 'desc';
  return `${ALL_ORGANISATION_INDEX}_${sorter.field}_${order}`;
}

const generateColumns = (visibleColumns = []) => {
  return [
    {
      title: 'Organization',
      dataIndex: 'name',
      width: 200
    },
    {
      title: 'Subscription Model',
      dataIndex: 'charge_model',
      width: 250
    },
    {
      title: 'Start Date',
      dataIndex: 'subscription_start_date',
      width: 250,
      sorter: true,
      render: start_date => start_date !== 0 && (
        <Flex>
          <Box>
            <DateFormat format="DD MMM YYYY">
              {start_date}
            </DateFormat>
          </Box>

          <Box ml="5px" color={colour.gray400}>
            ({distanceInWordsStrict(new Date(start_date), TODAY)} in)
          </Box>
        </Flex>
      )
    },
    {
      title: 'End Date',
      dataIndex: 'subscription_end_date',
      width: 200,
      sorter: true,
      render: end_date => (
        <DateFormat format="DD MMM YYYY">
          {end_date}
        </DateFormat>
      )
    },
    {
      title: 'Pending Invitations',
      dataIndex: 'pending_invitation_count',
      sorter: true,
      width: 200
    },
    {
      title: 'Accepted Invitations',
      dataIndex: 'accepted_invitation_count',
      sorter: true,
      width: 200
    },
    {
      title: 'Licenses',
      dataIndex: 'subscription_user_license_count',
      sorter: true,
      width: 100
    },
    {
      title: 'Consumed',
      dataIndex: 'consumed_license_count',
      width: 100,
      sorter: true
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining_license_count',
      width: 100,
      sorter: true
    },
    {
      title: 'Remaining %',
      width: 100,
      render: record => {
        const licenseCount = get(record, 'subscription_user_license_count') || 0;
        const remaining = get(record, 'remaining_license_count') || 0;
        const remainingPercent = licenseCount
          ? new BigNumber(remaining).div(licenseCount).times(100).toFixed(2)
          : 0;

        return (
          <>
            {
              licenseCount
                ? `${remainingPercent}%`
                : '-'
            }
          </>
        );
      }
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      width: 100,
      render: updated_at => (
        <DateFormat format="DD MMM YYYY">
          {updated_at}
        </DateFormat>
      )
    }
  ].filter(({ title }) => visibleColumns.includes(title));
};

const Organisations = ({ serverState }) => {
  const [page, setPage] = useState(1);
  const [sorter, setSorter] = useState();
  const [columns, setColumns] = useCachedState(ALL_COLUMNS);
  const indexName = getIndexName(sorter);
  return (
    <AdminLayout
      masthead={
        <Masthead
          title='Organizations'
          subtitle='List of all organizations'
        >
          <DisabledWhenReadOnly>
            <CreateOrganisation />
          </DisabledWhenReadOnly>

          <Box ml="8px">
            <PermissionButton onClick={() => serverState.history.push(`permission`)} />
          </Box>
        </Masthead>
      }
    >
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <OrganisationsFilters serverState={serverState}>
        {({
          query,
          filters,
          licenseStateFilter,
          accountTypeFilter,
          ACCOUNT_TYPES,
          chargeModelFilter,
          CHARGE_MODELS,
          updateUrlQuery
        }) => (
          <AlgoliaQuery
            query={query}
            page={page}
            hitsPerPage={50}
            indexName={indexName}
            filters={filters}
          >
            {({ error, searchResults }) => {
              const { total, page, pageSize } = searchResults;
              const data = get(searchResults, 'data') || [];

              const selectedAccountTypeFilter = Object.entries(accountTypeFilter).filter(([key, value]) => !!value).map(([key]) => key);

              return (
                <Box mt="-64px">
                  <PaginatedTable
                    title={() => (
                      <Box px="16px">
                        <Box>
                          <SearchBox
                            placeholder="Search organizations"
                            width="100%"
                            height="40px"
                            onChange={e => {
                              const value = get(e, 'target.value') || '';
                              updateUrlQuery({ type: 'query', value });
                              setPage(1);
                            }}
                            value={query}
                          />
                        </Box>
                        <Flex flexWrap="wrap" mt="10px">
                          <Box pr='10px'>
                            <Radio.Group
                              onChange={e => updateUrlQuery({ type: 'license', value: e.target.value })}
                              value={licenseStateFilter}
                            >
                              <Radio.Button value='ACTIVE'>Active</Radio.Button>
                              <Radio.Button value='EXPIRING'>Expiring Soon</Radio.Button>
                              <Radio.Button value='EXPIRED'>Expired</Radio.Button>
                              <Radio.Button value='ARCHIVED'>Archived</Radio.Button>
                              <Radio.Button value='ALL'>All</Radio.Button>
                            </Radio.Group>
                          </Box>
                          <Box pr='10px'>
                            <Select
                              mode="multiple"
                              style={{ textTransform: 'capitalize', minWidth: '350px' }}
                              placeholder="Subscription Model"
                              value={chargeModelFilter}
                              onChange={value => updateUrlQuery({ type: 'chargemodel', value })}
                              maxTagCount={2}
                              dropdownStyle={{ textTransform: 'capitalize' }}
                            >
                              {
                                CHARGE_MODELS.map(type => <Select.Option key={type} children={type.toLowerCase()} />)
                              }
                            </Select>
                          </Box>
                          <Box pr='10px' mr='auto'>
                            <Select
                              mode="multiple"
                              style={{ textTransform: 'capitalize', minWidth: '350px' }}
                              placeholder="Account Type"
                              value={selectedAccountTypeFilter}
                              onChange={value => updateUrlQuery({ type: 'account', value })}
                              maxTagCount={2}
                              dropdownStyle={{ textTransform: 'capitalize' }}
                            >
                              {
                                ACCOUNT_TYPES.map(type => <Select.Option key={type} children={type.toLowerCase()} />)
                              }
                            </Select>
                          </Box>
                          <Box>
                            <ToggleColumnsButton
                              columns={columns}
                              allColumns={ALL_COLUMNS}
                              setColumns={setColumns}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    )}
                    columns={generateColumns(columns)}
                    dataSource={error ? [] : data}
                    rowKey={({ objectID }) => objectID}
                    locale={{
                      emptyText: error
                        ? 'Something went wrong while trying to find your results'
                        : 'No results'
                    }}
                    onRow={(record) => ({
                      onClick: () => serverState.history.push(`organizations/${record.objectID}/details`)
                    })}
                    pagination={error ? null : {
                      current: page,
                      pageSize,
                      total,
                      onChange: current => {
                        setPage(current);
                        window.scrollTo(0, 0);
                      }
                    }}
                    onChange={(pagination, filters, sorter) => setSorter(sorter)}
                  />
                </Box>
              );
            }}
          </AlgoliaQuery>
        )}
      </OrganisationsFilters>
    </AdminLayout>
  );
}

export {
  Organisations
};
