import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';

const GET_USER_DATA = gql`
  query GetUserProfile {
    profile {
      id
      isMember
    }
    userIdentity {
      id
      pluralsightId
      pluralsightHandle
      organisation {
        id
      }
    }
    myProfile {
      id
      handle
      name
      picture
      email
      displayname
    }
    Packaging_userFeatureAccess {
      userFeatureAccess {
        packageId
        packageName
        features
      }
    }
  }
`;

export const UserDataContext = React.createContext();

export const UserDataProvider = ({ children }) => {

  const formatUserData = (data) => ({
    pluralsightHandle: get(data, 'userIdentity.pluralsightHandle'),
    pluralsightId: get(data, 'userIdentity.pluralsightId'),
    organisationId: get(data, 'userIdentity.organisation.id'),
    isMember: get(data, 'profile.isMember', null),
    packageId: get(data, 'Packaging_userFeatureAccess.userFeatureAccess.packageId'),
    packageName: get(data, 'Packaging_userFeatureAccess.userFeatureAccess.packageName'),
    originatesFrom: get(data, 'userIdentity.originatesFrom'),
    acgUserId: get(data, 'profile.id')
  });

  const { data, loading, error } = useQuery(GET_USER_DATA);

  return (
    <UserDataContext.Provider value={{ data: formatUserData(data), loading, error }}>
      {children}
    </UserDataContext.Provider>
  );
};
