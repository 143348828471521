import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '@a-cloud-guru/rainbow-ui';

import { StyledModal, FormContent, Title } from '/components/modal';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { colour } from '/styles/variables';

import { UpdateSamlConnectionDetailsForm } from './UpdateSamlConnectionDetailsForm';
import { isReadOnly } from '../../../../hooks/isReadOnly';

const UpdateSamlConnectionDetailsAction = ({ organisation }) => {
  const [visible, setVisible] = useState(false);
  const readOnly = isReadOnly();

  return (
    <Box>
      <PrimaryButton onClick={() => setVisible(!readOnly)}>
        Update
      </PrimaryButton>
      <StyledModal
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
        style={{ maxWidth: '600px' }}
      >
        <FormContent>
          <TitleContainer>
            <Title>Update SAML Connection Details</Title>
          </TitleContainer>
          <UpdateSamlConnectionDetailsForm
            closeModal={() => setVisible(false)}
            {...organisation}
          />
        </FormContent>
      </StyledModal>
    </Box>
  );
};

const TitleContainer = styled.div`
  padding: 18px 20px;
  border-bottom: solid 1px ${colour.gray300};
`;

export { UpdateSamlConnectionDetailsAction };
