import get from 'lodash.get';
import React from 'react';
import styled from 'styled-components';
import { Box } from '/components/box';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { DateFormat } from '/components/date/DateFormat';
import { StyledCard, StyledDivider, DetailsRow, Header } from './Shared';
import { UpdateSubscription } from './update-subscription/UpdateSubscription';
import { SelfServiceSubscriptionDetails } from './SelfServiceSubscriptionDetails';
import { SelfServiceSubscriptionSync } from './SelfServiceSubscriptionSync';
import { TransferSubscriptionMenu } from './transfer-subscription/TransferSubscriptionMenu';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';

const SubscriptionCard = (organisation) => {
  const { chargeModel, loading: orgLoading } = organisation;

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Subscription Details</StyledTitle>
        <DisabledWhenReadOnly>
          {chargeModel === 'selfservice' && (
            <SelfServiceSubscriptionSync
              organisation={organisation}
              update={() => {
                window.location.reload();
              }}
            />
          )}
          <UpdateSubscription organisation={organisation} />
        </DisabledWhenReadOnly>
        <DisabledWhenReadOnly>
          <TransferSubscriptionMenu organisation={organisation} />
        </DisabledWhenReadOnly>
      </StyledHeader>
      <StyledDivider />
      <Box py="6px">
        { !orgLoading && <DetailsRow label="Model">{chargeModel}</DetailsRow> }
        <SubscriptionDetails {...organisation} />
      </Box>
    </StyledCard>
  );
};

const SubscriptionDetails = (organisation) => {
  const chargeModel = get(organisation, 'chargeModel');
  const orgLoading = get(organisation, 'loading');

  if (orgLoading) {
    return <Container><Spinner /></Container>;
  }

  if (chargeModel === 'marketplace') {
    return <MarketplaceSubscriptionDetails {...organisation} />;
  }

  if (chargeModel === 'subscription') {
    return <StandardSubscriptionDetails {...organisation} />;
  }

  if (chargeModel === 'selfservice') {
    return <SelfServiceSubscriptionDetails {...organisation} />;
  }

  return null;
};

const StandardSubscriptionDetails = (organisation) => {
  const organisationId = get(organisation, 'id');
  const endDateOverride =
    get(organisation, 'subscription.endDateOverride') || '';
  const licenseCount = get(organisation, 'subscription.userLicenseCount') || 0;
  const consumedLicenseCount = get(organisation, 'consumedLicenses') || 0;
  const deactivatedLicenses = get(organisation, 'deactivatedLicenses') || 0;
  return (
    <>
      <DetailsRow label="Contract Start Date">
        <DateFormat>
          {get(organisation, 'subscription.startDate') || '-'}
        </DateFormat>
      </DetailsRow>
      <DetailsRow label="Contract End Date">
        <DateFormat>
          {get(organisation, 'subscription.endDate') || '-'}
        </DateFormat>
      </DetailsRow>
      <DetailsRow label="Extended End Date">
        {endDateOverride ? <DateFormat>{endDateOverride}</DateFormat> : '-'}
      </DetailsRow>
      <DetailsRow label="Extended End Date Reason">
        {get(organisation, 'subscription.endDateOverrideReason') || '-'}
      </DetailsRow>
      <DetailsRow label="License Count">{`${licenseCount} users`}</DetailsRow>
      <DetailsRow label="Consumed License Count">
        {`${consumedLicenseCount} users`}
      </DetailsRow>
      <DetailsRow label="Deactivations">{`${deactivatedLicenses}`}</DetailsRow>
    </>
  );
};

const MarketplaceSubscriptionDetails = (organisation) => {
  const endDateOverride =
    get(organisation, 'subscription.endDateOverride') || '';
  const licenseCount = get(organisation, 'subscription.userLicenseCount') || 0;
  const consumedLicenseCount = get(organisation, 'consumedLicenses') || 0;
  const deactivatedLicenses = get(organisation, 'deactivatedLicenses') || 0;
  const provider = (get(organisation, 'entitlement.marketplaceProvider') || 'AWS').toUpperCase();
  return (
    <>
      <DetailsRow label="Contract End Date">
        {get(organisation, 'subscription.endDate') ? (
          <DateFormat>{get(organisation, 'subscription.endDate')}</DateFormat>
        ) : (
          '-'
        )}
      </DetailsRow>
      <DetailsRow label="Extended End Date">
        {endDateOverride ? <DateFormat>{endDateOverride}</DateFormat> : '-'}
      </DetailsRow>
      <DetailsRow label="Extended End Date Reason">
        {get(organisation, 'subscription.endDateOverrideReason') || '-'}
      </DetailsRow>
      <DetailsRow label="License Count">{`${licenseCount} users`}</DetailsRow>
      <DetailsRow label="Consumed License Count">
        {`${consumedLicenseCount} users`}
      </DetailsRow>
      <DetailsRow label="Deactivations">{`${deactivatedLicenses}`}</DetailsRow>
      <DetailsRow label={`${provider} CustomerIdentifier`}>
        {get(organisation, 'entitlement.customerIdentifier') || '-'}
      </DetailsRow>
      <DetailsRow label={`${provider} ProductCode`}>
        {get(organisation, 'entitlement.productCode') || '-'}
      </DetailsRow>
    </>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

export { SubscriptionCard };
