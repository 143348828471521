import '@babel/polyfill';
require ('/index.less');
require('/fonts.css');
import { BugsnagErrorBoundary } from '/lib/bugsnag';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { GlobalStyle } from '/styles/global';
import { getApolloClient } from '/lib/make-apollo-client';
import App from '/App';

const client = getApolloClient();

ReactDOM.render(
  <BugsnagErrorBoundary>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    <GlobalStyle />
  </BugsnagErrorBoundary>,
  document.getElementById('root')
);
