import { get } from 'lodash';

const sortNumbersByAttribute = attribute => (a, b) => {
  return (get(a, attribute) || 0) - (get(b, attribute) || 0);
}

const sortAlphaNumericByAttribute = attribute => (a, b) => {
  return (get(a, attribute) || '').localeCompare(get(b, attribute) || '');
}

const sortDateTimeAttribute = attribute => (a, b) => {
  return new Date(get(a, attribute)).valueOf() - new Date(get(b, attribute)).valueOf();
}

export { sortNumbersByAttribute, sortAlphaNumericByAttribute, sortDateTimeAttribute };
