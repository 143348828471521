import { Button } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components';

import { colour } from '/styles/variables';

const PrimaryButton = styled(Button)`
  width: ${props => props.width || 'inherit'};
  height: ${props => props.height || 'inherit'};
  padding: 6px 18px;
  border: 0;
  color: ${colour.gray100};
  background-color: ${colour.blueLight};

  &:hover {
    color: ${colour.gray100};
    background-color: ${darken(0.05, colour.blueLight)};
  }

  &:focus {
    color: ${colour.gray100};
    background-color: ${colour.blueLight};
  }
`;

export { PrimaryButton };
