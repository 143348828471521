import React from 'react';
import { StyledModal, Title, Subtitle, Content } from '/components/modal';

const RevokeModal = props => (
  <StyledModal
    closable={false}
    confirmLoading={props.loading}
    maskClosable={!props.loading}
    onOk={async () => {
      await props.mutationCallback();
      setTimeout(props.onClose, 300);
    }}
    onCancel={() => {
      props.onCancel();
    }}
    okText='Revoke'
    visible={props.visible}
    width="600px"
  >
    <Content>
      <Title>
        Are you sure you wish to revoke this invitation for '{props.email}'?
      </Title>
      <Subtitle>
        This user will no longer be able to join your organisation.
      </Subtitle>
    </Content>
  </StyledModal>
);

export { RevokeModal };
