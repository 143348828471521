import React from 'react';
import { Box } from '/components/box';
import { Empty } from 'antd';

const RecentEngagementChartNoDataState = () => (
  <Box py="30px">
    <Empty />
  </Box>
);

export { RecentEngagementChartNoDataState };
