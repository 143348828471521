import get from 'lodash.get';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { isReadOnly } from '../../../hooks/isReadOnly';

// SelfServiceSubscriptionSyncMutation

const SELF_SERVICE_SUBSCRIPTION_SYNC_MUTATION = gql`
  mutation syncBillingSubscription($input: ACG_syncBillingSubscriptionInput!) {
    ACG_syncBillingSubscription(input: $input) {
      subscription {
        id
      }
    }
  }
`;

const SelfServiceSubscriptionSync = ({ organisation, update }) => {
  const organisationId = get(organisation, 'id');
  const readOnly = isReadOnly();

  return (
    <Mutation mutation={SELF_SERVICE_SUBSCRIPTION_SYNC_MUTATION}>
      {(syncMutation, { loading, error }) => {
        return (
          <Tooltip title="Sync subscription details from Chargebee">
            <StyledPrimaryButton
              disabled={loading || error}
              onClick={() => {
                if (readOnly) return;
                syncMutation({
                  variables: {
                    input: {
                      organisationId,
                    },
                  },
                  update: update,
                });
              }}
            >
              Sync
            </StyledPrimaryButton>
          </Tooltip>
        );
      }}
    </Mutation>
  );
};

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 10px;
`;

export { SelfServiceSubscriptionSync };
