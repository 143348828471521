import { Divider } from 'antd';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components'

import Flex from '../flex';
import { colour } from '../styles/variables';

const ModuleHeader = ({ header, subheader = '', hideDivider = false, children }) => (
  <>
    <Flex flex="auto" justifyContent="space-between" alignItems="center" p="16px 20px">
      <div>
        <Header>{header}</Header>
        <Subheader>{subheader}</Subheader>
      </div>
      {children}
    </Flex>
    {!hideDivider && <StyledDivider />}
  </>
);

const Header = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: ${colour.gray900};
  text-transform: uppercase;
`;

const Subheader = styled.div`
  font-size: 13px;
  padding: 2px 0;
  line-height: 1.4;
  color: ${lighten(0.1, colour.gray600)};
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

export default ModuleHeader;
