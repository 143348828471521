import gql from 'graphql-tag';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const GET_MARKETPLACE_EVENT_HISTORY = gql`
  query ACG_marketplaceEventHistory($CustomerIdentifier: String!) {
    ACG_marketplaceEventHistory(CustomerIdentifier: $CustomerIdentifier) {
      id
      entitlementValue
      expirationDate
      timestamp
      productCode
      productTitle
      customerIdentifier
      acgPackageId
      marketplaceProvider
      seats
    }
  }
`;

const MarketplaceEventHistoryQuery = ({
  marketplaceCustomerId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={GET_MARKETPLACE_EVENT_HISTORY}
      fetchPolicy={'network-only'}
      variables={{
        CustomerIdentifier: marketplaceCustomerId,
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: get(data, 'ACG_marketplaceEventHistory') || [],
              ...rest,
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );
};

export { MarketplaceEventHistoryQuery, GET_MARKETPLACE_EVENT_HISTORY };
