import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { GET_ACG_SUBSCRIBER_PACKAGE } from '../query/PackageQuery';

const ALL_LEARNING_PATHS_QUERY = gql`
  query ACG_learningPathsForOrganisation($organisationId: ID!, $includeCustomLearningPath: Boolean) {
    ACG_learningPathsForOrganisation(organisationId: $organisationId, includeCustomLearningPath: $includeCustomLearningPath) {
      id
      name
      tileUrl
      description
      shortDescription
      type
    }
  }
`;

const LEARNING_PATH_DESCRIPTION_OVERWRITES = {
  'aws-developer': 'All things development including managed services, traditional monolithic stacks and serverless development',
  'aws-architect': 'Deep dive into the whole AWS ecosystem while covering foundational services',
  'aws-data': 'Covering all things data from databases and data lakes to tooling, migration and data best practices',
  'aws-devops': 'Reacting faster and taking advantage of automation and the power of Infrastructure as Code',
  'aws-executive': 'Understanding the challenges businesses will face in adopting the cloud',
  'aws-security': 'Focusing on secure protocols, data encryption, specialized data classifications and best practices'
};

const mapResponseToLearningPaths = (data) => {
  return get(data, 'ACG_learningPathsForOrganisation', [])
    .map(({ id, shortDescription, description, type, ...learningPath }) => ({
      id,
      shortDescription: type === 'CUSTOM_LEARNING_PATH' ? description : get(LEARNING_PATH_DESCRIPTION_OVERWRITES, id) || shortDescription,
      ...learningPath
    }));
};

const useAllOrganisationLearningPathsQuery = (organisationId) => {
  const { data: packageData, loading: packageLoading } = useQuery(GET_ACG_SUBSCRIBER_PACKAGE, {
    variables: {
      input: {
        organisationId
      }
    }
  });
  const features = get(packageData, 'ACG_Packaging_subscriberPackage.subscriberPackage.features', '[]');
  const featuresParsed = JSON.parse(features);
  const includeCustomLearningPath = get(featuresParsed, 'custom-learning-paths.accessLevel', '') === 'ENABLED';
  const [fetch, { data: learningPathsData, loading: learningPathsLoading, error, refetch }] = useLazyQuery(ALL_LEARNING_PATHS_QUERY);

  useEffect(() => {
    if (packageLoading) {
      // Resolve whether organisation has Custom Learning Paths package
      return;
    }

    fetch({
      variables: {
        organisationId,
        includeCustomLearningPath
      }
    });

  }, [fetch, packageLoading, includeCustomLearningPath]);

  const loading = packageLoading || learningPathsLoading;

  return {
    data: mapResponseToLearningPaths(learningPathsData),
    loading: !error && (loading || learningPathsData === undefined),
    error,
    refetch
  };
};

export { useAllOrganisationLearningPathsQuery, ALL_LEARNING_PATHS_QUERY };
