module.exports = {
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  AUTH0_PRIMARY_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  ALL_ORGANISATION_INDEX: 'private_test_all_organisations',
  ALL_ORGANISATION_USERS_INDEX: 'private_test_all_organisation_users',
  ALL_ORGANISATION_GROUPS_INDEX: 'private_test_all_organisation_groups',
  ALL_ORGANISATION_INVITATIONS_INDEX: 'private_test_all_organisation_invitations',
  ORGANISATION_INVITATION_INDEX_PREFIX: 'private_test_organisation_invitation_',
  ORGANISATION_GROUP_INDEX_PREFIX: 'private_test_organisation_group_',
  COURSE_INDEX_NAME: 'test_courses',
  ACG_BERNIE_DOMAIN: 'https://staging-bernie.acloud.guru',
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY,
  BUGSNAG_RELEASE_STAGE: 'staging',
  ACG_ORGANISATION_ID: '6b0fdb94-bb69-f073-6623-312cf71ac88e',
  PS_ORGANISATION_ID: '840f1e01-c31a-4b5a-9f15-f8a92d417852',
  ALGOLIA_STAGE: 'test',
  LAUNCH_DARKLY_CLIENT_SIDE_ID: '5a7c7b5afd95332db01686a0'
};
