import get from 'lodash.get';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const currentDate = new Date().valueOf();

const FILTERS = {
  all: 'valid_until > 0',
  joined: 'accepted_at > 0',
  pending: `valid_until > ${currentDate} AND accepted_at = 0`,
  expired: `valid_until < ${currentDate} AND accepted_at = 0`
};

const useOrganisationInvitationQuery = ({
  query,
  page,
  pageSize = 10,
  status,
  key
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'invitation',
    query,
    page,
    hitsPerPage: pageSize,
    filters: FILTERS[status],
    key
  });
  return {
    data: get(searchResults, 'hits') || [],
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page,
      pageSize
    },
    ...restProps
  }
};

export { useOrganisationInvitationQuery };