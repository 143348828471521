import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { colour } from '/styles/variables';

const DropdownMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ type }) => type === 'input' && css`
    background-color: #fff;
    padding: 0px;
    height: 36px;

    :hover {
      background-color: #fff;
    }
  `}

  ${({ type }) => type !== 'input' && css`
    background-color: ${props => props.selected ? colour.gray100 : '#fff'};
    cursor: pointer;

    :hover {
      color: ${colour.gray900};
      background-color: ${colour.hover};
    }
  `};
`;

export { DropdownMenuItem };

