import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Tooltip, Row, Col, message } from 'antd';
import { lighten } from 'polished';
import { Select, Button } from 'antd';

import {
  StyledModal,
  FormContent,
  Title,
  TitleContainer,
} from '/components/modal';
import { LinkButton } from '/components/button/LinkButton';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { Flex } from '/components/box/Flex';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { Card } from '/components/card/Card';
import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';

import { colour } from '/styles/variables';
import {
  GET_ACG_SUBSCRIBER_PACKAGE,
  GET_PACKAGES_FEATURES,
  GET_ALL_FEATURES,
  MUTATE_ORG_PACKAGE,
  MUTATE_ORG_FEATURE_OVERRIDES,
} from '../../../../data/query/PackageQuery';
import { useQuery, useMutation } from 'react-apollo';
import { get, omit } from 'lodash';
import { useEffect } from 'react';
import packageDescriptions from './packageDescriptions.json';
import { isReadOnly } from '../../../../hooks/isReadOnly';

const { Option } = Select;

export const UpdatePackageModal = ({
  organisationId,
  userId,
  chargeModel,
  refetch,
}) => {
  const [visible, setVisible] = useState(false);
  const [overrides, setOverrides] = useState([]);
  const [confirmSave, setConfirmSave] = useState(false);
  const [featureNames, setFeatureNames] = useState('');
  const [selectedFeatureId, setSelectedFeatureId] = useState('');
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [organizationOverrides, setOrganizationOverrides] = useState([
    { featureId: '', value: '', id: 1 },
  ]);
  const readOnly = isReadOnly();
  const isSubscriptionOrg = chargeModel === 'subscription';

  const entryToObject = (entry) => ({ id: entry[0], ...entry[1] });

  const allPackagesFeatures = useQuery(GET_PACKAGES_FEATURES);
  const getAllFeatures = useQuery(GET_ALL_FEATURES);

  const subscriberQuery = useQuery(GET_ACG_SUBSCRIBER_PACKAGE, {
    variables: {
      input: {
        organisationId: organisationId,
        userId: userId,
      },
    },
  });

  const [mutateOrgPackages, { orgPackages }] = useMutation(MUTATE_ORG_PACKAGE);

  const [mutateOrgPackageFeatures, { featureOverrides }] = useMutation(
    MUTATE_ORG_FEATURE_OVERRIDES
  );

  const overridesString = get(
    subscriberQuery,
    'data.ACG_Packaging_subscriberPackage.subscriberPackage.overrides'
  );

  const featuresString = get(
    subscriberQuery,
    'data.ACG_Packaging_subscriberPackage.subscriberPackage.features'
  );

  const packagesFeatures = get(
    allPackagesFeatures,
    'data.ACG_Packaging_packagesFeatures.packagesFeatures'
  );

  const allFeatures = get(
    getAllFeatures,
    'data.ACG_Packaging_features.features',
    []
  );

  const initialPackageName = get(
    subscriberQuery,
    'data.ACG_Packaging_subscriberPackage.subscriberPackage.packageName',
    ''
  );

  const initialPackageId = get(
    subscriberQuery,
    'data.ACG_Packaging_subscriberPackage.subscriberPackage.packageId',
    ''
  );

  if (packagesFeatures && !packages.length) {
    setPackages(packagesFeatures);
  }

  useEffect(() => {
    if (overridesString) {
      const overridesParsed = JSON.parse(overridesString);
      const flattenedOverrides = Object.entries(overridesParsed).map(
        entryToObject
      );
      const organizationOverrides = flattenedOverrides
        ? flattenedOverrides.map((override, i) => {
            return {
              featureId: override.id,
              value: override.accessLevel,
              id: i,
            };
          })
        : [];
      setOverrides(flattenedOverrides);
      setOrganizationOverrides(organizationOverrides);
    }
  }, [overridesString]);

  if (featuresString && !featureNames) {
    const featuresParsed = JSON.parse(featuresString);
    const flattenedFeatures = Object.entries(featuresParsed).map(entryToObject);
    const displayNames = flattenedFeatures.map(
      (feature) => feature.displayName
    );
    const featureNames = displayNames.join(', ');
    setFeatureNames(featureNames);
  }

  useEffect(() => {
    handleSetFeatures();
  }, [selectedFeatureId]);

  const addOverride = (index) => {
    const blankOverride = {
      featureId: '',
      value: '',
      id: Math.floor(Math.random() * Math.floor(100000)),
    };
    setOrganizationOverrides([...organizationOverrides, blankOverride]);
  };

  const updateOverride = ({ orgOverrideId, featureId, overrideId }) => {
    const updatedOrgOverrides = organizationOverrides.map((orgOverride) => {
      if (orgOverride.id === orgOverrideId) {
        if (featureId) {
          orgOverride.featureId = featureId;
        }
        if (overrideId) {
          orgOverride.value = overrideId;
        }
      }
      return orgOverride;
    });
    setOrganizationOverrides(updatedOrgOverrides);
  };

  const removeOverride = (id) => {
    const newOverrides = organizationOverrides.filter(
      (orgOverride) => orgOverride.id !== id
    );
    setOrganizationOverrides(newOverrides);
  };

  const handleCancel = () => {
    setConfirmSave(false);
    setVisible(false);
    setSelectedPackage({});
    setFeatureNames('');
    setSelectedFeatureId('');
    setOrganizationOverrides([{ featureId: '', value: '', id: 1 }]);
    // Need to return overrides and packages to their original state
  };

  const handleConfirmAndContinue = () => {
    const featureOverrides = organizationOverrides.map((override) =>
      omit(override, 'id')
    );
    mutateOrgPackages({
      variables: {
        input: {
          organisationId,
          packageId: selectedPackage.packageId || initialPackageId,
        },
      },
    });
    mutateOrgPackageFeatures({
      variables: { input: { organisationId, overrides: featureOverrides } },
    });
    setTimeout(() => {
      refetch({
        variables: { input: { organisationId } },
        options: {
          fetchPolicy: 'network-only',
        },
      });
      setConfirmSave(false);
      setVisible(false);
      message.success('The package has been successfully updated.');
    }, 500);
  };

  const handleSetFeatures = () => {
    const selectedPackage =
      packages.find(
        (singlePackage) => singlePackage.packageId === selectedFeatureId
      ) || '{}';
    if (selectedPackage && selectedPackage.features) {
      const features = Object.entries(JSON.parse(selectedPackage.features)).map(
        entryToObject
      );
      const displayNames = features.map((feature) => feature.displayName);
      const featureNames = displayNames.join(', ');
      selectedPackage.features = features;
      setFeatureNames(featureNames);
      setSelectedPackage(selectedPackage);
    }
  };

  const isOverrideButtonDisabled = () => {
    return !!organizationOverrides.find(
      (orgOverride) => orgOverride.featureId === ''
    );
  };

  const findDescription = () => {
    const descriptionItem = packageDescriptions.find((descriptionItem) => {
      if (
        descriptionItem.packageId === selectedFeatureId ||
        descriptionItem.packageName === initialPackageName
      ) {
        return descriptionItem;
      }
    });
    return (descriptionItem && descriptionItem.description) || '';
  };

  return (
    <>
      <DisabledWhenReadOnly>
        <PrimaryButton onClick={() => setVisible(!readOnly)}>
          Update
        </PrimaryButton>
      </DisabledWhenReadOnly>
      <StyledModal
        destroyOnClose
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
        style={{ maxWidth: '600px' }}
        title={
          <Title level={3}>
            {!confirmSave ? 'Update package' : 'Confirm update package'}
          </Title>
        }
      >
        {!confirmSave ? (
          <FormContent style={{ padding: '24px' }}>
            <Form>
              <FormItem
                display="block"
                style={{ borderBottom: 'none', padding: '5px' }}
              >
                <InputLabel>Package</InputLabel>
                <Select
                  onSelect={(singlePackageId) =>
                    setSelectedFeatureId(singlePackageId)
                  }
                  value={selectedFeatureId || initialPackageName}
                  disabled={!isSubscriptionOrg}
                >
                  {packages.map((singlePackage) => {
                    return (
                      <Option
                        key={singlePackage.packageId}
                        value={singlePackage.packageId}
                      >
                        {singlePackage.packageName}
                      </Option>
                    );
                  })}
                </Select>
                <p style={{ lineHeight: '25px', marginBottom: '0' }}>
                  {featureNames}
                </p>
                <InputLabel style={{ marginTop: '1em', marginBottom: '0' }}>
                  Description
                </InputLabel>
                <p style={{ lineHeight: '30px', marginBottom: '2em' }}>
                  {findDescription()}
                </p>
              </FormItem>
              <FormItem
                display="block"
                style={{ borderBottom: 'none', padding: '5px' }}
              >
                <InputLabel style={{ marginTop: '0', marginBottom: '0' }}>
                  Overrides
                </InputLabel>
                <p style={{ lineHeight: '30px', marginBottom: '0' }}>
                  Overrides will remain even if a users' plan changes.
                </p>
                {organizationOverrides.map((orgOverride, i) => {
                  return (
                    <Flex
                      key={i}
                      justify="space-between"
                      style={{ margin: '5px 0' }}
                    >
                      <Select
                        placeholder="Select feature"
                        value={orgOverride.featureId || undefined}
                        onSelect={(featureId) =>
                          updateOverride({
                            orgOverrideId: orgOverride.id,
                            featureId,
                          })
                        }
                        style={{ marginRight: '1em' }}
                      >
                        {allFeatures.map((feature) => {
                          return (
                            <Option key={feature.id} value={feature.id}>
                              {feature.displayName}
                            </Option>
                          );
                        })}
                      </Select>

                      <Select
                        placeholder="Select override"
                        value={orgOverride.value || undefined}
                        onSelect={(overrideId) =>
                          updateOverride({
                            orgOverrideId: orgOverride.id,
                            overrideId,
                          })
                        }
                        style={{ marginRight: '1em' }}
                      >
                        <Option key={1} value={'ENABLED'}>
                          Enabled
                        </Option>
                        <Option key={1} value={'DISABLED'}>
                          Disabled
                        </Option>
                      </Select>

                      <Tooltip
                        title="Remove"
                        placement="right"
                        mouseEnterDelay={0.3}
                      >
                        <StyledIconDangerButton
                          ghost
                          icon="delete"
                          onClick={(event) => removeOverride(orgOverride.id)}
                        />
                      </Tooltip>
                    </Flex>
                  );
                })}
              </FormItem>
              <FormItem
                display="block"
                style={{ borderBottom: 'none', padding: '5px' }}
              >
                <Tooltip
                  placement="topLeft"
                  title={
                    isOverrideButtonDisabled()
                      ? 'Select a feature and override'
                      : ''
                  }
                >
                  <LinkButton
                    disabled={isOverrideButtonDisabled()}
                    icon="plus"
                    onClick={addOverride}
                  >
                    Add an override
                  </LinkButton>
                </Tooltip>
              </FormItem>
              <FormItem
                display="block"
                style={{ borderBottom: 'none', padding: '5px' }}
              >
                <Flex justifyContent="flex-end" style={{ margin: '1em 1em' }}>
                  <Button style={{ margin: '0em .5em' }} onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    style={{ margin: '0em .5em' }}
                    type="primary"
                    onClick={() => setConfirmSave(true)}
                  >
                    Save
                  </Button>
                </Flex>
              </FormItem>
            </Form>
          </FormContent>
        ) : (
          <FormContent style={{ padding: '24px' }}>
            <FormItem
              display="block"
              style={{ borderBottom: 'none', padding: '5px' }}
            >
              <p>
                Are you sure you want to apply this package for all users in
                this organisation?
              </p>
              <StyledCard>
                <Title level={2}>
                  {selectedPackage.packageName || initialPackageName}
                </Title>
                <p style={{ lineHeight: '25px' }}>{featureNames}</p>
              </StyledCard>
              <StyledCard>
                <Title level={2}>Overrides</Title>
                {organizationOverrides.map((orgOverride, i) => {
                  const feature = allFeatures.find(
                    (feature) => feature.id === orgOverride.featureId
                  );
                  return (
                    <Row type="flex" key={i}>
                      <Col span={12}>
                        <span>{(feature && feature.displayName) || ''}</span>
                      </Col>
                      <Col span={12}>
                        <span
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {orgOverride.value}
                        </span>
                      </Col>
                    </Row>
                  );
                })}
              </StyledCard>

              <Flex justifyContent="flex-end" style={{ margin: '1em 0em' }}>
                <Button style={{ margin: '0em .5em' }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  style={{ margin: '0em 0em' }}
                  type="primary"
                  onClick={handleConfirmAndContinue}
                >
                  Continue
                </Button>
              </Flex>
            </FormItem>
          </FormContent>
        )}
      </StyledModal>
    </>
  );
};
const StyledIconDangerButton = styled(Button)`
  margin-left: 5px;
  min-width: 60px;
  box-shadow: none;
  &:hover {
    background-color: ${lighten(0.4, colour.red)} !important;
  }
`;
const StyledCard = styled(Card)`
  box-shadow: 0px 2px 5px 2px rgba(7, 2, 71, 0.08);
  padding: 24px;
  margin: 1.5em 0;
`;
