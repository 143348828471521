import gql from 'graphql-tag';

export const GET_USER_PROFILE_QUERY = gql`
  query getUserProfile($userId: String!) {
    getUserProfile(userId: $userId) {
      id: userId
      userId
      organisationId
      isRegistered
      emailVerified
      isMember
      membershipStartDate
      givenName
      familyName
      memberUntil
      displayname
      name
      email
      picture
      employer
      jobTitle
      twitterUrl
      githubUrl
      facebookUrl
      linkedinUrl
      legacyVideoDownload
    }
    getUserHandle(userId: $userId) {
      id: handle
      handle
    }
  }
`;
