import React from 'react';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const STUDY_GROUP_PROGRESS_QUERY = gql`
  query ACG_studyGroupProgress($id: String!, $organisationId: String!) {
    ACG_studyGroupProgress(id: $id, organisationId: $organisationId) {
      id
      userId
      progress
      lastActivity
    }
  }
`;

const StudyGroupProgressQuery = ({
  organisationId,
  groupId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
    <Query
      query={STUDY_GROUP_PROGRESS_QUERY}
      variables={{ id: groupId, organisationId }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: get(data, 'ACG_studyGroupProgress') || [],
              ...rest
            })
          }}
        </QueryBoundary>
      )}
    </Query>
  );

export { StudyGroupProgressQuery };