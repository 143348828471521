import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import Flex from '../flex';
import { DropdownButton } from './DropdownButton';
import { DropdownInput } from './DropdownInput';
import { DropdownMenu } from './DropdownMenu';
import { DropdownMenuItem } from './DropdownMenuItem';
import { DropdownCategoryMenuItem } from './DropdownCategoryMenuItem';
import { DropdownCaretIcon } from './DropdownCaretIcon';
import { DropdownMenuDivider } from './DropdownMenuDivider';
import { colour } from '../styles/variables';

class MultiCategoryDropdown extends React.Component {
  static defaultProps = {
    async: false,
    width: "252px",
    optionCategories: [],
    showReset: true,
    placeholder: "",
    defaultLabel: "All",
    emptyState: null,
    onChange: () => { },
    onSearch: () => { }
  }

  constructor(props) {
    super(props);
    this.initialState = {
      visible: false,
      focused: false,
      value: this.props.value,
      input: ''
    };
    this.state = this.initialState;
  }

  selectItem = (value) => {
    this.setState({ value, visible: false, focused: false });
    const selectedOption = this.getOptionCategories().find(option => option.value === value);
    this.props.onChange(value, selectedOption);
  }

  getLabel = () => {
    const { value } = this.state;

    const option = this.getFlattenOptions().find(option => option.value === value);

    return option ? `${option.title}: ${option.text}` : this.props.defaultLabel;
  }

  getOptionCategories = () => {
    const { async, optionCategories } = this.props;
    const { input } = this.state;

    if (async) {
      return optionCategories;
    }

    return optionCategories
      .map(({ title, options }) => ({
        title,
        options: options.filter(({ text }) => text.toLowerCase().includes(input.toLowerCase()))
      }));
  }

  getFlattenOptions = () => {
    return this.getOptionCategories().reduce((accu, { title, options }) => ([
      ...accu,
      ...options.map(option => ({ ...option, title }))
    ]), []);
  }

  handleInputChange = (e) => {
    const { async, onSearch } = this.props;
    const input = e.target.value;

    if (async) {
      return onSearch(input);
    }
    return this.setState({ input });
  }

  render() {
    const { value, visible, focused } = this.state;
    const {
      showReset,
      placeholder,
      emptyState,
      onChange
    } = this.props;

    const optionCategories = this.getOptionCategories();
    const isEmpty = this.getFlattenOptions().length === 0;
    const isResetVisible = value && showReset;

    return (
      <Flex alignItems="center">
        <Dropdown
          visible={visible}
          trigger={["click"]}
          onVisibleChange={(visible) => {
            if (!visible && focused) return;
            this.setState({ visible });
          }}
          overlay={
            <DropdownMenu onClick={({ item, key }) => {
              if (key === 'input' || key === 'empty-state') return;
              this.selectItem(key);
            }}>
              <DropdownMenuItem key="input" type="input">
                <DropdownInput
                  placeholder={placeholder}
                  onMouseLeave={() => {
                    this.setState({ focused: false });
                  }}
                  onChange={this.handleInputChange}
                />
              </DropdownMenuItem>
              <DropdownMenuDivider />

              {isEmpty && emptyState && (
                <DropdownEmptyStateMenuItem key="empty-state">
                  {emptyState}
                </DropdownEmptyStateMenuItem>
              )}
              {
                optionCategories.map(({ title, options }) => {
                  if (options.length === 0) {
                    return null;
                  }

                  return [
                    <DropdownCategoryMenuItem
                      key={`menu-item-category-${title}`}
                      type="input"
                    >
                      {title}
                    </DropdownCategoryMenuItem>,
                    ...options.map(({ value, text }) => {
                      return (
                        <IndentDropdownMenuItem key={value}>
                          {text}
                        </IndentDropdownMenuItem>
                      )
                    })
                  ];
                })
              }
            </DropdownMenu>
          }
        >
          <StyledDropdownButton width={this.props.width}>
            <TruncatedLabel>{this.getLabel()}</TruncatedLabel>
            {isResetVisible ? (
              <div onClick={(e) => {
                e.stopPropagation();
                this.setState({ ...this.initialState, value: undefined });
                onChange();
              }}>
                <DropdownCloseIcon type="close-circle" />
              </div>
            ) : (
              <DropdownCaretIcon type = "caret-down" />
            )}
          </StyledDropdownButton>
        </Dropdown>
      </Flex>
    )
  }
};

const StyledDropdownButton = styled(DropdownButton)`
  padding: 8px 8px 8px 14px;
  margin: 0 8px;
`;

const TruncatedLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IndentDropdownMenuItem = styled(DropdownMenuItem)`
  padding-left: 24px;
`;

const DropdownEmptyStateMenuItem = styled(DropdownMenuItem)`
  cursor: default;

  background-color: #fff;

  :hover {
    background-color: #fff;
  }
`;

const DropdownCloseIcon = styled(DropdownCaretIcon)`
  :hover {
    color: ${colour.blueLight};
  }
`;

export { MultiCategoryDropdown };