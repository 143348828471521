import { useState } from 'react';
import { makeLocalStorage } from '/lib/local-storage';

const localStorage = makeLocalStorage();

const useCachedState = (defaultValue, cacheKey, mapper = state => state) => {
  const cachedState = localStorage.get(cacheKey);

  const [state, setState] = useState(cachedState ? mapper(cachedState) : defaultValue);
  const setStateCache = state => {
    localStorage.set(cacheKey, state);
    setState(state);
  };
  return [state, setStateCache];
};

export { useCachedState };