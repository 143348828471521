import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import get from 'lodash/get';

import { getConfig } from '../../config/get-config';
import { QueryBoundary } from '/components/data/QueryBoundary';

const { ALGOLIA_APP_ID } = getConfig();

const GET_ALGOLIA_API_KEY_AND_ORG_ID = gql`
  query {
    ACG_searchApiKey {
      id
      privateApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

const AlgoliaConfigProvider = ({
  renderLoading,
  renderError,
  children
}) => (
  <Query query={GET_ALGOLIA_API_KEY_AND_ORG_ID} fetchPolicy="network-only">
    {props => {
      return (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              configuring: false,
              appId: ALGOLIA_APP_ID,
              apiKey: get(data, 'ACG_searchApiKey.privateApiKey.apiKey')
            })
          }}
        </QueryBoundary>
      )
    }}
  </Query>
);

export { AlgoliaConfigProvider };
