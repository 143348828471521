import get from 'lodash.get';
import { useAlgoliaSearch } from '/hooks/use-algolia-search-latest';

const CoursesQuery = ({
  query,
  page,
  pageSize = 10,
  filters,
  children
}) => {
  const { searchResults, ...restProps } = useAlgoliaSearch({
    type: 'course',
    query,
    page,
    filters,
    hitsPerPage: pageSize
  });
  const data = get(searchResults, 'hits') || [];

  return children({
    data: data.map(({ uniqueid, shortTitle, shortSummary }) => ({
      courseId: uniqueid,
      courseTitle: shortTitle,
      description: shortSummary
    })),
    pagination: {
      total: get(searchResults, 'nbHits') || 0,
      page: (get(searchResults, 'page') || 0) + 1,
      pageSize: get(searchResults, 'hitsPerPage')
    },
    ...restProps
  });
};

export { CoursesQuery };
