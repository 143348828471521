import React from 'react';
import gql from 'graphql-tag';
import { Modal } from 'antd';
import { Box } from '@a-cloud-guru/rainbow-ui';

import { DangerButton } from '/components/button/DangerButton';
import { useMutation } from 'react-apollo';
import { isReadOnly } from '../../../../hooks/isReadOnly';

const { confirm } = Modal;

const REMOVE_ORGANISATION_SAML_CONFIGURATION = gql`
  mutation removeOrganisationSamlConfiguration($organisationId: String!) {
    ACG_removeOrganisationSamlConfiguration(organisationId: $organisationId) {
      organisation {
        id
        connectionName
        companyCode
        features {
          allowInvitations
          allowUserReactivationViaSAML
        }
      }
    }
  }
`;

const RemoveSamlConnectionAction = ({ id: organisationId }) => {
  const [
    removeOrganisationSamlConfiguration
  ] = useMutation(REMOVE_ORGANISATION_SAML_CONFIGURATION);

  const readOnly = isReadOnly();

  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to Remove this SAML Connection?',
      content:
        'If this SAML Connection is removed, SSO/SAML users will not be able to join this organisation!',
      onOk: async () => {
        await removeOrganisationSamlConfiguration({
          variables: {
            organisationId
          }
        });
      }
    });
  };

  return (
    <Box ml="5px">
      <DangerButton
        onClick={() => !readOnly && showConfirm()}
      >
        Remove
      </DangerButton>
    </Box>
  );
};

export { RemoveSamlConnectionAction };
