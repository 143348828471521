import { Button } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { darken } from 'polished';
import { default as styled, css } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Box, Flex } from '/components/box';
import { InviteUserModal } from '/components/invite/InviteUserModal';
import { SearchBox } from '/components/search/SearchBox';
import { DisplayName } from '/components/table/DisplayName';
import { PaginatedTable } from '/components/table/PaginatedTable';
import { colour } from '/styles/variables';
import { useOrganisationUsersQuery } from '/data/query-hooks';
import { useAlgoliaBrowseAll } from '/hooks/use-algolia-browse-all';
import { formatDate } from '/utils/date';

import { StatusFilter } from './filter/StatusFilter';
import { ExportButton } from './ExportButton';
import { BulkRemove } from './BulkRemove';
import { ActionsDropdown } from './ActionDropdowns';

import { ACTION_TYPES, INITIAL_STATE, reducer } from './reducer';
import { getConfig } from '../../../config/get-config';

const { ACG_ENV } = getConfig();

const LAST_SEEN_DEPLOY_DATE = new Date(2018, 11, 3);

const getRole = (user) => {
  if (user.organisation_team_coordinator === true && user.organisation_admin_only === true) {
    return 'Team Coordinator';
  }

  if (user.organisation_team_coordinator === true && user.organisation_admin_only === false) {
    return 'Team Coordinator + Student';
  }

  if (user.organisation_admin === true && user.organisation_admin_only === true) {
    return 'Admin';
  }

  if (user.organisation_admin === true && user.organisation_admin_only === false) {
    return 'Admin + Student';
  }

  return 'Student';
};

const generateColumns = ({
  active,
  reload,
  clearCache,
  dispatch,
  statusFilter,
  impersonationEnabled
}) => [
  {
    title: 'Display Name',
    dataIndex: 'displayname',
    render: (displayname, { picture, organisation_license_consumed }) => (
      <DisplayName
        name={displayname}
        avatar={picture}
        active={organisation_license_consumed !== false && active}
      />
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name, userDetails) => (
      <>
        {userDetails._highlightResult ? (
          <span
            dangerouslySetInnerHTML={{
              __html: get(userDetails, '_highlightResult.name.value') || ''
            }}
          />
        ) : (
          name
        )}
      </>
    )
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
    render: (email, userDetails) => (
      <>
        {userDetails._highlightResult ? (
          <div
            dangerouslySetInnerHTML={{
              __html: get(userDetails, '_highlightResult.email.value') || ''
            }}
          />
        ) : (
          email
        )}
      </>
    )
  },
  {
    title: 'Role',
    dataIndex: 'organisation_admin',
    render: (organisation_admin, user) => {
      return <Role>{getRole(user)}</Role>;
    }
  },
  {
    title: () =>
      active ? (
        <StatusFilter
          statusFilter={statusFilter}
          updateStatusFilter={(status) =>
            dispatch({
              type: ACTION_TYPES.UPDATE_FILTER,
              payload: {
                filter: 'statusFilter',
                value: status
              }
            })
          }
        />
      ) : (
        'Status'
      ),
    key: 'Status',
    render: ({ organisation_license_consumed }) => (
      <Role>
        {organisation_license_consumed === false || !active
          ? 'Deactivated'
          : 'Active'}
      </Role>
    )
  },
  {
    title: 'Last Seen',
    dataIndex: 'last_seen_timestamp',
    sorter: true,
    render: (last_seen_timestamp) =>
      last_seen_timestamp
        ? formatDate({
          date: last_seen_timestamp,
          dateFormat: 'D MMM YYYY hh:mm A'
        })
        : 'never logged in'
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (record) => {
      const {
        objectID: userId,
        organisation_id: organisationId,
        organisation_admin: organisationAdmin,
        email
      } = record;

      const isAdmin = !!organisationAdmin;

      return (
        <ActionsDropdown
          userId={userId}
          email={email}
          organisationId={organisationId}
          isAdmin={isAdmin}
          clearCache={clearCache}
          reload={reload}
          impersonationEnabled={impersonationEnabled}
        />
      );
    }
  }
];

const OrganisationUsers = ({ organisationId, active }) => {
  const { acgBernieImpersonation } = useFlags();
  const isProductionEnv = ACG_ENV === 'production';
  const impersonationEnabled = isProductionEnv && acgBernieImpersonation;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [key, setKey] = useState(0);
  const reload = () => setKey((key) => key + 1);
  const { query, page, sorter, statusFilter, roleFilter } = state;

  const {
    data,
    pagination: { total, pageSize },
    error,
    clearCache
  } = useOrganisationUsersQuery({
    query,
    page,
    sorter,
    pageSize: 10,
    filters: {
      role: roleFilter,
      status: statusFilter
    },
    key
  });
  const { browse } = useAlgoliaBrowseAll({
    type: 'user',
    filters: 'active:true',
    replica: isEmpty(sorter) ? undefined : `${sorter.field}_${sorter.order}`
  });

  const activeUsers = data.filter(user => user.organisation_license_consumed).map(user => user.email);
  const inactiveUsers = data.filter(user => !user.organisation_license_consumed).map(user => user.email);

  const currentEmailStatus = {
    active: activeUsers,
    inactive: inactiveUsers,
    pending: []
  };

  useEffect(() => {
    clearCache();
  }, [statusFilter]);

  return (
    <Box mt="-64px">
      <PaginatedTable
        title={() => (
          <Flex px="16px" justifyContent="space-between">
            <Button.Group>
              <FilterButton
                selected={roleFilter === 'user'}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.UPDATE_FILTER,
                    payload: {
                      filter: 'roleFilter',
                      value: 'user'
                    }
                  });
                }}
              >
                All Users
              </FilterButton>
              <FilterButton
                selected={roleFilter === 'admin'}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.UPDATE_FILTER,
                    payload: {
                      filter: 'roleFilter',
                      value: 'admin'
                    }
                  });
                }}
              >
                Admins
              </FilterButton>
            </Button.Group>
            <Flex>
              <Box mr="8px">
                <ExportButton
                  onExport={browse}
                  defaults={{ last_seen_timestamp: LAST_SEEN_DEPLOY_DATE }}
                />
              </Box>
              <Box mr="8px">
                <InviteUserModal
                  inviteAsAdmin
                  organisationId={organisationId}
                  buttonText="Invite Admins"
                  tip="Please add emails you wish to send admin invitations to. These admins will be invited with student access."
                  currentEmailStatus={currentEmailStatus}
                />
              </Box>
              <Box mr="8px">
                <DisabledWhenReadOnly>
                  <InviteUserModal
                    inviteAsAdmin={false}
                    organisationId={organisationId}
                    buttonText="Invite Students"
                    tip="Please add emails you wish to send student invitations to."
                    currentEmailStatus={currentEmailStatus}
                  />
                </DisabledWhenReadOnly>
              </Box>
              <Box mr="8px">
                <DisabledWhenReadOnly>
                  <BulkRemove organisationId={organisationId} />
                </DisabledWhenReadOnly>
              </Box>
              <SearchBox
                placeholder="Search Users"
                width="272px"
                onChange={(e) => {
                  dispatch({
                    type: ACTION_TYPES.UPDATE_QUERY,
                    payload: { query: e.target.value }
                  });
                }}
                value={query}
              />
            </Flex>
          </Flex>
        )}
        columns={generateColumns({
          active,
          reload,
          clearCache,
          dispatch,
          statusFilter,
          impersonationEnabled
        })}
        dataSource={error ? [] : data}
        rowKey={({ user_id }) => user_id}
        pagination={
          error
            ? null
            : {
              current: page,
              pageSize,
              total,
              onChange: (current) =>
                dispatch({
                  type: ACTION_TYPES.UPDATE_STATE,
                  payload: {
                    key: 'page',
                    value: current
                  }
                })
            }
        }
        onChange={(pagination, filter, sorter) => {
          const { order, field } = sorter || {};

          if (!order || !field) {
            return dispatch({
              type: ACTION_TYPES.UPDATE_STATE,
              payload: {
                key: 'sorter',
                value: undefined
              }
            });
          }

          return dispatch({
            type: ACTION_TYPES.UPDATE_STATE,
            payload: {
              key: 'sorter',
              value: {
                field,
                order
              }
            }
          });
        }}
      />
    </Box>
  );
};

const Role = styled.span`
  word-break: keep-all;
`;

const FilterButton = styled(Button)`
  ${(props) =>
  props.selected &&
    css`
      background-color: ${colour.blue};
      color: #fff;
      border-color: ${colour.blue};

      &:hover {
        color: #fff;
        background-color: ${colour.blue};
      }

      &:focus {
        background-color: ${colour.blue};
        color: #fff;
        border-color: ${colour.blue};
      }
    `}

  ${(props) =>
      !props.selected &&
    css`
      background-color: transparent;
      color: ${colour.gray600};
      border-color: ${colour.gray600};

      &:hover {
        color: ${darken(0.5, colour.gray600)};
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
        color: ${darken(0.5, colour.gray600)};
        border-color: ${colour.gray600};
      }
    `}
`;

export { OrganisationUsers };
