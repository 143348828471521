import React, { useState } from 'react';
import { Button, Progress } from 'antd';
import styled from 'styled-components';
import get from 'lodash.get';
import gql from 'graphql-tag';

import { useApolloClient, useProcessList } from '/lib/hooks';
import { jsonToCsvDownload } from '/lib/json-to-csv';

const DEACTIVATE_USER_FROM_ORGANISATION = gql`
  mutation deactivateUsersFromOrganisation (
    $organisationId: String!,
    $input: ToggleUserLicenseConsumedInput!
  ) {
    ACG_toggleLicenseConsumed (
      organisationId: $organisationId
      input: $input
    ) {
      taskId
    }
  }
`;

const BulkRemoveConfirmForm = (props) => {
  const { found, organisationId } = props
  const [submitting, setSubmitting] = useState(false);
  const {
    setItemsToProcess,
    completed,
    processed,
    success,
    failed
  } = useProcessList({
    command: removeUser
  });

  if (!submitting) { // run once
    setSubmitting(true);
    setItemsToProcess(found);
  }

  const total = found.length;
  const percent = total
  ? Math.round((processed/total)*100)
  : 100;

  const apolloClient = useApolloClient();

  function removeUser(record) {
    return apolloClient.mutate({
      mutation: DEACTIVATE_USER_FROM_ORGANISATION,
      variables: {
        organisationId,
        input: {
          userIds: [get(record, 'userId')],
          licenseConsumed: false
        }
      }
    })
    .then(() => record);
  }

  return (
    <>
      {
        completed
        ? <Completed
            {...props}
            success={success}
            failed={failed}
          />
        : 'Deactivating ...'
      }
      <Progress percent={percent} />
    </>
  )
};

const Completed = ({ success, failed, missing }) => {
  return (
    <StyledContainer>
      <StyledResults>
        {
          `Deactivated ${success.length} user(s).`
        }
        <Button
          disabled={!success.length}
          onClick={() => jsonToCsvDownload({ data: success, fileName: `${new Date().toISOString()}-deactivated.csv`})}>
          Export list to csv
        </Button>
      </StyledResults>
      <StyledResults>
        {
          `Failed to deactivate the following ${failed.length} user(s).`
        }
        <Button
          disabled={!failed.length}
          onClick={() => jsonToCsvDownload({ data: failed, fileName: `${new Date().toISOString()}-failed-to-deactivate.csv`})}>
          Export list to csv
        </Button>
      </StyledResults>
      <StyledResults>
        {
          `Could not find the following ${missing.length} user(s).`
        }
        <Button
          disabled={!missing.length}
          onClick={() => jsonToCsvDownload({ data: missing, fileName: `${new Date().toISOString()}-missing-users.csv`})}>
          Export list to csv
        </Button>
      </StyledResults>
    </StyledContainer>
  );
}

const StyledResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export { BulkRemoveConfirmForm };
