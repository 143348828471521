import React from 'react';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';
import { QueryError } from '/components/data/QueryError';

class QueryBoundary extends React.Component {
  static defaultProps = {
    retry: 1
  }

  constructor(props) {
    super(props);
    this.retried = 0;
  }

  componentDidUpdate() {
    const { retry, error, refetch } = this.props;
    if (error && (this.retried < retry)) {
      this.retried++;
      refetch();
    }
  }

  render() {
    const {
      loading,
      error,
      data,
      retry,
      children,
      renderLoading = () => <Container><Spinner /></Container>,
      renderError = () => <QueryError refetch={this.props.refetch} />,
      ...restProps
    } = this.props;
    return (
      <>
        {renderLoading && (loading || (this.retried < retry && error)) && renderLoading()}
        {this.retried >= retry && error && renderError && renderError()}
        {(!renderLoading || !loading) && (!error || !renderError) && children({ loading, data, error, ...restProps })}
      </>
    )
  }
}

export { QueryBoundary };