const { getConfigForEnvironment } = require('./get-config-for-environment');
// allow either to be set
const CONFIG_ENV = process.env.REACT_APP_ACG_ENV || process.env.ACG_ENV;

let config;

const getConfig = () => {
  if (config) {
    return config;
  }

  if (!CONFIG_ENV) {
    throw new Error('REACT_APP_ACG_ENV / ACG_ENV is required');
  }

  config = getConfigForEnvironment(CONFIG_ENV);

  return config;
};

module.exports = { getConfig };
