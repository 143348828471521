import React, { useState } from 'react';
import { get } from 'lodash';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { useInvitationSummaryQuery } from '/data/query-hooks';
import { Box, Flex } from '/components/box';
import { Stat } from '/components/stat/Stat';
import { Button } from 'antd';
import { FilterButton } from '@a-cloud-guru/kermit';
import { SignupProgressChart } from './SignupProgressChart';
import { transform } from './transform-chart-data';

const InvitationStats = ({ organisation }) => {
  const [type, setType] = useState('day');
  const {
    loading,
    summary: { joinedCount = 0, pendingCount = 0, expiredCount = 0, data },
  } = useInvitationSummaryQuery({
    interval: type,
  });

  const { userLicenseCount = 0 } = get(organisation, 'subscription') || {};

  return (
    <Module>
      <ModuleHeader header="Signup Progress" hideDivider>
        <Flex justifyContent="flexEnd" alignItems="center">
          <Box ml="10px">
            <Button.Group>
              <FilterButton
                selected={type === 'day'}
                onClick={() => setType('day')}
              >
                DAILY
              </FilterButton>
              <FilterButton
                selected={type === 'week'}
                onClick={() => setType('week')}
              >
                WEEKLY
              </FilterButton>
            </Button.Group>
          </Box>
        </Flex>
      </ModuleHeader>

      <Box position="relative" top="16px">
        <Box pl="15px">
          <SignupProgressChart
            height={400}
            loading={loading}
            licensePurchased={userLicenseCount}
            data={transform({ data })}
          />
        </Box>
        <Flex p="16px 26px 35px" justifyContent="space-between">
          <Flex>
            <Stat
              loading={loading}
              value={joinedCount}
              description="Total Joined"
            />
            <Stat
              loading={loading}
              value={pendingCount}
              description="Invites Pending"
            />
            <Stat
              loading={loading}
              value={expiredCount}
              description="Invites Expired"
            />
          </Flex>
        </Flex>
      </Box>
    </Module>
  );
};

export { InvitationStats };
