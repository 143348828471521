import DataSet from "@antv/data-set";
import { format } from 'date-fns';

const configs = {
  week: {
    format: (date) => format(date, 'DD MMM')
  },
  month: {
    format: (date) => format(date, 'YYYY MMM')
  }
}

export const transform = ({ columns, dataSource, type }) => {
  const dates = columns.slice(1).map(({ title }) => title);

  const events = dataSource
    .map(item => {
      return Object.keys(item)
        .filter(key => key !== 'event')
        .reduce((current, nextKey) => {
          return { ...current, [configs[type].format(nextKey)]: item[nextKey] }
        }, { event: item.event });
    });

  const fields = events.map(({ event }) => event);

  const chartData = dates.map(date => {
    const eventData = events.reduce((eventMap, event) => {
      return { ...eventMap, [event.event]: event[date] }
    }, {});
    return {
      Period: date,
      ...eventData
    }
  });

  const { DataView } = DataSet;

  const dv = new DataView();
  dv.source(chartData)
    .transform({
      type: 'fold',
      fields,
      key: 'event',
      value: 'userCount',
      retains: ['Period']
    })
    .transform({
      type: 'map'
    });

  return dv;
};