import React from 'react';
import styled from 'styled-components';
import { Table, DateFormat } from '@a-cloud-guru/kermit';
import { DisplayName } from '/components/table/DisplayName';
import { StudyGroupUserProgressQuery } from '/data/query';

const columns = [
  {
    title: 'Student',
    dataIndex: 'displayName',
    width: '40%',
    render: (displayName, record) => (
      <DisplayName name={displayName} avatar={record.picture} />
    )
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    width: '25%',
    render: progress => `${progress}%`
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    width: '35%',
    render: lastActivity => (
      lastActivity ? <DateFormat format='D MMM YYYY, hh:mm:ss A'>{lastActivity}</DateFormat> : ''
    )
  }
];

const StudyGroupProgressTable = ({
  groupId,
  organisationId,
  numberOfUsers
}) => (
  <StudyGroupUserProgressQuery
    groupId={groupId}
    organisationId={organisationId}
    numberOfUsers={numberOfUsers}
  >
    {({ data }) => (
      <StyledTable
        pagination
        rowKey={({ userId }) => userId}
        columns={columns}
        dataSource={data}
      />
    )}
  </StudyGroupUserProgressQuery>
);

const StyledTable = styled(Table)`
  &&& {
    box-shadow: none;
    background-color: #fbfbfb;
    margin: 0;
    tr > th {
      background-color:  #fbfbfb;
    }

    .ant-pagination {
      float: right;
      padding: 0 10px;
    }
  }
`;

export { StudyGroupProgressTable };
