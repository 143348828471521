import { Form, Input, InputNumber, DatePicker, Button, Switch } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash.get'
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Box, Flex } from '/components/box';
import { FormItem } from '/components/form/FormItem';
import { InputLabel } from '/components/form/InputLabel';
import { useMutation } from '/lib/hooks';
import { getConfig } from '../../../../config/get-config'

const { ACG_ENV } = getConfig();

const UPDATE_ORGANISATION_SUBSCRIPTION = gql`
  mutation updateOrganisationSubscription($input: ACG_UpdateOrganisationSubscriptionInput) {
    ACG_updateOrganisationSubscription(input: $input) {
      organisation {
        id
        subscription {
          startDate
          endDate
          userLicenseCount
        }
      }
    }
  }
`;

const EXTEND_ORGANISATION_END_DATE_MUTATION = gql`
  mutation extendOrganisationEndDate($input: ACG_extendOrganisationSubscriptionEndDateInput) {
    ACG_extendOrganisationSubscriptionEndDate(input: $input) {
      organisation {
        id
        subscription {
          endDateOverride
          endDateOverrideReason
        }
      }
    }
  }
`;

const UpdateSubscriptionForm = (props) => {
  const organisationId = get(props, 'id');
  const closeModal = get(props, 'closeModal');
  const subscriptionChargeModel = get(props, 'chargeModel') === "subscription";

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [extendSubscription, setExtendSubscription] = useState(!!get(props, "subscription.endDateOverride"));
  const updateOrganisationSubscriptionMutation = useMutation({
    mutation: UPDATE_ORGANISATION_SUBSCRIPTION
  });
  const extendOrganisationEndDateMutation = useMutation({
    mutation: EXTEND_ORGANISATION_END_DATE_MUTATION
  });

  const form = get(props, 'form');
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const updateSubscription = async (event) => {
    event.preventDefault();
    form.validateFields((err) => {
      if (err) {
        console.error('Unable to validate fields')
        return;
      }
    });
    setSubmitting(true);

    if (subscriptionChargeModel) {
      await updateOrganisationSubscriptionMutation({
        variables: {
          input: {
            organisationId,
            subscriptionStartDate: getFieldValue('startDate').toISOString(),
            subscriptionEndDate: getFieldValue('endDate').toISOString(),
            subscriptionUserLicenseCount: getFieldValue('userLicenseCount')
          }
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setSubmitting(false);
      });
    }

    await extendOrganisationEndDateMutation({
      variables: {
        input: {
          organisationId,
          endDateOverride: extendSubscription ? getFieldValue('endDateOverride') : "",
          endDateOverrideReason: extendSubscription ? getFieldValue('endDateOverrideReason') : ""
        }
      }
    });

    return closeModal();
  }

  return (
    <Form
      onSubmit={updateSubscription}
      hideRequiredMark
    >
      <FormItem>
        <InputLabel>Contract Start Date</InputLabel>
        <InputField>
          {getFieldDecorator('startDate', {
            initialValue: moment(get(props, 'subscription.startDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the start date for this organization subscription',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabled={!subscriptionChargeModel}
              disabledDate={startDate => {
                const endDate = getFieldValue('endDate');
                return startDate > endDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Contract End Date</InputLabel>
        <InputField>
          {getFieldDecorator('endDate', {
            initialValue: moment(get(props, 'subscription.endDate') || new Date()),
            rules: [
              {
                required: true,
                message: 'Please give the end date for this organization subscription',
              }
            ],
          })(
            <StyledDatePicker
              format="LL"
              allowClear={false}
              disabled={!subscriptionChargeModel}
              disabledDate={endDate => {
                const startDate = getFieldValue('startDate');
                return endDate < startDate;
              }}
            />
          )}
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Extended End Date</InputLabel>
        <InputField>
          <Flex flexDirection="column" position="relative" top="-6px">
            <Box mb="8px">
              <Switch
                defaultChecked={extendSubscription}
                onChange={e => setExtendSubscription(e)}
              />
            </Box>
            {
              extendSubscription && (
                <>
                  {getFieldDecorator('endDateOverride', {
                    initialValue: moment(get(props, 'subscription.endDateOverride') || new Date()),
                    rules: [
                      {
                        required: extendSubscription,
                        message: 'Please give the date to extend this organisation subscription by',
                      }
                    ],
                  })(
                    <StyledDatePicker
                      format="LL"
                      allowClear={false}
                      disabled={!extendSubscription}
                      disabledDate={endDate => {
                        const startDate = getFieldValue('startDate');
                        return endDate < startDate;
                      }}
                    />
                  )}
                  <Box mt="2px">
                    {getFieldDecorator('endDateOverrideReason', {
                      initialValue: get(props, 'subscription.endDateOverrideReason') || '',
                      rules: [
                        {
                          required: extendSubscription,
                          message: 'Please give a reason for extending the subscription',
                        }
                      ],
                    })(
                      <Input disabled={!extendSubscription} placeholder={extendSubscription ? "Please give a reason" : ""} />
                    )}
                  </Box>
                </>
              )
            }
          </Flex>
        </InputField>
      </FormItem>
      <FormItem>
        <InputLabel>Licensed User Count</InputLabel>
        <InputField>
          {getFieldDecorator('userLicenseCount', {
            initialValue: get(props, 'subscription.userLicenseCount') || 0,
            rules: [{
              type: 'number', message: 'The input is not valid license count',
            }, {
              required: true, message: 'Please input the organization license count',
            }],
          })(
            <InputNumber
              disabled={!subscriptionChargeModel}
            />
          )}
        </InputField>
      </FormItem>
      <Footer>
        <span>{errorMessage}</span>
        <Button type="primary" htmlType="submit" loading={submitting}>
          {submitting ? 'Updating' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
}

const InputField = styled.div`
  flex: 3;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 20px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 220px;
`;

const ComposedUpdateSubscriptionForm = Form.create()(UpdateSubscriptionForm);

export {
  ComposedUpdateSubscriptionForm as UpdateSubscriptionForm
};
