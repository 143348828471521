import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Spinner, DateFormat, Box, Flex } from '@a-cloud-guru/kermit';
import { OrganisationUserCourseActivityQuery } from '/data/query';
import { DisplayName } from '/components/table/DisplayName';
import { StyledTable } from '/components/table/StyledTable';
import { ApolloConsumer } from 'react-apollo';
import { AsyncExportButton } from '/components/export/AsyncExportButton';

const QUERY_ATTRIBUTES = `
  {
    total
    data {
      userId
      displayName
      email
      picture
      courseId
      courseTitle
      progress
      lastActivity
    }
  }
`;

const columns = [
  {
    title: 'Display Name',
    dataIndex: 'displayName',
    render: (displayName, record) => (
      <DisplayName name={displayName} avatar={record.picture} />
    ),
  },
  {
    title: 'Email Address',
    dataIndex: 'email'
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    render: progress => (
      `${progress}%`
    )
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    render: lastActivity => (
      <DateFormat format='D MMM YYYY, hh:mm:ss A'>{lastActivity || '-'}</DateFormat>
    )
  }
];

const ACG_GET_USER_COURSE_ACTIVITY_DOWNLOAD_URL_QUERY = gql`
  query ACG_organisationCourseActivityReport (
    $organisationId: String!,
    $groupId: String,
    $courseId: String
  ){
    ACG_organisationCourseActivityReport (
      organisationId: $organisationId,
      filters: {
        groupId: $groupId,
        courseId: $courseId
      }
    ) {
      downloadUrl
    }
  }
`;

class UserCourseActivityTable extends React.Component {
  state = { page: 1 };

  render() {
    const { courseId, organisationId } = this.props;

    return (
      <OrganisationUserCourseActivityQuery
        attributes={QUERY_ATTRIBUTES}
        organisationId={organisationId}
        courseId={courseId}
        page={this.state.page}
        pageSize={10}
        renderLoading={false}
      >
        {({ loading, data, pagination: { total, page, pageSize } }) => (
          <>
            <Flex justifyContent="flex-end">
              <Box ml="8px">
                <ApolloConsumer>
                  {client => (
                    <AsyncExportButton
                      onExport={async () => {
                        const { data } = await client.query({
                          query: ACG_GET_USER_COURSE_ACTIVITY_DOWNLOAD_URL_QUERY,
                          variables: {
                            organisationId,
                            courseId
                          }
                        });
                        return data.ACG_organisationCourseActivityReport.downloadUrl;
                      }}
                    />
                  )}
                </ApolloConsumer>
              </Box>
            </Flex>
            <StyledActivityTable
              rowKey={({ userId, courseId }) => `${userId}-${courseId}`}
              loading={{
                indicator: <Spinner size="2rem" />,
                spinning: loading
              }}
              columns={columns}
              dataSource={data}
              onChange={(pagination) => this.setState({ page: pagination.current })}
              pagination={{
                current: page,
                pageSize,
                total
              }}
            />
          </>
        )}
      </OrganisationUserCourseActivityQuery>
    )
  }
};

const StyledActivityTable = styled(StyledTable)`
  &&& {
    box-shadow: none;
    background-color: #fbfbfb;
    margin: 0;
    tr > th {
      background-color:  #fbfbfb;
    }

    .ant-pagination {
      float: right;
      padding: 0 10px;
    }
  }
`

export { UserCourseActivityTable };