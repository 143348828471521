import { useGroupUsersQuery } from '/data/query-hooks';

const GroupUsersQuery = ({
  query,
  page,
  pageSize = 10,
  groupId,
  children
}) => {
  const { data, pagination: { total }, ...restProps } = useGroupUsersQuery({
    query,
    page,
    pageSize,
    groupId
  });

  return children({
    data,
    pagination: { total, page, pageSize },
    ...restProps
  });
}

export { GroupUsersQuery };