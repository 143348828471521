import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';
import { get } from 'lodash'

const SELF_SERVICE_SUBSCRIPTION_QUERY = (attributes) => gql`
  query ACG_billingSubscription($orgId: String!) {
    ACG_billingSubscription(input: { organisationId: $orgId }) ${attributes}
  }
`;

const SelfServiceSubscriptionQuery = ({
  orgId,
  attributes = `{
    subscription {
      id
      plan {
        id
        name
        subscriberPackage {
          packageId
          packageName
        }
      }
      currentTermStart
      currentTermEnd
      seats
      providerSubscriptionId
      providerSubscriptionUrl
      trialStart
      trialEnd
    }
  }`,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => (
  <Query
    query={SELF_SERVICE_SUBSCRIPTION_QUERY(attributes)}
    variables={{ orgId }}
    {...restProps}
  >
    {(props) => (
      <QueryBoundary
        {...props}
        renderLoading={renderLoading}
        renderError={renderError}
      >
        {({ data, ...rest}) => {
          return children({
            data: get(data, 'ACG_billingSubscription.subscription'),
            ...rest
          })
        }}
      </QueryBoundary>
    )}
  </Query>
);

export { SelfServiceSubscriptionQuery };
