
import styled from 'styled-components';

import Card from '../card';

const Module = styled(Card)`
  margin: 0 0 24px;
`;

export default Module;
