import React from 'react';
import { useAuth } from '@a-cloud-guru/react-auth';

import { Spin } from 'antd';
import { getConfig } from '../config/get-config';
import urlJoin from 'url-join';
import _ from 'lodash';

const config = getConfig();

const withAuthorization = ComposedComponent => props => {
  const { loading, viewer, error } = useAuth();
  const isAdmin = !!_.get(viewer, 'roles.admin');

  if (loading) {
    return (
      <div style={{ minHeight: '100vh', position: 'relative' }}>
        <Spin style={{ position: 'absolute', left: '50%', top: '50% '}} size="large" />
      </div>
    );
  }

  if (error) {
    const loginUrl = urlJoin(config.ACG_BERNIE_DOMAIN, 'login');
    window.location.replace(loginUrl);
    return null;
  }

  if (!isAdmin) {
    const oopsUrl = urlJoin(config.ACG_BERNIE_DOMAIN, 'oops');
    window.location.replace(oopsUrl);
    return null;
  }

  return <ComposedComponent serverState={props} />
}

export {
  withAuthorization
};
