import { SearchOutline } from '@ant-design/icons/lib/dist';
import AntdIcon from '@ant-design/icons-react';
import { Input } from 'antd';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { boxShadow, colour } from '../styles/variables';

AntdIcon.add(SearchOutline);

const SearchBox = ({
  placeholder = "",
  onChange = () => {},
  ...restProps
}) => (
  <Search
    prefix={<AntdIcon type={SearchOutline} />}
    placeholder={placeholder}
    onChange={onChange}
    {...restProps}
  />
);

const Search = styled(Input)`
  && {
    width: ${props => props.width || '272px'};
    height: ${props => props.height || '32px'};
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    :focus, :hover {
      border: none;
    }
  }

  input {
    color: ${colour.gray900};
    box-shadow: ${boxShadow.inset};
    border: solid 1px ${lighten(0.15, colour.gray400)};

    :focus, :hover {
      border-color: ${colour.gray400};
      box-shadow: ${boxShadow.inset};
    }
  }
`;

export default SearchBox;