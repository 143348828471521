import React from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { colour } from '/styles/variables';

import { DropdownButton, DropdownInput, DropdownMenu, DropdownMenuItem, DropdownCaretIcon, DropdownMenuDivider } from '/components/dropdown';

class ACGDropdown extends React.Component {
  static defaultProps = {
    options: [],
    placeholder: "",
    defaultLabel: "All",
    showReset: true,
    onChange: () => {},
    onSearch: () => {}
  }

  constructor(props) {
    super(props);
    this.initialState = {
      visible: false,
      focused: false,
      label: this.props.defaultLabel,
      selectedIndex: -1
    };
    this.state = this.initialState;
    this.initialValueSet = false;
  }

  componentDidUpdate() {
    const { initialValue, options } = this.props;
    const didOptionsLoaded = options.length > 0;
    const requireSetInitialValue = initialValue && didOptionsLoaded;

    if (!this.initialValueSet && requireSetInitialValue) {
      const option = options.find(opt => opt.value === initialValue);
      if (option) {
        this.selectItem(option.text, initialValue);
        this.initialValueSet = true;
      }
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.bindKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.bindKeyPress);
  }

  bindKeyPress = (e) => {
    const keyListeners = {
      9: this.onTabPressed,
      13: this.onEnterPressed,
      38: this.onUpPressed,
      40: this.onDownPressed
    };

    if (Object.keys(keyListeners).includes(e.which.toString())) {
      keyListeners[e.which](e);
    }
  }

  onTabPressed = (e) => {
    e.preventDefault();
    this.selectItemOnIndex();
  }

  onEnterPressed = (e) => {
    e.preventDefault();
    this.selectItemOnIndex();
  }

  onUpPressed = (e) => {
    e.preventDefault();
    const { selectedIndex } = this.state;
    if (selectedIndex === 0) return;
    this.setState({ selectedIndex: selectedIndex - 1 });
  }

  onDownPressed = (e) => {
    e.preventDefault();
    const { selectedIndex } = this.state;
    const { options } = this.props;
    if (selectedIndex === options.length - 1) return;
    this.setState({ selectedIndex: selectedIndex + 1 });
  }

  filterOptions = (input) => {
    this.setState({ input }, () => {
      this.props.onSearch(input);
    });
  }

  selectItem = (label, value) => {
    this.setState({ label, visible: false, focused: false, selectedIndex: -1 });
    this.props.onChange(value, { text: label, value });
  }

  selectItemOnIndex = () => {
    const { selectedIndex } = this.state;
    const { options } = this.props;

    const index = selectedIndex === -1 ? 0 : selectedIndex;
    if (options.length > 0) {
      const { text, value } = options[index];
      this.selectItem(text, value);
    }
  }

  render() {
    const { label, visible, focused, selectedIndex } = this.state;
    const { placeholder, showReset, defaultLabel, options, onChange, emptyState } = this.props;

    return (
      <React.Fragment>
        {label && label !== defaultLabel  && showReset && (
          <ResetLink onClick={() => {
            this.setState(this.initialState);
            onChange();
          }}>Reset</ResetLink>
        )}
        <Dropdown
          visible={visible}
          trigger={["click"]}
          onVisibleChange={(visible) => {
            if (!visible && focused) return;
            this.setState({ visible });
          }}
          overlay={
            <DropdownMenu onClick={({ item, key }) => {
              if (key === 'input' || key === 'empty') return;
              this.selectItem(item.props.children, key);
            }}>
              <DropdownMenuItem key="input" type="input">
                <DropdownInput
                  placeholder={placeholder}
                  onMouseLeave={() => {
                    this.setState({ focused: false });
                  }}
                  onChange={(e) => {
                    const input = e.target.value;
                    this.filterOptions(input);
                  }}
                />
              </DropdownMenuItem>
              <DropdownMenuDivider />
              {
                options.length > 0 ? (
                  options.map(({ value, text }, index) => {
                    return (
                      <DropdownMenuItem
                        key={value}
                        selected={selectedIndex === index}
                      >
                        {text}
                      </DropdownMenuItem>
                    )
                  })
                ) : (
                  <EmptyState key="none">{emptyState}</EmptyState>
                )
              }
            </DropdownMenu>
          }
        >
          <StyledDropdownButton>
            {label} <DropdownCaretIcon type="caret-down" />
          </StyledDropdownButton>
        </Dropdown>
      </React.Fragment>
    )
  }
}

const ResetLink = styled.a`
  color: ${colour.blueLight};
  padding: 6px 0;
`;

const StyledDropdownButton = styled(DropdownButton)`
  min-width: 252px;
  padding: 8px 8px 8px 14px;
  margin: 0 8px;
`;

const EmptyState = styled(DropdownMenuItem)`
  cursor: default;
`;

export { ACGDropdown as Dropdown };
