import React from 'react';
import { colour } from '@a-cloud-guru/themes';
import { Button, Dropdown } from 'antd';
import styled from 'styled-components';
import urlJoin from 'url-join';
import { Box } from '/components/box';
import { DropdownMenu, DropdownMenuItem } from '/components/dropdown';
import { getConfig } from '/config/get-config';
import { ImpersonateModal } from './ImpersonateModal';
import { RemoveUser } from './RemoveUser';
import { ChangeRole } from './ChangeRole';

const { ACG_BERNIE_DOMAIN } = getConfig();

const ActionsDropdown = ({
  userId,
  email,
  organisationId,
  isAdmin,
  reload,
  clearCache,
  impersonationEnabled
}) => {
  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <StyledDropdownMenu>
          <ActionMenuItem>
            <ActionItem p="8px 12px" width="100%" height="100%">
              <a href={urlJoin(ACG_BERNIE_DOMAIN, `user/${userId}`)}>
                Open Profile
              </a>
            </ActionItem>
          </ActionMenuItem>
          <ActionMenuItem>
            <ActionItem p="8px 12px" width="100%" height="100%">
              <ChangeRole
                userId={userId}
                organisationId={organisationId}
                clearCache={clearCache}
                isAdmin={isAdmin}
                reload={reload}
              />
            </ActionItem>
          </ActionMenuItem>
          {impersonationEnabled && (
            <ActionMenuItem>
              <ActionItem p="8px 12px" width="100%" height="100%" danger>
                <ImpersonateModal email={email} isAdmin={isAdmin} />
              </ActionItem>
            </ActionMenuItem>
          )}
          <ActionMenuItem>
            <ActionItem p="8px 12px" width="100%" height="100%" danger>
              <RemoveUser
                userId={userId}
                organisationId={organisationId}
                clearCache={clearCache}
              />
            </ActionItem>
          </ActionMenuItem>
        </StyledDropdownMenu>
      }
    >
      <ActionDropdownButton>
        <Ellipses>...</Ellipses>
      </ActionDropdownButton>
    </Dropdown>
  )
};

const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 4px 0;
`;

const ActionMenuItem = styled(DropdownMenuItem)`
  padding: 0;
  width: 160px;
  font-size: 13px;
  font-weight: 500;

  a {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const ActionDropdownButton = styled(Button)`
  border: 1px solid #fff;
  padding: 10px 14px;
`;

const Ellipses = styled.div`
  position: relative;
  top: -10px;
  color: ${colour.grey500};
  font-size: 16px;
  font-weight: 900;
`;

const ActionItem = styled(Box)`
  padding: 8px 12px;
  width: 100%;
  height: 100%;
  color: ${props => props.danger ? colour.brandRed : null};
`;

export { ActionsDropdown, ActionDropdownButton };
