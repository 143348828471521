import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { get } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { Modal, message } from 'antd';
import { colour } from '@a-cloud-guru/themes';
import { useAlgoliaIndex } from '/hooks/use-algolia-index';

const { confirm } = Modal;

const ASSIGN_ORGANISATION_ROLE_MUTATION = gql`
  mutation ERNIE_assignOrganisationUserRole (
    $organisationId: String!,
    $input: AssignOrganisationUserRoleInput!
  ) {
    ACG_assignOrganisationUserRole(
      organisationId: $organisationId,
      input: $input
    ) {
      taskId
    }
  }
`;


const ChangeRole = ({ isAdmin, ...rest }) => {
  if (isAdmin) {
    return <RevokeAdmin {...rest} />;
  }

  return <GrantAdmin {...rest} />;
}

const GrantAdmin = ({
  userId,
  organisationId,
  clearCache,
  reload,
  ...rest
}) => {
  const [grantAdminAccess, { data }] = useMutation(ASSIGN_ORGANISATION_ROLE_MUTATION);
  const processed = !!data;
  const userIndex = useAlgoliaIndex({
    type: 'user'
  });

  const showConfirm = () => {
    confirm({
      title: 'Do you want to grant this user admin access?',
      content: 'Granting this role will allow this user to invite users to your organization, promote users to an administrator role, and to change organization settings.',
      onOk: () => {
        const closeMessage = message.loading('Assign admin role', 10);
        grantAdminAccess({
          variables: {
            organisationId,
            input: {
              userId,
              role: 'ADMIN'
            }
          }
        }).then(response => {
          const taskId = get(response, 'data.ACG_assignOrganisationUserRole.taskId');
          return userIndex.waitTask(taskId);
        }).then(() => {
          reload();
          closeMessage();
        });
      }
    })
  }
  if (processed) {
    return <DisabledText>Processed</DisabledText>
  }

  return (
    <span onClick={showConfirm}>
      Grant Admin
    </span>
  );
}

const RevokeAdmin = ({
  userId,
  organisationId,
  clearCache,
  reload,
  ...rest
}) => {
  const [revokeAdminAccess, { data }] = useMutation(ASSIGN_ORGANISATION_ROLE_MUTATION);
  const processed = !!data;
  const userIndex = useAlgoliaIndex({
    type: 'user'
  });
  const showConfirm = () => {
    confirm({
      title: 'Do you want to revoke admin access of this user?',
      content: 'Once revoked, this user will no longer be able to invite users to your organization, promote users to an administrator role, and to change organization settings.',
      onOk: async () => {
        const closeMessage = message.loading('Revoking admin role', 10);
        revokeAdminAccess({
          variables: {
            organisationId,
            input: {
              userId,
              role: 'STUDENT'
            }
          }
        }).then(response => {
          const taskId = get(response, 'data.ACG_assignOrganisationUserRole.taskId');
          return userIndex.waitTask(taskId);
        }).then(() => {
          reload();
          closeMessage();
        });
      }
    })
  }
  if (processed) {
    return <DisabledText>Processed</DisabledText>
  }

  return (
    <span onClick={showConfirm}>
      Revoke Admin
    </span>
  );
}

const DisabledText = styled.span`
  color: ${colour.grey400};
  cursor: default;
`;

export {
  ChangeRole
};
