import React from 'react';
import styled from 'styled-components';
import { useLearningPathUsersQuery } from '/data/query-hooks';

const LearningPathStudentCount = ({
  learningPathId
}) => {
  const { loading, pagination: { total } } = useLearningPathUsersQuery({
    learningPathId
  });
  return (
    <MemberCount>
      {loading ? '' : total}
      {' '}Students
    </MemberCount>
  )
};

const MemberCount = styled.div`
  word-break: keep-all;
`;

export { LearningPathStudentCount };