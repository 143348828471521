import React from 'react';
import { PageStatus } from '/components/status/PageStatus';

const QueryError = ({ message, refetch = () => { } }) => (
  <PageStatus header="An error occurred">
    {message || `Something has gone wrong.`}
    <br />
    Please either{' '}
    <span onClick={() => refetch()}>try again</span> or
    contact <a href="mailto:support@acloud.guru">support</a> for help.
  </PageStatus>
);

export { QueryError };
