import React from 'react';
import moment from 'moment';

import { Module } from '/components/module/Module';
import { LoadingState } from '/components/chart/LoadingState';
import { useCachedState } from '/hooks/use-cached-state';
import { RecentEngagementChart } from './RecentEngagementChart';
import { RecentEngagementModuleHeader } from './RecentEngagementModuleHeader';
import gql from 'graphql-tag';
import get from 'lodash.get';
import { Query, QueryBoundary } from '/components/data';

const FILTER_KEY_PREFIX = 'teams-course-engagement-report';

const today = moment();

const RecentEngagementModule = ({ organisationId }) => {
  const [type, setType] = useCachedState('WEEK', `${FILTER_KEY_PREFIX}-type`);

  const [ranges, setRange] = useCachedState(
    [today.clone().subtract(12, 'weeks'), today],
    `${FILTER_KEY_PREFIX}-ranges`,
    (ranges) => [moment(ranges[0]), moment(ranges[1])]
  );

  const renderLoading = ({ ranges, type, setRange, setType }) => (
    <Module>
      <RecentEngagementModuleHeader
        ranges={ranges}
        type={type}
        setRange={setRange}
        setType={setType}
      />
      <LoadingState height={400} />
    </Module>
  );

  const renderModule = ({
    data,
    summary,
    ranges,
    type,
    setRange,
    setType,
    organisationId,
  }) => {
    return (
      <Module>
        <RecentEngagementModuleHeader
          ranges={ranges}
          type={type}
          setRange={setRange}
          setType={setType}
        />
        <RecentEngagementChart
          data={data}
          summary={summary}
          type={type}
          organisationId={organisationId}
        />
      </Module>
    );
  };

  return (
    <OrganisationCourseEngagementQuery
      organisationId={organisationId}
      from={ranges[0].format('YYYY-MM-DD')}
      to={ranges[1].format('YYYY-MM-DD')}
      interval={type}
      renderLoading={() => <LoadingState height={350} />}
    >
      {({ data, summary }) =>
        renderModule({
          data,
          summary,
          ranges,
          type,
          setRange,
          setType,
          organisationId,
        })
      }
    </OrganisationCourseEngagementQuery>
  );
};

export { RecentEngagementModule };

const ORGANISATION_COURSE_ENGAGEMENT_QUERY = (attributes) => gql`
  query ACG_organisationCourseEngagement (
    $from: String!,
    $to: String!,
    $courseId: String,
    $groupId: String,
    $period: Period!
    $organisationId: String!
  ){
    ACG_organisationCourseEngagementReport (
      organisationId: $organisationId,
      filters: {
        courseId: $courseId,
        groupId: $groupId,
        period: $period,
        dateRange: {
          from: $from,
          to: $to
        }
      }
    ) ${attributes[0]}
    ACG_organisationCourseEngagementSummaryReport (
      organisationId: $organisationId,
      filters: {
        courseId: $courseId,
        groupId: $groupId,
        dateRange: {
          from: $from,
          to: $to
        }
      }
    ) ${attributes[1]}
  }
`;

const ORGANISATION_USER_JOINED_QUERY = gql`
  query ACG_organisationUserEngagementReport(
    $from: String!
    $to: String!
    $interval: DatePeriodInterval!
    $organisationId: String!
  ) {
    ACG_organisationUserEngagementReport(
      organisationId: $organisationId
      filters: { from: $from, to: $to, interval: $interval }
    ) {
      id
      startDate
      endDate
      joinedCount
    }
  }
`;

const ORGANISATION_GROUP_USER_JOINED_QUERY = (attributes) => gql`
  query ACG_organisationGroupUserJoinedEngagement (
    $from: String!,
    $to: String!,
    $groupId: String!,
    $interval: DatePeriodInterval!
    $organisationId: String!
  ){
    ACG_organisationGroupUserJoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        groupId: $groupId,
        interval: $interval
      }
    ) ${attributes[0]}
    ACG_organisationGroupUserLeftStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        groupId: $groupId,
        interval: $interval
      }
    ) ${attributes[1]}
    ACG_organisationGroupUserRejoinedStats (
      organisationId: $organisationId,
      input: {
        from: $from,
        to: $to,
        groupId: $groupId,
        interval: $interval
      }
    ) ${attributes[2]}
  }
`;
const mergeResponse = (engagement = {}, userJoined = {}, isGroupQuery) => {
  let engagementStats = engagement['ACG_organisationCourseEngagementReport'];
  engagementStats = engagementStats || [];
  if (!isGroupQuery) {
    const userJoinedStats = userJoined['ACG_organisationUserEngagementReport'];
    return engagementStats.map((stat, index) => {
      return {
        ...stat,
        joinedCount: get(userJoinedStats, `${index}.joinedCount`),
      };
    });
  }

  const userJoinedStats = get(userJoined, 'organisationGroupUserJoinedStats');
  const userLeftStats = get(userJoined, 'organisationGroupUserLeftStats');
  const userRejoinedStats = get(
    userJoined,
    'organisationGroupUserRejoinedStats'
  );

  return engagementStats.map((stat, index) => {
    const userJoinedCount =
      get(userJoinedStats, `${index}.cumulativeJoinedCount`) || 0;
    const userLeftCount =
      get(userLeftStats, `${index}.cumulativeLeftCount`) || 0;
    const userRejoinedCount =
      get(userRejoinedStats, `${index}.cumulativeRejoinedCount`) || 0;

    return {
      ...stat,
      joinedCount: userJoinedCount - userLeftCount + userRejoinedCount,
    };
  });
};

const OrganisationCourseEngagementQuery = ({
  from,
  to,
  interval,
  courseId,
  groupId,
  organisationId,
  attributes = [
    `{
      startDate
      endDate
      activeUsersCount
      completedComponentsCount
      completedComponentsPerActiveUser
    }`,
    `{
      activeUsersCount
      completedComponentsCount
      completedComponentsPerActiveUser
      totalContentDurationMinutes
    }`,
    `{
      startDate
      endDate
      joinedCount
      cumulativeJoinedCount
    }`,
    `{
      startDate
      endDate
      leftCount
      cumulativeLeftCount
    }`,
    `{
      startDate
      endDate
      rejoinedCount
      cumulativeRejoinedCount
    }`,
  ],
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  const [
    engagementAttributes,
    engagementSummaryAttributes,
    ...userJoinedAttributes
  ] = attributes;

  return (
    <Query
      query={ORGANISATION_COURSE_ENGAGEMENT_QUERY([
        engagementAttributes,
        engagementSummaryAttributes,
      ])}
      variables={{
        organisationId,
        from,
        to,
        period: interval,
        groupId,
        courseId,
      }}
      {...restProps}
    >
      {(props) => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data: engagement, ...rest }) => {
            return (
              <Query
                query={
                  groupId
                    ? ORGANISATION_GROUP_USER_JOINED_QUERY(userJoinedAttributes)
                    : ORGANISATION_USER_JOINED_QUERY
                }
                variables={{
                  organisationId,
                  from,
                  to,
                  groupId,
                  interval,
                }}
              >
                {({ data: userJoined }) => {
                  return children({
                    data: mergeResponse(engagement, userJoined, !!groupId),
                    summary: get(
                      engagement,
                      'ACG_organisationCourseEngagementSummaryReport'
                    ),
                    ...rest,
                  });
                }}
              </Query>
            );
          }}
        </QueryBoundary>
      )}
    </Query>
  );
};
