import React from 'react';
import { Icon, Dropdown } from 'antd';
import styled from 'styled-components';
import { DropdownMenu } from '../dropdown/DropdownMenu';
import { DropdownMenuItem } from '../dropdown/DropdownMenuItem';
import { colour } from '../styles/variables';

class PresetFilter extends React.Component {
  state = {
    label: 'Select a range'
  }

  handleRangeChange = ({ key }) => {
    const { ranges, setRange } = this.props;
    const [startDate, endDate] = ranges[key];

    this.setState({ label: key });
    setRange(startDate, endDate);
  }

  render() {
    const { label } = this.state;
    const { ranges } = this.props;

    return (
      <Dropdown
        trigger={["click"]}
        placement="bottomCenter"
        overlay={
          <StyledMenu onClick={this.handleRangeChange}>
            {Object.keys(ranges).map(rangeKey => {
              return (
                <DropdownMenuItem key={rangeKey}>{rangeKey}</DropdownMenuItem>
              )
            })}
          </StyledMenu>
        }
      >
        <Filter>{label} <CaretIcon type="caret-down" /></Filter>
      </Dropdown>
    );
  }
}

const Filter = styled.div`
  display: flex;
  align-items: center;
  width: 252px;
  font-weight: 600;
  cursor: pointer;
`;

const CaretIcon = styled(Icon)`
  position: relative;
  margin: 2px 0 0 4px;
  color: ${colour.gray500};
  align-self: center;
`;

const StyledMenu = styled(DropdownMenu)`
  left: -12px;
`

export { PresetFilter };