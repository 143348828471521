import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import { colour } from '@a-cloud-guru/themes';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const { confirm } = Modal;

const REMOVE_USER_FROM_ORGANISATON_MUTATION= gql`
  mutation removeUserFromOrganisation($input: ACG_RemoveFromOrganisationInput) {
    ACG_removeFromOrganisation(input: $input) {
      userIdentity {
        id
        organisationId
      }
    }
  }
`;

const RemoveUser = ({ userId, organisationId, clearCache }) => {
  const [removeUserFromOrganisation, { data }] = useMutation(REMOVE_USER_FROM_ORGANISATON_MUTATION);
  const processed = !!data;
  const showConfirm = () => {
    confirm({
      title: 'Do you want to remove this user?',
      content: 'This user will be removed from current organisation.',
      onOk: async () => {
        await removeUserFromOrganisation({
          variables: {
            input: {
              userId,
              organisationId
            }
          }
        });
        clearCache();
      }
    })
  }

  if (processed) {
    return <DisabledText>Removed</DisabledText>
  }

  return (
    <span onClick={showConfirm}>
      Remove User
    </span>
  )
}

const DisabledText = styled.span`
  color: ${colour.grey400};
  cursor: default;
`;

export {
  RemoveUser
};
