import React from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import { Icon, Button, Tooltip} from 'antd';
import { SelfServiceBillingInvoicesQuery } from '/data/query';
import { DateFormat } from '/components/date/DateFormat';
import { StyledTable } from '/components/table/StyledTable';
import { Header, StyledCard, StyledDivider } from "./Shared";

const formatCurrency = (amount, currency) => {
  const numFormat = { style: 'currency', currency }
  const formatter = new Intl.NumberFormat('en-US', numFormat)
  return formatter.format(amount * 0.01)
}

const SelfServiceBillingInvoices = organisation => {
  let refetchData;
  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>Billing Invoices</StyledTitle>
        <Tooltip title="Refresh Billing Invoices">
          <StyledRefreshButton icon="reload" onClick={() => {
            if (refetchData) {
              refetchData();
            }
          }}/>
        </Tooltip>
      </StyledHeader>

      <StyledDivider />

      <SelfServiceBillingInvoicesQuery
        organisationId={get(organisation, 'id')}
        notifyOnNetworkStatusChange={true}
      >
        {
          ({ data, refetch })=> {
            refetchData = refetch;
            return (
              <ReStyledTable
                rowKey="id"
                pagination={data.length > 10 ? { pageSize: 10 } : false}
                scroll={{ y: 460 }}
                columns={[
                  {
                    align: 'left',
                    width: 150,
                    title: 'Invoice ID',
                    dataIndex: 'id',
                    key: 'id'
                  },
                  {
                    align: 'left',
                    width: 200,
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: date => <DateFormat format='D MMM YYYY, hh:mm A'>{date}</DateFormat>
                  },
                  {
                    align: 'left',
                    width: 200,
                    title: 'Amount Paid',
                    dataIndex: 'total',
                    key: 'total',
                    render: (total, { currencyCode }) => (
                      <span>
                        {formatCurrency(total, currencyCode)} {currencyCode}
                      </span>
                    )
                  },
                  {
                    align: 'left',
                    width: 100,
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: status => (
                      <span style={{ textTransform: 'capitalize', color: 'green' }}>
                        {status}
                      </span>
                    )
                  },
                  {
                    align: 'left',
                    width: 200,
                    title: '',
                    dataIndex: 'downloadUrl',
                    key: 'downloadUrl',
                    render: downloadUrl => (
                      <>
                        <Icon type='file-pdf' style={{ marginRight: '5px' }} />
                        <a href={downloadUrl}>Download Invoice</a>
                      </>
                    )
                  }
                ]}
                dataSource={data}
              />
            );
          }
        }
      </SelfServiceBillingInvoicesQuery>
    </StyledCard>
  )
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

const StyledRefreshButton = styled(Button)`
  min-width: auto;
`;

const ReStyledTable = styled(StyledTable)`
  &&& {
    .ant-table-header {
      overflow-y: auto !important;
    }
    .ant-table-body {
      min-height: ${props => props.dataSource.length > 10 ? '460px' : '0px'};
      overflow-y: auto !important;
    }
    .ant-pagination {
      padding: 16px 10px;
      margin: 0px;
      border-top: 1px solid rgba(0,0,0,0.12);
      width: 100%;
    }
  }
`;

export {
  SelfServiceBillingInvoices
};
