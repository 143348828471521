import React, { useState } from 'react';
import get from 'lodash.get';
import styled from 'styled-components';

import { DisabledWhenReadOnly } from '/components/auth/DisabledWhenReadOnly';
import { Box } from '/components/box';
import { DateFormat } from '/components/date/DateFormat';
import { useOrganisationUsersQuery } from '/data/query-hooks';

import { useApiSettingsQuery } from './use-api-settings-query';
import {
  StyledCard, StyledDivider,
  DetailsRow, Header
} from './Shared';
import { ModifyAccountDetails } from './modify-account-details/ModifyAccountDetails';
import { Button, Tooltip } from 'antd';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from '/components/container';
import { useSplitTreatment } from 'splitio-react/lib/hooks/use-split-treatment';

const AccountDetailsCard = (organisation) => {
  const setResellerFlag = useSplitTreatment('BERNIE_SET_RESELLERS');
  const canSetResellerFlag = setResellerFlag === 'on';
  const organisationId = get(organisation, 'id');
  const refetchOrgData = get(organisation, 'refetch');
  const orgLoading     = get(organisation, 'loading');
  const [key, changeKey] = useState(0);

  const { data = [], loading: usersLoading } = useOrganisationUsersQuery({
    page:1,
    pageSize: 100,
    adminOnly: true,
    key: key
  });

  const { apiSettings, lastCreatedKey, refetch: refetchApiSettings, loading: apiLoading } = useApiSettingsQuery({ organisationId });

  const lastSeenAdminTimestamp = data
    .filter(user => user.organisation_admin && user.last_seen_timestamp)
    .map(user => user.last_seen_timestamp)
    .reduce((current, next) => Math.max(current, next), '');

  return (
    <StyledCard>
      <StyledHeader>
        <StyledTitle>
          Account Details
        </StyledTitle>
        <DisabledWhenReadOnly>
          <ModifyAccountDetails organisation={organisation} />
        </DisabledWhenReadOnly>
        <Tooltip title="Refresh Organization Details">
          <StyledRefreshButton icon="reload" onClick={() => {
            if (refetchOrgData) {
              refetchOrgData();
              changeKey(key + 1);
              refetchApiSettings();
            }
          }}/>
        </Tooltip>
      </StyledHeader>
      <StyledDivider />
      {
        (orgLoading || usersLoading || apiLoading) ?
        <Container><Spinner /></Container> :
        <Box py="6px">
          <DetailsRow label='Name'>
            {
              get(organisation, 'name')
            }
          </DetailsRow>
          <DetailsRow label='Account Type'>
            {
              get(organisation, 'accountType')
            }
          </DetailsRow>
          <DetailsRow label='Created At'>
            {
              get(organisation, 'createdAt')
                ? <DateFormat format='D MMM YYYY, hh:mm A'>{get(organisation, 'createdAt')}</DateFormat>
                : 'n/a'
            }
          </DetailsRow>
          <DetailsRow label='Website'>
            {
              get(organisation, 'website')
            }
          </DetailsRow>
          <DetailsRow label='Last Admin Login'>
          {
            organisationId && (
              <DateFormat format='D MMM YYYY, hh:mm A'>
                {lastSeenAdminTimestamp}
              </DateFormat>
            )
          }
          </DetailsRow>
          <DetailsRow label='Org ID'>
            {organisationId}
          </DetailsRow>
          <DetailsRow label='Archived'>
            {
              get(organisation, 'archivedAt') ? 'Yes' : 'No'
            }
          </DetailsRow>

          <DetailsRow label='API Keys'>
            {
              get(apiSettings, 'length') > 0 ? (
                <>
                  {apiSettings.length} Keys
                  <br />
                  Last key created <DateFormat format='D MMM YYYY, hh:mm A'>{lastCreatedKey}</DateFormat>
                </>
              ) : '-'
            }
          </DetailsRow>
          {canSetResellerFlag && <DetailsRow label='Will distribute seats'>
            {
              get(organisation, 'isReseller') ? 'Yes' : 'No'
            }
          </DetailsRow>}
        </Box>
      }
    </StyledCard>
  );
};

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.span`
  margin-right: auto;
`;

const StyledRefreshButton = styled(Button)`
  min-width: auto;
  margin-left: 10px;
`;

export {
  AccountDetailsCard
};
