import { colour } from '@a-cloud-guru/themes';
import { Button } from 'antd';
import styled from 'styled-components';

const FilterDropdownButton = styled(Button)`
  position: relative;
  left: -8px;
  color: ${colour.grey900};
  border: 1px solid #fff;
  padding: 4px 6px;
  height: inherit;
  line-height: 1;
  font-weight: 600;

  :focus,
  :hover {
    border: 1px solid ${colour.brandBlue};
  }
`;

export { FilterDropdownButton };
