import { Tabs } from 'antd';
import get from 'lodash.get';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { CourseProgressTable } from './CourseProgressReport/CourseProgressTable';
import { ActivityReport } from './ActivityReport';
import { CertificateActivityReport } from './CertificateActivityReport';
import { LessonEngagementReport } from './LessonEngagementReport';
import { LegacyTransactions } from './LegacyTransactions';
import { RecentEngagement } from './OnBoardingReports/RecentEngagement';
import { CourseEngagement } from './CourseEngagementReport/CourseEngagement';
import { DashboardReport } from './DashboardReport';

import { colour } from '/styles/variables';

const { TabPane } = Tabs;

const DASHBOARD_REPORT_KEY = 'dashboard-report';
const ONBOARDING_REPORT_KEY = 'onboarding-report';
const COURSE_ENGAGEMENT_REPORT = 'course-engagement-report';
const COURSE_PROGRESS_REPORT_KEY = 'course-popularity';
const LEGACY_TRANSACTIONS_KEY = 'legacy-transactions';
const LEGACY_TOP_UPS_KEY = 'legacy-top-ups';
const ACTIVITY_REPORT_KEY = 'user-activity';
const CERTIFICATE_ACTIVITY_REPORT_KEY = 'user-certificate-activity';
const LESSON_ENGAGEMENT_KEY = 'lesson-engagement';

const makeReports = ({ chargeModel, ...rest }) => {
  let tabs = {
    [DASHBOARD_REPORT_KEY]: {
      title: 'Dashboard',
      component: <DashboardReport {...rest} />,
    },
    [ONBOARDING_REPORT_KEY]: {
      title: 'Onboarding',
      component: <RecentEngagement {...rest} />,
    },
    [COURSE_ENGAGEMENT_REPORT]: {
      title: 'Course Engagement',
      component: <CourseEngagement {...rest} />,
    },
    [ACTIVITY_REPORT_KEY]: {
      title: 'User Activity',
      component: <ActivityReport {...rest} />,
    },
    [CERTIFICATE_ACTIVITY_REPORT_KEY]: {
      title: 'Certifications',
      component: <CertificateActivityReport {...rest} />,
    },
    [COURSE_PROGRESS_REPORT_KEY]: {
      title: 'Course Popularity',
      component: <CourseProgressTable {...rest} />,
    },
    [LESSON_ENGAGEMENT_KEY]: {
      title: 'Lesson Engagement',
      component: <LessonEngagementReport {...rest} />,
    }
  };

  return tabs;
};

export const OrganisationReports = (props) => {
  const option = get(props, 'option') || DASHBOARD_REPORT_KEY;
  const reports = makeReports(props);

  return (
    <PageContentContainer>
      <ReportsNavigation activeKey={option} onChange={props.setSubroute}>
        {Object.keys(reports).map((key) => {
          const { title, component, hidden } = reports[key];

          if (hidden) {
            return null;
          }

          return (
            <TabPane tab={title} key={key}>
              {option === key && component}
            </TabPane>
          );
        })}
      </ReportsNavigation>
    </PageContentContainer>
  );
};

const ReportsNavigation = styled(Tabs)`
  && {
    .ant-tabs-bar {
      margin: 0;
      border-bottom: 1px solid ${rgba('#000', 0.2)};
    }

    .ant-tabs-nav {
      color: ${rgba('#000', 0.4)};
    }

    .ant-tabs-tab:hover {
      color: ${colour.gray900};
    }

    .ant-tabs-tab-active {
      color: ${colour.gray900};
    }

    .ant-tabs-ink-bar {
      background-color: ${colour.blueLight};
    }
  }
`;

const PageContentContainer = styled.div`
  margin-top: -64px;
  background-color: #fff;
`;
