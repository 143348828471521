import React from 'react';
import { Row, Col } from 'antd';
import { CertificationsGraph } from '../CertificateActivityReport/CertificationsGraph';
import { CertificationSummaryPanel } from '../CertificateActivityReport/CertificationSummaryPanel';
import { RecentEngagementModule } from './recent-engagement';
import { InvitationStats } from './invitation-stats';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { PopularCourses } from './popular-courses/PopularCourses';
import { CourseStatsSummary } from './course-stats/CourseStatsSummary';
import Algolia from '/lib/algolia';
import { LatestUsers } from './latest-users/LatestUsers';

const DashboardReport = ({ organisationId, organisation }) => {
  return (
    <Algolia organisationId={organisationId}>
      <Row
        gutter={16}
        style={{ backgroundColor: colours.lightGrey300, paddingTop: '16px' }}
      >
        <Col xs={24} xl={18}>
          <div style={{ backgroundColor: colours.white }}>
            <CourseStatsSummary organisationId={organisationId} />
          </div>
          <InvitationStats organisation={organisation} />

          <RecentEngagementModule organisationId={organisationId} />
          <div style={{ backgroundColor: colours.white }}>
            <CertificationsGraph organisationId={organisationId} />
            <CertificationSummaryPanel
              organisationId={organisationId}
              location="dashboard"
            />
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <PopularCourses organisationId={organisationId} />
          <LatestUsers organisationId={organisationId} />
        </Col>
      </Row>
    </Algolia>
  );
};
export { DashboardReport };
