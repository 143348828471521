import { DropdownMenuItem } from './DropdownMenuItem';
import styled from 'styled-components';
import { colour } from '../styles/variables';

const DropdownCategoryMenuItem = styled(DropdownMenuItem)`
  background-color: ${colour.gray300};
  font-weight: 600;
  pointer-events: none;
  padding-left: 12px;
`;

export { DropdownCategoryMenuItem };