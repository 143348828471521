import React from 'react';
import { Button } from '@a-cloud-guru/kermit';
import styled from 'styled-components';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const USER_IDENTITY_QUERY = gql`
  query userIdentity {
    userIdentity {
      id
      roles {
        superadmin
      }
    }
  }
`

const PermissionButton = ({
  onClick
}) => {
  const { data } = useQuery(USER_IDENTITY_QUERY);

  const superAdmin = get(data, 'userIdentity.roles.superadmin');

  if (!superAdmin) {
    return null;
  }

  return (
    <StyledButton type="primary" onClick={onClick} icon="unlock">
      Permissions
    </StyledButton>
  )
};

const StyledButton = styled(Button)`
  height: 36px;
  width: 150px;
  font-weight: 600;
`;
export { PermissionButton };