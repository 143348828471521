import React from 'react';

class KeyboardNavigation extends React.Component {
  static defaultProps = {
    options: [],
    onSelect: () => {}
  }

  state = {
    selectedIndex: -1
  }

  componentDidMount() {
    document.addEventListener('keydown', this.bindKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.bindKeyPress);
  }

  onTabPressed = (e) => {
    e.preventDefault();
    this.selectItemOnIndex();
  }

  onEnterPressed = (e) => {
    e.preventDefault();
    this.selectItemOnIndex();
  }

  onUpPressed = (e) => {
    e.preventDefault();
    const { selectedIndex } = this.state;
    if (selectedIndex === 0) return;
    this.setState({ selectedIndex: selectedIndex - 1 });
  }

  onDownPressed = (e) => {
    e.preventDefault();
    const { selectedIndex } = this.state;
    if (selectedIndex === this.props.options.length - 1) return;
    this.setState({ selectedIndex: selectedIndex + 1 });
  }

  bindKeyPress = (e) => {
    const keyListeners = {
      9: this.onTabPressed,
      13: this.onEnterPressed,
      38: this.onUpPressed,
      40: this.onDownPressed
    };

    if (e.which && Object.keys(keyListeners).includes(e.which.toString())) {
      keyListeners[e.which](e);
    }
  }

  selectItemOnIndex = () => {
    const { selectedIndex } = this.state;
    const { options, onSelect } = this.props;

    const index = selectedIndex === -1 ? 0 : selectedIndex;
    if (options.length > 0) {
      const { value } = options[index];
      onSelect(value);
    }
  }

  render() {
    return this.props.children({
      selectedIndex: this.state.selectedIndex
    });
  }
};

export { KeyboardNavigation };