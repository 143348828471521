import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover, Button, Checkbox } from 'antd';
import { Flex } from '/components/box';

const CheckboxGroup = Checkbox.Group;

const ToggleColumnsButton = ({
  columns,
  allColumns,
  setColumns
}) => {
  const [visible, setVisible] = useState(false);

  const content = (
    <Flex width={250}>
      <StyledCheckboxGroup
        options={allColumns.map(column => ({ label: column, value: column, disabled: column === 'Organization' }))}
        defaultValue={columns}
        onChange={setColumns}
      />
    </Flex>
  )
  return (
    <Popover
      placement="bottom"
      content={content}
      title="Show/Hide Columns"
      trigger="click"
      visible={visible}
      onVisibleChange={visible => setVisible(visible)}
    >
      <Button icon="bars" type="primary" style={{ width: '100%' }}>
        Show/Hide Columns
      </Button>
    </Popover>
  )
};

const StyledCheckboxGroup = styled(CheckboxGroup)`
  .ant-checkbox-group-item {
    width: 100%
  }
`;

export { ToggleColumnsButton };