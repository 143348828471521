import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { formatDate } from '/utils/date';
import { jsonToCsvDownload } from '/lib/json-to-csv';

const currentDate = new Date().valueOf();

class ExportButton extends React.PureComponent {
  state = { loading: false };

  static defaultProps = {
    label: 'Export CSV',
    loadingLabel: 'Exporting',
    fileName: `invitations-${new Date().toISOString()}.csv`,
    onExport: () => Promise.resolve(),
    defaults: {}
  }

  getInvitationStatus = ({ acceptedAt, validUntil }) => {
    if (acceptedAt) {
      return 'Joined'
    }

    if (currentDate < validUntil) {
      return 'Pending';
    }

    return 'Expired';
  };


  makeMapper = () => (data) => data.map(
    ({ email, invitation_code, sent_at, accepted_at, valid_until, invite_as_admin }) => ({
      'Email Address': email,
      'Invitation Code': invitation_code,
      'Invitation Sent': formatDate({ date: sent_at, dateFormat: 'D MMM YYYY hh:mm A' }),
      'Status': this.getInvitationStatus({ acceptedAt: accepted_at, validUntil: valid_until }),
      'Admin': invite_as_admin ? 'Yes' : 'No'
    })
  );

  render() {
    const { label, loadingLabel, onExport, fileName, defaults } = this.props;
    const mapper = this.makeMapper({ defaults });

    return (
      <StyledButton
        loading={this.state.loading}
        type="primary"
        onClick={async () => {
          this.setState({ loading: true });

          const data = await onExport();

          jsonToCsvDownload({
            fileName,
            data,
            mapper
          });

          this.setState({ loading: false });
        }}
      >
        {this.state.loading ? loadingLabel : label}
      </StyledButton>
    )
  }
};

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export { ExportButton };