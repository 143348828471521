import React from 'react';
import { get, set, max } from 'lodash';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { LoadingState } from '/components/chart/LoadingState';
import {
  containerTemplate,
  tooltipTemplate,
} from './tooltip/signup-progress-tooltip';
import { Chart, Geom, Axis, Tooltip, Legend, Guide } from 'bizcharts';

const handleTooltipChange = (ev) => {
  const items = get(ev, 'items') || [];

  if (items.length === 0) return;

  const { count: invitedCountOffset = 0 } = get(items, `0.point._origin`) || {};
  const { count: joinedCount = 0 } = get(items, `1.point._origin`) || {};

  set(items, `0.value`, (invitedCountOffset + joinedCount).toString());
};

const getScale = (licensePurchased, maxCount) => {
  const threshold = 3;
  const maxYAxis = Math.ceil(max([licensePurchased, maxCount]) * 1.1);

  return {
    count: {
      max: maxYAxis,
      tickInterval: maxYAxis <= threshold ? 1 : undefined,
    },
  };
};

const getMaxCount = (data) => {
  const lastInvited = get(data, 'origin')[0];
  const lastJoined = get(data, 'origin')[1];

  const lastInvitedOffsetKeys = Object.keys(lastInvited).filter(
    (key) => key !== 'name'
  );

  const lastInvitedOffsetKey =
    lastInvitedOffsetKeys[lastInvitedOffsetKeys.length - 1];
  const lastJoinedKeys = Object.keys(lastJoined).filter(
    (key) => key !== 'name'
  );
  const lastJoinedKey = lastJoinedKeys[lastJoinedKeys.length - 1];
  return (
    get(lastInvited, lastInvitedOffsetKey, 0) +
    get(lastJoined, lastJoinedKey, 0)
  );
};

const SignupProgressChart = ({ data, licensePurchased, height, loading }) => {
  if (loading) {
    return <LoadingState height={height} />;
  }

  const maxCount = getMaxCount(data);
  const { Line } = Guide;

  return (
    <Chart
      height={height}
      data={data}
      autoFit
      padding={[48, 60, 50, 'auto']}
      scale={getScale(licensePurchased, maxCount)}
      onTooltipChange={handleTooltipChange}
    >
      <Legend clickable position="top-left" offsetX={5} offsetY={-15} />
      <Axis name="date" />
      <Axis
        name="count"
        label={{
          formatter: (text, item, index) => {
            return index === 0 ? '' : text;
          },
        }}
      />
      <Tooltip
        crosshairs={{
          type: 'y',
        }}
        itemTpl={tooltipTemplate}
        containerTpl={containerTemplate}
      />
      <Geom
        type="interval"
        position="date*count"
        color={['date', [`${colours.lightGrey500}`, `${colours.blue}`]]}
        style={{
          stroke: '#fff',
          lineWidth: 1,
          marginLeft: '10px',
        }}
      />
      <Guide>
        <Line
          top
          start={{
            date: 'min',
            count: licensePurchased,
          }}
          end={{
            date: 'max',
            count: licensePurchased,
          }}
          text={{
            content: 'licenses purchased',
            position: 'start',
            autoRotate: false,
            offsetY: -5,
            style: {
              fill: colours.yellow,
              textAlign: 'start',
            },
          }}
          lineStyle={{
            stroke: colours.yellow,
            lineWidth: 3,
            lineDash: [8, 3],
          }}
        />
      </Guide>
    </Chart>
  );
};

export { SignupProgressChart };
