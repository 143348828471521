import { colour } from '@a-cloud-guru/themes';
import React from 'react';

const BenchmarkIndicator = ({ size = 6, benchmark, value }) => {
  const color = value >= benchmark ? colour.brandInfo : colour.brandDanger;

  const height = size * 2;
  const width = size * 2;

  return <svg height={height} width={width}>
    <circle fill={color} cx={size} cy={size} r={size}></circle>
  </svg>;
};

export default BenchmarkIndicator;
