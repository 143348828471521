import React from 'react';
import styled from 'styled-components';
import { Flex } from '@a-cloud-guru/kermit';

const LevelAchieved = ({ level, title }) => (
  <Flex>
    {level > 0 && <Level>Level {level}</Level>}
    {title}
  </Flex>
);

const Level = styled.div`
  font-weight: 700;
  width: 58px;
`;

export { LevelAchieved };
