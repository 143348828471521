import React from 'react';
import { Box, Flexbox } from '@a-cloud-guru/rainbow-ui';
import { CourseStatsTile } from './CourseStatsTile';

const CourseStatsSummary = ({ organisationId }) => {
  return (
    <Flexbox flexDirection={{ _: 'column', xl: 'row' }}>
      <Box width={{ _: 1, xl: 1 / 3 }}>
        <CourseStatsTile
          title="lesson completed"
          type="lessonsCompleted"
          organisationId={organisationId}
        />
      </Box>

      <Box width={{ _: 1, xl: 1 / 3 }} ml={{ _: 0, xl: 'lg' }}>
        <CourseStatsTile
          title="average lesson completed"
          type="averageLessons"
          organisationId={organisationId}
        />
      </Box>

      <Box width={{ _: 1, xl: 1 / 3 }} ml={{ _: 0, xl: 'lg' }}>
        <CourseStatsTile
          title="minutes watched"
          type="minutesWatched"
          organisationId={organisationId}
        />
      </Box>
    </Flexbox>
  );
};

export { CourseStatsSummary };
