import { Menu } from 'antd';
import styled from 'styled-components';
import { boxShadow } from '/styles/variables';

const DropdownMenu = styled(Menu)`
  border: solid 1px #bfc6d8;
  border-radius: 3px;
  box-shadow: ${boxShadow.standard};
  background-color: #ffffff;
  padding: 0;
  width: ${props => props.width || 'auto'}

  .ant-menu-root.ant-menu-vertical {
    box-shadow: ${boxShadow.standard};
  }
`;

export { DropdownMenu };

