import React, { useState } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useAlgoliaSearch } from 'react-algolia';
import { useQuery } from '@apollo/react-hooks';
import { StyledTable } from '/components/table/StyledTable';
import { Flex, Box } from '/components/box';
import { SearchBox } from '/components/search/SearchBox';
import { getConfig } from '/config/get-config';
import { colour } from '/styles/variables';
import { ToggleUserPermission } from './ToggleUserPermission';

const { ALL_ORGANISATION_USERS_INDEX } = getConfig();

const COLUMNS = [
  {
    title: 'User',
    dataIndex: 'displayname',
    width: '25%'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '25%'
  },
  {
    title: 'Permission',
    render: ({ write_access }) => {
      return write_access ? (
        <Box color={colour.green}>Write</Box>
      ) : (
        <Box>Read</Box>
      )
    },
    width: '25%'
  },
  {
    title: 'Action',
    render: ({ user_id, write_access }) => {
      return <ToggleUserPermission userId={user_id} writeAccess={write_access} />
    },
    width: '25%'
  }
];

const generateQuery = userIds => {
  const queryString = userIds.reduce((query, userId, index) => {
    return `${query}
      ACG_organisationSupportRolesForUser${index}: ACG_organisationSupportRolesForUser(userId: $userId${index}) {
      id
      organisationWriteAccess
    }
    `
  }, '');
  const variables = userIds.map((userId, index) => `$userId${index}: String!`).join(', ')
  return gql`query ACG_organisationSupportRolesForUser(${variables}) {
    ${queryString}
  }`;
};

const getUserPermissionMap = data => {
  if (!data) {
    return {};
  }

  return Object.keys(data).reduce((accu, key) => {
    return {
      ...accu,
      [get(data, `${key}.id`)]: get(data, `${key}.organisationWriteAccess`) || false
    }
  }, {});
};

const UserPermissionTable = ({ userIds = [] }) => {
  const gqlQuery = generateQuery(userIds);
  const [query, setQuery] = useState('');
  const { data, loading: gqlLoading } = useQuery(gqlQuery, {
    variables: userIds.reduce((accu, userId, index) => {
      return {
        ...accu,
        [`userId${index}`]: userId
      }
    }, {})
  });
  const { searchResults, loading } = useAlgoliaSearch({
    indexName: ALL_ORGANISATION_USERS_INDEX,
    query,
    hitsPerPage: 1000
  });
  const userPermissionMap = getUserPermissionMap(data);
  const users = (get(searchResults, 'hits', []))
    .filter(({ objectID }) => userIds.includes(objectID))
    .map(user => ({
      ...user,
      write_access: get(userPermissionMap, `${user.objectID}`)
    }));

  return (
    <Box mt="-64px">
      <StyledTable
        loading={loading || gqlLoading}
        rowKey={({ objectID }) => objectID}
        dataSource={users}
        columns={COLUMNS}
        title={() => (
          <Flex px="16px" justifyContent="space-between" flexDirection="row-reverse">
            <SearchBox
              placeholder="Search Users"
              width="272px"
              onChange={e => setQuery(e.target.value)}
            />
          </Flex>
        )}
      />
    </Box>
  )
};

export { UserPermissionTable };