import { useOrganisationUsersQuery } from '/data/query-hooks';

const OrganisationUsersQuery = ({
  query = '',
  page = 1,
  pageSize = 10,
  adminOnly,
  children
}) => {
  const {
    data,
    pagination: {
      total
    },
    ...restProps
  } = useOrganisationUsersQuery({
    query,
    page,
    pageSize,
    adminOnly
  });
  return children({
    data,
    pagination: { total, page, pageSize },
    ...restProps
  });
}

export { OrganisationUsersQuery };