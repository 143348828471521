import moment from 'moment';

const makeLocalStorage = (configs = { ttl: 60 * 60 * 24 * 7 }) => {
  const { ttl } = configs;
  return {
    get: (key) => {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return undefined;
      }
      const item = JSON.parse(itemStr);
      if (moment(item.expireAt).isBefore(moment())) {
        localStorage.removeItem(key);
        return undefined;
      }

      return item.data;
    },
    set: (key, data) => {

      localStorage.setItem(key, JSON.stringify({
        data,
        expireAt: moment().add(ttl, 'second')
      }))
    }
  }
};

export { makeLocalStorage };