import gql from 'graphql-tag';

export const GET_ACG_SUBSCRIBER_PACKAGE = gql`
  query ACG_Packaging_SubscriberPackage(
    $input: ACG_Packaging_SubscriberPackageInput!
  ) {
    ACG_Packaging_subscriberPackage(input: $input) {
      subscriberPackage {
        ... on ACG_Packaging_SubscriberPackage {
          packageId
          packageName
          features
          overrides
        }
        ... on ACG_Packaging_SubscriberPackageNotFound {
          subscriberId
          message
        }

        ... on ACG_Packaging_SubscriberPackageNotFound {
          subscriberId
          message
        }
      }
    }
  }
`;

export const CREATE_OR_UPDATE_OVERRIDE = gql`
  mutation ACG_Packaging_AddSubscriptionFeatureOverride(
    $input: ACG_Packaging_AddSubscriptionFeatureOverrideInput!
  ) {
    ACG_Packaging_addSubscriptionFeatureOverride(input: $input) {
      subscriptionFeatureOverride {
        ... on ACG_Packaging_SubscriptionFeatureOverride {
          featureId
          subscriberId
          boolValue
          numberValue
        }
        ... on ACG_Packaging_FeatureDoesNotExistError {
          featureIdNotFound
          message
        }
        ... on ACG_Packaging_SubscriberHasOverrideError {
          subscriberIdHasOverride
          message
        }
      }
    }
  }
`;

export const GET_ALL_PACKAGES = gql`
  query ACG_Packaging_packages {
    ACG_Packaging_packages {
      packages {
        id
        name
        displayName
        description
        baseId
        isPrivate
      }
    }
  }
`;

export const GET_PACKAGES_FEATURES = gql`
  query ACG_Packaging_packagesFeatures {
    ACG_Packaging_packagesFeatures {
      packagesFeatures {
        packageId
        packageName
        features
      }
    }
  }
`;

export const GET_ALL_FEATURES = gql`
  query ACG_Packaging_features {
    ACG_Packaging_features {
      features {
        id
        name
        displayName
        valueType
      }
    }
  }
`;

export const MUTATE_ORG_PACKAGE = gql`
  mutation ACG_Packaging_UpdateOrganisationPackage(
    $input: ACG_Packaging_UpdateOrganisationPackageInput!
  ) {
    ACG_Packaging_updateOrganisationPackage(input: $input) {
      subscriberPackage {
        ... on ACG_Packaging_SubscriberPackage {
          packageId
          packageName
          features
          overrides
        }
        ... on ACG_Packaging_PackageDoesNotExistError {
          packageIdNotFound
          message
        }
      }
    }
  }
`;

export const MUTATE_ORG_FEATURE_OVERRIDES = gql`
  mutation ACG_Packaging_UpdateOrganisationFeatureOverrides(
    $input: ACG_Packaging_UpdateOrganisationFeatureOverridesInput!
  ) {
    ACG_Packaging_updateOrganisationFeatureOverrides(input: $input) {
      organisationOverrides {
        ... on ACG_Packaging_OrganisationFeatureOverrides {
          organisationId
          overrides {
            featureId
            value
          }
        }
        ... on ACG_Packaging_EmptyOrganisationIdError {
          message
        }
        ... on ACG_Packaging_FeaturesNotFound {
          featuresIds
          message
        }
      }
    }
  }
`;

export const GET_ORG_AUDIT_TRAIL = gql`
  query ACG_Packaging_OrganisationAuditTrail(
    $input: ACG_Packaging_OrganisationAuditTrailInput!
  ) {
    ACG_Packaging_organisationAuditTrail(input: $input) {
      organisationFeatureAccessAudit {
        ... on ACG_Packaging_OrganisationAuditTrail {
          id
          organisationId
          action
          createdAt
          packageId
          featureId
          previousPackageId
          actionedBy
          actionedAt
        }
      }
    }
  }
`;

export const GET_GLOBAL_AUDIT_TRAIL = gql`
  query ACG_Packaging_GlobalAuditTrail {
    ACG_Packaging_globalAuditTrail {
      globalAuditTrail {
        ... on ACG_Packaging_GlobalAuditTrail {
          id
          action
          createdAt
          type
          displayName
          packageId
          featureId
          actionedBy
          actionedAt
        }
      }
    }
  }
`;
