const INITIAL_STATE = {
  query: '',
  page: 1,
  sorter: undefined,
  roleFilter: 'user',
  statusFilter: 'all',
  queryKey: 0
};

const ACTION_TYPES = {
  UPDATE_STATE: 'UPDATE_STATE',
  RELOAD: 'RELOAD',
  UPDATE_QUERY: 'UPDATE_QUERY',
  UPDATE_FILTER: 'UPDATE_FILTER'
};

const reducer = (state, { type, payload }) => {
  switch(type) {
    case ACTION_TYPES.UPDATE_STATE:
      return {
        ...state,
        [payload.key]: payload.value
      };

    case ACTION_TYPES.RELOAD:
      return {
        ...INITIAL_STATE,
        queryKey: state.queryKey + 1
      };

    case ACTION_TYPES.UPDATE_QUERY:
      return {
        ...state,
        query: payload.query,
        page: 1
      };

    case ACTION_TYPES.UPDATE_FILTER:
      return {
        ...state,
        [payload.filter]: payload.value,
        page: 1,
        selectedUsers: []
      };

    default:
      return state;
  };
};

export {
  INITIAL_STATE,
  ACTION_TYPES,
  reducer
};
