import React from 'react';
import styled from 'styled-components';
import { colour } from '/styles/variables';

import { SearchBox } from '/components/data-entry/SearchBox';

const DropdownInput = (props) => (
  <NoBoarderSearchBox {...props} />
)

const NoBoarderSearchBox = styled(SearchBox)`
  color: ${colour.gray900};

  anticon anticon-search {
    color: ${colour.gray400};
  }

  .ant-input {
    border: none;
    box-shadow: none;

    :focus, :hover {
      border: none;
      box-shadow: none;
    }
  }
`;

export { DropdownInput };
