import React from 'react';
import { Box } from '@a-cloud-guru/kermit';
import { LearningPathsTable } from './LearningPathsTable';

const OrganisationLearningPaths = ({ organisationId }) => (
  <Box mt="-64px">
    <LearningPathsTable organisationId={organisationId} />
  </Box>
);

export { OrganisationLearningPaths };
