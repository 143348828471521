import React, { useState } from 'react';
import styled from 'styled-components';

import { StyledModal, FormContent, Title } from '/components/modal';
import { PrimaryButton } from '/components/button/PrimaryButton';
import { colour } from '/styles/variables';

import { UpdateSubscriptionForm } from './UpdateSubscriptionForm';

const UpdateSubscription = ({ isClickDisabled, organisation }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setVisible(!isClickDisabled)}>
        Update
      </PrimaryButton>
      <StyledModal
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="50%"
      >
        <FormContent>
          <TitleContainer>
            <Title>Update Subscription</Title>
          </TitleContainer>
          <UpdateSubscriptionForm
            closeModal={() => setVisible(false)}
            {...organisation}
          />
        </FormContent>
      </StyledModal>
    </>
  );
};

const TitleContainer = styled.div`
  padding: 18px 20px;
  border-bottom: solid 1px ${colour.gray300};
`;

export { UpdateSubscription };
