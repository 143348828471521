const Joi = require('joi');

const schema = Joi.object()
  .keys({
    AUTH0_DOMAIN: Joi.string().required(),
    AUTH0_CUSTOM_DOMAIN: Joi.string().required(),
    AUTH0_API_AUDIENCE: Joi.string().required(),
    AUTH0_PRIMARY_CLIENT_ID: Joi.string().required(),
    ACG_BERNIE_DOMAIN: Joi.string().required(),
    ACG_DOMAIN: Joi.string().required(),
    ACG_ENV: Joi.string(),
    ACG_ORGANISATION_ID: Joi.string().required(),
    PS_ORGANISATION_ID: Joi.string().required(),
    ALL_ORGANISATION_INDEX: Joi.string().required(),
    ALL_ORGANISATION_USERS_INDEX: Joi.string().required(),
    ALL_ORGANISATION_GROUPS_INDEX: Joi.string().required(),
    ALL_ORGANISATION_INVITATIONS_INDEX: Joi.string().required(),
    ALGOLIA_APP_ID: Joi.string().required(),
    ALGOLIA_STAGE: Joi.string().required(),
    AUTH0_CLIENT_ID: Joi.string().required(),
    GRAPHQL_API_ENDPOINT: Joi.string().required(),
    CLOUDINARY_ACCOUNT: Joi.string().required(),
    CLOUDINARY_BASE_URL: Joi.string().required(),
    BUGSNAG_APP_VERSION: Joi.string(),
    BUGSNAG_API_KEY: Joi.string(),
    BUGSNAG_RELEASE_STAGE: Joi.string(),
    BUGSNAG_MINIFIED_URL_PREFIX: Joi.string(),
    ORGANISATION_INVITATION_INDEX_PREFIX: Joi.string().required(),
    ORGANISATION_GROUP_INDEX_PREFIX: Joi.string().required(),
    COURSE_INDEX_NAME: Joi.string().required(),
    SEGMENT_API_KEY: Joi.string(),
    SPLIT_IO_API_KEY: Joi.string().required(),
    LAUNCH_DARKLY_CLIENT_SIDE_ID: Joi.string().required()
  })
  .required();

module.exports = { schema };
