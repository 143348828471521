import React, { Fragment } from 'react';
import { Table } from 'antd';
import { useQuery } from 'react-apollo';
import { GET_GLOBAL_AUDIT_TRAIL } from '../../../../data/query/PackageQuery';
import { PackageError } from './PackageError';
import get from 'lodash.get';
import { ActionedByColumn } from './ActionedByColumn';
import { DetailsColumn } from './DetailsColumn';

export const GlobalAuditTrail = () => {
  const { loading, data, error } = useQuery(GET_GLOBAL_AUDIT_TRAIL);

  const globalPackageChanges = get(
    data,
    'ACG_Packaging_globalAuditTrail.globalAuditTrail',
    []
  );

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '15%',
      render: (text) => {
        const formattedDate = new Date(text);
        return (
          <span>
            {formattedDate.toLocaleDateString()}{' '}
            {formattedDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'action',
      width: '15%',
    },
    {
      title: 'Package',
      dataIndex: 'packageId',
      width: '15%',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: '15%',
      render: (col, row) => {
        return <DetailsColumn row={row} />;
      },
    },
    {
      title: 'Actioned by',
      dataIndex: 'actionedBy',
      width: '15%',
      render: (actionedBy) => {
        const parsedActionedBy = actionedBy ? JSON.parse(actionedBy) : null;
        const userId = (parsedActionedBy && parsedActionedBy.id) || '';
        return <ActionedByColumn userId={userId} />;
      },
    },
  ];

  return (
    <Fragment>
      <h3>Global Package Changes</h3>
      <p>
        These are changes that are made across the entire platform and may not
        affect this users current package.
      </p>
      {error ? (
        <PackageError error={error} {...props} />
      ) : (
        <div>
          <Table
            dataSource={globalPackageChanges}
            columns={columns}
            rowKey="id"
            loading={loading}
            size="middle"
            scroll={{ y: 200 }}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
      )}
    </Fragment>
  );
};
