import { useEffect } from 'react';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const GET_ALGOLIA_API_KEY_AND_ORG_ID = gql`
  query algoliaApiKeyQuery($organisationIds: [String!]) {
    ACG_searchApiKey(organisationIds: $organisationIds) {
      id
      publicApiKey {
        apiKey
        validUntil
      }
      scopedApiKey {
        apiKey
        validUntil
      }
    }
  }
`;

const useAlgoliaApiKey = (organisationIds) => {
  const [execute, { data }] = useLazyQuery(GET_ALGOLIA_API_KEY_AND_ORG_ID, {
    variables: {
      organisationIds,
    },
  });

  useEffect(() => {
    execute();
  }, []);

  return {
    publicKey: get(data, 'ACG_searchApiKey.publicApiKey.apiKey'),
    scopedKey: get(data, 'ACG_searchApiKey.scopedApiKey.apiKey'),
  };
};

export { useAlgoliaApiKey };
