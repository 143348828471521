import get from 'lodash.get';
import React, { useEffect } from 'react';
import { Spinner, Flex, Box } from '@a-cloud-guru/kermit';
import { OrganisationFeatureFlagsQuery } from '/data/query';
import { FeatureFlagList } from './FeatureFlagList';

import {
  DetailsRowWithState,
  StyledCard,
  StyledDivider,
  Header
} from './Shared';

const settingMap = {
  restrictProfileEdit: {
    description: '[Used by: Accenture] - Disable profile editting'
  },
  restrictSocial: {
    description: '[Used by: Accenture] - Disable social login'
  }
};

const LoadingSpinner = () => (
  <Flex my='30px' justifyContent='center' alignItems='center'>
    <Spinner />
  </Flex>
);

const SettingCard = organisation => {
  const features = get(organisation, 'features') || {};
  const organisationId = get(organisation, 'id');
  const organisationName = get(organisation, 'name');
  const orgLoading = get(organisation, 'loading');
  const settings = Object.entries(features)
    .filter(([key]) => key !== '__typename')
    .filter(([key]) => settingMap[key])
    .map(([key, value]) => ({
      ...settingMap[key],
      name: key,
      value
    }));
  let refetchFeatures;

  useEffect(() => {
    if (refetchFeatures && !orgLoading) {
      refetchFeatures();
    }
  }, [orgLoading]);

  if (!organisationId || orgLoading) {
    return (
      <StyledCard>
        <Header>Settings</Header>
        <StyledDivider />
        <Box py="6px">
          <LoadingSpinner/>
        </Box>
      </StyledCard>
    );
  }

  return (
    <StyledCard>
      <Header>Settings</Header>
      <StyledDivider />
      <OrganisationFeatureFlagsQuery
        organisationId={organisationId}
        renderLoading={() => <LoadingSpinner />}
      >
        {({ data, refetch }) =>  {
          refetchFeatures = refetch;
          return (
            <Box py="6px">
              {settings.map(props => (
                <DetailsRowWithState key={props.name} {...props} />
              ))}
              <FeatureFlagList
                featureFlags={data}
                organisationId={organisationId}
                organisationName={organisationName}
              />
            </Box>
          );
        }}
        </OrganisationFeatureFlagsQuery>
    </StyledCard>
  );
};

export { SettingCard };
