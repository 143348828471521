import React, { useState } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { Switch } from "antd";
import { GET_ORGANISATION_FEATURE_FLAGS } from '/data/query';
import { ToggleFeatureFlagsModal } from "./ToggleFeatureFlagsModal";

const TOGGLE_FEATURE_FLAGS_MUTATION = functionName => gql`
  mutation toggleFeatureFlag($organisationId: String!, $featureFlagName: String!){
    ${functionName}(organisationId: $organisationId, featureFlagName: $featureFlagName)
  }
`;

const ToggleFeatureFlags = ({ 
  organisationId, 
  featureFlagName, 
  enabled 
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  
  const onCancel = () => {
    setModalVisible(false);
  };

  const toggleFeatureFlagsMutation = enabled
    ? TOGGLE_FEATURE_FLAGS_MUTATION("ACG_disableFeatureFlag")
    : TOGGLE_FEATURE_FLAGS_MUTATION("ACG_enableFeatureFlag");

  return (
    <Mutation 
      mutation={toggleFeatureFlagsMutation}
      refetchQueries={[{ 
        query: GET_ORGANISATION_FEATURE_FLAGS,
        variables: { 
          organisationId 
        }
      }]}
    >
      {(toggleFeatureFlags, { loading }) => {
        return (
          <>
            <Switch size="small" checked={enabled} onChange={() => showModal()} />
            <ToggleFeatureFlagsModal
              loading={loading}
              onCancel={onCancel}
              visible={modalVisible}
              featureFlagName={featureFlagName}
              enabled={enabled}
              mutationCallback={() =>
                toggleFeatureFlags({
                  variables: {
                    organisationId,
                    featureFlagName
                  }
                })
              }
            />
          </>
        );
      }}
    </Mutation>
  );
};

export { ToggleFeatureFlags };


