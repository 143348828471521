import gql from 'graphql-tag';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import { QueryBoundary } from '/components/data/QueryBoundary';

const GET_ORGANISATION_FEATURE_FLAGS = gql`
  query getOrganisationFeatureFlags($organisationId: String!, $tag: String) {
    ACG_featureFlags(organisationId: $organisationId, tag: $tag) {
      id
      name
      description
      enabled
    }
  }
`;

const OrganisationFeatureFlagsQuery = ({
  organisationId,
  renderLoading,
  renderError,
  children,
  ...restProps
}) => {
  return (
    <Query
      query={GET_ORGANISATION_FEATURE_FLAGS}
      fetchPolicy={'network-only'}
      variables={{
        organisationId,
        tag: 'bernie_visible'
      }}
      {...restProps}
    >
      {props => (
        <QueryBoundary
          {...props}
          renderLoading={renderLoading}
          renderError={renderError}
        >
          {({ data, ...rest }) => {
            return children({
              data: get(data, 'ACG_featureFlags') || [],
              ...rest
            });
          }}
        </QueryBoundary>
      )}
    </Query>
  );
};

export { OrganisationFeatureFlagsQuery, GET_ORGANISATION_FEATURE_FLAGS };
