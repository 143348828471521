import React from 'react';
import styled from 'styled-components';
import { Table } from '@a-cloud-guru/kermit';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { OrganisationUserQuizAttemptStatsQuery } from '/data/query';

const columns = [
  {
    title: 'Chapter',
    dataIndex: 'sectionTitle',
    render: (sectionTitle, { sectionSequence }) => {
      if (isNaN(sectionSequence)) {
        return sectionTitle;
      }
      return `${sectionSequence + 1} - ${sectionTitle}`
    }
  },
  {
    title: 'Quiz',
    dataIndex: 'quizTitle'
  },
  {
    title: '# of Students',
    dataIndex: 'numberOfUsers'
  },
  {
    title: '# Attempts',
    dataIndex: 'numberOfAttempts',
  },
  {
    title: 'Avg Best Score',
    dataIndex: 'averageBestScore',
    render: score => `${score}%`
  },
  {
    title: 'Avg Score',
    dataIndex: 'averageScore',
    render: score => `${score}%`
  }
];

const UserQuizAttemptStatsTable = ({ organisationId, courseId}) => (
  <StyledModule>
    <ModuleHeader header="Quiz Result Summary" />
    <OrganisationUserQuizAttemptStatsQuery
      organisationId={organisationId}
      courseId={courseId}
    >
      {({ data }) => {
        return (
          <Table
            rowKey={({ quizId }) => quizId}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        )
      }}
    </OrganisationUserQuizAttemptStatsQuery>
  </StyledModule>
);

const StyledModule = styled(Module)`
  margin: 25px 0 0 0;
`

export { UserQuizAttemptStatsTable };