import React, { useState } from 'react';

import { StyledModal, Title, TitleContainer } from '/components/modal';
import { LinkButton } from '/components/button/LinkButton';
import { OrganisationAuditTrail } from './OrganisationAuditTrail';
import { GlobalAuditTrail } from './GlobalAuditTrail';

export const PackageChangeModal = ({ organisationId }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <LinkButton
        style={{ textAlign: 'left', padding: '0' }}
        onClick={() => setVisible(true)}
      >
        View package changes
      </LinkButton>
      <StyledModal
        destroyOnClose
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        width="90%"
      >
        <TitleContainer>
          <Title>Package Audit Trail</Title>
        </TitleContainer>
        <div style={{ padding: '2em' }}>
          <OrganisationAuditTrail organisationId={organisationId} />
          <div style={{ padding: '10px 0px' }}></div>
          <GlobalAuditTrail />
        </div>
      </StyledModal>
    </>
  );
};
