import React from 'react';
import moment from 'moment';
import { Spinner, colour } from '@a-cloud-guru/kermit';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useCachedState } from '/hooks/use-cached-state';
import { OrganisationCourseEngagementQuery } from '/data/query';
import { Flex, Box } from '/components/box';
import { StyledTable } from '/components/table/StyledTable';
import { Module } from '/components/module/Module';
import { ModuleHeader } from '/components/module/ModuleHeader';
import { jsonToCsvDownload } from '/lib/json-to-csv';
import { LessonEngagementChart } from './LessonEngagementChart';
import { UserQuizAttemptStatsTable } from './QuizReport/UserQuizAttemptStatsTable';
import { UserQuizAttemptsTable } from './QuizReport/UserQuizAttemptsTable';
import { CourseDropdown } from './CourseDropdown';
import { IntervalButtonGroup } from './IntervalTypeButton';
import { EngageRangePicker } from './EngageRangePicker';
import { DownloadButton } from './DownloadButton';
import { generateDataSource } from './transform-lesson-engagement-table-data';
import { transform } from './transform-lesson-engagement-chart-data';

const FILTER_KEY_PREFIX = "ernie-engagement-filter-key";

const today = moment();

const configs = {
  week: {
    format: (date) => format(date, 'DD MMM')
  },
  month: {
    format: (date) => format(date, 'YYYY MMM')
  }
};

const Percentage_Events = ['% Active Users'];

const download = (dataSource) => jsonToCsvDownload({
  data: dataSource,
  mapper: data => data.map(item => {
    return Object.keys(item).reduce((current, nextProp) => {
      if (nextProp === 'event') {
        return { ...current, 'Event': item[nextProp] };
      }

      return { ...current, [format(nextProp, 'YYYY-MM-DD')]: item[nextProp] };
    }, {})
  })
});

const generateColumns = (engagementStats, type) => {
  const eventColumn = {
    title: 'As at end of',
    dataIndex: 'event',
    width: 280,
    fixed: 'left',
    render: (event) => <strong>{event}</strong>
  };

  const dates = engagementStats.map(({ endDate }) => endDate);
  const columns = dates.reduce((columns, date, index) => {
    return [...columns, {
      title: configs[type.toLowerCase()].format(date),
      dataIndex: date,
      width: 150,
      render: (value, item) => {
        if (Percentage_Events.includes(item.event)) {
          return value === 0 ? '-' : `${value}%`
        }
        return value === 0 ? '-' : value
      }
    }]
  }, [eventColumn])
  return columns
}

let chart;

const LessonEngagementReport = ({ organisationId }) => {
  const [courseId, setCourseId] = useCachedState(undefined, `${FILTER_KEY_PREFIX}-courseId`);
  const [ranges, setRanges] = useCachedState([today.clone().subtract(6, 'months'), today], `${FILTER_KEY_PREFIX}-ranges`, (ranges) => {
    return [moment(ranges[0]), moment(ranges[1])];
  });
  const [type, setType] = useCachedState('MONTH', `${FILTER_KEY_PREFIX}-type`);

  return (
    <React.Fragment>
      <NoMarginModule>
        <ModuleHeader
          header="LESSON ENGAGEMENT"
        >
          <Flex justifyContent="flex-end">
            <Flex ml="10px">
              <CourseDropdown
                courseId={courseId}
                selectCourse={setCourseId}
              />
            </Flex>
            <Box ml="10px">
              <IntervalButtonGroup
                value={type}
                onChange={setType}
              />
            </Box>
            <Box ml="10px">
              <EngageRangePicker
                value={ranges}
                onRangeChange={setRanges}
                onTypeChange={setType}
              />
            </Box>
          </Flex>
        </ModuleHeader>
        <OrganisationCourseEngagementQuery
          organisationId={organisationId}
          courseId={courseId}
          from={ranges[0].format('YYYY-MM-DD')}
          to={ranges[1].format('YYYY-MM-DD')}
          interval={type}
          renderLoading={() => (
            <Flex justifyContent="center" alignItems="center" height="400px">
              <Spinner size="3rem" />
            </Flex>
          )}
        >
          {({ data, loading }) => {
            const columns = generateColumns(data, type);
            const dataSource = generateDataSource(data);
            const chartData = transform({ dataSource, type })
            const scrollX = 280 + (Object.keys(dataSource[0]).length - 1) * 150;

            return (
              <React.Fragment>
                <LessonEngagementChart
                  data={chartData}
                  onLoad={(chartInstance) => chart = chartInstance}
                />
                <EngagementTable
                  rowKey="event"
                  pagination={false}
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: scrollX }}
                  title={() => (
                    <Flex justifyContent="flex-end" pr="20px" pb="5px">
                      <DownloadButton
                        downloadTable={() => dataSource && download(dataSource)}
                        downloadChart={() => chart && chart.downloadImage()}
                      />
                    </Flex>
                  )}
                  loading={{
                    indicator: <Spinner size="2rem" />,
                    spinning: loading
                  }}
                />
              </React.Fragment>
            )
          }}
        </OrganisationCourseEngagementQuery>
      </NoMarginModule>
      { courseId && (
        <React.Fragment>
          <UserQuizAttemptStatsTable organisationId={organisationId} courseId={courseId} />
          <UserQuizAttemptsTable organisationId={organisationId} courseId={courseId} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
};

const EngagementTable = styled(StyledTable)`
  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td {
    background: ${colour.gray100};
  }
`;

const NoMarginModule = styled(Module)`
  margin: 0;

  .kermit-date-range-picker {
    right: 10px;
    z-index: 1049;
  }
`;

const QuizModule = styled(Module)`
  margin-top: 15px;
  margin-bottom: 0px;
`;
export { LessonEngagementReport };