import React, { useState, Fragment } from 'react';
import { Table, Button, Tooltip, Input } from 'antd';
import { useQuery } from 'react-apollo';
import { GET_ORG_AUDIT_TRAIL } from '../../../../data/query/PackageQuery';
import { PackageError } from './PackageError';
import get from 'lodash.get';
import { ActionedByColumn } from './ActionedByColumn';
import { DetailsColumn } from './DetailsColumn';

const { TextArea } = Input;

export const OrganisationAuditTrail = ({ organisationId }) => {
  const [editing, setEditing] = useState([]);

  const { loading, data, error } = useQuery(GET_ORG_AUDIT_TRAIL, {
    variables: { input: { organisationId } },
    skip: !organisationId,
  });

  const orgPackageChanges = get(
    data,
    'ACG_Packaging_organisationAuditTrail.organisationFeatureAccessAudit',
    []
  );

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      width: '15%',
      render: (text) => {
        const formattedDate = new Date(text);
        return (
          <span>
            {formattedDate.toLocaleDateString()}{' '}
            {formattedDate.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'action',
      width: '15%',
    },
    {
      title: 'Package',
      dataIndex: 'packageId',
      width: '15%',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: '15%',
      render: (col, row) => {
        return <DetailsColumn row={row} />;
      },
    },
    {
      title: 'Actioned by',
      dataIndex: 'actionedBy',
      width: '15%',
      render: (actionedBy) => {
        const parsedActionedBy = actionedBy ? JSON.parse(actionedBy) : null;
        const userId = (parsedActionedBy && parsedActionedBy.id) || '';
        return <ActionedByColumn userId={userId} />;
      },
    },
    /**
     * Notes are out of scope for initial release
     * Will be released in the future
     * 
         {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => {
        const notes = text ? <span>{text}</span> : null;
        if (editing.includes(record.id)) {
          return (
            <Fragment>
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <TextArea defaultValue={text} />
                <Button
                  type="primary"
                  style={{ marginLeft: '1rem', width: '10px' }}
                  onClick={() =>
                    setEditing((editingRow) =>
                      editing.filter((row) => row !== record.id)
                    )
                  }
                >
                  Save
                </Button>
              </span>
            </Fragment>
          );
        }
        return (
          <Fragment>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {notes}
              <Tooltip title="Edit">
                <Button
                  type="outline"
                  icon="edit"
                  style={{
                    marginLeft: '1em',
                    minWidth: '1em',
                    background: 'none',
                    boxShadow: 'none',
                  }}
                  onClick={() =>
                    setEditing((editingRows) => [...editingRows, record.id])
                  }
                />
              </Tooltip>
            </span>
          </Fragment>
        );
      },
    },
     */
  ];

  return (
    <Fragment>
      <h3>Organisation Package Changes</h3>
      {error ? (
        <PackageError error={error} {...props} />
      ) : (
        <div>
          <Table
            dataSource={orgPackageChanges}
            columns={columns}
            rowKey="id"
            loading={loading}
            size="middle"
            scroll={{ y: 200 }}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
      )}
    </Fragment>
  );
};
