import { Divider } from 'antd';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { colour } from '/styles/variables';

const ModuleHeader = ({
  header,
  subheader,
  showDivider = true,
  children,
  style,
}) => (
  <>
    <Container style={style}>
      <div>
        <Header>{header}</Header>
        {subheader && <Subheader>{subheader}</Subheader>}
      </div>
      {children}
    </Container>
    {showDivider && <StyledDivider />}
  </>
);

const Container = styled.div`
  display: flex;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;

const Header = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: ${colour.gray900};
  text-transform: uppercase;
`;

const Subheader = styled.div`
  font-size: 13px;
  padding: 2px 0;
  line-height: 1.4;
  color: ${lighten(0.1, colour.gray600)};
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

export { ModuleHeader };
