import React from 'react';
import styled from 'styled-components';

import Box from '../box';
import { colour } from '../styles/variables';

const Stat = ({ value, description, loading }) => (
  <Box mr="40px">
    <Value loading={loading}>{value}</Value>
    <Description>{description}</Description>
  </Box>
);

const Value = styled.div`
  color: ${({ loading }) => loading ? colour.gray300 : colour.gray900};
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
`;

const Description = styled.div`
  font-size: 13px;
  padding: 2px 0;
  line-height: 1.4;
  color: ${colour.gray500};
`;

export default Stat;
