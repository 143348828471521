import { Button } from 'antd';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colour } from '/styles/variables';

export const CTAButton = styled(Button)`
  background-color: ${colour.white};
  width: ${props => props.width || 'auto'}; 
  height: ${props => props.height || 'auto'};
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  border-color: ${colour.spindle};
  border-radius: 99px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px 0 ${rgba(colour.midnightBlue, 0.15)};
  box-sizing: border-box;
  color: ${colour.bunting};
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  line-height: 20.5px;
  outline-color: ${colour.bunting};
  padding: 5px 17px 
  position: relative;
`;

