import React from 'react';
import { GET_USER_PROFILE_QUERY } from '../../../../data/query/UsersQuery';
import { useQuery } from 'react-apollo';
import get from 'lodash.get';

export const ActionedByColumn = ({ userId }) => {
  const { loading, data, error } = useQuery(GET_USER_PROFILE_QUERY, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  const actionedBy = get(data, 'getUserProfile.displayname', '');
  return <span>{actionedBy}</span>;
};
