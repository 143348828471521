import { Table } from 'antd';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { colour } from '/styles/variables';

const StyledTable = styled(Table)`
  background-color: #fff;
  box-shadow: 0 12px 24px 0 ${rgba(colour.blueDark, 0.06)};
  flex: 1;

  tr > th {
    font-weight: 600;
    color: ${colour.gray900};
    background-color: #fff;
    ${props => props.title && css`
      border-top: 1px solid #e8e8e8;
    `}
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: #fff;
  }

  tr > td {
    padding: 12px 16px;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  tr:hover > td {
    background-color: ${colour.hover};
  }

  .ant-table table {
    cursor: pointer;
  }

  .ant-table-body {
    width: 100%;
    overflow-x: auto;
  }

  em {
    font-style: normal;
    background-color: #fff9a4;
  }

  .ant-table-content {
    border-radius: 0;
  }

  .ant-pagination {
    float: left;
    padding: 0 10px;
  }
`;

export { StyledTable };
