import React from 'react';
import { Dropdown } from 'antd';
import { DropdownButton, DropdownMenu, DropdownMenuItem, DropdownCaretIcon } from '/components/dropdown';

const DownloadButton = ({
  downloadTable,
  downloadChart
}) => (
  <Dropdown
    placement="bottomRight"
    overlay={(
      <DropdownMenu width="150px" onClick={({ key }) => {
        if (key === 'chart') {
          downloadChart();
        }
        if (key === 'table') {
          downloadTable();
        }
      }}>
        <DropdownMenuItem key="chart">Chart</DropdownMenuItem>
        <DropdownMenuItem key="table">Table</DropdownMenuItem>
      </DropdownMenu>
    )}
  >
    <DropdownButton>
      Download <DropdownCaretIcon type="caret-down" />
    </DropdownButton>
  </Dropdown>
);

export { DownloadButton };