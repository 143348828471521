import React from 'react';
import { Radio } from 'antd';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

const IntervalRadioButton = ({
  setInterval,
  intervals = [30, 60, 90].map((value) => ({
    value,
    type: 'days',
  })),
  defaultValue = 30,
}) => {
  return (
    <div>
      <Radio.Group defaultValue={defaultValue} size="small" buttonStyle="solid">
        {intervals.map(({ value, type }, key) => (
          <StyledRadioButton
            key={key}
            value={value}
            onClick={() => {
              setInterval({
                value,
                type,
              });
            }}
          >
            {value} {type}
          </StyledRadioButton>
        ))}
      </Radio.Group>
    </div>
  );
};

const StyledRadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: ${colours.blue100};
    background: ${colours.blue100};
    color: ${colours.blue};
    box-shadow: -1px 0 0 0 ${colours.blue};

    &:hover {
      border-color: ${colours.blue100};
      background: ${colours.blue100};
      color: ${colours.blue};
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${colours.blue100} !important;
    opacity: 1;
    top: -1px;
    height: 110%;
  }
`;

export { IntervalRadioButton };
