import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Box from '../box'
import Flex from '../flex';
import { colour } from '../styles/variables';

const getIconType = (value) => {
  if (value > 0) {
    return 'caret-up';
  }

  if (value < 0) {
    return 'caret-down';
  }

  return 'minus';
};

const getIconColour = (value) => {
  if (value > 0) {
    return colour.green;
  }

  if (value < 0) {
    return colour.red;
  }

  return colour.gray400;
}

const DifferenceIndicator = ({
  value = 0,
  loading = false
}) => {
  if (loading) {
    return <StyledCaretIconContainer />
  }

  return (
    <StyledCaretIconContainer>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          position="relative"
          top={value === 0 ? '11px' : '4px'}
          fontWeight={1000}
        >
          <StyledCaretIcon
            type={getIconType(value)}
            colour={getIconColour(value)}
          />
        </Box>
        {value !== 0 && (
          <Box
            fontSize="11px"
            color={colour.gray500}
            fontWeight={600}
            lineHeight={1}
          >
            {Math.ceil(value)}%
          </Box>
        )}
      </Flex>
    </StyledCaretIconContainer>
  )
};

const StyledCaretIconContainer = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: ${colour.gray100};
  margin-right: 10px;
`;

const StyledCaretIcon = styled(Icon)`
  font-size: 23px;
  color: ${props => props.colour};
`;

export default DifferenceIndicator;
