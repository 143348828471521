const getValue = (field) => {
  try {
    return field.toString();
  } catch (e) {
    return '';
  }
};

const escapeSpecialChars = (value) => {
  return value.replace(/"/g, '""').replace(/(<([^>]+)>)/gi, ' ');
};

const escapeComma = (value) => {
  if (value.search(/("|,|\n)/g) >= 0) {
    return '"' + value + '"';
  }

  return value;
};

const jsonToCsvRow = (jsonObject) => {
  const dataArray = Object.keys(jsonObject).reduce((current, nextKey) => {
    const value = getValue(jsonObject[nextKey]);
    const result = escapeComma(escapeSpecialChars(value));
    return [...current, result];
  }, []);

  return dataArray.join(',') + '\r\n';
};

export const jsonToCsv = (arrayOfObjects) => {
  if (!arrayOfObjects.length) {
    return;
  }

  let csvBody = '';
  // headers
  csvBody += jsonToCsvRow(Object.keys(arrayOfObjects[0]));
  arrayOfObjects.forEach(function (item) {
    csvBody += jsonToCsvRow(item);
  });

  return csvBody;
};

export const jsonToCsvDownload = ({ data = [], mapper = data => data, fileName = 'export.csv' }) => {
  if (!document) { return; }

  const mappedData = mapper(data);
  const csvContent = jsonToCsv(mappedData);
  const contentType = 'text/csv';
  const csvFile = new Blob([csvContent], { type: contentType });

  const link = document.createElement('a');
  link.download = fileName;
  link.href = window.URL.createObjectURL(csvFile);
  link.dataset.downloadurl = [contentType, link.download, link.href].join(':');

  document.body.appendChild(link); // required for FireFox
  link.click();
  document.body.removeChild(link);
}
