import React from 'react';
import { Button } from 'antd';
import { FilterButton } from '/components/button/FilterButton';

const IntervalButtonGroup = ({
  value,
  onChange
}) => (
  <Button.Group>
    <FilterButton
      selected={value === 'WEEK'}
      onClick={() => onChange('WEEK')}
    >
      Week
    </FilterButton>
    <FilterButton
      selected={value === 'MONTH'}
      onClick={() => onChange('MONTH')}
    >
      Month
    </FilterButton>
  </Button.Group>
)

export { IntervalButtonGroup };